import {
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { City, DictFilterInput } from "shared/api/apollo/__generated__";

const getCities = createEvent<DictFilterInput>();

const getCitiesFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.searchCities({ input });
  return response.searchCities;
});

const $isLoading = getCitiesFx.pending;
const $cities = createStore<City[]>([]);
const $citiesCount = createStore<number>(0);

forward({
  from: getCities,
  to: getCitiesFx,
});

sample({
  clock: getCitiesFx.doneData,
  fn: (data) => data.nodes as City[],
  target: $cities,
});

sample({
  clock: getCitiesFx.doneData,
  fn: (data) => data.count,
  target: $citiesCount,
});

export const citiesModel = { $isLoading, $cities, $citiesCount, getCities };
