/* eslint-disable array-callback-return */
import React from "react";

import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";

import { SerializedCardTransaction } from "../config";

interface CardTransProps {
  cardTransaction: SerializedCardTransaction;
}

export const CardTransactionView: React.FC<CardTransProps> = ({
  cardTransaction,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {cardTransaction && (
        <Descriptions
          layout="horizontal"
          bordered
          size="small"
          column={1}
          key="cardTransactionCard"
          title={t("cardTransaction.infoCard")}
          style={{
            minWidth: "600px",
            maxWidth: "800px",
            marginBottom: "10px",
          }}
        >
          {cardTransaction &&
            Object.entries(cardTransaction).map((info) => {
              return (
                <Descriptions.Item
                  label={t(`cardTransaction.${info[0]}`)}
                  key={info[0]}
                >
                  {info[1] || "-"}
                </Descriptions.Item>
              );
            })}
        </Descriptions>
      )}
    </>
  );
};
