import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getRegions = createEvent<DictFilterInput>();

const getRegionsFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.regions({ input });
  return response.regions;
});

const $isLoading = getRegionsFx.pending;
const $regions = restore(getRegionsFx.doneData, []);

forward({
  from: getRegions,
  to: getRegionsFx,
});

export const regionsModel = { $isLoading, $regions, getRegions };
