import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateCityInput,
} from "shared/api/apollo/__generated__";

const updateCity = createEvent<UpdateCityInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteCity = createEvent<RemoveDictItemsInput>();

const deleteCityFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteCity({ input });
  return response.removeCities;
});

const updateCityFx = createEffect(async (input: UpdateCityInput) => {
  const response = await requestClient.updateCity({ input });
  return response.updateCity;
});

const $isLoading = combine(
  updateCityFx.pending,
  deleteCityFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: deleteCity,
  to: deleteCityFx,
});

forward({
  from: updateCity,
  to: updateCityFx,
});

sample({
  clock: updateCityFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateCityFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for city  with ID ${data.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: deleteCityFx.doneData,
  fn: () => (document.location = "/dictionary/cities"),
});

sample({
  clock: deleteCityFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateCityFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

export const updateCityModel = {
  $isChangeMode,
  $isLoading,
  updateCity,
  deleteCity,
  setIsChangeMode,
};
