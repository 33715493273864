import React from "react";

import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { createContinentModel } from "../model";
import { useNavigate } from "react-router-dom";

export const CreateContinentForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isLoading = useStore(createContinentModel.$isLoading);

  const [form] = useForm();

  const handleCreateContinentClick = (values: {
    name: string;
    code: string;
    tags: string;
  }) => {
    createContinentModel.createContinent({
      continent: {
        item: {
          name: values.name,
          code: values.code,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
          localizedNames: [],
        },
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/dictionary/continents");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createContinent"
          onFinish={handleCreateContinentClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item key="label">
            <span>{t("createContinent.description")}</span>
          </Form.Item>
          <Form.Item
            label={t("createContinent.continentName")}
            key="name"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input placeholder={t("createContinent.continentName")} />
          </Form.Item>
          <Form.Item
            label={t("createContinent.continentCode")}
            key="code"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="code"
          >
            <Input placeholder={t("createContinent.continentCode")} />
          </Form.Item>

          <Form.Item
            label={t("createContinent.tags")}
            key="tags"
            name="tags"
            tooltip={t("createContinent.tagsTooltip")}
          >
            <Input placeholder={t("createContinent.tags")} />
          </Form.Item>
          <Form.Item key="submitButton">
            <Button type="primary" htmlType="submit">
              {t("createContinent.createContinent")}
            </Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
