import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getCountries = createEvent<DictFilterInput>();

const getCountriesFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.getCountries({ input });
  return response.countries;
});

const $countries = restore(getCountriesFx.doneData, []);
const $isLoading = getCountriesFx.pending;

forward({
  from: getCountries,
  to: getCountriesFx,
});

export const countriesModel = { getCountries, $countries, $isLoading };
