import { CurrencyType, DictFilterInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: DictFilterInput = {};
  if (searchParams.get("id")) {
    if (searchParams.get("searchType") === "tickers") {
      params = {
        tickers: [searchParams.get("id") as string],
      };
    } else if (searchParams.get("searchType") === "tags") {
      params = {
        tags: [searchParams.get("id") as string],
      };
    } else if (searchParams.get("currencyType") === "fiat") {
      params = {
        type: CurrencyType.Fiat,
      };
    } else if (searchParams.get("currencyType") === "crypto") {
      params = {
        type: CurrencyType.Crypto,
      };
    }
  }

  return params;
};
