import { CountriesBlock } from "features/CountriesBlock/ui";
import React from "react";
import { AdminTemplate } from "shared/templates";

export const DictionaryCountriesPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CountriesBlock />
    </AdminTemplate>
  );
};
