import React from "react";

import { CreateBankForm } from "features";

import { AdminTemplate } from "shared/templates";

export const CreateNewBankPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateBankForm />
    </AdminTemplate>
  );
};
