import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  PaginationProps,
  Row,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Language } from "shared/api/apollo/__generated__";

import { DataType } from "../config";
import { checkSearchParams, serializeDataForTable } from "../lib";
import { languagesModel } from "../model";

export const LanguagesBlock: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = useForm();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType");

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  useEffect(() => {
    if (!searchParams.get("id") && !searchParams.get("searchType")) {
      languagesModel.getLanguages({});
    } else {
      languagesModel.getLanguages(checkSearchParams(searchParams));
      setIsSearchResult(true);
    }
  }, []);
  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "languageId");
      setSearchParams(searchParams);
    }
  }, []);

  const isLoading = useStore(languagesModel.$isLoading);
  const languagesList = useStore(languagesModel.$languages);

  const columns: ColumnsType<DataType> = [
    {
      title: t("languagesPage.languageName"),
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("languagesPage.languageId"),
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("languagesPage.languageDeleted"),
      dataIndex: "isDeleted",
      key: "isDeleted",
    },
    {
      title: t("languagesPage.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("languagesPage.updatedAt"),
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  const tableData = serializeDataForTable(languagesList as Language[]);

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleTableItemClick = (id: string) => {
    navigate(`/dictionary/languages/${id}`);
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    setSearchParams(searchParams);
    languagesModel?.getLanguages({});
    setIsSearchResult(false);
  };

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    languagesModel?.getLanguages(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleCreateLanguageClick = () => {
    navigate("/dictionary/languages/new/create");
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("languagesPage.searchById"),
          key: "languageId",
        },
        {
          label: t("languagesPage.searchByName"),
          key: "languageName",
        },
      ]}
    />
  );

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Row justify="space-between">
            <Form form={form} name="search" onFinish={handleSearchClick}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Button onClick={handleCreateLanguageClick}>
                    {t("languagesPage.createNewLanguage")}
                  </Button>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={dropdownItems}>
                    <Button>{t(`languagesPage.${searchType}`)}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Form.Item required name="id">
                    <Input
                      required
                      placeholder={t(`languagesPage.${searchType}`)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      {t("common.search")}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {isSearchResult && (
                    <Button
                      key="resetSearch"
                      onClick={handleResetSearchResultClick}
                      loading={isLoading}
                      type="primary"
                    >
                      {t("common.resetSearch")}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Row>
          <Table
            columns={columns}
            dataSource={tableData}
            onRow={(record) => {
              return {
                style: { cursor: "pointer" },
                onClick: () => handleTableItemClick(record?.id),
              };
            }}
            style={{ userSelect: "none" }}
            pagination={{
              position: ["bottomLeft"],
              showSizeChanger: false,
              onChange: handlePageClick,
              current: Number(currentSearchPage) || 1,
            }}
          />
        </>
      )}
    </>
  );
};
