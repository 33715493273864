import React from "react";
import { AdminTemplate } from "shared/templates";

export const UsersInfoPage: React.FC = () => {
  return (
    <AdminTemplate>
      <h1>Users info</h1>
    </AdminTemplate>
  );
};
