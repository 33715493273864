import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";

import { useStore } from "effector-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createCityModel } from "../model";

export const CreateCityForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = useForm();

  const isLoading = useStore(createCityModel.$isLoading);

  const handleCancelClick = () => {
    navigate("/dictionary/cities");
  };

  const handleCreateCityClick = (values: {
    name: string;
    code: string;
    countryId: string;
    regionId: string;
    tags: string;
  }) => {
    createCityModel.createCity({
      city: {
        countryId: values?.countryId,
        regionId: values?.regionId || null,
        item: {
          code: values?.code,
          localizedNames: [],
          name: values?.name,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
        },
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createCity"
          onFinish={handleCreateCityClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item key="label">
            <span>Create city</span>
          </Form.Item>
          <Form.Item
            label="City name"
            key="name"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input placeholder="City name" />
          </Form.Item>
          <Form.Item
            label="City code"
            key="code"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="code"
          >
            <Input placeholder="City code" />
          </Form.Item>
          <Form.Item
            label="City country ID"
            key="countryId"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="countryId"
          >
            <Input placeholder="City country ID" />
          </Form.Item>
          <Form.Item label="City region ID" key="regionId" name="regionId">
            <Input placeholder="City region ID" />
          </Form.Item>
          <Form.Item
            label="Tags"
            key="tags"
            name="tags"
            tooltip={t("createContinent.tagsTooltip")}
          >
            <Input placeholder="Tags" />
          </Form.Item>
          <Form.Item key="submitButton">
            <Button type="primary" htmlType="submit">
              {t("createContinent.createContinent")}
            </Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
