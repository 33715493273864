import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  PaginationProps,
  Row,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import { CryptoSearchTypeEnum } from "shared/config";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

import { cryptoBalancesModel } from "../model";
import { DataType } from "../config";
import { serializeData, checkSearchParams } from "../lib";

export const CryptoBalances: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType") || "address";

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "address");
      setSearchParams(searchParams);
    }
  }, []);

  const balances = useStore(cryptoBalancesModel.$cryptoBalances);
  const isAccountsOnLoad = useStore(cryptoBalancesModel?.$isLoading);
  const transactionsCount = useStore(cryptoBalancesModel?.$cryptoBalancesCount);

  const [form] = useForm();
  const { t } = useTranslation();

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  useEffect(() => {
    cryptoBalancesModel.getCryptoBalances(checkSearchParams(searchParams));
    if (searchParams.get("id")) {
      setIsSearchResult(true);
    }
  }, [currentSearchPage]);

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    cryptoBalancesModel?.getCryptoBalances(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    searchParams.set("searchType", "address");
    setSearchParams(searchParams);
    cryptoBalancesModel.getCryptoBalances(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("cryptoAccountsBlock.searchBy", {
            searchType: t(`cryptoAccountsBlock.address`),
          }),
          key: CryptoSearchTypeEnum.ADDRESS,
        },
        {
          label: t("cryptoAccountsBlock.searchBy", {
            searchType: t(`cryptoAccountsBlock.blockchains`),
          }),
          key: CryptoSearchTypeEnum.BLOCKCHAINS,
        },
        {
          label: t("cryptoAccountsBlock.searchBy", {
            searchType: t(`cryptoAccountsBlock.tokens`),
          }),
          key: CryptoSearchTypeEnum.TOKENS,
        },
        {
          label: t("cryptoAccountsBlock.searchBy", {
            searchType: t(`cryptoAccountsBlock.userId`),
          }),
          key: CryptoSearchTypeEnum.USER_ID,
        },
        {
          label: t("cryptoAccountsBlock.searchBy", {
            searchType: t(`cryptoAccountsBlock.balanceId`),
          }),
          key: CryptoSearchTypeEnum.BALANCE_ID,
        },
      ]}
    />
  );

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleTableItemClick = (id: string) => {
    navigate(`/balances/crypto/${id}`);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Balance coin",
      dataIndex: "coin",
      key: "coin",
      sorter: (a, b) => {
        if (a.coin > b.coin) {
          return -1;
        } else if (a.coin < b.coin) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => Number(a.balance) - Number(b.balance),
    },
    {
      title: "Holder ID",
      dataIndex: "holderId",
      key: "holderId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.holderId > b.holderId) {
          return -1;
        } else if (a.holderId < b.holderId) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (addressText) => (
        <Paragraph
          style={{ padding: 0, margin: 0 }}
          copyable={{ text: addressText }}
        >
          {addressText.length > 50
            ? `${addressText.slice(0, 5)}...${addressText.slice(
                -3,
                addressText.length
              )}`
            : addressText}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.address > b.address) {
          return -1;
        } else if (a.address < b.address) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Balance ID",
      dataIndex: "balanceId",
      key: "balanceId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.balanceId > b.balanceId) {
          return -1;
        } else if (a.balanceId < b.balanceId) {
          return 1;
        }
        return 0;
      },
    },
  ];

  return (
    <Col>
      <Row justify="space-between">
        <Form form={form} name="search" onFinish={handleSearchClick}>
          <Row>
            <Dropdown disabled={isAccountsOnLoad} overlay={dropdownItems}>
              <Button>{t(`cryptoAccountsBlock.${searchType}`)}</Button>
            </Dropdown>
            <Row>
              <Form.Item required name="id" style={{ marginLeft: "10px" }}>
                <Input
                  required
                  placeholder={t("cryptoAccountsBlock.searchBy", {
                    searchType: t(`cryptoAccountsBlock.${searchType}`),
                  })}
                />
              </Form.Item>
              <Form.Item style={{ marginLeft: "10px" }}>
                <Button
                  loading={isAccountsOnLoad}
                  disabled={isAccountsOnLoad}
                  htmlType="submit"
                  type="primary"
                >
                  {t("cryptoAccountsBlock.search")}
                </Button>
              </Form.Item>
            </Row>

            {isSearchResult && (
              <Button
                key="resetSearch"
                style={{ marginLeft: "10px" }}
                onClick={handleResetSearchResultClick}
                loading={isAccountsOnLoad}
                type="primary"
              >
                {t("cryptoAccountsBlock.resetSearch")}
              </Button>
            )}
          </Row>
        </Form>
      </Row>
      <Table
        style={{ marginBottom: "10px" }}
        onRow={(record) => {
          return {
            style: { cursor: "pointer" },
            onClick: () => handleTableItemClick(record?.balanceId),
          };
        }}
        loading={isAccountsOnLoad}
        pagination={false}
        columns={columns}
        dataSource={serializeData(balances)}
      />
      <Pagination
        disabled={isAccountsOnLoad}
        current={Number(currentSearchPage) ? Number(currentSearchPage) : 1}
        defaultCurrent={Number(currentSearchPage)}
        onChange={handlePageClick}
        total={transactionsCount}
        showSizeChanger={false}
      />
    </Col>
  );
};
