import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  PaginationProps,
  Row,
  Spin,
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";

import {
  GenderEnum,
  RoleEnum,
  ServiceEnum,
  StatusEnum,
} from "shared/api/apollo/__generated__";

import { DataType } from "../config";
import { checkSearchParams, serializeTableData } from "../lib";
import { usersModel } from "../model";

export const AllUsersBlock: React.FC = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = useForm();

  const navigate = useNavigate();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType");
  const gender = searchParams.get("gender");
  const role = searchParams.get("role");
  const service = searchParams.get("service");
  const status = searchParams.get("status");
  const id = searchParams.get("id");

  const allUsersList = useStore(usersModel.$allUsers);
  const usersCount = useStore(usersModel.$usersCount);
  const isLoading = useStore(usersModel.$isLoading);

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const [isShowAllFilters, setIsShowAllFilters] = useState<boolean>(false);

  useEffect(() => {
    if (!searchType) {
      searchParams.set("searchType", "userId");
      setSearchParams(searchParams);
    }
    if (id || status || service || role || gender) {
      setIsSearchResult(true);
    }
    usersModel.getAllUsers(checkSearchParams(searchParams));
  }, [currentSearchPage]);

  const tableData = serializeTableData(allUsersList);

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleTableItemClick = (id: string) => {
    navigate(`/all-users/${id}`);
  };

  const handleSearchClick = (values: { id: string }) => {
    if (values?.id) {
      searchParams.set("id", values?.id);
      searchParams.delete("page");
      setSearchParams(searchParams);
    }
    usersModel?.getAllUsers(checkSearchParams(searchParams));
    if (values?.id || gender || service || status || role) {
      setIsSearchResult(true);
    }

    form.resetFields();
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("gender");
    searchParams.delete("role");
    searchParams.delete("status");
    searchParams.delete("service");
    searchParams.delete("page");
    setSearchParams(searchParams);
    usersModel?.getAllUsers(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleGenderItemClick = (menuItem: ItemType) => {
    if (menuItem?.key === "delete") {
      searchParams.delete("gender");
      setSearchParams(searchParams);
    } else {
      searchParams.set("gender", menuItem?.key as string);
      setSearchParams(searchParams);
    }
  };

  const handleRoleItemClick = (menuItem: ItemType) => {
    if (menuItem?.key === "delete") {
      searchParams.delete("role");
      setSearchParams(searchParams);
    } else {
      searchParams.set("role", menuItem?.key as string);
      setSearchParams(searchParams);
    }
  };

  const handleServiceItemClick = (menuItem: ItemType) => {
    if (menuItem?.key === "delete") {
      searchParams.delete("service");
      setSearchParams(searchParams);
    } else {
      searchParams.set("service", menuItem?.key as string);
      setSearchParams(searchParams);
    }
  };

  const handleStatusItemClick = (menuItem: ItemType) => {
    if (menuItem?.key === "delete") {
      searchParams.delete("status");
      setSearchParams(searchParams);
    } else {
      searchParams.set("status", menuItem?.key as string);
      setSearchParams(searchParams);
    }
  };

  const handleShowAllFiltersClick = () => {
    setIsShowAllFilters((prev) => !prev);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("allUsers.searchById"),
          key: "userId",
        },
        {
          label: t("allUsers.searchByPhone"),
          key: "phoneNumber",
        },
        {
          label: t("allUsers.searchByEmail"),
          key: "userEmail",
        },
      ]}
    />
  );

  const genderDropdownItem = (
    <Menu
      onClick={handleGenderItemClick}
      items={[
        {
          label: t("allUsers.deleteGender"),
          key: "delete",
        },
        ...(Object.keys(GenderEnum) as Array<keyof typeof GenderEnum>).map(
          (key) => ({ label: key, key: key })
        ),
      ]}
    />
  );

  const rolesDropdownItem = (
    <Menu
      onClick={handleRoleItemClick}
      items={[
        {
          label: t("allUsers.deleteRole"),
          key: "delete",
        },
        ...(Object.keys(RoleEnum) as Array<keyof typeof RoleEnum>).map(
          (key) => ({ label: key, key: key })
        ),
      ]}
    />
  );

  const serviceDropdownItem = (
    <Menu
      onClick={handleServiceItemClick}
      items={[
        {
          label: t("allUsers.deleteService"),
          key: "delete",
        },
        ...(Object.keys(ServiceEnum) as Array<keyof typeof ServiceEnum>).map(
          (key) => ({ label: key, key: key })
        ),
      ]}
    />
  );

  const statusDropdownItem = (
    <Menu
      onClick={handleStatusItemClick}
      items={[
        {
          label: t("allUsers.deleteStatus"),
          key: "delete",
        },
        ...(Object.keys(StatusEnum) as Array<keyof typeof StatusEnum>).map(
          (key) => ({ label: key, key: key })
        ),
      ]}
    />
  );

  const columns: ColumnsType<DataType> = [
    {
      title: t("allUsers.userEmail"),
      dataIndex: "userEmail",
      key: "userEmail",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("allUsers.firstName"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("allUsers.lastName"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("allUsers.userTableId"),
      dataIndex: "userId",
      key: "userId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("allUsers.role"),
      dataIndex: "userRole",
      key: "userRole",
    },
    {
      title: t("allUsers.service"),
      dataIndex: "userService",
      key: "userService",
    },
    {
      title: t("allUsers.status"),
      dataIndex: "userStatus",
      key: "userStatus",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <p>
            {t("allUsers.totalCount")}: {usersCount}
          </p>
          <Form form={form} name="search" onFinish={handleSearchClick}>
            <Row gutter={[10, 10]}>
              <Col>
                <Dropdown disabled={isLoading} overlay={dropdownItems}>
                  <Button>{t(`allUsers.${searchType}`)}</Button>
                </Dropdown>
              </Col>
              <Col>
                <Form.Item style={{ marginBottom: "10px" }} required name="id">
                  <Input placeholder={t(`allUsers.${searchType}`)} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ marginBottom: "10px" }}>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    {t("common.search")}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  key="showSearchFilters"
                  onClick={handleShowAllFiltersClick}
                  loading={isLoading}
                  type="primary"
                >
                  {isShowAllFilters
                    ? t("allUsers.hideFilters")
                    : t("allUsers.showFilters")}
                </Button>
              </Col>
              <Col>
                {isSearchResult && (
                  <Button
                    key="resetSearch"
                    onClick={handleResetSearchResultClick}
                    loading={isLoading}
                    type="primary"
                  >
                    {t("common.resetSearch")}
                  </Button>
                )}
              </Col>
            </Row>
            {isShowAllFilters && (
              <Row style={{ marginBottom: "10px" }} gutter={[10, 10]}>
                <Col>
                  <Dropdown disabled={isLoading} overlay={genderDropdownItem}>
                    <Button>{gender || t("allUsers.choseGender")}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={rolesDropdownItem}>
                    <Button>{role || t("allUsers.choseRole")}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={serviceDropdownItem}>
                    <Button>{service || t("allUsers.choseService")}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={statusDropdownItem}>
                    <Button>{status || t("allUsers.choseStatus")}</Button>
                  </Dropdown>
                </Col>
              </Row>
            )}
          </Form>
          <Table
            style={{ marginBottom: "10px", userSelect: "none" }}
            columns={columns}
            pagination={false}
            dataSource={tableData}
            onRow={(record) => {
              return {
                style: { cursor: "pointer" },
                onClick: () => handleTableItemClick(record?.userId),
              };
            }}
          />
          <Pagination
            total={usersCount}
            showSizeChanger={false}
            current={Number(currentSearchPage) || 1}
            onChange={handlePageClick}
            style={{ userSelect: "none" }}
          />
        </>
      )}
    </>
  );
};
