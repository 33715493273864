import { DictFilterInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: DictFilterInput = {};
  const searchType = searchParams.get("searchType");
  const id = searchParams.get("id");
  if (id) {
    if (searchParams.get("searchType") === "countryId") {
      params = {
        countryId: id as string,
      };
    } else if (searchType === "cityName") {
      params = {
        itemQuery: id,
      };
    }
  }
  params.filter = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
    ids: searchType === "cityId" ? (id ? [id as string] : []) : [],
  };
  return params;
};
