import { DictFilterInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: DictFilterInput = {};
  if (searchParams.get("id")) {
    if (searchParams.get("searchType") === "countryId") {
      params = {
        filter: {
          ids: searchParams.get("id") ? [searchParams.get("id")] : [],
        },
      };
    } else if (searchParams.get("searchType") === "countryName") {
      params = {
        itemQuery: searchParams.get("id") as string,
      };
    }
  }
  return params;
};
