/* eslint-disable array-callback-return */
import React from "react";

import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";

import { CryptoTransaction } from "shared/api/apollo/__generated__";

interface CryptoTransProps {
  cryptoTransaction: CryptoTransaction;
}

export const CryptoTransactionView: React.FC<CryptoTransProps> = ({
  cryptoTransaction,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {cryptoTransaction && (
        <Descriptions
          layout="horizontal"
          bordered
          size="small"
          column={1}
          key="cryptoTransCard"
          title={t("cryptoTransaction.infoCard")}
          style={{
            minWidth: "600px",
            maxWidth: "800px",
            marginBottom: "10px",
          }}
        >
          {cryptoTransaction &&
            Object.entries(cryptoTransaction).map((info) => {
              if (info[0] === "network" && typeof info[1] === "object") {
                return (
                  <Descriptions.Item
                    label={t(`cryptoTransaction.${info[0]}`)}
                    key={info[0]}
                  >
                    {cryptoTransaction?.network?.network || "-"}
                  </Descriptions.Item>
                );
              }
              if (info[0].toLowerCase().includes("amount")) {
                return (
                  <Descriptions.Item
                    label={t(`cryptoTransaction.${info[0]}`)}
                    key={info[0]}
                  >
                    {`${info[1]} ${cryptoTransaction?.coin?.ticker}`}
                  </Descriptions.Item>
                );
              }
              if (typeof info[1] !== "object" && !info[0].includes("__")) {
                return (
                  <Descriptions.Item
                    label={t(`cryptoTransaction.${info[0]}`)}
                    key={info[0]}
                  >
                    {info[1] || "-"}
                  </Descriptions.Item>
                );
              }
            })}
        </Descriptions>
      )}
    </>
  );
};
