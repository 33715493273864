import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  PaginationProps,
  Row,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Country } from "shared/api/apollo/__generated__";

import { DataType } from "../config";
import { checkSearchParams, serializeData } from "../lib";
import { countriesModel } from "../model";

export const CountriesBlock: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType");

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "countryId");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get("id") && !searchParams.get("searchType")) {
      countriesModel.getCountries({});
    } else {
      countriesModel.getCountries(checkSearchParams(searchParams));
      setIsSearchResult(true);
    }
  }, []);

  const [form] = useForm();

  const countriesList = useStore(countriesModel.$countries);
  const isLoading = useStore(countriesModel.$isLoading);

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  //TODO: localization
  const columns: ColumnsType<DataType> = [
    {
      title: "Country name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "Alpha3",
      dataIndex: "alpha3",
      key: "alpha3",
      render: (text) => text || "-",
    },
    {
      title: "Country ID",
      dataIndex: "countryId",
      key: "countryId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "Country's continent",
      dataIndex: "continent",
      key: "continent",
    },
    {
      title: "Phone code",
      dataIndex: "phoneCode",
      key: "phoneCode",
    },
  ];

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("countries.searchById"),
          key: "countryId",
        },
        {
          label: t("countries.searchByName"),
          key: "countryName",
        },
      ]}
    />
  );

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    countriesModel?.getCountries(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    setSearchParams(searchParams);
    countriesModel?.getCountries({});
    setIsSearchResult(false);
  };

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleTableItemClick = (id: string) => {
    navigate(`/dictionary/countries/${id}`);
  };

  const handleCreateCountryClick = () => {
    navigate("/dictionary/countries/new/create");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Row justify="space-between">
            <Form form={form} name="search" onFinish={handleSearchClick}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Button onClick={handleCreateCountryClick}>
                    Create new country
                  </Button>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={dropdownItems}>
                    <Button>{t(`countries.${searchType}`)}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Form.Item required name="id">
                    <Input
                      required
                      placeholder={t(`countries.${searchType}`)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {isSearchResult && (
                    <Button
                      key="resetSearch"
                      onClick={handleResetSearchResultClick}
                      loading={isLoading}
                      type="primary"
                    >
                      Reset search
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Row>
          <Table
            onRow={(record) => {
              return {
                style: { cursor: "pointer" },
                onClick: () => handleTableItemClick(record?.countryId),
              };
            }}
            style={{ userSelect: "none" }}
            dataSource={serializeData(countriesList as Country[])}
            columns={columns}
            pagination={{
              position: ["bottomLeft"],
              showSizeChanger: false,
              onChange: handlePageClick,
              current: Number(currentSearchPage) || 1,
            }}
          />
        </>
      )}
    </>
  );
};
