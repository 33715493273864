import React, { useEffect, useState } from "react";

import { Button, Form, Input, notification, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CurrencyType } from "shared/api/apollo/__generated__";

import { createCurrencyModel } from "../model";

export const CreateCurrencyForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const isLoading = useStore(createCurrencyModel.$isLoading);

  const [chosenCurrencyType, setChosenCurrencyType] = useState<CurrencyType>(
    CurrencyType.Crypto
  );

  const isCrypto = chosenCurrencyType === CurrencyType.Crypto;

  const handleCreateCurrencyClick = (values: {
    currencyName: string;
    precision: string;
    ticker: string;
    symbol: string;
    tags: string;
  }) => {
    if (Number.isNaN(Number(values?.precision))) {
      notification.warn({ message: "Precision must be a number value!" });
      return;
    }
    createCurrencyModel.createCurrency({
      currency: {
        name: values?.currencyName,
        precision: Number(values?.precision),
        ticker: values?.ticker,
        type: chosenCurrencyType,
        symbol: values?.symbol,
        tags: values?.tags?.length
          ? values?.tags?.replace(/\s+/g, "").split(",")
          : [] || [],
      },
    });
  };

  const handleChoseCurrencyType = (type: CurrencyType) => {
    setChosenCurrencyType(type);
  };

  const handleCancelClick = () => {
    navigate("/dictionary/currencies");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createCurrency"
          onFinish={handleCreateCurrencyClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item key="label">{t("createCurrency.title")}</Form.Item>
          <Form.Item
            label={t("createCurrency.currencyName")}
            key="currencyName"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="currencyName"
          >
            <Input placeholder={t("createCurrency.currencyName")} />
          </Form.Item>
          <Form.Item
            label={t("createCurrency.currencyPrecision")}
            key="precision"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="precision"
          >
            <Input placeholder={t("createCurrency.currencyPrecision")} />
          </Form.Item>
          <Form.Item
            label={t("createCurrency.currencyTicker")}
            key="ticker"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="ticker"
          >
            <Input placeholder={t("createCurrency.currencyTicker")} />
          </Form.Item>
          <Form.Item
            label={t("createCurrency.currencyType")}
            key="currencyType"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="currencyType"
            initialValue={CurrencyType.Crypto}
            tooltip={t("createCurrency.currencyTypeTooltip")}
          >
            <Button
              onClick={() => {
                handleChoseCurrencyType(CurrencyType.Crypto);
              }}
              type={isCrypto ? "primary" : "default"}
            >
              {t("createCurrency.crypto")}
            </Button>
            <Button
              onClick={() => {
                handleChoseCurrencyType(CurrencyType.Fiat);
              }}
              type={!isCrypto ? "primary" : "default"}
            >
              {t("createCurrency.fiat")}
            </Button>
          </Form.Item>
          <Form.Item
            label={t("createCurrency.currencySymbol")}
            key="symbol"
            name="symbol"
          >
            <Input placeholder={t("createCurrency.currencySymbol")} />
          </Form.Item>
          <Form.Item
            label={t("createCurrency.tags")}
            key="tags"
            name="tags"
            tooltip={t("createCurrency.tagsTooltip")}
          >
            <Input placeholder={t("createCurrency.tags")} />
          </Form.Item>
          <Form.Item key="createButton">
            <Button type="primary" htmlType="submit">
              {t("createCurrency.createNewCurrency")}
            </Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
