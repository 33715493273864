import { GetBankInput, GetBanksInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: GetBanksInput = {};
  params = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
  };
  return params;
};

export const checkOneBankSearchParams = (searchParams: URLSearchParams) => {
  let params: GetBankInput = {};
  const searchType = searchParams.get("searchType");
  const id = searchParams.get("id");
  switch (searchType) {
    case "bankId":
      params = {
        id: id,
      };
      break;
    case "bankCode":
      params = {
        bankCode: id,
      };
      break;
    case "address":
      params = {
        address: id,
      };
      break;
    case "bankName":
      params = {
        bankName: id,
      };
      break;
    case "bankBic":
      params = {
        bic: id,
      };
  }
  return params;
};
