import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getLanguages = createEvent<DictFilterInput>();

const getLanguagesFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.languages({ input });
  return response.languages;
});

const $isLoading = getLanguagesFx.pending;
const $languages = restore(getLanguagesFx.doneData, []);

forward({
  from: getLanguages,
  to: getLanguagesFx,
});

export const languagesModel = { $isLoading, $languages, getLanguages };
