import React from "react";

import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { WalletObject } from "shared/api/apollo/__generated__";

interface CryptoBalanceProps {
  cryptoBalance: WalletObject;
}

export const CurrentCryptoBalanceView: React.FC<CryptoBalanceProps> = ({
  cryptoBalance,
}) => {
  const { t } = useTranslation();

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("chosenCryptoBalance.cryptoAccountCard")}
      style={{ minWidth: "600px", maxWidth: "800px" }}
    >
      {cryptoBalance && (
        <>
          {Object.entries(cryptoBalance)?.map((balanceInfo) => {
            if (
              typeof balanceInfo[1] === "string" ||
              typeof balanceInfo[1] === "boolean"
            ) {
              if (!balanceInfo[0]?.includes("__") && balanceInfo[1]) {
                if (balanceInfo[0] === "userId") {
                  return (
                    <Descriptions.Item
                      label={t(`chosenCryptoBalance.${balanceInfo[0]}`)}
                    >
                      <Link to={`/all-users/${balanceInfo[1]}`}>
                        {balanceInfo[1]}
                      </Link>
                    </Descriptions.Item>
                  );
                }
                return (
                  <Descriptions.Item
                    label={t(`chosenCryptoBalance.${balanceInfo[0]}`)}
                  >
                    {balanceInfo[1] || "-"}
                  </Descriptions.Item>
                );
              }
            } else if (balanceInfo[1]?.__typename === "BalanceObject") {
              return (
                <Descriptions.Item
                  label={t(`chosenCryptoBalance.${balanceInfo[0]}`)}
                >
                  {balanceInfo[1]?.current} {balanceInfo[1]?.token}
                </Descriptions.Item>
              );
            }
          })}
        </>
      )}
      <br />
      {cryptoBalance?.blockchain && (
        <>
          {Object.entries(cryptoBalance?.blockchain).map((blockchainInfo) => {
            if (!blockchainInfo[0].includes("__") && blockchainInfo[1]) {
              return (
                <Descriptions.Item
                  label={t(`chosenCryptoBalance.${blockchainInfo[0]}`)}
                >
                  {blockchainInfo[1] ? blockchainInfo[1].toString() : "-"}
                </Descriptions.Item>
              );
            }
          })}
        </>
      )}
    </Descriptions>
  );
};
