import { CreateCityForm } from "features";
import React from "react";
import { AdminTemplate } from "shared/templates";

export const CreateCityPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateCityForm />
    </AdminTemplate>
  );
};
