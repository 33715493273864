import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  PaginationProps,
  Row,
  Spin,
  Table,
} from "antd";
import { useStore } from "effector-react";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useTranslation } from "react-i18next";

import { City } from "shared/api/apollo/__generated__";

import { citiesModel } from "../model";
import { DataType } from "../config";
import { checkSearchParams, serializeTableData } from "../lib";

export const CitiesBlock: React.FC = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType");

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "cityId");
      setSearchParams(searchParams);
    }
    if (searchParams.get("id")) {
      setIsSearchResult(true);
    }

    citiesModel.getCities(checkSearchParams(searchParams));
  }, [currentSearchPage]);

  const isLoading = useStore(citiesModel.$isLoading);
  const citiesList = useStore(citiesModel.$cities);
  const citiesCount = useStore(citiesModel.$citiesCount);

  const [form] = useForm();

  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: t("citiesBlock.cityName"),
      dataIndex: "cityName",
      key: "cityName",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("citiesBlock.cityId"),
      dataIndex: "cityId",
      key: "cityId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("citiesBlock.cityCountry"),
      dataIndex: "cityCountry",
      key: "cityCountry",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("citiesBlock.cityCountryId"),
      dataIndex: "countryId",
      key: "countryId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("citiesBlock.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("citiesBlock.updatedAt"),
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  const tableData = serializeTableData(citiesList as City[]);

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    citiesModel?.getCities(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleTableItemClick = (id: string) => {
    navigate(`/dictionary/cities/${id}`);
  };

  const handleCreateCountryClick = () => {
    navigate("/dictionary/cities/new/create");
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    setSearchParams(searchParams);
    citiesModel?.getCities(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("citiesBlock.searchByCityId"),
          key: "cityId",
        },
        {
          label: t("citiesBlock.searchByCityName"),
          key: "cityName",
        },
        {
          label: t("citiesBlock.searchByCountryId"),
          key: "countryId",
        },
      ]}
    />
  );

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Row justify="space-between">
            <Form form={form} name="search" onFinish={handleSearchClick}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Button onClick={handleCreateCountryClick}>
                    {t("citiesBlock.createNew")}
                  </Button>
                </Col>
                <Col>
                  <Dropdown disabled={isLoading} overlay={dropdownItems}>
                    <Button>{t(`citiesBlock.${searchType}`)}</Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Form.Item required name="id">
                    <Input
                      required
                      placeholder={t(`citiesBlock.${searchType}`)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      {t("common.search")}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {isSearchResult && (
                    <Button
                      key="resetSearch"
                      onClick={handleResetSearchResultClick}
                      loading={isLoading}
                      type="primary"
                    >
                      {t("common.resetSearch")}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Row>
          <Table
            style={{ marginBottom: "10px", userSelect: "none" }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            onRow={(record) => {
              return {
                style: { cursor: "pointer" },
                onClick: () => handleTableItemClick(record?.cityId),
              };
            }}
          />
          <Pagination
            total={citiesCount}
            showSizeChanger={false}
            current={Number(currentSearchPage) || 1}
            onChange={handlePageClick}
            style={{ userSelect: "none" }}
          />
        </>
      )}
    </>
  );
};
