import { Bank } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeData = (data: Bank[]): DataType[] => {
  let result: DataType[] = [];
  data.forEach((bank) =>
    result.push({
      bankName: bank?.bankName,
      bankCode: bank?.bankCode as string,
      bankBic: bank?.bic as string,
      bankId: bank?.id,
      key: bank.id,
    })
  );
  return result;
};
