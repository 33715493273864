import React, { useEffect, useState } from "react";

import { Button, Col, Form, Input, notification, Row, Spin, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createCountryModel } from "../model";

export const CreateCountryForm: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useStore(createCountryModel.$isLoading);

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const [addNewLocalizedNameMode, setIsAddNewLocalizedNameMode] =
    useState<boolean>(false);
  const [localizedNames, setLocalizedNames] = useState<
    { langId: string; name: string }[]
  >([]);

  const handleCreateClick = (values: {
    continentId: string;
    countryCode: string;
    localizedNames: { langId: string; name: string }[];
    name: string;
    tags: string;
    phoneCode: string;
    phoneTemplate: string;
    alpha3: string;
    currencyIds: string;
    iconFileId: string;
    languagesIds: string;
  }) => {
    createCountryModel.createCountry({
      country: {
        continentId: values?.continentId,
        item: {
          code: values?.countryCode,
          localizedNames: localizedNames,
          name: values?.name,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
        },
        phoneCode: values?.phoneCode,
        phoneTemplate: values?.phoneTemplate,
        alpha3: values?.alpha3 || "",
        currencyIds: values?.currencyIds?.length
          ? values?.currencyIds?.replace(/\s+/g, "").split(",")
          : [] || [],
        iconFileId: values?.iconFileId || "",
        languageIds: values?.languagesIds?.length
          ? values?.languagesIds?.replace(/\s+/g, "").split(",")
          : [] || [],
      },
    });
  };

  const handleAddNewName = () => {
    if (form.getFieldValue("langId") && form.getFieldValue("localizedName")) {
      const newValue = {
        langId: form.getFieldValue("langId"),
        name: form.getFieldValue("localizedName"),
      };
      setLocalizedNames((prev) => [...prev, newValue]);
      form.resetFields(["langId", "localizedName"]);
      setIsAddNewLocalizedNameMode(false);
    } else {
      notification.warning({
        message: "Please enter each values for new localized name",
      });
    }
  };

  const handleCloseNameTag = (removedTag: string) => {
    setLocalizedNames((prev) => prev.filter((tag) => tag.name !== removedTag));
  };

  const showAddNewNameClick = () => {
    setIsAddNewLocalizedNameMode(true);
  };

  const hideAddNewNameClick = () => {
    form.resetFields(["langId", "localizedName"]);
    setIsAddNewLocalizedNameMode(false);
  };

  const handleCancelClick = () => {
    navigate("/dictionary/countries");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createBank"
          onFinish={handleCreateClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 4 }}
        >
          <Form.Item key="label">
            To create new country please fill all the fields:
          </Form.Item>
          <Form.Item
            label="Country name"
            key="name"
            rules={[{ required: true, message: "This field is required!" }]}
            name="name"
          >
            <Input placeholder="Country name"></Input>
          </Form.Item>
          <Form.Item
            label="Country continent ID"
            key="continentId"
            rules={[{ required: true, message: "This field is required!" }]}
            name="continentId"
          >
            <Input placeholder="Country code"></Input>
          </Form.Item>
          <Form.Item
            label="Country code"
            key="countryCode"
            rules={[{ required: true, message: "This field is required!" }]}
            name="countryCode"
          >
            <Input placeholder="Bank address"></Input>
          </Form.Item>
          <Form.Item
            label="Country phone code"
            key="phoneCode"
            rules={[{ required: true, message: "This field is required!" }]}
            name="phoneCode"
          >
            <Input placeholder="Country phone code"></Input>
          </Form.Item>
          <Form.Item
            label="Country phone template"
            key="phoneTemplate"
            rules={[{ required: true, message: "This field is required!" }]}
            name="phoneTemplate"
          >
            <Input placeholder="Country phone template"></Input>
          </Form.Item>

          <Form.Item
            label="Localized names  "
            key="localizedName"
            name="localizedName"
          >
            <>
              {localizedNames.map((localizedName) => (
                <Tag
                  key={localizedName.name}
                  closable
                  onClose={() => handleCloseNameTag(localizedName.name)}
                >
                  {localizedName.name}
                </Tag>
              ))}
            </>
            {!addNewLocalizedNameMode && (
              <Button onClick={showAddNewNameClick}>Add localized name</Button>
            )}
            {addNewLocalizedNameMode && (
              <>
                <Input.Group>
                  <Form.Item name="localizedName">
                    <Input placeholder="Localized name" />
                  </Form.Item>
                  <Form.Item name="langId">
                    <Input placeholder="Language ID" />
                  </Form.Item>
                </Input.Group>
                <Row>
                  <Col>
                    <Button onClick={handleAddNewName}>
                      Add new localized name
                    </Button>
                  </Col>
                  <Col>
                    <Button danger onClick={hideAddNewNameClick}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.Item>

          <Form.Item label="Country alpha3 code" key="alpha3" name="alpha3">
            <Input placeholder="Country alpha3 code"></Input>
          </Form.Item>
          <Form.Item
            label="Country currency IDs"
            key="currencyIds"
            name="currencyIds"
            tooltip="Enter currency IDs separated by commas"
          >
            <Input placeholder="Currency IDs"></Input>
          </Form.Item>
          <Form.Item
            label="Country icon file ID"
            key="iconFileId"
            name="iconFileId"
          >
            <Input placeholder="Country icon file ID"></Input>
          </Form.Item>
          <Form.Item
            label="Country languages ID"
            key="languageIds"
            name="languageIds"
            tooltip="Enter language IDs separated by commas"
          >
            <Input placeholder="Languages IDs"></Input>
          </Form.Item>
          <Form.Item
            label="Tags"
            key="tags"
            name="tags"
            tooltip="Enter tags separated by commas"
          >
            <Input placeholder="Tags"></Input>
          </Form.Item>
          <Form.Item key="createButton">
            <Button type="primary" htmlType="submit">Create new country</Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
