import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Region } from "shared/api/apollo/__generated__";
import { updateRegionModel } from "../model";

interface ChangeRegionProps {
  region: Region;
}

export const ChangeRegionInfoForm: React.FC<ChangeRegionProps> = ({
  region,
}) => {
  const { t } = useTranslation();

  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const handleUpdateRegionInfoClick = (values: {
    countryId: string;
    regionCode: string;
    regionName: string;
    tags: string;
  }) => {
    updateRegionModel.updateRegion({
      id: region?.item?.id,
      region: {
        countryId: values?.countryId,
        item: {
          code: values?.regionCode,
          name: values?.regionName,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
          localizedNames: [],
        },
      },
    });
  };

  return (
    <Form
      style={{ userSelect: "none" }}
      form={form}
      layout="horizontal"
      name="updateRegion"
      onFinish={handleUpdateRegionInfoClick}
      wrapperCol={{ span: 10 }}
      labelCol={{ span: 2 }}
    >
      <Form.Item
        label={t("regionEdit.regionName")}
        key="regionName"
        initialValue={region?.item?.name}
        rules={[{ required: true, message: t("common.requiredMessage") }]}
        name="regionName"
      >
        <Input
          defaultValue={region?.item?.name}
          placeholder={t("regionEdit.regionName")}
        />
      </Form.Item>
      <Form.Item
        label={t("regionEdit.regionCode")}
        key="regionCode"
        initialValue={region?.item?.code}
        rules={[{ required: true, message: t("common.requiredMessage") }]}
        name="regionCode"
      >
        <Input
          defaultValue={region?.item?.code}
          placeholder={t("regionEdit.regionCode")}
        />
      </Form.Item>
      <Form.Item
        label={t("regionEdit.countryId")}
        key="countryId"
        initialValue={region?.country?.item?.id}
        rules={[{ required: true, message: t("common.requiredMessage") }]}
        name="countryId"
      >
        <Input
          defaultValue={region?.country?.item?.id}
          placeholder={t("regionEdit.countryId")}
        />
      </Form.Item>
      <Form.Item
        label={t("regionEdit.tags")}
        key="tags"
        initialValue={region.item.tags || ""}
        name="tags"
      >
        <Input
          defaultValue={region.item.tags?.join(", ") || ""}
          placeholder={t("regionEdit.tags")}
        ></Input>
      </Form.Item>
      <Form.Item key="createButton">
        <Button type="primary" htmlType="submit">
          {t("regionEdit.update")}
        </Button>
      </Form.Item>
    </Form>
  );
};
