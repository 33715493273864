import { GetNaceCodesInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: GetNaceCodesInput = {};
  const searchType = searchParams.get("searchType");
  const id = searchParams.get("id");
  if (id) {
    switch (searchType) {
      case "codeId":
        params = {
          id: id,
        };
        break;
      case "naceCode":
        params = {
          code: id as string,
        };
        break;
      case "codeParentId":
        params = {
          parentId: id as string,
        };
        break;
    }
  }
  params.filter = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
  };
  return params;
};
