import {
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { GetAllUsersInput, UserFull } from "shared/api/apollo/__generated__";

const getAllUsers = createEvent<GetAllUsersInput>();

const getAllUsersFx = createEffect(async (input: GetAllUsersInput) => {
  const response = await requestClient.getAllUsers({ input });
  return response.getAllUsers;
});

const $isLoading = getAllUsersFx.pending;
const $allUsers = createStore<UserFull[]>([]);
const $usersCount = createStore<number>(0);

forward({
  from: getAllUsers,
  to: getAllUsersFx,
});

sample({
  clock: getAllUsersFx.doneData,
  fn: (data) => data.nodes as UserFull[],
  target: $allUsers,
});

sample({
  clock: getAllUsersFx.doneData,
  fn: (data) => data.count,
  target: $usersCount,
});

export const usersModel = { $allUsers, $isLoading, $usersCount, getAllUsers };
