import React from "react";

import { useTranslation } from "react-i18next";
import { Descriptions, Tag, Tooltip } from "antd";

import { Country } from "shared/api/apollo/__generated__";

import { serializeCountry } from "../lib";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

interface CountryProps {
  country: Country;
}

export const CurrentCountryView: React.FC<CountryProps> = ({ country }) => {
  const { t } = useTranslation();

  const descriptionData = serializeCountry(country);

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("countryView.title")}
      style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
    >
      {Object.entries(descriptionData).map((countryInfo) => {
        if (Array.isArray(countryInfo[1])) {
          return (
            <Descriptions.Item
              key={countryInfo[0]}
              label={t(`countryView.${countryInfo[0]}`)}
            >
              {countryInfo[1].length
                ? countryInfo[1].map((tag: string) => <Tag>{tag}</Tag>)
                : "-"}
            </Descriptions.Item>
          );
        } else if (countryInfo[0].includes("continent")) {
          return (
            <Descriptions.Item
              key={countryInfo[0]}
              label={t(`countryView.${countryInfo[0]}`)}
            >
              <Link
                to={`/dictionary/continents/${country?.continent?.item?.id}`}
              >
                {countryInfo[1]}
              </Link>{" "}
              <Tooltip title={t("countryView.tooltip")}>
                <InfoCircleOutlined
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
              </Tooltip>
            </Descriptions.Item>
          );
        } else {
          return (
            <Descriptions.Item
              key={countryInfo[0]}
              label={t(`countryView.${countryInfo[0]}`)}
            >
              {countryInfo[1]}
            </Descriptions.Item>
          );
        }
      })}
    </Descriptions>
  );
};
