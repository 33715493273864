import { Continent } from "shared/api/apollo/__generated__";
import { SerializedContinent } from "../config";

export const serializeContinent = (data: Continent): SerializedContinent => ({
  name: data?.item?.name,
  code: data?.item?.code,
  id: data?.item?.id,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  tags: data?.item?.tags as string[],
});
