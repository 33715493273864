import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createRegionModel } from "../model";

export const CreateRegionForm: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const isLoading = useStore(createRegionModel.$isLoading);

  const handleCreateRegionClick = (values: {
    countryId: string;
    regionName: string;
    regionCode: string;
    tags: string;
  }) => {
    createRegionModel.createRegion({
      region: {
        countryId: values?.countryId,
        item: {
          code: values?.regionCode,
          localizedNames: [],
          name: values?.regionName,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
        },
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/dictionary/regions");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createRegion"
          onFinish={handleCreateRegionClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label={t("createRegion.regionName")}
            key="regionName"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="regionName"
          >
            <Input placeholder={t("createRegion.regionName")} />
          </Form.Item>
          <Form.Item
            label={t("createRegion.regionCode")}
            key="regionCode"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="regionCode"
          >
            <Input placeholder={t("createRegion.regionCode")} />
          </Form.Item>
          <Form.Item
            label={t("createRegion.countryId")}
            key="countryId"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="countryId"
          >
            <Input placeholder={t("createRegion.countryId")} />
          </Form.Item>
          <Form.Item label={t("createRegion.tags")} key="tags" name="tags">
            <Input placeholder={t("createRegion.tags")} />
          </Form.Item>
          <Form.Item key="createButton">
            <Button type="primary" htmlType="submit">
              {t("createRegion.createNew")}
            </Button>
            <Button onClick={handleCancelClick} style={{ marginLeft: "10px" }}>
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
