import { Descriptions, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/api/apollo/__generated__";
import { serializeLanguage } from "../lib";

interface LanguageProps {
  language: Language;
}

export const CurrentLanguageView: React.FC<LanguageProps> = ({ language }) => {
  const { t } = useTranslation();
  const languageData = serializeLanguage(language);

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("languageView.title")}
      style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
    >
      {languageData &&
        Object.entries(languageData).map((info) => {
          if (Array.isArray(info[1])) {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`languageView.${info[0]}`)}
              >
                {info[1].length ? info[1].map((tag) => <Tag>{tag}</Tag>) : "-"}
              </Descriptions.Item>
            );
          } else {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`languageView.${info[0]}`)}
              >
                {info[1] || "-"}
              </Descriptions.Item>
            );
          }
        })}
    </Descriptions>
  );
};
