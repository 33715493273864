import React from "react";
import { AdminTemplate } from "shared/templates";

export const DictionaryPage: React.FC = () => {
  return (
    <AdminTemplate>
      <h1>Dictionary</h1>
    </AdminTemplate>
  );
};
