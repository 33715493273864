import React from "react";

import { CreateCurrencyForm } from "features";

import { AdminTemplate } from "shared/templates";

export const CreateCurrencyPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateCurrencyForm />
    </AdminTemplate>
  );
};
