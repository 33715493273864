import React, { useEffect, useState } from "react";

import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import {
  ChangeLanguageInfoForm,
  updateLanguageModel,
  languagesModel,
} from "features";

import { CurrentLanguageView } from "entities/index";

import { Language } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";

export const CurrentLanguagePage: React.FC = () => {
  const params = useParams();

  const isEditMode = useStore(updateLanguageModel.$isChangeMode);
  const isLoading = useStore(languagesModel.$isLoading);
  const chosenLanguage = useStore(languagesModel.$languages);
  const isChangeOnLoad = useStore(updateLanguageModel.$isLoading);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    languagesModel.getLanguages({ filter: { ids: [params.id as string] } });
  }, [isEditMode]);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateLanguageModel.setIsChangeMode(false)
      : updateLanguageModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteBankClick = () => {
    setIsModalOpen(false);
    updateLanguageModel.deleteLanguage({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeLanguageInfoForm
                language={chosenLanguage[0] as Language}
              />
            ) : (
              <CurrentLanguageView language={chosenLanguage[0] as Language} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit language info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete language
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteBankClick}
        visible={isModalOpen}
        title="Delete language confirmation"
      >
        Delete "{chosenLanguage[0]?.item?.name}" language?
      </Modal>
    </>
  );
};
