import { City } from "shared/api/apollo/__generated__";
import { SerializedCity } from "../config";

export const serializeCity = (data: City): SerializedCity => ({
  name: data?.item?.name,
  code: data?.item?.code,
  country: data?.country?.item?.name as string,
  countryId: data?.country?.id as string,
  key: data?.id,
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  region: data?.region?.item?.name as string,
  tags: data?.item?.tags as string[],
});
