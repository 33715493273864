import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Button, Layout } from "antd";
import "antd/dist/antd.min.css";
import { useTranslation } from "react-i18next";

import { loginModel, LogInPage } from "pages";
import { routes } from "routes";

import { fiatCurrenciesModel, userModel } from "entities/index";

import { PrivateRoute } from "shared/lib";
import { StatusEnum } from "shared/api/apollo/__generated__";
import { requestClient } from "shared/api/apollo/requestClient";
import { useStore } from "effector-react";
import { HomeFilled, HomeOutlined, HomeTwoTone } from "@ant-design/icons";

const { Header, Content } = Layout;

export const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    userModel.getUser();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("@accessToken")) {
        requestClient
          .getUserStatus()
          .then(({ viewer: { status } }) => {
            if (status !== StatusEnum.Active) {
              document.location = "/login";
            }
          })
          .catch(() => {
            userModel.resetUser();
            document.location = "/login";
          });
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fiatCurrenciesModel.getFiatCurrencies();
  }, []);

  const handleLogoutClick = () => {
    userModel.resetUser();
    loginModel?.logOut();
  };

  const currentUser = useStore(userModel.$user);

  return (
    <Layout
      className="site-layout"
      style={{ minHeight: "100vh", display: "flex" }}
    >
      <Header
        className="site-layout-background"
        style={{
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ color: "white" }}>
          {t("common.currentStand")}: {process.env.REACT_APP_ENV_TYPE}
        </span>
        <div>
          {currentUser && (
            <span style={{ color: "white" }}>
              {currentUser?.email?.identity}
            </span>
          )}
          <Button style={{ marginLeft: "10px" }} onClick={handleLogoutClick}>
            {t("common.logOut")}
          </Button>
        </div>
      </Header>
      <Layout style={{ display: "flex", flexDirection: "row" }}>
        <Content>
          <Router>
            <Routes>
              <Route path="/login" element={<LogInPage />} />
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <PrivateRoute>
                      <route.component />
                    </PrivateRoute>
                  }
                />
              ))}
            </Routes>
          </Router>
        </Content>
      </Layout>
    </Layout>
  );
};
