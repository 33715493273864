import { createEffect, createEvent, createStore, sample } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { Account, SearchAccountsInput } from "shared/api/apollo/__generated__";

const getBalances = createEvent<SearchAccountsInput>();
const resetFilterParams = createEvent();

const getBalancesFx = createEffect(async (input: SearchAccountsInput) => {
  const response = await requestClient.accounts({ input });
  return response?.searchAccounts;
});

const $balances = createStore<Account[]>([]).reset(resetFilterParams);
const $accountsCount = createStore<number>(0);
const $isLoading = getBalancesFx.pending;

sample({
  clock: getBalances,
  target: getBalancesFx,
});

sample({
  clock: getBalancesFx.doneData,
  fn: (newBalances) => newBalances.nodes as Account[],
  target: $balances,
});

sample({
  clock: getBalancesFx.doneData,
  fn: (newBalances) => newBalances.count,
  target: $accountsCount,
});

export const balancesModel = {
  getBalances,
  $balances,
  $isLoading,
  resetFilterParams,
  $accountsCount,
};
