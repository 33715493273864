import React from "react";

import { Descriptions, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { Continent } from "shared/api/apollo/__generated__";

import { serializeContinent } from "../lib";

interface ContinentProps {
  continent: Continent;
}

export const CurrentContinentView: React.FC<ContinentProps> = ({
  continent,
}) => {
  const { t } = useTranslation();
  const descriptionData = serializeContinent(continent);

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("continents.title")}
      style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
    >
      {descriptionData &&
        Object.entries(descriptionData).map((info) => {
          if (!Array.isArray(info[1])) {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`continents.${info[0]}`)}
              >
                {info[1]}
              </Descriptions.Item>
            );
          } else {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`continents.${info[0]}`)}
              >
                {info[1].length ? info[1].map((tag) => <Tag>{tag}</Tag>) : "-"}
              </Descriptions.Item>
            );
          }
        })}
    </Descriptions>
  );
};
