import React from "react";

import { CitiesBlock } from "features";

import { AdminTemplate } from "shared/templates";

export const DictionaryCitiesPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CitiesBlock />
    </AdminTemplate>
  );
};
