import React from "react";

import { Descriptions, Tabs, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { UserFull } from "shared/api/apollo/__generated__";

import { serializeUser } from "../lib";

interface UserProps {
  user: UserFull;
}

export const CurrentUserView: React.FC<UserProps> = ({ user }) => {
  const { t } = useTranslation();
  const serializedUser = serializeUser(user);

  return (
    <>
      {user?.companies?.length ? (
        <Tabs defaultActiveKey="userInfo">
          <Tabs.TabPane key="userInfo" tab={t("currentUser.userInfo")}>
            <Descriptions
              layout="horizontal"
              bordered
              size="small"
              column={1}
              key="userCard"
              title={t("currentUser.userCard")}
              style={{
                minWidth: "600px",
                maxWidth: "800px",
                marginBottom: "10px",
              }}
            >
              {serializedUser &&
                Object.entries(serializedUser).map((user) => {
                  return (
                    <Descriptions.Item
                      key={user[0]}
                      label={t(`currentUser.${user[0]}`)}
                    >
                      {user[1] || "-"}
                    </Descriptions.Item>
                  );
                })}
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="userCompanies"
            tab={t("currentUser.company.companiesInfo")}
          >
            <Tabs
              tabPosition="left"
              defaultActiveKey={user?.companies[0]?.id?.toString()}
            >
              {user.companies.map((company) => {
                return (
                  <Tabs.TabPane
                    key={company?.id}
                    tab={company?.legalName || t("currentUser.company.noName")}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      size="small"
                      column={1}
                      key="companyCard"
                      title={t("currentUser.companyCard")}
                      style={{
                        minWidth: "600px",
                        maxWidth: "800px",
                        marginBottom: "10px",
                      }}
                    >
                      {company &&
                        Object.entries(company).map((info) => {
                          if (Array.isArray(info[1])) {
                            return (
                              <Descriptions.Item
                                key={info[0]}
                                label={t(`currentUser.company.${info[0]}`)}
                              >
                                {info[1].length
                                  ? info[1].map((item) => (
                                      <Tag
                                        style={{
                                          display: "block",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        {item}
                                      </Tag>
                                    ))
                                  : "-"}
                              </Descriptions.Item>
                            );
                          }
                          if (!info[0].includes("__")) {
                            return (
                              <Descriptions.Item
                                key={info[0]}
                                label={t(`currentUser.company.${info[0]}`)}
                              >
                                {info[1] || "-"}
                              </Descriptions.Item>
                            );
                          }
                        })}
                    </Descriptions>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Descriptions
          layout="horizontal"
          bordered
          size="small"
          column={1}
          key="userCard"
          title={t("currentUser.userCard")}
          style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
        >
          {serializedUser &&
            Object.entries(serializedUser).map((user) => {
              return (
                <Descriptions.Item
                  key={user[0]}
                  label={t(`currentUser.${user[0]}`)}
                >
                  {user[1] || "-"}
                </Descriptions.Item>
              );
            })}
        </Descriptions>
      )}
    </>
  );
};
