import { UserFull } from "shared/api/apollo/__generated__";
import { SerializedUser } from "../config";

export const serializeUser = (data: UserFull): SerializedUser => ({
  firstName: (data?.firstName as string) || "-",
  lastName: (data?.lastName as string) || "-",
  birthDate: (data?.birthDate as string) || "-",
  username: (data?.username as string) || "-",
  userId: (data?.userId as string) || "-",
  userEOS: (data?.EOS?.key as string) || "-",
  userEmail: data?.email?.identity || "-",
  isEmailConfirmed: data?.email?.confirm.toString() || "-",
  phoneNumber: (data?.phone?.identity as string) || "-",
  isPhoneNumberConfirmed: data?.phone?.confirm.toString() || "-",
  p256: (data?.p256?.key as string) || "-",
  gender: data?.gender?.toString() || "-",
  leadId: (data?.leadId as string) || "-",
  role: data?.role.toString() || "-",
  service: data?.service.toString() || "-",
  status: data?.status.toString() || "-",
  createdAt: data?.createdAt || "-",
  updatedAt: data?.updatedAt || "-",
});
