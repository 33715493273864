import {
  BankDictionaryPage,
  CreateNewBankPage,
  CryptoBalancesPage,
  CurrentBankPage,
  CurrentCountryPage,
  CurrentCryptoBalancePage,
  CurrentFiatBalancePage,
  CurrentTransactionPage,
  DictionaryCountriesPage,
  DictionaryPage,
  FiatBalancesPage,
  HomePage,
  TransactionsPage,
  UsersInfoPage,
  CreateCountryPage,
  DictionaryCurrenciesPage,
  CurrentCurrencyPage,
  CreateCurrencyPage,
  DictionaryNetworksPage,
  DictionaryNaceCodesPage,
  CurrentNaceCodePage,
  DictionaryContinentsPage,
  CurrentContinentPage,
  CreateContinentPage,
  DictionaryCitiesPage,
  DictionaryLanguagesPage,
  CurrentLanguagePage,
  CreateLanguagePage,
  DictionaryRegionsPage,
  CreateRegionPage,
  CurrentRegionPage,
  CurrentCityPage,
  CreateCityPage,
  AllUsersPage,
  CurrentUserPage,
} from "pages";

export const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/balances/crypto",
    component: CryptoBalancesPage,
  },
  {
    path: "/balances/crypto/:id",
    component: CurrentCryptoBalancePage,
  },
  {
    path: "/balances/fiat",
    component: FiatBalancesPage,
  },
  {
    path: "/balances/fiat/:id",
    component: CurrentFiatBalancePage,
  },
  {
    path: "/transactions",
    component: TransactionsPage,
  },
  {
    path: "/transactions/:id",
    component: CurrentTransactionPage,
  },
  {
    path: "/users",
    component: UsersInfoPage,
  },
  {
    path: "/dictionary",
    component: DictionaryPage,
  },
  {
    path: "/dictionary/banks",
    component: BankDictionaryPage,
  },
  {
    path: "/dictionary/banks/:id",
    component: CurrentBankPage,
  },
  {
    path: "/dictionary/banks/new/create",
    component: CreateNewBankPage,
  },
  {
    path: "/dictionary/countries",
    component: DictionaryCountriesPage,
  },
  {
    path: "/dictionary/countries/:id",
    component: CurrentCountryPage,
  },
  {
    path: "/dictionary/countries/new/create",
    component: CreateCountryPage,
  },
  {
    path: "/dictionary/currencies",
    component: DictionaryCurrenciesPage,
  },
  {
    path: "/dictionary/currencies/:id",
    component: CurrentCurrencyPage,
  },
  {
    path: "/dictionary/currencies/new/create/",
    component: CreateCurrencyPage,
  },
  {
    path: "/dictionary/networks",
    component: DictionaryNetworksPage,
  },
  {
    path: "/dictionary/nace-codes",
    component: DictionaryNaceCodesPage,
  },
  {
    path: "/dictionary/nace-codes/:id",
    component: CurrentNaceCodePage,
  },
  {
    path: "/dictionary/continents",
    component: DictionaryContinentsPage,
  },
  {
    path: "/dictionary/continents/:id",
    component: CurrentContinentPage,
  },
  {
    path: "/dictionary/continents/new/create/",
    component: CreateContinentPage,
  },
  {
    path: "/dictionary/cities",
    component: DictionaryCitiesPage,
  },
  {
    path: "/dictionary/languages",
    component: DictionaryLanguagesPage,
  },
  {
    path: "/dictionary/languages/:id",
    component: CurrentLanguagePage,
  },
  {
    path: "/dictionary/languages/new/create",
    component: CreateLanguagePage,
  },
  {
    path: "/dictionary/regions",
    component: DictionaryRegionsPage,
  },
  {
    path: "/dictionary/regions/new/create",
    component: CreateRegionPage,
  },
  {
    path: "/dictionary/regions/:id",
    component: CurrentRegionPage,
  },
  {
    path: "/dictionary/cities/:id",
    component: CurrentCityPage,
  },
  {
    path: "/dictionary/cities/new/create",
    component: CreateCityPage,
  },
  {
    path: "/all-users",
    component: AllUsersPage,
  },
  {
    path: "/all-users/:id",
    component: CurrentUserPage,
  },
];
