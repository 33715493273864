import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { City } from "shared/api/apollo/__generated__";
import { updateCityModel } from "../model";

interface CityProps {
  city: City;
}

export const ChangeCityInfo: React.FC<CityProps> = ({ city }) => {
  const { t } = useTranslation();
  const isLoading = useStore(updateCityModel.$isLoading);
  const [form] = useForm();

  const handleUpdateCityClick = (values: {
    countryId: string;
    regionId: string;
    name: string;
    code: string;
    tags: string;
  }) => {
    updateCityModel.updateCity({
      id: city?.id,
      city: {
        countryId: values?.countryId,
        regionId: values?.regionId,
        item: {
          code: values?.code,
          localizedNames: [],
          name: values?.name,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
        },
      },
    });
  };
  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="updateContinent"
          onFinish={handleUpdateCityClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label="City name"
            key="name"
            initialValue={city?.item?.name}
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input defaultValue={city?.item?.name} placeholder="City name" />
          </Form.Item>
          <Form.Item
            label="City country ID"
            key="countryId"
            initialValue={city?.country?.id}
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="countryId"
          >
            <Input
              defaultValue={city?.country?.id}
              placeholder="City country ID"
            />
          </Form.Item>
          <Form.Item
            label="City region ID"
            key="regionId"
            initialValue={city?.region?.item?.id}
            name="regionId"
          >
            <Input
              defaultValue={city?.region?.item?.id}
              placeholder="City region ID"
            />
          </Form.Item>
          <Form.Item
            label="City code"
            key="code"
            initialValue={city?.item.code}
            name="code"
          >
            <Input defaultValue={city?.item.code} placeholder="City code" />
          </Form.Item>
          <Form.Item
            label={t("updateContinent.tags")}
            key="tags"
            initialValue={city?.item?.tags?.join(", ") || ""}
            name="tags"
            tooltip={t("updateContinent.tagsTooltip")}
          >
            <Input
              defaultValue={city?.item?.tags?.join(", ") || ""}
              placeholder={t("updateContinent.tags")}
            />
          </Form.Item>
          <Form.Item key="submitButton">
            <Button type="primary" htmlType="submit">
              Update city
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
