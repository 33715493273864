import React from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Descriptions, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { Region } from "shared/api/apollo/__generated__";

import { serializeRegion } from "../lib";
import { useTranslation } from "react-i18next";

interface RegionProps {
  region: Region;
}

export const CurrentRegionView: React.FC<RegionProps> = ({ region }) => {
  const { t } = useTranslation();
  const regionInfo = serializeRegion(region);

  return (
    <Descriptions
      key="regionInfo"
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("regionView.title")}
      style={{
        minWidth: "600px",
        maxWidth: "800px",
        marginBottom: "10px",
      }}
    >
      {regionInfo &&
        Object.entries(regionInfo).map((info) => {
          if (Array.isArray(info[1])) {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`regionView.${info[0]}`)}
              >
                {info[1].length ? info[1].map((tag) => <Tag>{tag}</Tag>) : "-"}
              </Descriptions.Item>
            );
          } else if (info[0].includes("countryName")) {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`regionView.${info[0]}`)}
              >
                {(
                  <>
                    <Link to={`/dictionary/countries/${regionInfo?.countryId}`}>
                      {info[1]}
                    </Link>
                    <Tooltip title="Tap to see country card">
                      <InfoCircleOutlined
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </Tooltip>
                  </>
                ) || "-"}
              </Descriptions.Item>
            );
          } else {
            return (
              <Descriptions.Item
                key={info[0]}
                label={t(`regionView.${info[0]}`)}
              >
                {info[1] || "-"}
              </Descriptions.Item>
            );
          }
        })}
    </Descriptions>
  );
};
