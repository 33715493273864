/* eslint-disable array-callback-return */
import React, { useEffect } from "react";

import { Descriptions, Tabs } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CashTransaction } from "shared/api/apollo/__generated__";
import { detailedInfoModel } from "../model";
import { useStore } from "effector-react";

interface CashTransProps {
  cashTransaction: CashTransaction;
}

export const CashTransactionView: React.FC<CashTransProps> = ({
  cashTransaction,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (cashTransaction?.from?.bankCityId) {
      detailedInfoModel.getBankFromCityName({
        filter: { ids: [cashTransaction?.from?.bankCityId as string] },
      });
    }
    if (cashTransaction?.to?.bankCityId) {
      detailedInfoModel.getBankToCityName({
        filter: { ids: [cashTransaction?.to?.bankCityId as string] },
      });
    }
    if (cashTransaction?.to?.countryId) {
      detailedInfoModel.getCountryInfo({
        filter: { ids: [cashTransaction?.to?.countryId as string] },
      });
    }
  }, [cashTransaction]);

  const bankFromInfo = useStore(detailedInfoModel?.$bankFromCityInfo);
  const bankToInfo = useStore(detailedInfoModel?.$bankToCityInfo);
  const countryInfo = useStore(detailedInfoModel?.$countryInfo) || [];

  return (
    <>
      {cashTransaction && (
        <Tabs defaultActiveKey="cashTransInfo">
          <Tabs.TabPane
            key="cashTransInfo"
            tab={t("cashTransaction.tabTransInfo")}
          >
            <Descriptions
              layout="horizontal"
              bordered
              size="small"
              column={1}
              key="cashTransCard"
              title={t("cashTransaction.infoCard")}
              style={{
                minWidth: "600px",
                maxWidth: "800px",
                marginBottom: "10px",
              }}
            >
              {cashTransaction &&
                Object.entries(cashTransaction).map((info) => {
                  if (info[0].toLowerCase().includes("amount")) {
                    return (
                      <Descriptions.Item
                        label={t(`cashTransaction.${info[0]}`)}
                        key={info[0]}
                      >
                        {`${info[1]} ${cashTransaction?.coin?.ticker}`}
                      </Descriptions.Item>
                    );
                  }
                  if (typeof info[1] !== "object" && !info[0].includes("__")) {
                    return (
                      <Descriptions.Item
                        label={t(`cashTransaction.${info[0]}`)}
                        key={info[0]}
                      >
                        {info[1] || "-"}
                      </Descriptions.Item>
                    );
                  }
                })}
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane key="fromInfo" tab={t(`cashTransaction.transFromInfo`)}>
            <Descriptions
              layout="horizontal"
              bordered
              size="small"
              column={1}
              key="tranFromInfo"
              title={t(`cashTransaction.transFromCard`)}
              style={{
                minWidth: "600px",
                maxWidth: "800px",
                marginBottom: "10px",
              }}
            >
              {cashTransaction &&
                Object.entries(cashTransaction.from).map((info) => {
                  if (!info[0].includes("__") && info[1]) {
                    if (info[0] === "accountId") {
                      return (
                        <Descriptions.Item
                          label={t(`cashTransaction.fromInfo.${info[0]}`)}
                          key={info[0]}
                        >
                          <Link to={`/balances/fiat/${info[1]}`}>
                            {info[1]}
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    if (info[0] === "bankCityId") {
                      return (
                        <Descriptions.Item
                          label={t(`cashTransaction.fromInfo.${info[0]}`)}
                          key={info[0]}
                        >
                          <Link to={`/dictionary/cities/${info[1]}`}>
                            {info[1]} ({bankFromInfo?.nodes[0].item?.name})
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    return (
                      <Descriptions.Item
                        label={t(`cashTransaction.fromInfo.${info[0]}`)}
                        key={info[0]}
                      >
                        {info[1] || "-"}
                      </Descriptions.Item>
                    );
                  }
                })}
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane key="toInfo" tab={t(`cashTransaction.transToInfo`)}>
            <Descriptions
              layout="horizontal"
              bordered
              size="small"
              column={1}
              key="transToInfoCard"
              title={t(`cashTransaction.transToCard`)}
              style={{
                minWidth: "600px",
                maxWidth: "800px",
                marginBottom: "10px",
              }}
            >
              {cashTransaction &&
                Object.entries(cashTransaction.to).map((info) => {
                  if (!info[0].includes("__") && info[1]) {
                    if (info[0] === "accountId") {
                      return (
                        <Descriptions.Item
                          label={t(`cashTransaction.toInfo.${info[0]}`)}
                          key={info[0]}
                        >
                          <Link to={`/balances/fiat/${info[1]}`}>
                            {info[1]}
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    if (info[0] === "countryId") {
                      return (
                        <Descriptions.Item
                          label={t(`cashTransaction.toInfo.${info[0]}`)}
                          key={info[0]}
                        >
                          <Link to={`/dictionary/countries/${info[1]}`}>
                            {info[1]} ({countryInfo[0]?.item?.name})
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    if (info[0] === "bankCityId") {
                      return (
                        <Descriptions.Item
                          label={t(`cashTransaction.toInfo.${info[0]}`)}
                          key={info[0]}
                        >
                          <Link to={`/dictionary/cities/${info[1]}`}>
                            {info[1]} ({bankToInfo?.nodes[0].item?.name})
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    return (
                      <Descriptions.Item
                        label={t(`cashTransaction.toInfo.${info[0]}`)}
                        key={info[0]}
                      >
                        {info[1] || "-"}
                      </Descriptions.Item>
                    );
                  }
                })}
            </Descriptions>
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
};
