import React from "react";

import { CreateCountryForm } from "features";

import { AdminTemplate } from "shared/templates";

export const CreateCountryPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateCountryForm />
    </AdminTemplate>
  );
};
