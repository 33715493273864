import jwtDecode from "jwt-decode";
import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { useAuth } from "./useAuth";

interface TokenType {
  role?: string;
}

export const PrivateRoute = ({ children }: RouteProps): JSX.Element => {
  const auth = useAuth();
  const token = localStorage.getItem("@accessToken");

  const checkIsAdmin = (token?: string | null) => {
    if (token) {
      const decodedToken = jwtDecode<TokenType>(token);
      if (decodedToken) {
        return decodedToken?.role === "ADMIN";
      }
    }

    return false;
  };

  return (
    <>
      {auth?.isLoggedIn && checkIsAdmin(token) ? (
        children
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};
