import { Button, Input } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/api/apollo/__generated__";
import { updateLanguageModel } from "../model";

interface LanguageProps {
  language: Language;
}

export const ChangeLanguageInfoForm: React.FC<LanguageProps> = ({
  language,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const handleUpdateLanguageClick = (values: {
    name: string;
    code: string;
    tags: string;
  }) => {
    updateLanguageModel.updateLanguage({
      id: language.id,
      language: {
        name: values?.name,
        code: values?.code,
      },
    });
  };
  return (
    <Form
      form={form}
      layout="horizontal"
      name="updateLanguage"
      onFinish={handleUpdateLanguageClick}
      wrapperCol={{ span: 10 }}
      labelCol={{ span: 2 }}
    >
      <Form.Item
        label={t("updateLanguage.languageName")}
        key="name"
        initialValue={language.item?.name || ""}
        name="name"
      >
        <Input
          defaultValue={language.item?.name || ""}
          placeholder={t("updateLanguage.languageName")}
        />
      </Form.Item>
      <Form.Item
        label={t("updateLanguage.languageCode")}
        key="code"
        initialValue={language.item?.code || ""}
        name="code"
      >
        <Input
          defaultValue={language.item?.code || ""}
          placeholder={t("updateLanguage.languageCode")}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {t("updateLanguage.saveNewInfo")}
        </Button>
      </Form.Item>
    </Form>
  );
};
