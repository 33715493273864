import {
  GenderEnum,
  GetAllUsersInput,
  RoleEnum,
  Service,
  StatusEnum,
} from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: GetAllUsersInput = {};
  const searchType = searchParams.get("searchType");
  const id = searchParams.get("id");
  const page = searchParams.get("page");
  switch (searchType) {
    case "userId":
      if (id) params.userIds = [id as string];
      break;
    case "phoneNumber":
      params.phone = id;
      break;
    case "userEmail":
      params.email = id;
  }
  if (searchParams.get("gender")) {
    params.gender = searchParams.get("gender")?.toUpperCase() as GenderEnum;
  }
  if (searchParams.get("role")) {
    if (searchParams.get("role") === "AmlOfficer") {
      params.roles = [RoleEnum.AmlOfficer];
    } else {
      params.roles = [searchParams.get("role")?.toUpperCase() as RoleEnum];
    }
  }
  if (searchParams.get("service")) {
    params.service = searchParams.get("service")?.toUpperCase() as Service;
  }
  if (searchParams.get("status")) {
    params.status = searchParams.get("status")?.toUpperCase() as StatusEnum;
  }
  params.filter = {
    skip: Number(page) >= 1 ? (Number(page) - 1) * 10 : 0,
    limit: 10,
  };
  return params;
};
