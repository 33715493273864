import { createForm } from "effector-forms";

export const twoFactorForm = createForm({
  fields: {
    emailCode: {
      init: "",
      rules: [],
    },
    smsCode: {
      init: "",
      rules: [],
    },
    otpCode: {
      init: "",
      rules: [],
    },
  },
  validateOn: ["submit"],
});
