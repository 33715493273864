import React, { useEffect, useState } from "react";

import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import { citiesModel } from "features/CitiesBlock/model";

import { CurrentCityView } from "entities/index";

import { City } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";
import { ChangeCityInfo, updateCityModel } from "features";

export const CurrentCityPage: React.FC = () => {
  const params = useParams();

  const isEditMode = useStore(updateCityModel.$isChangeMode);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    citiesModel.getCities({ filter: { ids: [params.id as string] } });
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      updateCityModel.setIsChangeMode(false);
    };
  }, []);

  const isLoading = useStore(citiesModel.$isLoading);
  const chosenCity = useStore(citiesModel.$cities);
  const isChangeOnLoad = useStore(updateCityModel.$isLoading);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateCityModel.setIsChangeMode(false)
      : updateCityModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCityClick = () => {
    setIsModalOpen(false);
    updateCityModel.deleteCity({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeCityInfo city={chosenCity[0] as City} />
            ) : (
              <CurrentCityView city={chosenCity[0] as City} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit city info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete city
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteCityClick}
        visible={isModalOpen}
        title="Delete city confirmation"
      >
        Delete "{chosenCity[0]?.item?.name}" city?
      </Modal>
    </>
  );
};
