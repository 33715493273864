import { UserFull } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeTableData = (data: UserFull[]): DataType[] => {
  const result: DataType[] = data.map((user) => ({
    firstName: (user?.firstName as string) || "-",
    lastName: (user?.lastName as string) || "-",
    userEmail: (user?.email?.identity as string) || "-",
    key: user?.userId,
    userId: user?.userId,
    userRole: user?.role,
    userService: user?.service,
    userStatus: user?.status,
  }));
  return result;
};
