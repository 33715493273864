import { createEffect, createEvent, forward, sample } from "effector";

import { toastModel } from "entities/index";

import { requestClient } from "shared/api/apollo/requestClient";
import { CreateBankInput } from "shared/api/apollo/__generated__";

const createBank = createEvent<CreateBankInput>();

const createBankFx = createEffect(async (input: CreateBankInput) => {
  const response = await requestClient.createBank({ input });
  return response.createBank;
});

forward({
  from: createBank,
  to: createBankFx,
});

sample({
  clock: createBankFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createBankFx.doneData,
  fn: (doneData) => (document.location = `/dictionary/banks/${doneData.id}`),
});

const $isLoading = createBankFx.pending;

export const createBankModel = { createBank, $isLoading };
