import React, { useEffect, useState } from "react";

import { Button, Col, Form, Input, notification, Row, Spin, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";

import { Country } from "shared/api/apollo/__generated__";

import { updateCountryModel } from "../model";

interface ChangeCountryProps {
  country: Country;
}

export const ChangeCountryInfoForm: React.FC<ChangeCountryProps> = ({
  country,
}) => {
  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  useEffect(() => {
    if (country?.item?.localizedNames) {
      setLocalizedNames(
        country?.item?.localizedNames?.map((name) => {
          return { langId: name?.id as string, name: name?.name as string };
        })
      );
    }
  }, [country]);

  const [addNewLocalizedNameMode, setIsAddNewLocalizedNameMode] =
    useState<boolean>(false);
  const [localizedNames, setLocalizedNames] = useState<
    { langId: string; name: string }[]
  >([]);

  const isLoading = useStore(updateCountryModel.$isLoading);

  const handleUpdateCountryInfoClick = (values: {
    continentId: string;
    countryName: string;
    code: string;
    tags: string;
    phoneCode: string;
    phoneTemplate: string;
    alpha3: string;
    currencyIds: string;
    iconField: string;
    languagesIds: string;
  }) => {
    updateCountryModel.updateCountryInfo({
      id: country?.id,
      country: {
        continentId: values?.continentId,
        item: {
          code: values?.code,
          localizedNames: localizedNames,
          name: values?.countryName,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
        },
        phoneCode: values?.phoneCode,
        phoneTemplate: values?.phoneTemplate,
        alpha3: values?.alpha3,
        currencyIds: values?.currencyIds?.length
          ? values?.currencyIds?.replace(/\s+/g, "").split(",")
          : [] || [],
        iconFileId: values?.iconField,
        languageIds: values?.languagesIds?.length
          ? values?.languagesIds?.replace(/\s+/g, "").split(",")
          : [] || [],
      },
    });
  };

  const handleCloseNameTag = (removedTag: string) => {
    setLocalizedNames((prev) => prev.filter((tag) => tag.name !== removedTag));
  };

  const showAddNewNameClick = () => {
    setIsAddNewLocalizedNameMode(true);
  };

  const hideAddNewNameClick = () => {
    form.resetFields(["langId", "localizedName"]);
    setIsAddNewLocalizedNameMode(false);
  };

  const handleAddNewName = () => {
    if (form.getFieldValue("langId") && form.getFieldValue("localizedName")) {
      const newValue = {
        langId: form.getFieldValue("langId"),
        name: form.getFieldValue("localizedName"),
      };
      setLocalizedNames((prev) => [...prev, newValue]);
      form.resetFields(["langId", "localizedName"]);
      setIsAddNewLocalizedNameMode(false);
    } else {
      notification.warning({
        message: "Please enter each values for new localized name",
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="updateCountry"
          onFinish={handleUpdateCountryInfoClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label="Country name"
            key="countryName"
            initialValue={country.item?.name || ""}
            name="countryName"
          >
            <Input
              defaultValue={country.item?.name || ""}
              placeholder="Country name"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country continent ID"
            key="continentId"
            initialValue={country?.continent?.item?.id || ""}
            name="continentId"
          >
            <Input
              defaultValue={country?.continent?.item?.id || ""}
              placeholder="Country continent ID"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country code"
            key="code"
            initialValue={country?.item?.code || ""}
            name="code"
          >
            <Input
              defaultValue={country?.item?.code || ""}
              placeholder="Country code"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country phone code"
            key="phoneCode"
            initialValue={country?.phoneCode || ""}
            name="phoneCode"
          >
            <Input
              defaultValue={country?.phoneCode || ""}
              placeholder="Country phone code"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country phone template"
            key="phoneTemplate"
            initialValue={country?.phoneTemplate || ""}
            name="phoneTemplate"
          >
            <Input
              defaultValue={country?.phoneTemplate || ""}
              placeholder="Country phone template"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country alpha3 code"
            key="alpha3"
            initialValue={country?.alpha3 || ""}
            name="alpha3"
          >
            <Input
              defaultValue={country?.alpha3 || ""}
              placeholder="Country alpha3 code"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country icon file ID"
            key="iconFileId"
            initialValue={country?.icon?.id || ""}
            name="iconFileId"
          >
            <Input
              defaultValue={country?.icon?.id || ""}
              placeholder="Country icon file ID"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country localized names"
            key="localizedNames"
            initialValue={country?.icon?.id || ""}
            name="localizedNames"
          >
            <>
              {localizedNames.map((localizedName) => (
                <Tag
                  key={localizedName.name}
                  closable
                  onClose={() => handleCloseNameTag(localizedName.name)}
                >
                  {localizedName.name}
                </Tag>
              ))}
            </>
            {!addNewLocalizedNameMode && (
              <Button onClick={showAddNewNameClick}>Add localized name</Button>
            )}
            {addNewLocalizedNameMode && (
              <>
                <Input.Group>
                  <Form.Item name="localizedName">
                    <Input placeholder="Localized name" />
                  </Form.Item>
                  <Form.Item name="langId">
                    <Input placeholder="Language ID" />
                  </Form.Item>
                </Input.Group>
                <Row>
                  <Col>
                    <Button onClick={handleAddNewName}>
                      Add new localized name
                    </Button>
                  </Col>
                  <Col>
                    <Button danger onClick={hideAddNewNameClick}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.Item>
          <Form.Item
            label="Tags"
            key="tags"
            initialValue={country?.item?.tags?.join(", ") || ""}
            name="tags"
          >
            <Input
              defaultValue={country?.item?.tags?.join(", ") || ""}
              placeholder="Country icon file ID"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Country languages IDs"
            key="languageIds"
            initialValue={country?.languages || ""}
            name="languageIds"
          >
            <Input
              defaultValue={country?.languages?.join(", ") || ""}
              placeholder="Country languages IDs"
            ></Input>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Save new country info
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
