import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";

const getUser = createEvent();
const resetUser = createEvent();

const getUserFx = createEffect(async () => {
  const response = await requestClient.viewer();
  return response.viewer;
});

const $isLoading = getUserFx.pending;
const $user = restore(getUserFx.doneData, null).reset(resetUser);

forward({
  from: getUser,
  to: getUserFx,
});

export const userModel = { $isLoading, $user, getUser, resetUser };
