import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Descriptions, Spin } from "antd";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { AdminTemplate } from "shared/templates";
import { cryptoBalancesModel } from "features";
import { CurrentCryptoBalanceView } from "entities/index";

export const CurrentCryptoBalancePage: React.FC = () => {
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    cryptoBalancesModel.getCryptoBalances({
      filter: { ids: [params?.id as string] },
    });
  }, []);

  const chosenBalance = useStore(cryptoBalancesModel?.$cryptoBalances);
  const isLoading = useStore(cryptoBalancesModel?.$isLoading);

  return (
    <AdminTemplate>
      {isLoading || !chosenBalance ? (
        <Spin />
      ) : (
        <CurrentCryptoBalanceView cryptoBalance={chosenBalance[0]} />
      )}
    </AdminTemplate>
  );
};
