import React, { useEffect } from "react";

import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";

import { Bank } from "shared/api/apollo/__generated__";

import { updateBankInfoModel } from "../model";

interface ChangeBankInfoFormProps {
  bank: Bank;
}

export const ChangeBankInfoForm: React.FC<ChangeBankInfoFormProps> = ({
  bank,
}) => {
  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const isLoading = useStore(updateBankInfoModel.$isLoading);

  const handleUpdateBankClick = (values: {
    address: string;
    bankCode: string;
    bankName: string;
    bankBic: string;
    bankCity: string;
    bankCountry: string;
    name: string;
    code: string;
  }) => {
    updateBankInfoModel.updateBankInfo({
      id: bank?.id,
      bank: {
        address: values?.address,
        bankCode: values?.bankCode,
        bankName: values?.bankName,
        bic: values?.bankBic,
        city: values?.bankCity,
        country: values?.bankCountry,
        name: bank?.item.name,
        code: bank.item?.code,
      },
    });
  };
  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          layout="horizontal"
          name="updateBank"
          onFinish={handleUpdateBankClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 2 }}
        >
          <Form.Item
            label="Bank address"
            key="address"
            initialValue={bank.address || ""}
            rules={[{ required: true, message: "This field is required!" }]}
            name="address"
          >
            <Input
              defaultValue={bank.address || ""}
              placeholder="Bank address"
            ></Input>
          </Form.Item>
          <Form.Item
            initialValue={(bank.bankCode as string) || ""}
            label="Bank code"
            key="bankCode"
            rules={[{ required: true, message: "This field is required!" }]}
            required
            name="bankCode"
          >
            <Input
              defaultValue={(bank.bankCode as string) || ""}
              placeholder="Bank code"
            ></Input>
          </Form.Item>
          <Form.Item
            initialValue={bank.bankName || ""}
            label="Bank name"
            key="bankName"
            rules={[{ required: true, message: "This field is required!" }]}
            name="bankName"
          >
            <Input
              defaultValue={bank.bankName || ""}
              placeholder="Bank name"
            ></Input>
          </Form.Item>
          <Form.Item
            initialValue={bank.bic || ""}
            label="Bank BIC"
            key="bankBic"
            rules={[{ required: true, message: "This field is required!" }]}
            name="bankBic"
          >
            <Input defaultValue={bank.bic || ""} placeholder="Bank BIC"></Input>
          </Form.Item>
          <Form.Item
            initialValue={bank.city || ""}
            label="Bank city"
            key="bankCity"
            rules={[{ required: true, message: "This field is required!" }]}
            name="bankCity"
          >
            <Input
              defaultValue={bank.city || ""}
              placeholder="Bank city"
            ></Input>
          </Form.Item>
          <Form.Item
            initialValue={bank.country || ""}
            label="Bank country"
            key="bankCountry"
            rules={[{ required: true, message: "This field is required!" }]}
            name="bankCountry"
          >
            <Input
              defaultValue={bank.country || ""}
              placeholder="Bank country"
            ></Input>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Save new bank info
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
