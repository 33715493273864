import { Region } from "shared/api/apollo/__generated__";
import { SerializedRegion } from "../config";

export const serializeRegion = (data: Region): SerializedRegion => ({
  name: data?.item?.name,
  id: data?.item?.id,
  code: data?.item?.code,
  countryName: data?.country?.item?.name as string,
  countryId: data?.country?.item?.id as string,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  tags: data?.item?.tags as string[],
});
