import React from "react";

import { RegionsBlock } from "features";

import { AdminTemplate } from "shared/templates";

export const DictionaryRegionsPage: React.FC = () => {
  return (
    <AdminTemplate>
      <RegionsBlock />
    </AdminTemplate>
  );
};
