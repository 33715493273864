import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  Bank,
  GetBankInput,
  GetBanksInput,
} from "shared/api/apollo/__generated__";

const getBanks = createEvent<GetBanksInput>();
const getOneBank = createEvent<GetBankInput>();

const getBanksFx = createEffect(async (input: GetBanksInput) => {
  const response = await requestClient.getBanks({ input });
  return response.getBanks;
});

const getOneBankFx = createEffect(async (input: GetBankInput) => {
  const response = await requestClient.getBank({ input });
  return response.getBank;
});

const $banksList = createStore<Bank[]>([]);
const $banksCount = createStore<number>(0);
const $isLoading = combine(
  getBanksFx.pending,
  getOneBankFx.pending,
  (isBanksLoading, isBankLoading) => isBankLoading || isBanksLoading
);

forward({
  from: getBanks,
  to: getBanksFx,
});

forward({
  from: getOneBank,
  to: getOneBankFx,
});

sample({
  clock: getBanksFx.doneData,
  fn: (banks) => banks.nodes,
  target: $banksList,
});

sample({
  clock: getBanksFx.doneData,
  fn: (banks) => banks.count,
  target: $banksCount,
});

sample({
  clock: getOneBankFx.doneData,
  filter: (bank) => Boolean(bank),
  fn: (bank) => [bank] as Bank[],
  target: $banksList,
});

sample({
  clock: getOneBankFx.doneData,
  filter: (bank) => Boolean(bank),
  fn: () => 1,
  target: $banksCount,
});

export const banksModel = {
  getBanks,
  $banksList,
  $isLoading,
  $banksCount,
  getOneBank,
};
