import { SearchAccountsInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: SearchAccountsInput = {};
  const searchType = searchParams.get("searchType");
  const id = searchParams.get("id");
  if (searchType === "currencyId" && id) {
    params.currencyIds = [id as string];
  }
  if (searchType === "accountNumber" && id) {
    params.accountNumbers = [id as string];
  }
  if (searchType === "walletId" && id) {
    params.walletIds = [id as string];
  }
  if (searchType === "userId" && id) {
    params.userIds = [id as string];
  }
  params.withNonZeroBalances = Boolean(searchParams.get("onlyPositive"));
  params.filter = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
    ids:
      searchType === "accountId"
        ? searchParams.get("id")
          ? [searchParams.get("id") as string]
          : []
        : [],
  };
  return params;
};
