import React from "react";

import { Button, Form, Input, Row, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createBankModel } from "../model";

export const CreateBankForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoading = useStore(createBankModel.$isLoading);

  const [form] = useForm();

  const handleCreateBankClick = (values: {
    address: string;
    bankCode: string;
    bankName: string;
    bankBic: string;
    bankCity: string;
    bankCountry: string;
    name: string;
    code: string;
  }) => {
    createBankModel.createBank({
      bank: {
        address: values?.address,
        bankCode: values?.bankCode,
        bankName: values?.bankName,
        bic: values?.bankBic,
        city: values?.bankCity,
        country: values?.bankCountry,
        name: values?.name,
        code: values?.code,
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/dictionary/banks");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createBank"
          onFinish={handleCreateBankClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item key="label">{t("createBank.title")}</Form.Item>
          <Form.Item
            label={t("createBank.bankAddress")}
            key="address"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="address"
          >
            <Input placeholder={t("createBank.bankAddress")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.bankCode")}
            key="bankCode"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            required
            name="bankCode"
          >
            <Input placeholder={t("createBank.bankCode")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.bankName")}
            key="bankName"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="bankName"
          >
            <Input placeholder={t("createBank.bankName")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.bankBic")}
            key="bankBic"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="bankBic"
          >
            <Input placeholder={t("createBank.bankBic")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.bankCity")}
            key="bankCity"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="bankCity"
          >
            <Input placeholder={t("createBank.bankCity")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.bankCountryId")}
            key="bankCountry"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="bankCountry"
          >
            <Input placeholder={t("createBank.bankCountryId")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.name")}
            key="name"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input placeholder={t("createBank.name")}></Input>
          </Form.Item>
          <Form.Item
            label={t("createBank.code")}
            key="code"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="code"
          >
            <Input placeholder={t("createBank.code")}></Input>
          </Form.Item>
          <Form.Item
            key="submitButton"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
          >
            <Button type="primary" htmlType="submit">
              {t("createBank.createBank")}
            </Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
