import { Input } from "antd";

interface TwoFAModalProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

export const TwoFAInput: React.FC<TwoFAModalProps> = ({
  value,
  onChange,
  label,
}) => {
  return (
    <>
      <p>{label}</p>
      <Input
        maxLength={6}
        minLength={6}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value}
      />
    </>
  );
};
