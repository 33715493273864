import { Language } from "shared/api/apollo/__generated__";
import { SerializedLanguage } from "../config";

export const serializeLanguage = (data: Language): SerializedLanguage => ({
  name: data?.item?.name,
  id: data?.id,
  code: data?.item?.code,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  tags: data?.item?.tags as string[],
});
