import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  PaginationProps,
  Row,
  Spin,
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useTranslation } from "react-i18next";

import { Bank } from "shared/api/apollo/__generated__";

import { DataType } from "../config";
import {
  checkOneBankSearchParams,
  checkSearchParams,
  serializeData,
} from "../lib";
import { banksModel } from "../model";

export const BanksTable: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchPage = searchParams.get("page");
  const currentSearchType = searchParams.get("searchType");

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "bankId");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get("id")) {
      banksModel.getBanks(checkSearchParams(searchParams));
    } else if (searchParams.get("id")) {
      banksModel.getOneBank(checkOneBankSearchParams(searchParams));
      setIsSearchResult(true);
    }
  }, []);

  const banksList = useStore(banksModel?.$banksList);
  const isLoading = useStore(banksModel?.$isLoading);
  const banksCount = useStore(banksModel?.$banksCount);

  const [form] = useForm();

  const columns: ColumnsType<DataType> = [
    {
      title: t("bankTable.bankName"),
      dataIndex: "bankName",
      key: "bankName",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("bankTable.bankCode"),
      dataIndex: "bankCode",
      key: "bankCode",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("bankTable.bankBic"),
      dataIndex: "bankBic",
      key: "bankBic",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("bankTable.bankId"),
      dataIndex: "bankId",
      key: "bankId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
  ];

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    setSearchParams(searchParams);
    banksModel.getBanks(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    banksModel?.getOneBank(checkOneBankSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("bankTable.searchByID"),
          key: "bankId",
        },
        {
          label: t("bankTable.searchByAddress"),
          key: "address",
        },
        {
          label: t("bankTable.searchByName"),
          key: "bankName",
        },
        {
          label: t("bankTable.searchByCode"),
          key: "bankCode",
        },
        {
          label: t("bankTable.searchByBic"),
          key: "bankBic",
        },
      ]}
    />
  );

  const handleRowClick = (id: string) => {
    navigate(`/dictionary/banks/${id}`);
  };

  const handleCreateBankClick = () => {
    navigate("/dictionary/banks/new/create");
  };

  useEffect(() => {
    if (Number(currentSearchPage) && !searchParams.get("id")) {
      banksModel.getBanks(checkSearchParams(searchParams));
    }
  }, [currentSearchPage]);

  const serializedBanks = serializeData(banksList as Bank[]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Form name="search" onFinish={handleSearchClick}>
            <Row style={{ marginBottom: "10px" }} gutter={10}>
              <Col>
                <Button onClick={handleCreateBankClick}>
                  {t("bankTable.createNewBank")}
                </Button>
              </Col>
              <Col>
                <Dropdown overlay={dropdownItems}>
                  <Button>{t(`bankTable.${currentSearchType}`)}</Button>
                </Dropdown>
              </Col>
              <Col>
                <Form.Item
                  noStyle
                  required
                  name="id"
                  style={{ marginLeft: "10px" }}
                >
                  <Input
                    required
                    placeholder={t(`bankTable.${currentSearchType}`)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle style={{ marginLeft: "10px" }}>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    {t("common.search")}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                {isSearchResult && (
                  <Button
                    key="resetSearch"
                    onClick={handleResetSearchResultClick}
                    loading={isLoading}
                    type="primary"
                  >
                    {t("bankTable.resetSearchResult")}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <Table
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record?.bankId),
                style: { cursor: "pointer" },
              };
            }}
            dataSource={serializedBanks}
            columns={columns}
            pagination={false}
            style={{ marginBottom: "10px", userSelect: "none" }}
          />
          <Pagination
            total={banksCount}
            onChange={handlePageClick}
            current={Number(currentSearchPage) ? Number(currentSearchPage) : 1}
            defaultCurrent={1}
            style={{ userSelect: "none" }}
          />
        </>
      )}
    </>
  );
};
