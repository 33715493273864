import React from "react";

import { CreateRegionForm } from "features";

import { AdminTemplate } from "shared/templates";

export const CreateRegionPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateRegionForm />
    </AdminTemplate>
  );
};
