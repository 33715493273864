import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateCountryInput,
} from "shared/api/apollo/__generated__";

const updateCountryInfo = createEvent<UpdateCountryInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteCountry = createEvent<RemoveDictItemsInput>();

const deleteCountryFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteCountry({ input });
  return response.removeCountries;
});

const updateCountryInfoFx = createEffect(async (input: UpdateCountryInput) => {
  const response = await requestClient.updateCountry({ input });
  return response.updateCountry;
});

const $isLoading = combine(
  updateCountryInfoFx.pending,
  deleteCountryFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateCountryInfo,
  to: updateCountryInfoFx,
});

forward({
  from: deleteCountry,
  to: deleteCountryFx,
});

sample({
  clock: updateCountryInfoFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateCountryInfoFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for bank with ID ${data.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateCountryInfoFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteCountryFx.doneData,
  fn: () => (document.location = "/dictionary/countries"),
});

sample({
  clock: deleteCountryFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateCountryModel = {
  $isChangeMode,
  $isLoading,
  updateCountryInfo,
  setIsChangeMode,
  deleteCountry,
};
