import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import "./localization/i18next";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const script = document.createElement("script");
script.src = `https://www.google.com/recaptcha/enterprise.js?render=6LewrDkgAAAAAMA1_fbTY5tI-6xjoTlnEv4rsSHr`;
document.head.append(script);

root.render(<App />);
