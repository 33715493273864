import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateLanguageInput,
} from "shared/api/apollo/__generated__";

const updateLanguage = createEvent<UpdateLanguageInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteLanguage = createEvent<RemoveDictItemsInput>();

const deleteLanguageFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteLanguage({ input });
  return response.removeLanguages;
});

const updateLanguageFx = createEffect(async (input: UpdateLanguageInput) => {
  const response = await requestClient.updateLanguage({ input });
  return response.updateLanguage;
});

const $isLoading = combine(
  deleteLanguageFx.pending,
  updateLanguageFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateLanguage,
  to: updateLanguageFx,
});

forward({
  from: deleteLanguage,
  to: deleteLanguageFx,
});

sample({
  clock: updateLanguageFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateLanguageFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for language with ID ${data.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateLanguageFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteLanguageFx.doneData,
  fn: () => (document.location = "/dictionary/languages"),
});

sample({
  clock: deleteLanguageFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateLanguageModel = {
  $isChangeMode,
  $isLoading,
  updateLanguage,
  setIsChangeMode,
  deleteLanguage,
};
