import { SearchWalletsInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: SearchWalletsInput = {};
  if (searchParams.get("searchType") === "address") {
    params = {
      addresses: searchParams.get("id")
        ? searchParams.get("id")
          ? [searchParams.get("id") as string]
          : []
        : [],
    };
  } else if (searchParams.get("searchType") === "tokens") {
    params = {
      tokens: [searchParams.get("id") as string],
    };
  } else if (searchParams.get("searchType") === "userId") {
    params = {
      userIds: [searchParams.get("id") as string],
    };
  }
  params.filter = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
    ids:
      searchParams.get("searchType") === "balanceId"
        ? [searchParams.get("id") as string]
        : [],
  };
  return params;
};
