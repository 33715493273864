import { Spin } from "antd";
import { useStore } from "effector-react";
import { CurrentNaceCodeInfoView } from "entities/index";
import { naceCodesModel } from "features/NaceCodesBlock/model";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { NaceCode } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";

export const CurrentNaceCodePage: React.FC = () => {
  const params = useParams();
  useEffect(() => {
    naceCodesModel.getNaceCode({ id: params.id });
  }, [params?.id]);

  const isLoading = useStore(naceCodesModel.$oneNaceCodeLoading);
  const chosenNaceCode = useStore(naceCodesModel.$chosenNaceCode);

  return (
    <AdminTemplate>
      {isLoading ? (
        <Spin />
      ) : (
        <CurrentNaceCodeInfoView naceCode={chosenNaceCode as NaceCode} />
      )}
    </AdminTemplate>
  );
};
