import React, { useEffect } from "react";

import { Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import { balancesModel } from "features";

import { CurrentFiatBalance } from "entities/index";

import { AdminTemplate } from "shared/templates";

import { serializeData } from "../lib";

export const CurrentFiatBalancePage: React.FC = () => {
  const params = useParams();

  useEffect(() => {
    balancesModel?.getBalances({ filter: { ids: [params?.id as string] } });
  }, []);

  const chosenBalance = useStore(balancesModel?.$balances);
  const isLoading = useStore(balancesModel?.$isLoading);

  const balance = serializeData(chosenBalance[0]);

  return (
    <AdminTemplate>
      {isLoading ? <Spin /> : <CurrentFiatBalance fiatBalance={balance} />}
    </AdminTemplate>
  );
};
