import { NaceCode } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeDataForTable = (data: NaceCode[]): DataType[] => {
  const result: DataType[] = [];
  data.forEach((naceCode) => {
    result.push({
      name: naceCode?.item.name,
      code: naceCode?.item?.code,
      codeId: naceCode?.item?.id,
      section: naceCode?.section?.description,
      key: naceCode?.item?.id,
    });
  });
  return result;
};
