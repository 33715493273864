import { NaceCode } from "shared/api/apollo/__generated__";
import { SerializedNaceCodeInfo } from "../config";

export const serializeNaceCodeInfo = (
  data: NaceCode
): SerializedNaceCodeInfo => ({
  code: data?.item?.code,
  name: data?.item.name,
  id: data?.item?.id,
  codeSectionName: data?.section?.item?.name,
  codeSectionCode: data?.section?.item?.code,
  codeSectionDescription: data?.section?.description,
  codeSectionId: data?.section.item?.id,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  tags: data?.item?.tags as string[],
});
