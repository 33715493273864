import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";

const getFiatCurrencies = createEvent();

const getFiatCurrenciesFx = createEffect(async () => {
  const response = await requestClient.currencies();

  return response?.getCurrencies;
});

forward({
  from: getFiatCurrencies,
  to: getFiatCurrenciesFx,
});

const $fiatCurrencies = restore(getFiatCurrenciesFx.doneData, []);

export const fiatCurrenciesModel = { $fiatCurrencies, getFiatCurrencies };
