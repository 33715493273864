import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  PaginationProps,
  Row,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DataType } from "../config";
import { checkSearchParams, serializeDataForTable } from "../lib";
import { naceCodesModel } from "../model";

export const NaceCodesBlock: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType");

  useEffect(() => {
    if (!searchParams.get("id")) {
      searchParams.set("searchType", "naceCode");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    naceCodesModel.getAllNaceCodes(checkSearchParams(searchParams));
    if (searchParams.get("id")) {
      setIsSearchResult(true);
    }
  }, []);

  const isLoading = useStore(naceCodesModel.$isLoading);
  const totalCount = useStore(naceCodesModel.$totalCount);
  const naceCodesList = useStore(naceCodesModel.$naceCodes);

  const columns: ColumnsType<DataType> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text || "-",
    },
    {
      title: "Nace code ID",
      dataIndex: "codeId",
      key: "codeId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "Nace code section",
      dataIndex: "section",
      key: "section",
    },
  ];

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("naceCodes.searchByCode"),
          key: "naceCode",
        },
        {
          label: t("naceCodes.searchById"),
          key: "codeId",
        },
        {
          label: t("naceCodes.searchByParentId"),
          key: "codeParentId",
        },
      ]}
    />
  );

  const tableData = serializeDataForTable(naceCodesList);

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    if (Number(currentSearchPage) && !searchParams.get("id")) {
      naceCodesModel.getAllNaceCodes(checkSearchParams(searchParams));
    }
  }, [currentSearchPage]);

  const handleSearchClick = (values: { id: string }) => {
    searchParams.set("id", values?.id as string);
    searchParams.delete("page");
    setSearchParams(searchParams);
    naceCodesModel?.getAllNaceCodes(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    setSearchParams(searchParams);
    naceCodesModel.getAllNaceCodes(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleRowClick = (id: string) => {
    navigate(`/dictionary/nace-codes/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Form form={form} name="search" onFinish={handleSearchClick}>
            <Row style={{ marginBottom: "10px" }} gutter={10}>
              <Col>
                <Dropdown overlay={dropdownItems}>
                  <Button>{t(`naceCodes.${searchType}`)}</Button>
                </Dropdown>
              </Col>
              <Col>
                <Form.Item
                  noStyle
                  required
                  name="id"
                  style={{ marginLeft: "10px" }}
                >
                  <Input required placeholder={t(`naceCodes.${searchType}`)} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle style={{ marginLeft: "10px" }}>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                {isSearchResult && (
                  <Button
                    key="resetSearch"
                    onClick={handleResetSearchResultClick}
                    loading={isLoading}
                    type="primary"
                  >
                    Reset search result
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <Table
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record?.codeId),
                style: { cursor: "pointer" },
              };
            }}
            style={{ userSelect: "none" }}
            pagination={{
              position: ["bottomLeft"],
              showSizeChanger: false,
              onChange: handlePageClick,
              current: Number(currentSearchPage) || 1,
              total: totalCount,
            }}
            columns={columns}
            dataSource={tableData}
          />
        </>
      )}
    </>
  );
};
