import { Currency } from "shared/api/apollo/__generated__";
import { SerializedCurrency } from "../config";

export const serializeCurrency = (data: Currency): SerializedCurrency => {
  return {
    name: data?.item?.name,
    code: data?.item?.code,
    ticker: data?.ticker,
    type: data?.type,
    symbol: data?.symbol || "-",
    isDeleted: data?.item?.common?.isDeleted.toString(),
    precision: data?.precision.toString(),
    createdAt: data?.item?.common?.timestamps?.createdAt,
    updatedAt: data?.item?.common?.timestamps?.updatedAt,
    tags: data?.item?.tags as string[],
  };
};
