import { combine, createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getBankFromCityName = createEvent<DictFilterInput>();
const getBankToCityName = createEvent<DictFilterInput>();
const getCountryInfo = createEvent<DictFilterInput>();

const getCountryInfoFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.getCountries({ input });
  return response.countries;
});

const getBankFromCityNameFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.searchCities({ input });
  return response.searchCities;
});
const getBankToCityNameFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.searchCities({ input });
  return response.searchCities;
});

const $isLoading = combine(
  getBankFromCityNameFx.pending,
  getBankToCityNameFx.pending,
  getCountryInfoFx.pending,
  (a, b, c) => Boolean(a || b || c)
);

const $bankFromCityInfo = restore(getBankFromCityNameFx.doneData, null);
const $bankToCityInfo = restore(getBankToCityNameFx.doneData, null);
const $countryInfo = restore(getCountryInfoFx.doneData, null);

forward({
  from: getBankFromCityName,
  to: getBankFromCityNameFx,
});

forward({
  from: getBankToCityName,
  to: getBankToCityNameFx,
});

forward({
  from: getCountryInfo,
  to: getCountryInfoFx,
});

export const detailedInfoModel = {
  getBankFromCityName,
  getBankToCityName,
  getCountryInfo,
  $bankFromCityInfo,
  $bankToCityInfo,
  $isLoading,
  $countryInfo,
};
