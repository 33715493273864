import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateRegionInput,
} from "shared/api/apollo/__generated__";

const updateRegion = createEvent<UpdateRegionInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteRegion = createEvent<RemoveDictItemsInput>();

const deleteRegionFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteRegion({ input });
  return response.removeRegions;
});

const updateRegionFx = createEffect(async (input: UpdateRegionInput) => {
  const response = await requestClient.updateRegion({ input });
  return response.updateRegion;
});

const $isLoading = combine(
  updateRegionFx.pending,
  deleteRegionFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateRegion,
  to: updateRegionFx,
});

forward({
  from: deleteRegion,
  to: deleteRegionFx,
});

sample({
  clock: updateRegionFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateRegionFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for region with ID ${data.item?.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateRegionFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteRegionFx.doneData,
  fn: () => (document.location = "/dictionary/regions"),
});

sample({
  clock: deleteRegionFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateRegionModel = {
  $isChangeMode,
  $isLoading,
  updateRegion,
  setIsChangeMode,
  deleteRegion,
};
