import {
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";

import { requestClient } from "shared/api/apollo/requestClient";
import {
  CardTransaction,
  CashTransaction,
  CryptoTransaction,
  EarnTransaction,
  SearchTransactionsInput,
  TransactionWithRepeatable,
} from "shared/api/apollo/__generated__";
import { serializeAccounts } from "../lib";

export type Result = {
  crypto?: CryptoTransaction[];
  card?: CardTransaction[];
  cash?: CashTransaction[];
  earn?: EarnTransaction[];
};

const getAllTransactions = createEvent<SearchTransactionsInput>();

const getAllTransactionsFx = createEffect(
  async (input: SearchTransactionsInput) => {
    const response = await requestClient.getAllTransactions({ input });
    return response.searchTransactions;
  }
);

const $allTransactions = createStore<TransactionWithRepeatable[]>([]);
const $serializedAccounts = createStore<Result>({});
const $transactionsCount = createStore<number>(0);

const $isTransactionsOnLoad = getAllTransactionsFx.pending;

forward({
  from: getAllTransactions,
  to: getAllTransactionsFx,
});

sample({
  clock: $allTransactions,
  fn: (transactions) => serializeAccounts(transactions),
  target: $serializedAccounts,
});

sample({
  clock: getAllTransactionsFx.doneData,
  source: $allTransactions,
  fn: (oldTransactions, result) => {
    return [...(result?.nodes as TransactionWithRepeatable[])];
  },
  target: $allTransactions,
});

sample({
  clock: getAllTransactionsFx.doneData,
  fn: (result) => result?.count,
  target: $transactionsCount,
});

export const allTransactionsModel = {
  getAllTransactions,
  $allTransactions,
  $transactionsCount,
  $serializedAccounts,
  $isTransactionsOnLoad,
};
