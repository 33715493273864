import React from "react";

import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Account } from "shared/api/apollo/__generated__";

interface FiatBalanceProps {
  fiatBalance: Account;
}

export const CurrentFiatBalance: React.FC<FiatBalanceProps> = ({
  fiatBalance,
}) => {
  const { t } = useTranslation();

  return (
    <Descriptions
      column={1}
      style={{ minWidth: "600px", maxWidth: "800px" }}
      bordered
      size="small"
      title={t("chosenFiatBalance.fiatAccountCard")}
    >
      {fiatBalance && (
        <>
          {Object.entries(fiatBalance)?.map((balanceInfo) => {
            if (
              (typeof balanceInfo[1] === "string" ||
                typeof balanceInfo[1] === "boolean") &&
              !balanceInfo[0].includes("__")
            ) {
              if (balanceInfo[0] === "userId") {
                return (
                  <Descriptions.Item
                    label={t(`chosenFiatBalance.${balanceInfo[0]}`)}
                  >
                    <Link to={`/all-users/${balanceInfo[1]}`}>
                      {balanceInfo[1]}
                    </Link>
                  </Descriptions.Item>
                );
              }
              return (
                <Descriptions.Item
                  key={balanceInfo[0]}
                  label={t(`chosenFiatBalance.${balanceInfo[0]}`)}
                >
                  {balanceInfo[1].toString() || "-"}
                </Descriptions.Item>
              );
            } else if (
              typeof balanceInfo[1] === "object" &&
              balanceInfo[1]?.__typename === "Balance"
            ) {
              return (
                <Descriptions.Item
                  key={balanceInfo[0]}
                  label={t(`chosenFiatBalance.${balanceInfo[0]}`)}
                >
                  {`${balanceInfo[1]?.available?.amount} ${balanceInfo[1]?.available?.currency?.ticker}` ||
                    "-"}
                </Descriptions.Item>
              );
            }
          })}
        </>
      )}
    </Descriptions>
  );
};
