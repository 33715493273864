import React, { useEffect } from "react";

import { Form, Input, Button } from "antd";
import "antd/dist/antd.min.css";
import { useTranslation } from "react-i18next";

import { useStore } from "effector-react";

import { TwoFAModal } from "features";

import { loginModel } from "../model";

import {
  Device,
  Service,
  TfaType,
  VerifyTokenInput,
} from "shared/api/apollo/__generated__";

export const LogInPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem("@accessToken");
    localStorage.removeItem("@refreshToken");
  }, []);

  const loading = useStore(loginModel.$isPending);
  const $showModal = useStore(loginModel.$toggleTFAModal);
  const signInResponse = useStore(loginModel.$signInResponse);
  const codeMessage = useStore(loginModel.$resendCodeMessage);

  const onFinish = (values: { username: string; password: string }) => {
    loginModel.signIn({
      device: Device.Email,
      identity: values?.username,
      password: values?.password,
      service: Service.Banxeid,
    });
  };

  const isLoading = useStore(loginModel.$isSendingTFARequest);
  return (
    <div style={{ padding: "20px 0" }}>
      <Form
        name="basic"
        layout="vertical"
        style={{ maxWidth: 960, margin: "0 auto", width: "100%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("common.submit")}
          </Button>
        </Form.Item>
      </Form>
      {signInResponse?.tfaTypes && (
        <TwoFAModal
          isLoading={isLoading}
          resetError={loginModel.resetCodeMessage}
          codeRequestMessage={codeMessage}
          onResend={(type: TfaType) => {
            loginModel.resendCode(type);
          }}
          onSubmit={(tokens: Array<VerifyTokenInput>) => {
            loginModel.twoFAconfirm({
              operationId: signInResponse.operationId,
              tokens: tokens,
            });
            loginModel.resetCodeMessage();
          }}
          tfaTypes={signInResponse.tfaTypes}
          showModal={$showModal}
          toggleModal={() => loginModel.toggleTFAModal(false)}
        />
      )}
    </div>
  );
};
