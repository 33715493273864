import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { CurrentCurrencyView } from "entities/index";
import { updateCurrencyModel } from "features";
import { UpdateCurrencyInfoForm } from "features/ChangeCurrencyInfoForm/ui";
import { currenciesModel } from "features/CurrenciesBlock/model";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Currency } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";

export const CurrentCurrencyPage: React.FC = () => {
  const params = useParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isLoading = useStore(currenciesModel.$isLoading);
  const chosenCurrency = useStore(currenciesModel.$currencies);
  const isEditMode = useStore(updateCurrencyModel.$isChangeMode);

  useEffect(() => {
    currenciesModel.getCurrencies({ tickers: [params?.id as string] });
  }, [isEditMode]);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateCurrencyModel.setIsChangeMode(false)
      : updateCurrencyModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteBankClick = () => {
    setIsModalOpen(false);
    updateCurrencyModel.deleteCurrency({
      ids: [chosenCurrency[0]?.id as string],
    });
  };

  return (
    <>
      {" "}
      <AdminTemplate>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <UpdateCurrencyInfoForm
                currency={chosenCurrency[0] as Currency}
              />
            ) : (
              <CurrentCurrencyView currency={chosenCurrency[0] as Currency} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit currency info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete currency
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteBankClick}
        visible={isModalOpen}
        title="Delete currency confirmation"
      >
        Delete "{chosenCurrency[0]?.item?.name}" currency?
      </Modal>
    </>
  );
};
