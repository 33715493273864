import { Currency } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeDataForTable = (data: Currency[]): DataType[] => {
  const result: DataType[] = [];
  data.forEach((currency) =>
    result.push({
      currencyId: currency?.id,
      name: currency?.item?.name,
      ticker: currency?.ticker,
      currencyType: currency?.type,
      key: currency?.id,
    })
  );
  return result;
};
