import { CardTransaction } from "shared/api/apollo/__generated__";
import { SerializedCardTransaction } from "../config";

export const serializeCardTransaction = (
  data: CardTransaction
): SerializedCardTransaction => ({
  cardCategory: data?.cardCategory,
  cardDirection: data?.cardDirection as string,
  cardId: data?.cardId as string,
  cardStatus: data?.cardStatus,
  cryptoAmount: `${data?.cryptoAmount} ${data?.cryptoCoin?.ticker}`,
  cryptoFee: `${data?.cryptoFee} ${data?.cryptoCoin?.ticker}`,
  fiatAmount: `${data?.fiatAmount} ${data?.fiatCoin?.ticker}`,
  fiatFee: `${data?.fiatFee} ${data?.fiatCoin?.ticker}`,
  serviceFee: `${data?.serviceFee} ${data?.serviceCoin?.ticker}`,
  serviceFeeInCryptoCurrency: `${data?.serviceFeeInCryptoCurrency} ${data?.cryptoCoin?.ticker}`,
  id: data?.id,
  merchantCountryCode: data?.merchantCountryCode as string,
  merchantName: data?.merchantName as string,
  time: data?.time,
  totalCryptoAmount: `${data?.totalCryptoAmount} ${data?.cryptoCoin?.ticker}`,
  transactionId: data?.transactionId,
  userId: data?.userId,
});
