import { TransactionWithRepeatable } from "shared/api/apollo/__generated__";
import { DataType } from "../config/tableInterface";

export const serializeTransInfo = (
  transactions: TransactionWithRepeatable[]
) => {
  let result: DataType[] = [];
  transactions?.forEach((trans) => {
    if (trans?.transaction?.__typename === "CryptoTransaction") {
      result.push({
        type: "Crypto",
        amount: `${trans?.transaction?.amount} ${trans?.transaction?.coin?.ticker}`,
        category: trans?.transaction?.cryptoCategory,
        status: trans?.transaction?.cryptoStatus,
        time: trans?.transaction?.time,
        transId: trans?.transaction?.id,
      });
    } else if (trans?.transaction?.__typename === "CashTransaction") {
      result.push({
        type: "Cash",
        amount: `${trans?.transaction?.amount} ${trans?.transaction?.coin?.ticker}`,
        category: trans?.transaction?.cashCategory,
        status: trans?.transaction?.status,
        time: trans?.transaction?.time,
        transId: trans?.transaction?.id,
      });
    } else if (trans?.transaction?.__typename === "CardTransaction") {
      result.push({
        type: "Card",
        amount: `${trans?.transaction?.fiatAmount} ${trans?.transaction?.fiatCoin?.ticker}`,
        category: trans?.transaction?.cardCategory,
        status: trans?.transaction?.cardStatus,
        time: trans?.transaction?.time,
        transId: trans?.transaction?.id,
      });
    } else if (trans?.transaction?.__typename === "EarnTransaction") {
      result.push({
        type: "Earn",
        amount: `${trans?.transaction.amount} ${trans?.transaction?.coin?.ticker}`,
        category: trans?.transaction?.operation,
        status: trans?.transaction?.direction,
        time: trans?.transaction?.time,
        transId: trans?.transaction?.id,
      });
    }
  });
  return result;
};
