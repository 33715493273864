import React from "react";
import { useTranslation } from "react-i18next";

import { AllTransactionsBlock } from "features";

import { AdminTemplate } from "shared/templates";

export const TransactionsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AdminTemplate>
      <h1>{t("pagesNames.allTransactions")}</h1>
      <AllTransactionsBlock />
    </AdminTemplate>
  );
};
