import { Bank } from "shared/api/apollo/__generated__";
import { SerializedBankType } from "../config";

export const serializeBankInfo = (data: Bank): SerializedBankType => ({
  bankName: data?.item?.name,
  bankId: data?.id,
  bankCode: data?.bankCode as string,
  bankAddress: data?.address,
  bankBic: data?.bic as string,
  bankSwift: data?.swift as string,
  bankCity: data?.city,
  bankCountry: data?.country,
  isDeleted: data?.item?.common?.isDeleted.toString(),
  createdAt: data?.item?.common?.timestamps?.createdAt,
  updatedAt: data?.item?.common?.timestamps?.updatedAt,
  bankTags: data?.item?.tags as string[],
});
