export const CAPTCHA_KEY = "6LewrDkgAAAAAMA1_fbTY5tI-6xjoTlnEv4rsSHr";

export const getToken = (action: string) =>
  new Promise<string>((resolve) => {
    // @ts-ignore
    window.grecaptcha.enterprise.ready(async () => {
      // @ts-ignore
      const token: string = await window.grecaptcha.enterprise.execute(
        CAPTCHA_KEY,
        { action }
      );
      resolve(token);
    });
  });
