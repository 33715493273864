import { SearchTransactionsInput } from "shared/api/apollo/__generated__";

export const checkSearchParams = (searchParams: URLSearchParams) => {
  let params: SearchTransactionsInput = {};
  if (searchParams.get("id")) {
    if (searchParams.get("searchType") === "transId") {
      params.transactionIds = [searchParams.get("id") as string];
    }
  }
  if (searchParams.get("from") && searchParams.get("to")) {
    params.dateFilter = {
      from: searchParams.get("from"),
      to: searchParams.get("to"),
    };
  }
  params.filter = {
    skip:
      Number(searchParams.get("page")) >= 1
        ? (Number(searchParams.get("page")) - 1) * 10
        : 0,
    limit: 10,
  };
  return params;
};
