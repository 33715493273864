import React, { useEffect, useState } from "react";

import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import { banksModel, updateBankInfoModel, ChangeBankInfoForm } from "features";

import { CurrentBankView } from "entities/index";

import { AdminTemplate } from "shared/templates";

export const CurrentBankPage: React.FC = () => {
  const params = useParams();

  const isEditMode = useStore(updateBankInfoModel.$isChangeMode);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isEditMode) {
      banksModel.getOneBank({ id: params?.id });
    }
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      updateBankInfoModel.setIsChangeMode(false);
    };
  }, []);

  const chosenBank = useStore(banksModel.$banksList);
  const isLoading = useStore(banksModel.$isLoading);
  const isChangeOnLoad = useStore(updateBankInfoModel.$isLoading);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateBankInfoModel.setIsChangeMode(false)
      : updateBankInfoModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteBankClick = () => {
    setIsModalOpen(false);
    updateBankInfoModel.deleteBank({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeBankInfoForm bank={chosenBank[0]} />
            ) : (
              <CurrentBankView bank={chosenBank[0]} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit bank info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete bank
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteBankClick}
        visible={isModalOpen}
        title="Delete bank confirmation"
      >
        Delete "{chosenBank[0]?.item?.name}" bank?
      </Modal>
    </>
  );
};
