import {
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";

import { getNewToken } from "shared/api/apollo/client";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  AuthInput,
  TfaType,
  Verify2FaInput,
} from "shared/api/apollo/__generated__";

import { getToken, serverErrorsFormat } from "shared/lib";

import { getAuthStatusFromToken } from "./lid";

const signIn = createEvent<Omit<AuthInput, "captcha">>();
const logOut = createEvent();
const checkAuth = createEvent();

type SignInResponseType = {
  operationId: string;
  tfaTypes: TfaType[];
};

const signInFx = createEffect(
  async (input: Omit<AuthInput, "captcha">): Promise<SignInResponseType> => {
    console.log("123152");
    localStorage.clear();
    const token = await getToken("LOGIN");
    console.log("working here");
    const response = await requestClient.signIn({
      input: {
        ...input,
        captcha: { captchaAction: "LOGIN", captchaToken: token },
      },
    });

    console.log("isWorking");

    return {
      operationId: response.signIn.operationId,
      tfaTypes: response.signIn.response,
    };
  }
);

signInFx.fail.watch(console.log);

forward({
  from: signIn,
  to: signInFx,
});

const $signInResponse = createStore<SignInResponseType | null>(null).on(
  signInFx.doneData,
  (_, newState) => newState
);

const twoFAconfirm = createEvent<Verify2FaInput>();

const twoFAconfirmFx = createEffect(async (twoFAconfirm: Verify2FaInput) => {
  const response = await requestClient.confirmSignIn({
    input: {
      tfaVerifyData: {
        operationId: twoFAconfirm.operationId,
        tokens: twoFAconfirm.tokens,
      },
    },
  });
  return response;
});

sample({
  clock: twoFAconfirmFx.doneData,
  fn: (response) => {
    localStorage.setItem("@accessToken", response.confirmSignIn.accessToken);
    localStorage.setItem("@refreshToken", response.confirmSignIn.refreshToken);
    window.location.href = "/";
  },
});

forward({
  from: twoFAconfirm,
  to: twoFAconfirmFx,
});

const resendCode = createEvent<TfaType>();
const resendCodeFx = createEffect(async (input?: any) => {
  if (input?.operationId) {
    const response = await requestClient.send2FAToken({
      input,
    });
    return response;
  }
});
const resetCodeMessage = createEvent();
const $resendCodeMessage = createStore<string>("").reset(resetCodeMessage);

const $isSendingTFARequest = twoFAconfirmFx.pending;

sample({
  clock: twoFAconfirmFx.failData,
  fn: () => "Error! Wrong code",
  target: $resendCodeMessage,
});

sample({
  clock: twoFAconfirmFx.doneData,
  fn: () => "Code sended successfully!",
  target: $resendCodeMessage,
});

sample({
  clock: resendCodeFx.failData,
  fn: (error) => serverErrorsFormat(error),
  target: $resendCodeMessage,
});

sample({
  clock: resendCode,
  source: $signInResponse,
  fn: (signInResponse, type) => {
    const operationId = signInResponse?.operationId;
    return { operationId, type };
  },
  target: resendCodeFx,
});

const toggleTFAModal = createEvent<boolean>();

const $toggleTFAModal = createStore(false)
  .on(signInFx.doneData, (prev) => {
    return !prev;
  })
  .on(toggleTFAModal, () => false);

const $isLoggedIn = createStore(
  getAuthStatusFromToken(localStorage.getItem("@accessToken"))
)
  .on(signInFx.done, () => true)
  .on(logOut, () => false);

const getTokenFx = createEffect(async () => {
  return await getNewToken();
});

forward({
  from: getTokenFx.failData,
  to: logOut,
});

forward({
  from: checkAuth,
  to: getTokenFx,
});

const setTokensFx = createEffect((response: any) => {
  localStorage.setItem("@accessToken", response.data.token.accessToken);
  localStorage.setItem("@refreshToken", response.data.token.refreshToken);
});

forward({
  from: getTokenFx.doneData,
  to: setTokensFx,
});

const $isPending = signInFx.pending;

export const loginModel = {
  signIn,
  logOut,
  $isLoggedIn,
  $isPending,
  checkAuth,
  $toggleTFAModal,
  toggleTFAModal,
  $signInResponse,
  twoFAconfirm,
  resendCode,
  $resendCodeMessage,
  resetCodeMessage,
  $isSendingTFARequest,
};
