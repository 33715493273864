import React from "react";

import { Descriptions, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { City } from "shared/api/apollo/__generated__";

import { serializeCity } from "../lib";

interface CityProps {
  city: City;
}

export const CurrentCityView: React.FC<CityProps> = ({ city }) => {
  const { t } = useTranslation();

  const descriptionData = serializeCity(city);

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title="City card"
      style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
    >
      {descriptionData &&
        Object.entries(descriptionData).map((info) => {
          if (!Array.isArray(info[1])) {
            if (info[0] === "country") {
              return (
                <Descriptions.Item key={info[0]} label={t(`cities.${info[0]}`)}>
                  <Link
                    to={`/dictionary/countries/${descriptionData?.countryId}`}
                  >
                    {info[1]?.toString()}
                  </Link>
                </Descriptions.Item>
              );
            }
            return (
              <Descriptions.Item key={info[0]} label={t(`cities.${info[0]}`)}>
                {info[1] || "-"}
              </Descriptions.Item>
            );
          } else {
            return (
              <Descriptions.Item key={info[0]} label={t(`cities.${info[0]}`)}>
                {info[1].length ? info[1].map((tag) => <Tag>{tag}</Tag>) : "-"}
              </Descriptions.Item>
            );
          }
        })}
    </Descriptions>
  );
};
