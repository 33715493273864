import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getContinents = createEvent<DictFilterInput>();

const getContinentsFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.continents({ input });
  return response.continents;
});

const $continents = restore(getContinentsFx.doneData, []);
const $isLoading = getContinentsFx.pending;

forward({
  from: getContinents,
  to: getContinentsFx,
});

export const continentsModel = { getContinents, $continents, $isLoading };
