import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
  sample,
} from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  GetNaceCodeInput,
  GetNaceCodesInput,
  NaceCode,
} from "shared/api/apollo/__generated__";

const getAllNaceCodes = createEvent<GetNaceCodesInput>();
const getNaceCode = createEvent<GetNaceCodeInput>();

const getAllNaceCodesFx = createEffect(async (input: GetNaceCodesInput) => {
  const response = await requestClient.naceCodes({ input });
  return response.naceCodes;
});

const getNaceCodeFx = createEffect(async (input: GetNaceCodeInput) => {
  const response = await requestClient.naceCode({ input });
  return response.naceCode;
});

const $totalCount = createStore<number>(0);
const $naceCodes = createStore<NaceCode[]>([]);
const $chosenNaceCode = restore(getNaceCodeFx.doneData, null);

const $isLoading = getAllNaceCodesFx.pending;
const $oneNaceCodeLoading = getNaceCodeFx.pending;

sample({
  clock: getAllNaceCodesFx.doneData,
  fn: (data) => Number(data?.count),
  target: $totalCount,
});

sample({
  clock: getAllNaceCodesFx.doneData,
  fn: (data) => data?.nodes as NaceCode[],
  target: $naceCodes,
});

forward({
  from: getAllNaceCodes,
  to: getAllNaceCodesFx,
});

forward({
  from: getNaceCode,
  to: getNaceCodeFx,
});

export const naceCodesModel = {
  getAllNaceCodes,
  $isLoading,
  $naceCodes,
  $totalCount,
  $chosenNaceCode,
  getNaceCode,
  $oneNaceCodeLoading,
};
