import { Button, Form } from "antd";
import Modal from "antd/lib/modal/Modal";

import { TwoFAInput } from "./TwoFAInput";
import Text from "antd/lib/typography/Text";
import { useForm } from "effector-forms";
import {
  TfaType,
  VerifyTokenInput,
} from "shared/api/apollo/__generated__";
import { twoFactorForm } from "features/twoFactor/model";

interface TwoFAModalProps {
  tfaTypes: Array<TfaType>;
  showModal: boolean;
  toggleModal: (value: boolean) => void;
  onSubmit: (tokens: Array<VerifyTokenInput>) => void;
  onResend?: (type: TfaType) => void;
  codeRequestMessage?: string;
  resetError?: () => void;
  isLoading?: boolean;
}

export const TwoFAModal: React.FC<TwoFAModalProps> = ({
  tfaTypes,
  showModal,
  toggleModal,
  onSubmit,
  onResend = () => {},
  codeRequestMessage,
  resetError,
  isLoading,
}) => {
  const { fields } = useForm(twoFactorForm);
  const tokens: Array<VerifyTokenInput> = [];
  if (tfaTypes.includes(TfaType.Email)) {
    tokens.push({ token: fields.emailCode.value, type: TfaType.Email });
  }
  if (tfaTypes.includes(TfaType.Otp)) {
    tokens.push({ token: fields.otpCode.value, type: TfaType.Otp });
  }
  if (tfaTypes.includes(TfaType.Sms)) {
    tokens.push({ token: fields.smsCode.value, type: TfaType.Sms });
  }

  return (
    <Modal
      confirmLoading={isLoading}
      onOk={() => {
        onSubmit(tokens);
      }}
      visible={showModal}
      onCancel={() => {
        toggleModal(false);
        resetError && resetError();
        fields.emailCode.reset();
        fields.otpCode.reset();
        fields.smsCode.reset();
      }}
      title="Confirm via 2FA"
    >
      {tfaTypes.includes(TfaType.Email) && (
        <Form.Item>
          <TwoFAInput
            label="Enter email code"
            value={fields.emailCode.value}
            onChange={fields.emailCode.onChange}
          />
        </Form.Item>
      )}
      {tfaTypes.includes(TfaType.Otp) && (
        <Form.Item>
          <TwoFAInput
            label="Enter otp code"
            value={fields.otpCode.value}
            onChange={fields.otpCode.onChange}
          />
        </Form.Item>
      )}
      {tfaTypes.includes(TfaType.Sms) && (
        <Form.Item>
          <TwoFAInput
            label="Enter sms code"
            value={fields.smsCode.value}
            onChange={fields.smsCode.onChange}
          />
        </Form.Item>
      )}
      {codeRequestMessage && (
        <Form.Item>
          <Text type="secondary">{codeRequestMessage}</Text>
        </Form.Item>
      )}

      <Button type="primary" onClick={() => onResend(TfaType.Email)}>
        Resend Code
      </Button>
    </Modal>
  );
};
