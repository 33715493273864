import React, { useEffect } from "react";

import { Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import { usersModel } from "features";

import { CurrentUserView } from "entities/index";

import { AdminTemplate } from "shared/templates";

export const CurrentUserPage: React.FC = () => {
  const params = useParams();

  useEffect(() => {
    usersModel.getAllUsers({ userIds: [params.id as string] });
  }, []);

  const isLoading = useStore(usersModel.$isLoading);
  const chosenUser = useStore(usersModel.$allUsers);

  return (
    <AdminTemplate>
      {isLoading ? <Spin /> : <CurrentUserView user={chosenUser[0]} />}
    </AdminTemplate>
  );
};
