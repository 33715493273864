import React, { useEffect } from "react";

import { Button, Spin } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";
import { useNavigate } from "react-router-dom";

import { Continent } from "shared/api/apollo/__generated__";

import { DataType } from "../config";
import { serializeDataFortable } from "../lib";
import { continentsModel } from "../model";

export const ContinentsBlock: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    continentsModel.getContinents({});
  }, []);

  const isLoading = useStore(continentsModel.$isLoading);
  const continentsList = useStore(continentsModel.$continents);

  const columns: ColumnsType<DataType> = [
    {
      title: "Continent name",
      dataIndex: "continentName",
      key: "continentName",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "Continent code",
      dataIndex: "continentCode",
      key: "continentCode",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "Continent ID",
      dataIndex: "continentId",
      key: "continentId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
  ];

  const tableData = serializeDataFortable(continentsList as Continent[]);

  const handleCreateNewContinentClick = () => {
    navigate("/dictionary/continents/new/create");
  };

  const handleRowClick = (id: string) => {
    navigate(`/dictionary/continents/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Button
            onClick={handleCreateNewContinentClick}
            style={{ marginBottom: "10px" }}
          >
            Create new continent
          </Button>
          <Table
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record?.continentId),
                style: { cursor: "pointer" },
              };
            }}
            style={{ userSelect: "none" }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </>
      )}
    </>
  );
};
