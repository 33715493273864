import React from "react";

import { Descriptions, Tabs, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { Currency } from "shared/api/apollo/__generated__";

import { serializeCurrency } from "../lib";

interface ChosenCurrency {
  currency: Currency;
}

export const CurrentCurrencyView: React.FC<ChosenCurrency> = ({ currency }) => {
  const { t } = useTranslation();
  const currencyData = serializeCurrency(currency);
  const networksData = currency?.networkList || [];

  return (
    <>
      <Tabs defaultActiveKey="currencyInfo">
        <Tabs.TabPane key="currencyInfo" tab="Currency info">
          <Descriptions
            key="currencyInfo"
            layout="horizontal"
            bordered
            size="small"
            column={1}
            title={t("currencyView.title")}
            style={{
              minWidth: "600px",
              maxWidth: "800px",
              marginBottom: "10px",
            }}
          >
            {currencyData &&
              Object.entries(currencyData).map((currency) => {
                if (!Array.isArray(currency[1])) {
                  return (
                    <Descriptions.Item
                      key={currency[0]}
                      label={t(`currencyView.${currency[0]}`)}
                    >
                      {currency[1]}
                    </Descriptions.Item>
                  );
                } else {
                  return (
                    <Descriptions.Item
                      key={currency[0]}
                      label={t(`currencyView.${currency[0]}`)}
                    >
                      {currency[1].length
                        ? currency[1].map((tag) => <Tag>{tag}</Tag>)
                        : "-"}
                    </Descriptions.Item>
                  );
                }
              })}
          </Descriptions>
        </Tabs.TabPane>
        {networksData[0] && (
          <Tabs.TabPane key="networksInfo" tab="Currency networks info">
            <Tabs
              key="networks"
              defaultActiveKey={networksData[0]?.name as string}
            >
              {networksData?.map((network) => (
                <Tabs.TabPane key={network?.name} tab={network?.name}>
                  <Descriptions
                    layout="horizontal"
                    bordered
                    size="small"
                    column={1}
                    title={t("currencyView.network.title")}
                    style={{
                      minWidth: "600px",
                      maxWidth: "800px",
                      marginBottom: "10px",
                    }}
                  >
                    {Object.entries(network).map((networkInfo) => {
                      if (!networkInfo[0].includes("__")) {
                        return (
                          <Descriptions.Item
                            label={t(`currencyView.network.${networkInfo[0]}`)}
                          >
                            {networkInfo[1]?.toString() || "-"}
                          </Descriptions.Item>
                        );
                      }
                    })}
                  </Descriptions>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};
