import React from "react";

import { CreateContinentForm } from "features";

import { AdminTemplate } from "shared/templates";

export const CreateContinentPage: React.FC = () => {
  return (
    <AdminTemplate>
      <CreateContinentForm />
    </AdminTemplate>
  );
};
