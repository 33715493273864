import { createEffect, createEvent, forward, restore } from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import { DictFilterInput } from "shared/api/apollo/__generated__";

const getCurrencies = createEvent<DictFilterInput>();

const getCurrenciesFx = createEffect(async (input: DictFilterInput) => {
  const response = await requestClient.getCurrencies({ input });
  return response.getCurrencies;
});

const $isLoading = getCurrenciesFx.pending;
const $currencies = restore(getCurrenciesFx.doneData, []);

forward({
  from: getCurrencies,
  to: getCurrenciesFx,
});

export const currenciesModel = { getCurrencies, $currencies, $isLoading };
