import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateBankInput,
} from "shared/api/apollo/__generated__";

const updateBankInfo = createEvent<UpdateBankInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteBank = createEvent<RemoveDictItemsInput>();

const deleteBankFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteBank({ input });
  return response.removeBanks;
});

const updateBankInfoFx = createEffect(async (input: UpdateBankInput) => {
  const response = await requestClient.updateBank({ input });
  return response.updateBank;
});

const $isLoading = combine(
  updateBankInfoFx.pending,
  deleteBankFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateBankInfo,
  to: updateBankInfoFx,
});

forward({
  from: deleteBank,
  to: deleteBankFx,
});

sample({
  clock: updateBankInfoFx.failData,
  fn: (data) => ({ text: data.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateBankInfoFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for bank with ID ${data.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateBankInfoFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteBankFx.doneData,
  fn: () => (document.location = "/dictionary/banks"),
});

sample({
  clock: deleteBankFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateBankInfoModel = {
  updateBankInfo,
  $isLoading,
  $isChangeMode,
  setIsChangeMode,
  deleteBank,
};
