import { City } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeTableData = (data: City[]): DataType[] => {
  const result: DataType[] = data.map((city) => ({
    cityCountry: city?.country?.item?.name as string,
    cityId: city?.id,
    cityName: city?.item?.name,
    key: city?.id,
    countryId: city?.country?.id as string,
    createdAt: city?.item?.common?.timestamps?.createdAt,
    updatedAt: city?.item?.common?.timestamps?.updatedAt,
  }));
  return result;
};
