import React, { useEffect, useState } from "react";

import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

import { updateCurrencyModel } from "../model";

import { Currency, CurrencyType } from "shared/api/apollo/__generated__";

interface UpdateInfoProps {
  currency: Currency;
}

export const UpdateCurrencyInfoForm: React.FC<UpdateInfoProps> = ({
  currency,
}) => {
  const [form] = useForm();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const [chosenCurrencyType, setChosenCurrencyType] = useState<CurrencyType>(
    currency?.type
  );

  const isCrypto = chosenCurrencyType === CurrencyType.Crypto;

  const handleUpdateCurrencyClick = (values: {
    currencyName: string;
    precision: string;
    ticker: string;
    symbol: string;
    tags: string;
  }) => {
    updateCurrencyModel.updateCurrency({
      id: currency?.id,
      currency: {
        name: values?.currencyName,
        precision: Number(values?.precision),
        ticker: values?.ticker,
        type: chosenCurrencyType,
        symbol: values?.symbol,
        tags: values?.tags?.length
          ? values?.tags?.replace(/\s+/g, "").split(",")
          : [] || [],
      },
    });
  };

  const handleChoseCurrencyType = (type: CurrencyType) => {
    setChosenCurrencyType(type);
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      name="updateCurrency"
      onFinish={handleUpdateCurrencyClick}
      wrapperCol={{ span: 10 }}
      labelCol={{ span: 4 }}
    >
      <Form.Item
        label="Currency name"
        key="currencyName"
        initialValue={currency.item?.name || ""}
        name="currencyName"
      >
        <Input
          defaultValue={currency.item?.name || ""}
          placeholder="Currency name"
        ></Input>
      </Form.Item>
      <Form.Item
        label="Currency precision"
        key="precision"
        initialValue={currency.precision || ""}
        name="precision"
      >
        <Input
          defaultValue={currency.precision || ""}
          placeholder="Currency precision"
        ></Input>
      </Form.Item>
      <Form.Item
        label="Currency ticker"
        key="ticker"
        initialValue={currency.ticker || ""}
        name="ticker"
      >
        <Input
          defaultValue={currency.ticker || ""}
          placeholder="Currency ticker"
        ></Input>
      </Form.Item>
      <Form.Item
        label="Currency type"
        key="type"
        initialValue={chosenCurrencyType}
        name="type"
      >
        <Button
          onClick={() => {
            handleChoseCurrencyType(CurrencyType.Crypto);
          }}
          type={isCrypto ? "primary" : "default"}
        >
          Crypto
        </Button>
        <Button
          onClick={() => {
            handleChoseCurrencyType(CurrencyType.Fiat);
          }}
          type={!isCrypto ? "primary" : "default"}
        >
          Fiat
        </Button>
      </Form.Item>
      <Form.Item
        label="Currency symbol"
        key="symbol"
        initialValue={currency.symbol || ""}
        name="symbol"
      >
        <Input
          defaultValue={currency.symbol || ""}
          placeholder="Currency symbol"
        ></Input>
      </Form.Item>
      <Form.Item
        label="Tags"
        key="tags"
        initialValue={currency.item.tags || ""}
        name="tags"
      >
        <Input
          defaultValue={currency.item.tags?.join(", ") || ""}
          placeholder="Currency tags"
        ></Input>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Save new currency info
        </Button>
      </Form.Item>
    </Form>
  );
};
