import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Continent } from "shared/api/apollo/__generated__";
import { updateContinentModel } from "../model";

interface ContinentProps {
  continent: Continent;
}

export const ChangeContinentInfoForm: React.FC<ContinentProps> = ({
  continent,
}) => {
  const { t } = useTranslation();
  const isLoading = useStore(updateContinentModel.$isLoading);

  const [form] = useForm();

  const handleUpdateContinentClick = (values: {
    name: string;
    code: string;
    tags: string;
  }) => {
    updateContinentModel.updateContinent({
      id: continent?.item?.id,
      continent: {
        item: {
          name: values?.name,
          code: values?.code,
          tags: values?.tags?.length
            ? values?.tags?.replace(/\s+/g, "").split(",")
            : [] || [],
          localizedNames: [],
        },
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="updateContinent"
          onFinish={handleUpdateContinentClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 2 }}
        >
          <Form.Item
            label={t("updateContinent.continentName")}
            key="name"
            initialValue={continent?.item?.name}
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input
              defaultValue={continent?.item?.name}
              placeholder={t("updateContinent.continentName")}
            />
          </Form.Item>
          <Form.Item
            label={t("updateContinent.continentCode")}
            key="code"
            initialValue={continent?.item?.code}
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="code"
          >
            <Input
              defaultValue={continent?.item?.code}
              placeholder={t("updateContinent.continentCode")}
            />
          </Form.Item>

          <Form.Item
            label={t("updateContinent.tags")}
            key="tags"
            initialValue={continent?.item?.tags?.join(", ") || ""}
            name="tags"
            tooltip={t("updateContinent.tagsTooltip")}
          >
            <Input
              defaultValue={continent?.item?.tags?.join(", ") || ""}
              placeholder={t("updateContinent.tags")}
            />
          </Form.Item>
          <Form.Item key="submitButton">
            <Button type="primary" htmlType="submit">
              {t("updateContinent.updateContinent")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
