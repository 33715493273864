import { Country } from "shared/api/apollo/__generated__";
import { SerializedCountry } from "../config";

export const serializeCountry = (data: Country): SerializedCountry => ({
  name: (data?.item?.name as string) || "-",
  id: data?.id || "-",
  alpha3: data?.alpha3 || "-",
  continent: data?.continent?.item?.name || "-",
  phoneCode: data?.phoneCode || "-",
  phoneTemplate: data?.phoneTemplate || "-",
  createdAt: data?.item?.common?.timestamps?.createdAt || "-",
  updatedAt: data?.item?.common?.timestamps?.updatedAt || "-",
  isDeleted: data?.item?.common?.isDeleted.toString() || "-",
  tags: data?.item?.tags as string[],
});
