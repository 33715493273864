import React, { useEffect, useState } from "react";

import { Button, Modal, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useStore } from "effector-react";

import { countriesModel } from "features";

import { CurrentCountryView } from "entities/index";

import { Country } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";
import { updateCountryModel } from "features/ChangeCountryInfoForm/model";
import { ChangeCountryInfoForm } from "features";

export const CurrentCountryPage: React.FC = () => {
  const params = useParams();

  const isEditMode = useStore(updateCountryModel.$isChangeMode);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    countriesModel.getCountries({ filter: { ids: [params?.id as string] } });
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      updateCountryModel.setIsChangeMode(false);
    };
  }, []);

  const currentCountry = useStore(countriesModel.$countries);
  const isLoading = useStore(countriesModel.$isLoading);
  const isChangeOnLoad = useStore(updateCountryModel.$isLoading);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateCountryModel.setIsChangeMode(false)
      : updateCountryModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCountryClick = () => {
    setIsModalOpen(false);
    updateCountryModel.deleteCountry({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeCountryInfoForm country={currentCountry[0] as Country} />
            ) : (
              <CurrentCountryView country={currentCountry[0] as Country} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit country info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete country
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteCountryClick}
        visible={isModalOpen}
        title="Delete country confirmation"
      >
        Delete "{currentCountry[0]?.item?.name}" country?
      </Modal>
    </>
  );
};
