import React from "react";

import { Menu, MenuProps, Tooltip } from "antd";
import "antd/dist/antd.min.css";
import { Link } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export const AdminTemplate: React.FC<Props> = ({ children }) => {
  const items: MenuProps["items"] = [
    {
      label: (
        <Link style={{ color: "#000" }} to="/all-users?searchType=userId">
          All users
        </Link>
      ),
      type: "group",
      key: "allUsers",
    },
    {
      label: "Users",
      key: "users",
      style: { padding: "0 0 0 16px" },
      children: [
        {
          label: (
            <Tooltip title="Disabled for now">
              <span style={{ cursor: "pointer" }}>Servcie users</span>
            </Tooltip>
            // <Link style={{ color: "gray" }} to="/">
            //   Service users
            // </Link>
          ),
          type: "group",
          key: "serviceUsers",
        },
        {
          label: (
            <Tooltip title="Disabled for now">
              <span style={{ cursor: "pointer" }}>Companies</span>
            </Tooltip>
            // <Link style={{ color: "gray" }} to="/">
            //   Companies
            // </Link>
          ),
          type: "group",
          key: "companies",
        },
      ],
    },

    {
      label: (
        <Link
          style={{ color: "#000" }}
          to="/balances/crypto?searchType=address"
        >
          Crypto accounts
        </Link>
      ),
      type: "group",
      key: "cryptoAccounts",
    },
    {
      label: (
        <Link
          style={{ color: "#000" }}
          to="/balances/fiat?searchType=accountId"
        >
          Fiat accounts
        </Link>
      ),
      type: "group",
      key: "fiatAccounts",
    },
    {
      label: (
        <Link style={{ color: "#000" }} to="/transactions">
          All transactions
        </Link>
      ),
      type: "group",
      key: "transactions",
    },
    {
      label: "Dictionary",
      key: "dictionary",
      style: { padding: "0 0 0 16px" },
      children: [
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/banks?searchType=bankId"
            >
              Banks
            </Link>
          ),
          type: "group",
          key: "banks",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/countries?searchType=countryId"
            >
              Countries
            </Link>
          ),
          type: "group",
          key: "countries",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/currencies?searchType=tickers"
            >
              Currencies
            </Link>
          ),
          type: "group",
          key: "currencies",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/nace-codes?searchType=naceCode"
            >
              Nace-codes
            </Link>
          ),
          type: "group",
          key: "naceCodes",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/cities?searchType=cityId"
            >
              Cities
            </Link>
          ),
          type: "group",
          key: "cities",
        },
        {
          label: (
            <Link style={{ color: "#000" }} to="/dictionary/continents">
              Continents
            </Link>
          ),
          type: "group",
          key: "continents",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/languages?searchType=languageId"
            >
              Languages
            </Link>
          ),
          type: "group",
          key: "languages",
        },
        {
          label: (
            <Link
              style={{ color: "#000" }}
              to="/dictionary/regions?searchType=regionId"
            >
              Regions
            </Link>
          ),
          type: "group",
          key: "regions",
        },
      ],
    },
    {
      label: (
        <Tooltip title="Disabled for now">
          <span style={{ cursor: "pointer" }}>Reconcilation</span>
        </Tooltip>
        // <Link style={{ color: "gray" }} to="/">
        //   Reconcilation
        // </Link>
      ),
      type: "group",
      key: "reconcilation",
    },
    {
      label: (
        <Tooltip title="Disabled for now">
          <span style={{ cursor: "pointer" }}>Service accounts</span>
        </Tooltip>
        // <Link style={{ color: "gray" }} to="/">
        //   Service accounts
        // </Link>
      ),
      type: "group",
      key: "serviceAccounts",
    },
    {
      label: (
        <Tooltip title="Disabled for now">
          <span style={{ cursor: "pointer" }}>Nostro accounts</span>
        </Tooltip>
        // <Link style={{ color: "gray" }} to="/">
        //    Nostro accounts
        // </Link>
      ),
      type: "group",
      key: "nostroAccounts",
    },
    {
      label: (
        <Tooltip title="Disabled for now">
          <span style={{ cursor: "pointer" }}>Settings</span>
        </Tooltip>
        // <Link style={{ color: "gray" }} to="/">
        //   Settings
        // </Link>
      ),
      type: "group",
      key: "settings",
    },
  ];

  return (
    <div
      style={{
        minHeight: "100%",
        minWidth: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{ minHeight: "100%", backgroundColor: "#fff", width: "200px" }}
      >
        <Menu
          inlineIndent={0}
          mode="inline"
          items={items}
          style={{ minHeight: "100%", userSelect: "none" }}
        />
      </div>
      <div
        style={{
          padding: "12px",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
};
