import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateContinentInput,
} from "shared/api/apollo/__generated__";

const updateContinent = createEvent<UpdateContinentInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteContinent = createEvent<RemoveDictItemsInput>();

const deleteContinentFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteContinent({ input });
  return response.removeContinents;
});

const updateContinentFx = createEffect(async (input: UpdateContinentInput) => {
  const response = await requestClient.updateContinent({ input });
  return response.updateContinent;
});

const $isLoading = combine(
  deleteContinentFx.pending,
  updateContinentFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateContinent,
  to: updateContinentFx,
});

forward({
  from: deleteContinent,
  to: deleteContinentFx,
});

sample({
  clock: updateContinentFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateContinentFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for continent with ID ${data.item.id}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateContinentFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteContinentFx.doneData,
  fn: () => (document.location = "/dictionary/continents"),
});

sample({
  clock: deleteContinentFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateContinentModel = {
  $isChangeMode,
  $isLoading,
  updateContinent,
  setIsChangeMode,
  deleteContinent,
};
