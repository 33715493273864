import { DocumentNode } from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigNumber: string;
  CardNumber: string;
  DateTime: string;
  IBAN: string;
  JSON: string;
  JsonObjectScalar: any;
  PhoneNumber: string;
  SWIFT: string;
  Timestamp: string;
};

export enum AbsPaymentStatusEnum {
  Error = "ERROR",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

export enum AbsPaymentType {
  AccToAccAny = "ACC_TO_ACC_ANY",
  AccToAccConversion = "ACC_TO_ACC_CONVERSION",
  AccToAccIncoming = "ACC_TO_ACC_INCOMING",
  AccToAccIncomingFx = "ACC_TO_ACC_INCOMING_FX",
  AccToAccIncomingOwn = "ACC_TO_ACC_INCOMING_OWN",
  AccToAccInside = "ACC_TO_ACC_INSIDE",
  AccToAccInternal = "ACC_TO_ACC_INTERNAL",
  AccToAccNostro = "ACC_TO_ACC_NOSTRO",
  External = "EXTERNAL",
  ExternalFx = "EXTERNAL_FX",
}

export type Account = {
  __typename?: "Account";
  absAccountId?: Maybe<Scalars["String"]>;
  absAccountNickname?: Maybe<Scalars["String"]>;
  absAccountStatus?: Maybe<Scalars["String"]>;
  absAccountType?: Maybe<Scalars["String"]>;
  accountNumber: Scalars["String"];
  balance: Balance;
  common: CommonItemInfo;
  contomobileSubAccount?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  currency: Currency;
  holderType?: Maybe<AccountHolderType>;
  id: Scalars["ID"];
  internalType: InternalAccountType;
  name: Scalars["String"];
  openingDate?: Maybe<Scalars["DateTime"]>;
  reasonForInactive?: Maybe<Scalars["String"]>;
  state: AccountState;
  type: AccountType;
  userId: Scalars["String"];
  walletId: Scalars["String"];
};

export enum AccountDocumentType {
  AvailableFunds = "AVAILABLE_FUNDS",
  CreditHistory = "CREDIT_HISTORY",
  PaymentsHistory = "PAYMENTS_HISTORY",
}

export enum AccountHolderType {
  Individual = "INDIVIDUAL",
  Legal = "LEGAL",
}

export enum AccountState {
  Active = "ACTIVE",
  Closed = "CLOSED",
  Error = "ERROR",
  External = "EXTERNAL",
  Inactive = "INACTIVE",
  Pending = "PENDING",
  Processing = "PROCESSING",
}

export enum AccountStatementGroup {
  All = "ALL",
  Cash = "CASH",
  Crypto = "CRYPTO",
}

export enum AccountType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export type Accounts = {
  __typename?: "Accounts";
  count: Scalars["Int"];
  nodes: Array<Account>;
};

export type AccountsInput = {
  accountNumbers?: InputMaybe<Array<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["ID"]>;
  currencyId?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<Filter>;
  likeConditions?: InputMaybe<Array<LikeConditionInput>>;
  requestGCP?: InputMaybe<Scalars["Boolean"]>;
  states?: InputMaybe<Array<AccountState>>;
  userId?: InputMaybe<Scalars["ID"]>;
  walletIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type AccountsUserData = {
  __typename?: "AccountsUserData";
  account: Account;
  truncatedFullName: Scalars["String"];
};

export enum AcquiringService {
  Tompay = "TOMPAY",
}

export type AddContactsInput = {
  contactInfo: Array<InputMaybe<ContactInput>>;
  userId: Scalars["ID"];
};

export type AddTagByCodeInput = {
  codes: Array<Scalars["String"]>;
  rewrite?: InputMaybe<Array<Scalars["String"]>>;
  tags: Array<Scalars["String"]>;
  type: ItemType;
};

export type AddTagInput = {
  ids: Array<Scalars["ID"]>;
  rewrite?: InputMaybe<Array<Scalars["String"]>>;
  tags: Array<Scalars["String"]>;
  type: ItemType;
};

export type Address = {
  __typename?: "Address";
  buildingName?: Maybe<Scalars["String"]>;
  buildingNumber?: Maybe<Scalars["String"]>;
  city?: Maybe<City>;
  /** additional comment */
  comment?: Maybe<Scalars["String"]>;
  flatNumber?: Maybe<Scalars["String"]>;
  /** presentation of address as formatted string */
  formattedStringValue: Scalars["String"];
  /** geolocation of addreass */
  geo: GeoLocation;
  id: Scalars["ID"];
  isActual?: Maybe<Scalars["Boolean"]>;
  isLegal?: Maybe<Scalars["Boolean"]>;
  isPrimary: Scalars["Boolean"];
  postalCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  subStreet?: Maybe<Scalars["String"]>;
};

export type AddressByGeoInput = {
  /** [Dictionary] Latitude and longitude of supposed address */
  geo: GeoLocationInput;
};

export type AddressData = {
  __typename?: "AddressData";
  /** [Dictionary] Represents name of building located at given address */
  buildingName?: Maybe<Scalars["String"]>;
  /** [Dictionary] Represents city at which address was located */
  city?: Maybe<City>;
  /** [Dictionary] Represents optional comment */
  comment?: Maybe<Scalars["String"]>;
  /** [Dictionary] Represents floor of building at given address */
  flatNumber?: Maybe<Scalars["String"]>;
  /** [Dictionary] Formatted value created from data of existing fields */
  formattedStringValue: Scalars["String"];
  /** [Dictionary] Latitude and longitude of supposed address */
  geo: GeoLocation;
  /** [Dictionary] Represents whether this address is primary for user */
  isPrimary: Scalars["Boolean"];
  /** [Dictionary] Represents address related postal code */
  postalCode?: Maybe<Scalars["String"]>;
  /** [Dictionary] Represents address' street */
  street?: Maybe<Scalars["String"]>;
  /** [Dictionary] Represents address' sub street */
  subStreet?: Maybe<Scalars["String"]>;
};

export type AddressesInput = {
  /** value that should be partially matched with next fields: 'postalCode', 'street', 'subStreet', 'buildingName', 'flatNumber','buildingNumber', 'comment', 'formattedStringValue' */
  addressQuery: Scalars["String"];
};

export type AdminSignUpInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  device: Device;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<GenderEnum>;
  identity: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  service: Service;
  userId?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type AllExchanges = {
  __typename?: "AllExchanges";
  createdAt: Scalars["DateTime"];
  cryptoExchange?: Maybe<CryptoExchangeObject>;
  cryptoTransaction?: Maybe<CryptoPayment>;
  failReason?: Maybe<Scalars["String"]>;
  fiatTransaction?: Maybe<FiatPayment>;
  id: Scalars["ID"];
  status: NeuronExchangeStatus;
  userId: Scalars["ID"];
};

export type AmountByTypesObject = {
  __typename?: "AmountByTypesObject";
  amount: Scalars["BigNumber"];
  currency: CoinsInfo;
};

export type AmountRangeInput = {
  max?: InputMaybe<Scalars["BigNumber"]>;
  min?: InputMaybe<Scalars["BigNumber"]>;
};

export type AppleSignInInput = {
  /** authorization code for auth user if no token provided */
  authorizationCode?: InputMaybe<Scalars["String"]>;
  /** first name given only at very first apple signin if present */
  firstName?: InputMaybe<Scalars["String"]>;
  /** identity token if present */
  identityToken?: InputMaybe<Scalars["String"]>;
  /** last name given only at very first apple signin if present */
  lastName?: InputMaybe<Scalars["String"]>;
  service: Service;
  /** token for PUSH notification */
  token?: InputMaybe<Scalars["String"]>;
};

export type ApproveExchangeInput = {
  operationId: Scalars["ID"];
  tokens: Array<VerificationTokens>;
};

export type ApprovePaymentInput = {
  paymentId: Scalars["String"];
  tokens: Array<VerifyTokenInput>;
};

export type ApproveRequestInput = {
  tokens: Array<TokenTypeInput>;
  twoFAOperationId: Scalars["String"];
};

export type ApproveTransaction = {
  __typename?: "ApproveTransaction";
  amount: Scalars["BigNumber"];
  blockchain: NetworkList;
  createdAt: Scalars["DateTime"];
  destination: Scalars["String"];
  destinationOwner?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  operationId: Scalars["ID"];
  source: Scalars["String"];
  sourceOwner?: Maybe<Scalars["String"]>;
  status: TransactionStatusEnum;
  token: CoinsInfo;
  transactionFee?: Maybe<Scalars["BigNumber"]>;
  transactionHash?: Maybe<Scalars["String"]>;
  /** Incoming or outgoing or transfer transactions. */
  type: DirectionTypeEnum;
  updatedAt: Scalars["DateTime"];
};

export type ApproveTransactionInput = {
  operationId: Scalars["ID"];
  tokens: Array<TokensForApprovalTransaction>;
};

export type ApproveUserPaymentInput = {
  paymentId: Scalars["String"];
  userId: Scalars["String"];
};

/** Return network by asset */
export type AssetNetwork = {
  __typename?: "AssetNetwork";
  address?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  memo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AttachDeviceToTopicInput = {
  /** device ids or identities */
  devices: Array<Scalars["String"]>;
  /** id or name */
  topicId: Scalars["String"];
};

export type Auth = {
  __typename?: "Auth";
  accessToken: Scalars["String"];
  expiresIn: Scalars["Float"];
  idToken?: Maybe<Scalars["String"]>;
  refreshToken: Scalars["String"];
  tokenType: Scalars["String"];
};

export type AuthInput = {
  captcha: ReCaptchaInput;
  device: Device;
  identity: Scalars["String"];
  password: Scalars["String"];
  service: Service;
};

export type AuthRegisterPhoneInput = {
  captcha: ReCaptchaInput;
  identity: Scalars["String"];
};

export type AuthTfaSend = {
  operationId: Scalars["String"];
  type: TfaType;
};

export enum AuthUserKybDocumentStatus {
  Pending = "PENDING",
  WaitingForUploadDocument = "WAITING_FOR_UPLOAD_DOCUMENT",
}

export type AuthVerify2FaInput = {
  /** token for PUSH notification */
  pushToken?: InputMaybe<Scalars["String"]>;
  tfaVerifyData: Verify2FaInput;
};

export type AuthorizedUser = {
  __typename?: "AuthorizedUser";
  EOS?: Maybe<PublicKeyInfo>;
  appleUserId?: Maybe<Scalars["String"]>;
  avatar?: Maybe<File>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  chatClientId?: Maybe<Scalars["String"]>;
  chatClientIdentity?: Maybe<Scalars["String"]>;
  companies?: Maybe<Array<CompanyPayloadAuthType>>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<EmailDeviceInfo>;
  /** User's extra data */
  extra?: Maybe<Scalars["JSON"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<GenderEnum>;
  kybDocumentStatus: AuthUserKybDocumentStatus;
  language: Scalars["String"];
  lastLoginDate?: Maybe<Scalars["DateTime"]>;
  lastName?: Maybe<Scalars["String"]>;
  leadId?: Maybe<Scalars["String"]>;
  p256?: Maybe<PublicKeyInfo>;
  phone?: Maybe<PhoneDeviceInfo>;
  referrerInfo?: Maybe<Referrer>;
  role: RoleEnum;
  service: Service;
  status: StatusEnum;
  sub?: Maybe<Scalars["String"]>;
  tariff: UserTariff;
  timezone: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["String"];
  username?: Maybe<Scalars["String"]>;
};

export type AuthorizedUserCompaniesArgs = {
  input?: InputMaybe<GetCompaniesFilterInput>;
};

export type AvailableErc20TokenForSumsubType = {
  __typename?: "AvailableErc20TokenForSumsubType";
  id: Scalars["String"];
  tokenId: Scalars["String"];
  tokenName: Scalars["String"];
};

export type AvailableErc20TokensForSumsubType = {
  __typename?: "AvailableErc20TokensForSumsubType";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<AvailableErc20TokenForSumsubType>;
};

export type Balance = {
  __typename?: "Balance";
  available: Money;
  full: Money;
  held: Money;
};

export type BalanceObject = {
  __typename?: "BalanceObject";
  current: Scalars["BigNumber"];
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type Bank = {
  __typename?: "Bank";
  address: Scalars["String"];
  bankCode?: Maybe<Scalars["String"]>;
  bankName: Scalars["String"];
  bic?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  id: Scalars["ID"];
  item: CommonDictItem;
  swift?: Maybe<Scalars["String"]>;
};

export type BankDetail = {
  __typename?: "BankDetail";
  accountHolder: Scalars["String"];
  beneficiaryAccountNumber?: Maybe<Scalars["String"]>;
  beneficiaryAddress?: Maybe<Scalars["String"]>;
  beneficiaryBIC?: Maybe<Scalars["String"]>;
  beneficiaryBank: Scalars["String"];
  beneficiaryIBAN?: Maybe<Scalars["String"]>;
  beneficiaryName: Scalars["String"];
  beneficiarySortCode?: Maybe<Scalars["String"]>;
  contomobileSubAccount?: Maybe<Scalars["String"]>;
  walletId: Scalars["String"];
};

export type BankDetails = {
  __typename?: "BankDetails";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<BankDetail>;
};

export type BankInfoInput = {
  bankAddress: Scalars["String"];
  bankCityId: Scalars["ID"];
  bankName: Scalars["String"];
};

export type BankInput = {
  address: Scalars["String"];
  bankCode?: InputMaybe<Scalars["String"]>;
  bankName: Scalars["String"];
  bic: Scalars["String"];
  city: Scalars["String"];
  code: Scalars["String"];
  country: Scalars["String"];
  /** [Dictionary] Bank name */
  name: Scalars["String"];
};

export type Banks = {
  __typename?: "Banks";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<Bank>;
};

export type BanxeBackPaginationInputType = {
  limit: Scalars["Float"];
  skip: Scalars["Float"];
};

export type BanxeBackSortInputType = {
  field: Scalars["String"];
  order: SortOrder;
};

export type BaseOrderFilter = {
  __typename?: "BaseOrderFilter";
  id: Scalars["ID"];
  name: Scalars["String"];
  owner: ExchangeService;
  /** JSON string with filter parameters. */
  parameters: BinanceFilterParameters;
  type: OrderFilterType;
};

export type BinanceExchangeMaxNumAlgoOrdersParameters = {
  __typename?: "BinanceExchangeMaxNumAlgoOrdersParameters";
  filterType: BinanceFilters;
  maxNumAlgoOrders: Scalars["BigNumber"];
};

export type BinanceExchangeMaxNumOrdersFilterParameters = {
  __typename?: "BinanceExchangeMaxNumOrdersFilterParameters";
  filterType: BinanceFilters;
  maxNumOrders: Scalars["BigNumber"];
};

export type BinanceFilterParameters =
  | BinanceExchangeMaxNumAlgoOrdersParameters
  | BinanceExchangeMaxNumOrdersFilterParameters
  | BinanceIcebergPartsFilterParameters
  | BinanceLotSizeFilterParameters
  | BinanceMarketLotSizeFilterParameters
  | BinanceMaxNumAlgoOrdersFilterParameters
  | BinanceMaxNumIcebergOrdersFilterParameters
  | BinanceMaxNumOrdersFilterParameters
  | BinanceMaxPositionFilterParameters
  | BinanceMinNotionalFilterParameters
  | BinancePercentPriceFilterParameters
  | BinancePriceFilterParameters
  | BinanceTrailingDeltaFilterParameters;

export enum BinanceFilters {
  ExchangeMaxNumAlgoOrders = "EXCHANGE_MAX_NUM_ALGO_ORDERS",
  ExchangeMaxNumOrders = "EXCHANGE_MAX_NUM_ORDERS",
  IcebergParts = "ICEBERG_PARTS",
  LotSize = "LOT_SIZE",
  MarketLotSize = "MARKET_LOT_SIZE",
  MaxNumAlgoOrders = "MAX_NUM_ALGO_ORDERS",
  MaxNumIcebergOrders = "MAX_NUM_ICEBERG_ORDERS",
  MaxNumOrders = "MAX_NUM_ORDERS",
  MaxPosition = "MAX_POSITION",
  MinNotional = "MIN_NOTIONAL",
  PercentPrice = "PERCENT_PRICE",
  PriceFilter = "PRICE_FILTER",
  TrailingDelta = "TRAILING_DELTA",
}

export type BinanceIcebergPartsFilterParameters = {
  __typename?: "BinanceIcebergPartsFilterParameters";
  filterType: BinanceFilters;
  limit: Scalars["BigNumber"];
};

export type BinanceLotSizeFilterParameters = {
  __typename?: "BinanceLotSizeFilterParameters";
  filterType: BinanceFilters;
  maxQty: Scalars["BigNumber"];
  minQty: Scalars["BigNumber"];
  reverseStepSize: Scalars["BigNumber"];
  stepSize: Scalars["BigNumber"];
};

export type BinanceMarketLotSizeFilterParameters = {
  __typename?: "BinanceMarketLotSizeFilterParameters";
  filterType: BinanceFilters;
  maxQty: Scalars["BigNumber"];
  minQty: Scalars["BigNumber"];
  stepSize: Scalars["BigNumber"];
};

export type BinanceMaxNumAlgoOrdersFilterParameters = {
  __typename?: "BinanceMaxNumAlgoOrdersFilterParameters";
  filterType: BinanceFilters;
  maxNumAlgoOrders: Scalars["BigNumber"];
};

export type BinanceMaxNumIcebergOrdersFilterParameters = {
  __typename?: "BinanceMaxNumIcebergOrdersFilterParameters";
  filterType: BinanceFilters;
  maxNumIcebergOrders: Scalars["BigNumber"];
};

export type BinanceMaxNumOrdersFilterParameters = {
  __typename?: "BinanceMaxNumOrdersFilterParameters";
  filterType: BinanceFilters;
  maxNumOrders: Scalars["BigNumber"];
};

export type BinanceMaxPositionFilterParameters = {
  __typename?: "BinanceMaxPositionFilterParameters";
  filterType: BinanceFilters;
  maxPosition: Scalars["BigNumber"];
};

export type BinanceMinNotionalFilterParameters = {
  __typename?: "BinanceMinNotionalFilterParameters";
  applyToMarket: Scalars["Boolean"];
  avgPriceMins: Scalars["BigNumber"];
  filterType: BinanceFilters;
  minNotional: Scalars["BigNumber"];
};

export type BinancePercentPriceFilterParameters = {
  __typename?: "BinancePercentPriceFilterParameters";
  avgPriceMins: Scalars["BigNumber"];
  filterType: BinanceFilters;
  multiplierDown: Scalars["BigNumber"];
  multiplierUp: Scalars["BigNumber"];
};

export type BinancePriceFilterParameters = {
  __typename?: "BinancePriceFilterParameters";
  filterType: BinanceFilters;
  maxPrice: Scalars["BigNumber"];
  minPrice: Scalars["BigNumber"];
  tickSize: Scalars["BigNumber"];
};

export type BinanceTrailingDeltaFilterParameters = {
  __typename?: "BinanceTrailingDeltaFilterParameters";
  filterType: BinanceFilters;
  maxTrailingAboveDelta: Scalars["BigNumber"];
  maxTrailingBelowDelta: Scalars["BigNumber"];
  minTrailingAboveDelta: Scalars["BigNumber"];
  minTrailingBelowDelta: Scalars["BigNumber"];
};

export type BitrixCompanyActiveLicenseInput = {
  country: Scalars["String"];
  licenseNumber: Scalars["String"];
};

export type BitrixCompanyFilesInput = {
  articlesOfAssociation?: InputMaybe<Scalars["String"]>;
  certificateOfIncorporation?: InputMaybe<Scalars["String"]>;
  license?: InputMaybe<Scalars["String"]>;
  proofOfLegalAddress?: InputMaybe<Scalars["String"]>;
  registerOfLegalEntities?: InputMaybe<Scalars["String"]>;
  representationDocuments?: InputMaybe<Scalars["String"]>;
  sourceOfCompanyFunds?: InputMaybe<Scalars["String"]>;
  sourceOfCompanyWealth?: InputMaybe<Scalars["String"]>;
};

export type BitrixCompanyLegalPersonInput = {
  address?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfShares?: InputMaybe<Scalars["String"]>;
};

export type BitrixCompanyMainBusinessPartnerInput = {
  country: Scalars["String"];
  name: Scalars["String"];
};

export type BitrixContactBeneficialOwnerInput = {
  address?: InputMaybe<Scalars["String"]>;
  citizenship?: InputMaybe<Scalars["String"]>;
  countryOfBirth?: InputMaybe<Scalars["String"]>;
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Timestamp"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  numberOfTheShares?: InputMaybe<Scalars["String"]>;
  personalCode?: InputMaybe<Scalars["String"]>;
  tin?: InputMaybe<Scalars["String"]>;
};

export type BitrixContactCustomerRepresentativeInput = {
  basisForYourRepresentation?: InputMaybe<Scalars["String"]>;
  citizenship?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  personalCode?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  representativeAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type BitrixContactLeadInput = {
  address?: InputMaybe<BitrixLeadAddressInput>;
  birthdate?: InputMaybe<Scalars["Timestamp"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalUserId: Scalars["String"];
  files?: InputMaybe<BitrixLeadFilesInput>;
  isFamilyMemberPep?: InputMaybe<Scalars["Boolean"]>;
  isPep?: InputMaybe<Scalars["Boolean"]>;
  leadStatus?: InputMaybe<BitrixLeadStatusEnum>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<BitrixLeadNameInput>;
  nationality?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Scalars["String"]>;
  reasonsForAccountOpening?: InputMaybe<Scalars["String"]>;
  scoring?: InputMaybe<Scalars["Int"]>;
  scoringRiskLevel?: InputMaybe<ScoringRiskLevelEnum>;
  screeningReasons?: InputMaybe<Scalars["String"]>;
  startManualKycUrl?: InputMaybe<Scalars["String"]>;
  sumsubStatus?: InputMaybe<Scalars["String"]>;
  termsAndConditions?: InputMaybe<Scalars["Boolean"]>;
};

export type BitrixContactPepInput = {
  country?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  type: BitrixContactPepTypeEnum;
};

export enum BitrixContactPepTypeEnum {
  Beneficial = "BENEFICIAL",
  BeneficialFamily = "BENEFICIAL_FAMILY",
  Representative = "REPRESENTATIVE",
  RepresentativeFamily = "REPRESENTATIVE_FAMILY",
}

export type BitrixCreateCompanyInput = {
  activityLicense?: InputMaybe<BitrixCompanyActiveLicenseInput>;
  additionalResponseData: Scalars["String"];
  annual?: InputMaybe<Scalars["String"]>;
  beneficialUSPersonDetails?: InputMaybe<Scalars["String"]>;
  businessModelExplanation?: InputMaybe<Scalars["String"]>;
  companyCorrespondenceAddress?: InputMaybe<Scalars["String"]>;
  companyFiles?: InputMaybe<BitrixCompanyFilesInput>;
  contacts?: InputMaybe<Array<BitrixCreateContactInput>>;
  countryOfResidenceForTax?: InputMaybe<Scalars["String"]>;
  customerRepresentativeUSDetails?: InputMaybe<Scalars["String"]>;
  dateOfRegistration?: InputMaybe<Scalars["Timestamp"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalUserId: Scalars["String"];
  isBeneficialFamilyPep?: InputMaybe<Scalars["Boolean"]>;
  isBeneficialPep?: InputMaybe<Scalars["Boolean"]>;
  isBeneficialUSPerson?: InputMaybe<Scalars["Boolean"]>;
  isBusinessActivityLicensedOrSupervised?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativeFamilyPep?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativePep?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativeUS?: InputMaybe<Scalars["Boolean"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  legalPersonsOver25?: InputMaybe<Array<BitrixCompanyLegalPersonInput>>;
  mainBusinessActivities?: InputMaybe<Scalars["String"]>;
  mainBusinessPartners?: InputMaybe<
    Array<BitrixCompanyMainBusinessPartnerInput>
  >;
  notAllPersonsOwn25?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Scalars["String"]>;
  reasonForAccountOpening?: InputMaybe<Scalars["String"]>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  tin?: InputMaybe<Scalars["String"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type BitrixCreateContactInput = {
  beneficialOwner?: InputMaybe<BitrixContactBeneficialOwnerInput>;
  customerRepresentative?: InputMaybe<BitrixContactCustomerRepresentativeInput>;
  lead?: InputMaybe<BitrixContactLeadInput>;
  pep?: InputMaybe<BitrixContactPepInput>;
};

export type BitrixCreateDealInput = {
  leadId: Scalars["String"];
  type: BitrixDealTypeEnum;
};

export type BitrixCreateOrUpdateLeadInput = {
  address?: InputMaybe<BitrixLeadAddressInput>;
  birthdate?: InputMaybe<Scalars["Timestamp"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalUserId: Scalars["String"];
  files?: InputMaybe<BitrixLeadFilesInput>;
  isFamilyMemberPep?: InputMaybe<Scalars["Boolean"]>;
  isPep?: InputMaybe<Scalars["Boolean"]>;
  leadStatus?: InputMaybe<BitrixLeadStatusEnum>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<BitrixLeadNameInput>;
  nationality?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Scalars["String"]>;
  reasonsForAccountOpening?: InputMaybe<Scalars["String"]>;
  scoring?: InputMaybe<Scalars["Int"]>;
  scoringRiskLevel?: InputMaybe<ScoringRiskLevelEnum>;
  screeningReasons?: InputMaybe<Scalars["String"]>;
  startManualKycUrl?: InputMaybe<Scalars["String"]>;
  sumsubStatus?: InputMaybe<Scalars["String"]>;
  termsAndConditions?: InputMaybe<Scalars["Boolean"]>;
};

export enum BitrixDealTypeEnum {
  CreateMultipleCurrencyAccount = "CREATE_MULTIPLE_CURRENCY_ACCOUNT",
  Unknown = "UNKNOWN",
}

export type BitrixEnPropertyInput = {
  en?: InputMaybe<Scalars["String"]>;
};

export type BitrixEnPropertyType = {
  __typename?: "BitrixEnPropertyType";
  en?: Maybe<Scalars["String"]>;
};

export enum BitrixEntitiesEnum {
  Company = "COMPANY",
  Contact = "CONTACT",
  Deal = "DEAL",
  Lead = "LEAD",
}

export type BitrixFieldsPropertiesType = {
  __typename?: "BitrixFieldsPropertiesType";
  filterLabel?: Maybe<Scalars["String"]>;
  formLabel?: Maybe<Scalars["String"]>;
  isDynamic: Scalars["Boolean"];
  isImmutable: Scalars["Boolean"];
  isMultiple: Scalars["Boolean"];
  isReadOnly: Scalars["Boolean"];
  isRequired: Scalars["Boolean"];
  items?: Maybe<Array<BitrixItemType>>;
  listLabel?: Maybe<Scalars["String"]>;
  statusType?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type BitrixItemType = {
  __typename?: "BitrixItemType";
  id: Scalars["String"];
  value: Scalars["String"];
};

export type BitrixLeadAddressInput = {
  address?: InputMaybe<BitrixEnPropertyInput>;
  city?: InputMaybe<BitrixEnPropertyInput>;
  country?: InputMaybe<BitrixEnPropertyInput>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<BitrixEnPropertyInput>;
};

export type BitrixLeadAddressType = {
  __typename?: "BitrixLeadAddressType";
  address?: Maybe<BitrixEnPropertyType>;
  city?: Maybe<BitrixEnPropertyType>;
  country?: Maybe<BitrixEnPropertyType>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<BitrixEnPropertyType>;
};

export type BitrixLeadFilePropertiesType = {
  __typename?: "BitrixLeadFilePropertiesType";
  downloadUrl?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Float"]>;
  showUrl?: Maybe<Scalars["String"]>;
};

export type BitrixLeadFileType = {
  __typename?: "BitrixLeadFileType";
  properties?: Maybe<BitrixLeadFilePropertiesType>;
  title?: Maybe<Scalars["String"]>;
};

export type BitrixLeadFilesInput = {
  AGREEMENT?: InputMaybe<Scalars["String"]>;
  BACK_SIDE_ID_CARD?: InputMaybe<Scalars["String"]>;
  BACK_SIDE_RESIDENCE_PERMIT?: InputMaybe<Scalars["String"]>;
  BANK_CARD?: InputMaybe<Scalars["String"]>;
  BANK_STATEMENT?: InputMaybe<Scalars["String"]>;
  CONTRACT?: InputMaybe<Scalars["String"]>;
  DRIVERS?: InputMaybe<Scalars["String"]>;
  DRIVERS_TRANSLATION?: InputMaybe<Scalars["String"]>;
  EMPLOYMENT_CERTIFICATE?: InputMaybe<Scalars["String"]>;
  FRONT_SIDE_ID_CARD?: InputMaybe<Scalars["String"]>;
  FRONT_SIDE_RESIDENCE_PERMIT?: InputMaybe<Scalars["String"]>;
  ID_CARD?: InputMaybe<Scalars["String"]>;
  ID_DOC_PHOTO?: InputMaybe<Scalars["String"]>;
  INCOME_SOURCE?: InputMaybe<Scalars["String"]>;
  INVESTOR_DOC?: InputMaybe<Scalars["String"]>;
  OTHER?: InputMaybe<Scalars["String"]>;
  PASSPORT?: InputMaybe<Scalars["String"]>;
  PAYMENT_METHOD?: InputMaybe<Scalars["String"]>;
  PROFILE_IMAGE?: InputMaybe<Scalars["String"]>;
  RESIDENCE_PERMIT?: InputMaybe<Scalars["String"]>;
  SELFIE?: InputMaybe<Scalars["String"]>;
  UTILITY_BILL?: InputMaybe<Scalars["String"]>;
  VEHICLE_REGISTRATION_CERTIFICATE?: InputMaybe<Scalars["String"]>;
  VIDEO_SELFIE?: InputMaybe<Scalars["String"]>;
  documentForKyb?: InputMaybe<Scalars["String"]>;
};

export type BitrixLeadNameAllType = {
  __typename?: "BitrixLeadNameAllType";
  first?: Maybe<BitrixEnPropertyType>;
  full?: Maybe<BitrixEnPropertyType>;
  last?: Maybe<BitrixEnPropertyType>;
  middle?: Maybe<BitrixEnPropertyType>;
  title?: Maybe<Scalars["String"]>;
};

export type BitrixLeadNameInput = {
  first?: InputMaybe<BitrixEnPropertyInput>;
  last?: InputMaybe<BitrixEnPropertyInput>;
  middle?: InputMaybe<BitrixEnPropertyInput>;
};

export enum BitrixLeadStatusEnum {
  Blocked = "BLOCKED",
  Rejected = "REJECTED",
  Successful = "SUCCESSFUL",
  SuccessfullyPassedScreeningAndScoring = "SUCCESSFULLY_PASSED_SCREENING_AND_SCORING",
  TransferredToAmlOfficer = "TRANSFERRED_TO_AML_OFFICER",
}

export type BitrixLeadType = {
  __typename?: "BitrixLeadType";
  address?: Maybe<BitrixLeadAddressType>;
  birthdate?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  externalUserId?: Maybe<Scalars["String"]>;
  files: Array<BitrixLeadFileType>;
  id: Scalars["String"];
  isFamilyMemberPep?: Maybe<Scalars["Boolean"]>;
  isPep?: Maybe<Scalars["Boolean"]>;
  leadStatus?: Maybe<BitrixLeadStatusEnum>;
  mobilePhone?: Maybe<Scalars["String"]>;
  name?: Maybe<BitrixLeadNameAllType>;
  nationality?: Maybe<Scalars["String"]>;
  plannedAccountUse?: Maybe<Scalars["String"]>;
  reasonsForAccountOpening?: Maybe<Scalars["String"]>;
  scoring?: Maybe<Scalars["Int"]>;
  scoringRiskLevel?: Maybe<ScoringRiskLevelEnum>;
  screeningReasons?: Maybe<Scalars["String"]>;
  startManualKycUrl?: Maybe<Scalars["String"]>;
  sumsubStatus?: Maybe<Scalars["String"]>;
  termsAndConditions?: Maybe<Scalars["Boolean"]>;
};

export type BitrixParsedFieldType = {
  __typename?: "BitrixParsedFieldType";
  field: Scalars["String"];
  properties: BitrixFieldsPropertiesType;
};

export type BitrixUpdateCompanyInput = {
  activityLicense?: InputMaybe<BitrixCompanyActiveLicenseInput>;
  annual?: InputMaybe<Scalars["String"]>;
  beneficialUSPersonDetails?: InputMaybe<Scalars["String"]>;
  businessModelExplanation?: InputMaybe<Scalars["String"]>;
  companyCorrespondenceAddress?: InputMaybe<Scalars["String"]>;
  companyFiles?: InputMaybe<BitrixCompanyFilesInput>;
  companyId: Scalars["String"];
  contacts?: InputMaybe<Array<BitrixUpdateContactInput>>;
  countryOfResidenceForTax?: InputMaybe<Scalars["String"]>;
  customerRepresentativeUSDetails?: InputMaybe<Scalars["String"]>;
  dateOfRegistration?: InputMaybe<Scalars["Timestamp"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalUserId: Scalars["String"];
  isBeneficialFamilyPep?: InputMaybe<Scalars["Boolean"]>;
  isBeneficialPep?: InputMaybe<Scalars["Boolean"]>;
  isBeneficialUSPerson?: InputMaybe<Scalars["Boolean"]>;
  isBusinessActivityLicensedOrSupervised?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativeFamilyPep?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativePep?: InputMaybe<Scalars["Boolean"]>;
  isCustomerRepresentativeUS?: InputMaybe<Scalars["Boolean"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  legalPersonsOver25?: InputMaybe<Array<BitrixCompanyLegalPersonInput>>;
  mainBusinessActivities?: InputMaybe<Scalars["String"]>;
  mainBusinessPartners?: InputMaybe<
    Array<BitrixCompanyMainBusinessPartnerInput>
  >;
  notAllPersonsOwn25?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Scalars["String"]>;
  reasonForAccountOpening?: InputMaybe<Scalars["String"]>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  tin?: InputMaybe<Scalars["String"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type BitrixUpdateContactInput = {
  beneficialOwner?: InputMaybe<BitrixContactBeneficialOwnerInput>;
  contactId: Scalars["String"];
  customerRepresentative?: InputMaybe<BitrixContactCustomerRepresentativeInput>;
  lead?: InputMaybe<BitrixContactLeadInput>;
  pep?: InputMaybe<BitrixContactPepInput>;
};

export type BlockCryptoCardInput = {
  blockType: CardBlockTypeEnum;
  /** Card unique identifier */
  cardId: Scalars["ID"];
};

export type BooleanResponse = {
  __typename?: "BooleanResponse";
  data?: Maybe<Scalars["Boolean"]>;
  error?: Maybe<ResponseError>;
};

export type CalculateAmountByTypesObject = {
  __typename?: "CalculateAmountByTypesObject";
  deposits: AmountByTypesObject;
  transfers: AmountByTypesObject;
  withdrawls: AmountByTypesObject;
};

export type CaptchaInput = {
  /** An action that is protected by a reCAPTCHA, for example: SIGN_IN, SEND_SMS */
  captchaAction?: InputMaybe<Scalars["String"]>;
  /** reCAPTCHA token */
  captchaToken: Scalars["String"];
};

export enum Card3DSecureTypeEnum {
  SmsotpAndStaticPassword = "SMSOTP_AND_STATIC_PASSWORD",
}

export enum CardBlockTypeEnum {
  BlockedByCardholder = "BLOCKED_BY_CARDHOLDER",
  BlockedByCardholderViaPhone = "BLOCKED_BY_CARDHOLDER_VIA_PHONE",
  BlockedByClient = "BLOCKED_BY_CLIENT",
  BlockedByIssuer = "BLOCKED_BY_ISSUER",
  Counterfeit = "COUNTERFEIT",
  Fraudulent = "FRAUDULENT",
  Lost = "LOST",
  MaxInvalidTriesCvv2 = "MAX_INVALID_TRIES_CVV2",
  MaxInvalidTriesPin = "MAX_INVALID_TRIES_PIN",
  NotDelivered = "NOT_DELIVERED",
  Stolen = "STOLEN",
}

export type CardData = {
  __typename?: "CardData";
  bin: Scalars["String"];
  cardCategory?: Maybe<Scalars["String"]>;
  cardType?: Maybe<Scalars["String"]>;
  expiryMonth: Scalars["Float"];
  expiryYear: Scalars["Float"];
  issuer?: Maybe<Scalars["String"]>;
  issuerCountry?: Maybe<Scalars["String"]>;
  last4: Scalars["String"];
  productId?: Maybe<Scalars["String"]>;
  productType?: Maybe<Scalars["String"]>;
  scheme?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  type: Scalars["String"];
};

/** This input represents card data received from checkout after requesting a token */
export type CardDataInput = {
  bin: Scalars["String"];
  cardCategory: Scalars["String"];
  cardType: Scalars["String"];
  expiryMonth: Scalars["Float"];
  expiryYear: Scalars["Float"];
  issuer: Scalars["String"];
  issuerCountry: Scalars["String"];
  last4: Scalars["String"];
  productId: Scalars["String"];
  productType: Scalars["String"];
  scheme: Scalars["String"];
  type: Scalars["String"];
};

export type CardStatistics = {
  __typename?: "CardStatistics";
  deposits: Scalars["BigNumber"];
  payments: Scalars["BigNumber"];
  withdraw: Scalars["BigNumber"];
};

export enum CardStatusEnum {
  Active = "ACTIVE",
  AwaitingRenewal = "AWAITING_RENEWAL",
  Blocked = "BLOCKED",
  Closed = "CLOSED",
  Closing = "CLOSING",
  Created = "CREATED",
  Dispatched = "DISPATCHED",
  Expired = "EXPIRED",
  Ordered = "ORDERED",
  Personalized = "PERSONALIZED",
}

export type CardTopUpConfig = {
  __typename?: "CardTopUpConfig";
  clientEeaFinancialInstitutionsContractId: Scalars["String"];
  clientNonEeaFinancialInstitutionsContractId: Scalars["String"];
  clientUkFinancialInstitutionsContractId: Scalars["String"];
  corporateClientTransitContractId: Scalars["String"];
  corporateInwardPaymentsIncomeContractId: Scalars["String"];
  dailyLimit: Scalars["Float"];
  defaultFee: Scalars["BigNumber"];
  defaultFeeMargin: Scalars["BigNumber"];
  feePercentage: Scalars["BigNumber"];
  minimalLimit: Scalars["Float"];
  monthlyLimit: Scalars["Float"];
  otherDirectCostsContractId: Scalars["String"];
  otherReceivablesContractId: Scalars["String"];
  ownEeaFinancialInstitutionsContractId: Scalars["String"];
  ownNonEeaFinancialInstitutionsContractId: Scalars["String"];
  ownUkFinancialInstitutionsContractId: Scalars["String"];
  payablesSettlementContractId: Scalars["String"];
  physicalClientTransitContractId: Scalars["String"];
  physicalInwardPaymentsIncomeContractId: Scalars["String"];
  provisionContractId: Scalars["String"];
  receivablesFromCustomersContractId: Scalars["String"];
  transactionLimit: Scalars["Float"];
};

export type CardTopUpFee = {
  __typename?: "CardTopUpFee";
  fee: Scalars["BigNumber"];
};

export type CardTransaction = {
  cardCategory: string;
  cardStatus: string;
  cardDirection: CardTransactionDirectionEnum;
  __typename?: "CardTransaction";
  cardId?: Maybe<Scalars["String"]>;
  category: CardTransactionTypeEnum;
  cryptoAmount: Scalars["BigNumber"];
  cryptoCoin: Currency;
  cryptoFee: Scalars["BigNumber"];
  direction: CardTransactionDirectionEnum;
  fiatAmount: Scalars["BigNumber"];
  fiatCoin: Currency;
  fiatFee: Scalars["BigNumber"];
  id: Scalars["ID"];
  merchantCountryCode?: Maybe<CountryCodeEnum>;
  merchantName?: Maybe<Scalars["String"]>;
  serviceCoin: Currency;
  serviceFee: Scalars["BigNumber"];
  serviceFeeInCryptoCurrency: Scalars["BigNumber"];
  status: CardTransactionStatusEnum;
  time: Scalars["DateTime"];
  totalCryptoAmount: Scalars["BigNumber"];
  transactionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export enum CardTransactionDirectionEnum {
  Commission = "COMMISSION",
  Exchange = "EXCHANGE",
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
  Transfer = "TRANSFER",
}

export type CardTransactionGroup = {
  __typename?: "CardTransactionGroup";
  /** The statistics for all transaction's categories. */
  statistics: CardStatistics;
  transactions: Array<Maybe<CardTransaction>>;
};

export enum CardTransactionStatusEnum {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Errored = "ERRORED",
  PartiallyApproved = "PARTIALLY_APPROVED",
  PartiallySettled = "PARTIALLY_SETTLED",
  Received = "RECEIVED",
  Reversed = "REVERSED",
  ReverseCompleted = "REVERSE_COMPLETED",
  Settled = "SETTLED",
}

export enum CardTransactionTypeEnum {
  Deposit = "DEPOSIT",
  Others = "OTHERS",
  Payment = "PAYMENT",
  Transfer = "TRANSFER",
  Withdraw = "WITHDRAW",
}

export enum CardTypeEnum {
  ChipAndPin = "CHIP_AND_PIN",
  ChipAndPinAnonymous = "CHIP_AND_PIN_ANONYMOUS",
  Virtual = "VIRTUAL",
}

export enum CashCategory {
  Deposit = "DEPOSIT",
  Payment = "PAYMENT",
  Transfer = "TRANSFER",
  Withdraw = "WITHDRAW",
}

export type CashPaymentAccount = {
  __typename?: "CashPaymentAccount";
  absAccountId?: Maybe<Scalars["String"]>;
  accountId?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankAddress?: Maybe<Scalars["String"]>;
  bankBic?: Maybe<Scalars["String"]>;
  bankCityId?: Maybe<Scalars["String"]>;
  bankId?: Maybe<Scalars["String"]>;
  bankName?: Maybe<Scalars["String"]>;
  bankSortCode?: Maybe<Scalars["String"]>;
  cardId?: Maybe<Scalars["String"]>;
  cardNumber?: Maybe<Scalars["String"]>;
  contactCompanyName?: Maybe<Scalars["String"]>;
  contactFirstName?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  contactLastName?: Maybe<Scalars["String"]>;
  countryId?: Maybe<Scalars["String"]>;
  eWalletId?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  swift?: Maybe<Scalars["String"]>;
  temenosAccountId?: Maybe<Scalars["String"]>;
};

export type CashStatistics = {
  __typename?: "CashStatistics";
  /** The total amount in BTC of transactions by specified category. */
  deposits: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  payments: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  transfers: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  withdrawals: Scalars["BigNumber"];
};

export enum CashStatus {
  Completed = "COMPLETED",
  Denied = "DENIED",
  Pending = "PENDING",
  Refunded = "REFUNDED",
}

export type CashTransaction = {
  cashCategory: string;
  __typename?: "CashTransaction";
  amount: Scalars["BigNumber"];
  category: CashCategory;
  coin: Currency;
  /** The amount of transaction in BTC equivalent. */
  compareAmount: Scalars["BigNumber"];
  direction: TransactionDirection;
  fee: Scalars["BigNumber"];
  from: CashPaymentAccount;
  id: Scalars["ID"];
  isPrintedFormEnabled: Scalars["Boolean"];
  service: Scalars["String"];
  sourceInfo?: Maybe<SourceInfo>;
  status: CashStatus;
  time: Scalars["DateTime"];
  to: CashPaymentAccount;
  totalAmount: Scalars["BigNumber"];
  transactionId: Scalars["ID"];
};

export type CashTransactionGroup = {
  __typename?: "CashTransactionGroup";
  /** The statistics for all transaction's categories. */
  statistics: CashStatistics;
  transactions: Array<Maybe<CashTransactionWithRepeatable>>;
};

export type CashTransactionWithRepeatable = {
  __typename?: "CashTransactionWithRepeatable";
  isRepeatable: Scalars["Boolean"];
  transaction: CashTransaction;
};

export type ChangeCryptoCardBalanceInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /**
   * Example: 'USDT', 'BTC', 'ETH'
   * Max length: 10
   */
  token: Scalars["String"];
};

export type ChangeCryptoCardNameInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /** Max length: 100 characters */
  name: Scalars["String"];
};

export type ChangeIdentityInput = {
  type: IdentityType;
};

/** Change Margin type */
export enum ChangeMarginEnum {
  /** Crossed change margin type */
  Crossed = "CROSSED",
  /** Isolated change margin type */
  Isolated = "ISOLATED",
}

export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangeUserStatusInput = {
  service: Service;
  status: StatusEnum;
  userId: Scalars["String"];
};

/** Return mark for chart */
export type ChartMark = {
  __typename?: "ChartMark";
  price?: Maybe<Scalars["Float"]>;
  side?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["Float"]>;
};

export type CheckCryptoTransaction = {
  __typename?: "CheckCryptoTransaction";
  applicantId: Scalars["String"];
  clientId: Scalars["String"];
  createdAt: Scalars["String"];
  id: Scalars["String"];
  key: Scalars["String"];
  paymentChecks: Array<CheckCryptoTransactionPaymentCheck>;
  review: CheckCryptoTransactionReview;
  type: Scalars["String"];
};

export type CheckCryptoTransactionInput = {
  /** Target address hash. */
  address: Scalars["String"];
  /** BTC, ETH, BCH, LTC, USDT, ERC-20 */
  currency: Scalars["String"];
  /** withdrawal or deposit */
  direction: DirectionCryptoTransaction;
  /** id INDIVIDUAL or COMPANY */
  identityId: Scalars["String"];
  /** Specify ERC-20 tokens. Full list of available tokens is https://developers.sumsub.com/payment-methods/tokenList.html */
  tokenId?: InputMaybe<Scalars["String"]>;
  /** Transaction hash. For withdrawals you can set txn as an empty string (e.g. "txn": "") */
  txn?: InputMaybe<Scalars["String"]>;
  /** user type, COMPANY or INDIVIDUAL */
  userType: UserTypeEnum;
};

export type CheckCryptoTransactionPaymentCheck = {
  __typename?: "CheckCryptoTransactionPaymentCheck";
  answer: Scalars["String"];
  checkType: Scalars["String"];
  createdAt: Scalars["String"];
  cryptoTxnRiskScoreInfo: CheckCryptoTransactionScore;
  id: Scalars["String"];
};

export type CheckCryptoTransactionReview = {
  __typename?: "CheckCryptoTransactionReview";
  autoChecked: Scalars["Boolean"];
  createDate: Scalars["String"];
  elapsedSincePendingMs: Scalars["Int"];
  elapsedSinceQueuedMs: Scalars["Int"];
  reprocessing: Scalars["Boolean"];
  reviewResult: CheckCryptoTransactionReviewResult;
  reviewStatus: Scalars["String"];
};

export type CheckCryptoTransactionReviewResult = {
  __typename?: "CheckCryptoTransactionReviewResult";
  reviewAnswer: Scalars["String"];
};

export type CheckCryptoTransactionScore = {
  __typename?: "CheckCryptoTransactionScore";
  errorName?: Maybe<Scalars["String"]>;
  riskScore: Scalars["Float"];
  txnMonitorData: CheckCryptoTransactionTxnMonitorData;
};

export type CheckCryptoTransactionSignals = {
  __typename?: "CheckCryptoTransactionSignals";
  atm?: Maybe<Scalars["Float"]>;
  dark_market?: Maybe<Scalars["Float"]>;
  dark_service?: Maybe<Scalars["Float"]>;
  exchange?: Maybe<Scalars["Float"]>;
  exchange_fraudulent?: Maybe<Scalars["Float"]>;
  exchange_mlrisk_high?: Maybe<Scalars["Float"]>;
  exchange_mlrisk_low?: Maybe<Scalars["Float"]>;
  exchange_mlrisk_moderate?: Maybe<Scalars["Float"]>;
  exchange_mlrisk_veryhigh?: Maybe<Scalars["Float"]>;
  gambling: Scalars["Float"];
  illegal_service?: Maybe<Scalars["Float"]>;
  marketplace?: Maybe<Scalars["Float"]>;
  miner?: Maybe<Scalars["Float"]>;
  mixer?: Maybe<Scalars["Float"]>;
  p2p_exchange_mlrisk_high?: Maybe<Scalars["Float"]>;
  p2p_exchange_mlrisk_low?: Maybe<Scalars["Float"]>;
  payment?: Maybe<Scalars["Float"]>;
  ransom?: Maybe<Scalars["Float"]>;
  scam?: Maybe<Scalars["Float"]>;
  stolen_coins?: Maybe<Scalars["Float"]>;
  trusted_exchange?: Maybe<Scalars["Float"]>;
  wallet?: Maybe<Scalars["Float"]>;
};

export type CheckCryptoTransactionTxnMonitorData = {
  __typename?: "CheckCryptoTransactionTxnMonitorData";
  address: Scalars["String"];
  amount?: Maybe<Scalars["Int"]>;
  direction: Scalars["String"];
  fiat?: Maybe<Scalars["Int"]>;
  fiatCode: Scalars["String"];
  riskscore: Scalars["Float"];
  riskyVolume?: Maybe<Scalars["Float"]>;
  riskyVolumeFiat?: Maybe<Scalars["Float"]>;
  signals: CheckCryptoTransactionSignals;
  status: Scalars["String"];
  time?: Maybe<Scalars["String"]>;
  txn?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["String"];
};

export type Checkout3ds = {
  __typename?: "Checkout3ds";
  downgraded: Scalars["Boolean"];
  enrolled: Scalars["String"];
};

export type Checkout3dsInput = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutCustomerInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type CheckoutHref = {
  __typename?: "CheckoutHref";
  href: Scalars["String"];
};

export type CheckoutLinks = {
  __typename?: "CheckoutLinks";
  redirect?: Maybe<CheckoutHref>;
  self: CheckoutHref;
};

export type CheckoutPayment = {
  __typename?: "CheckoutPayment";
  id: Scalars["String"];
  links: CheckoutLinks;
  reference: Scalars["String"];
  secure3d: Checkout3ds;
  status: CheckoutStatus;
};

export enum CheckoutPaymentType {
  Id = "ID",
  Token = "TOKEN",
}

export type CheckoutSourceInput = {
  id?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  type: CheckoutPaymentType;
};

export enum CheckoutStatus {
  Approved = "APPROVED",
  Canceled = "CANCELED",
  Captured = "CAPTURED",
  CaptureDeclined = "CAPTURE_DECLINED",
  CapturePending = "CAPTURE_PENDING",
  Declined = "DECLINED",
  Expired = "EXPIRED",
  Pending = "PENDING",
  Refunded = "REFUNDED",
  RefundDeclined = "REFUND_DECLINED",
  RefundPending = "REFUND_PENDING",
  Voided = "VOIDED",
  VoidDeclined = "VOID_DECLINED",
}

export enum CheckoutTokenType {
  Applepay = "APPLEPAY",
  Card = "CARD",
  Googlepay = "GOOGLEPAY",
}

export type Cities = {
  __typename?: "Cities";
  count: Scalars["Int"];
  nodes: Array<City>;
};

export type City = {
  __typename?: "City";
  country?: Maybe<Country>;
  id: Scalars["ID"];
  item: CommonDictItem;
  region?: Maybe<Region>;
};

export type CityByNameInput = {
  /** [Dictionary] Represents name by which search query will be executed */
  address: Scalars["String"];
  /** [Dictionary] Represents country by which search query will be executed */
  countryId: Scalars["String"];
};

export type CityInput = {
  /** [Dictionary] Unique identifier of country in which city is located */
  countryId: Scalars["ID"];
  item: CommonDictItemInput;
  /** [Dictionary] Unique identifier of region in which city is located */
  regionId?: InputMaybe<Scalars["ID"]>;
};

/** Return coin */
export type Coin = {
  __typename?: "Coin";
  name?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type CoinNetwork = {
  __typename?: "CoinNetwork";
  addressRegex: Scalars["String"];
  coin: Scalars["String"];
  depositDesc: Scalars["String"];
  depositEnable: Scalars["Boolean"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  memoRegex: Scalars["String"];
  name: Scalars["String"];
  network: Scalars["String"];
  resetAddressStatus: Scalars["Boolean"];
  sameAddress: Scalars["Boolean"];
  specialTips?: Maybe<Scalars["String"]>;
  withdrawDesc: Scalars["String"];
  withdrawEnable: Scalars["Boolean"];
  withdrawFee: Scalars["String"];
  withdrawIntegerMultiple: Scalars["String"];
  withdrawMax: Scalars["String"];
  withdrawMin: Scalars["String"];
};

export type CoinPortfolio = {
  __typename?: "CoinPortfolio";
  availableBalance: Scalars["BigNumber"];
  coin: Scalars["String"];
  pnl?: Maybe<PnL>;
  precision: Scalars["Int"];
  /** The crypto currency object. */
  token: CoinsInfo;
  totalBalance: Scalars["BigNumber"];
  /** Total balance converted to BTC. */
  totalBalanceToBtc: Scalars["BigNumber"];
};

export type CoinsInfo = {
  __typename?: "CoinsInfo";
  coin: Scalars["String"];
  defaultNetwork?: Maybe<NetworkList>;
  depositAllEnable: Scalars["Boolean"];
  name: Scalars["String"];
  /** @deprecated This field will be removed in the next version */
  networks: Array<Maybe<NetworkList>>;
  type: CurrencyType;
};

export type Common2FaInput = {
  operationId?: InputMaybe<Scalars["String"]>;
  operationTitle?: InputMaybe<Scalars["String"]>;
};

export type CommonCryptoCardInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
};

export type CommonDictItem = {
  __typename?: "CommonDictItem";
  /**
   * [Dictionary] Represents item code
   * Contains unique data for different parent types (city, country, measure unit, language, currency etc.)
   */
  code: Scalars["String"];
  /** [Dictionary] Represents common item fields */
  common: CommonItemInfo;
  /** [Dictionary] Unique identified */
  id: Scalars["ID"];
  /** [Dictionary] Representation of item name in chosen language */
  localizedNames?: Maybe<Array<Maybe<LocalizedName>>>;
  /** [Dictionary] Represents item name */
  name: Scalars["String"];
  /** [Dictionary] Item custon tags */
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type CommonDictItemLocalizedNamesArgs = {
  langId?: InputMaybe<Scalars["ID"]>;
};

export type CommonDictItemInput = {
  code: Scalars["String"];
  localizedNames: Array<InputMaybe<LocalizedNameInput>>;
  name: Scalars["String"];
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Input to retrieve secure data */
export type CommonGetCryptoCardSecureDataInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /** RSA Public key, base64 encoded */
  encodedPublicKey: Scalars["String"];
};

export type CommonItemInfo = {
  __typename?: "CommonItemInfo";
  isDeleted: Scalars["Boolean"];
  timestamps: Timestamps;
};

export type CommonSort = {
  createdAt?: InputMaybe<Scalars["Boolean"]>;
  updateAt?: InputMaybe<Scalars["Boolean"]>;
};

export type Company = {
  __typename?: "Company";
  documents: Array<CompanyIdentityDoc>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum CompanyAnnual {
  Biggest = "BIGGEST",
  High = "HIGH",
  Low = "LOW",
  Middle = "MIDDLE",
}

export type CompanyIdentityDoc = {
  __typename?: "CompanyIdentityDoc";
  company: Company;
  doc: IdentityDoc;
  subType: CompanyIdentitySubType;
  type: CompanyIdentityType;
};

export type CompanyIdentityDocInput = {
  country: Scalars["String"];
  fileId: Scalars["ID"];
  subType: CompanyIdentitySubType;
  type: CompanyIdentityType;
};

export enum CompanyIdentitySubType {
  DirectorsRegistry = "DIRECTORS_REGISTRY",
  GoodStandingCer = "GOOD_STANDING_CER",
  IncorporationArticles = "INCORPORATION_ARTICLES",
  IncorporationCert = "INCORPORATION_CERT",
  IncumbencyCert = "INCUMBENCY_CERT",
  InformationStatement = "INFORMATION_STATEMENT",
  ProofOfAddress = "PROOF_OF_ADDRESS",
  ProofOfDomain = "PROOF_OF_DOMAIN",
  ShareholderRegistry = "SHAREHOLDER_REGISTRY",
  StateRegistry = "STATE_REGISTRY",
  TrustAgreement = "TRUST_AGREEMENT",
}

export enum CompanyIdentityType {
  CompanyDoc = "COMPANY_DOC",
  PowerOfAttorney = "POWER_OF_ATTORNEY",
  TransparencyRegistryExtract = "TRANSPARENCY_REGISTRY_EXTRACT",
}

export type CompanyMember = {
  __typename?: "CompanyMember";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isOwner: Scalars["Boolean"];
  name: FullName;
  /** According to dictionary microservice position type */
  position?: Maybe<Position>;
};

export type CompanyPayloadAuthType = {
  __typename?: "CompanyPayloadAuthType";
  applicantId?: Maybe<Scalars["String"]>;
  bic?: Maybe<Scalars["String"]>;
  companyCorrespondenceAddress?: Maybe<Scalars["String"]>;
  countryForTaxId?: Maybe<Scalars["String"]>;
  dateOfRegistration?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  legalAddress?: Maybe<Scalars["String"]>;
  legalName?: Maybe<Scalars["String"]>;
  mainBusinessActivityIds: Array<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  registrationNumber?: Maybe<Scalars["String"]>;
  status: CompanyStatus;
  tariff: CompanyTariff;
  tin?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type CompanyPayloadType = {
  __typename?: "CompanyPayloadType";
  applicantId?: Maybe<Scalars["String"]>;
  bic?: Maybe<Scalars["String"]>;
  companyCorrespondenceAddress?: Maybe<Scalars["String"]>;
  countryForTaxId?: Maybe<Scalars["String"]>;
  dateOfRegistration?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  legalAddress?: Maybe<Scalars["String"]>;
  legalName?: Maybe<Scalars["String"]>;
  mainBusinessActivityIds: Array<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  registrationNumber?: Maybe<Scalars["String"]>;
  status: CompanyStatus;
  tariff: CompanyTariff;
  tin?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type CompanyQuestionnaire = {
  __typename?: "CompanyQuestionnaire";
  NACElvl1?: Maybe<Array<NaceCode>>;
  NACElvl2?: Maybe<Array<NaceCode>>;
  actualAddress?: Maybe<Address>;
  annualTurnover?: Maybe<NumberRange>;
  businessCountries: Country;
  businessCountryIds?: Maybe<Array<Country>>;
  businessModelExplanation?: Maybe<Scalars["String"]>;
  company: Company;
  legalAddress?: Maybe<Address>;
  numberOfEmployees?: Maybe<NumberRange>;
  partnerIds?: Maybe<Array<ExternalCompany>>;
  partners: ExternalCompany;
  plannedAccountUse?: Maybe<PlannedAccountUse>;
  reasonForAccountOpening?: Maybe<ReasonForCompanyAccountOpening>;
  sourceOfFunds?: Maybe<SourceOfFunds>;
  taxResidenceCountry?: Maybe<Country>;
  website?: Maybe<Scalars["String"]>;
};

export type CompanySourceOfFundsInput = {
  other?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<SourceOfFunds>;
};

export enum CompanyStatus {
  Pending = "PENDING",
  Verified = "VERIFIED",
}

export enum CompanyTariff {
  Base = "BASE",
}

export type CompleteOrderFilter = {
  __typename?: "CompleteOrderFilter";
  id: Scalars["ID"];
  name: Scalars["String"];
  owner: ExchangeService;
  pair?: Maybe<OrderPair>;
  /** JSON string with filter parameters. */
  parameters: BinanceFilterParameters;
  type: OrderFilterType;
};

export type Confirm2FaInput = {
  operationId: Scalars["String"];
  token: Scalars["String"];
  type: TfaType;
};

export type ConfirmChangePasswordInput = {
  changePassword: ChangePasswordInput;
  verify2FAInput: Verify2FaInput;
};

export type ConfirmChangedIdentityInput = {
  /** New identity */
  newIdentity: Scalars["String"];
  tfaVerifyData: Verify2FaInput;
  type: IdentityType;
};

export type ConfirmInput = {
  code: Scalars["String"];
  device: Device;
  identity: Scalars["String"];
  type: SendCode;
};

export type Contact = {
  __typename?: "Contact";
  accountNumber?: Maybe<Scalars["String"]>;
  addresses?: Maybe<Array<Maybe<ContactAddress>>>;
  birthday?: Maybe<Scalars["DateTime"]>;
  cardNumber?: Maybe<Scalars["CardNumber"]>;
  common: CommonItemInfo;
  emails?: Maybe<Array<Maybe<ContactEmail>>>;
  iban?: Maybe<Scalars["IBAN"]>;
  id: Scalars["ID"];
  image?: Maybe<File>;
  isBanxeUser: Scalars["Boolean"];
  isPaysendUser?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<FullName>;
  nickName?: Maybe<Scalars["String"]>;
  organization?: Maybe<ContactOrganization>;
  phones?: Maybe<Array<Maybe<ContactPhone>>>;
  socials?: Maybe<Array<Maybe<ContactSocial>>>;
  swift?: Maybe<Scalars["SWIFT"]>;
  urls?: Maybe<Array<Maybe<ContactUrl>>>;
};

export type ContactAddress = {
  __typename?: "ContactAddress";
  address: Address;
  type?: Maybe<Scalars["String"]>;
};

export type ContactAddressInput = {
  addressId: Scalars["ID"];
  type?: InputMaybe<Scalars["String"]>;
};

export type ContactEmail = {
  __typename?: "ContactEmail";
  email: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type ContactEmailInput = {
  email: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type ContactInput = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  addresses?: InputMaybe<Array<InputMaybe<ContactAddressInput>>>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  cardNumber?: InputMaybe<Scalars["CardNumber"]>;
  emails?: InputMaybe<Array<InputMaybe<ContactEmailInput>>>;
  iban?: InputMaybe<Scalars["IBAN"]>;
  imageFileId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<FullNameInput>;
  nickName?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<ContactOrganizationInput>;
  phones?: InputMaybe<Array<InputMaybe<ContactPhoneInput>>>;
  socials?: InputMaybe<Array<InputMaybe<ContactSocialInput>>>;
  swift?: InputMaybe<Scalars["SWIFT"]>;
  urls?: InputMaybe<Array<InputMaybe<ContactUrlInput>>>;
};

export type ContactOrganization = {
  __typename?: "ContactOrganization";
  departmentName?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
};

export type ContactOrganizationInput = {
  departmentName?: InputMaybe<Scalars["String"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  organizationName?: InputMaybe<Scalars["String"]>;
};

export type ContactPhone = {
  __typename?: "ContactPhone";
  isDeleted?: Maybe<Scalars["Boolean"]>;
  phone: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type ContactPhoneInput = {
  phone: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type ContactSocial = {
  __typename?: "ContactSocial";
  id: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type ContactSocialInput = {
  id: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type ContactUrl = {
  __typename?: "ContactUrl";
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ContactUrlInput = {
  type?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type Contacts = {
  __typename?: "Contacts";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<Contact>;
};

export type ContactsInput = {
  /** Contact keywords such as: e-mail, phone number, firstname, lastname...any name. */
  contactQuery?: InputMaybe<Scalars["String"]>;
  /** If set to true, deleted contacts included in result. */
  deleted?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Filter>;
};

export type Continent = {
  __typename?: "Continent";
  item: CommonDictItem;
};

export type ContinentInput = {
  item: CommonDictItemInput;
};

export type ConvertRate = {
  __typename?: "ConvertRate";
  baseAsset: Currency;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdateTime?: Maybe<Scalars["DateTime"]>;
  priceInBase?: Maybe<Scalars["BigNumber"]>;
  quoteAsset: Currency;
  type: ConvertRatesType;
  volume?: Maybe<Scalars["BigNumber"]>;
};

export type ConvertRateFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ConvertRateInput = {
  baseAssetId: Scalars["ID"];
  lastUpdateTime?: InputMaybe<Scalars["DateTime"]>;
  priceInBase: Scalars["BigNumber"];
  quoteAssetId: Scalars["ID"];
  type: ConvertRatesType;
  volume: Scalars["BigNumber"];
};

export enum ConvertRatesType {
  Auto = "AUTO",
  Manual = "MANUAL",
}

export type Countries = {
  __typename?: "Countries";
  count: Scalars["Int"];
  nodes: Array<Country>;
};

export type Country = {
  __typename?: "Country";
  alpha3?: Maybe<Scalars["String"]>;
  continent?: Maybe<Continent>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  icon?: Maybe<File>;
  id: Scalars["ID"];
  item?: Maybe<CommonDictItem>;
  languages?: Maybe<Array<Maybe<Language>>>;
  phoneCode: Scalars["String"];
  phoneTemplate: Scalars["String"];
};

export enum CountryCodeEnum {
  Abw = "ABW",
  Afg = "AFG",
  Ago = "AGO",
  Aia = "AIA",
  Ala = "ALA",
  Alb = "ALB",
  And = "AND",
  Are = "ARE",
  Arg = "ARG",
  Arm = "ARM",
  Asm = "ASM",
  Ata = "ATA",
  Atf = "ATF",
  Atg = "ATG",
  Aus = "AUS",
  Aut = "AUT",
  Aze = "AZE",
  Bdi = "BDI",
  Bel = "BEL",
  Ben = "BEN",
  Bes = "BES",
  Bfa = "BFA",
  Bgd = "BGD",
  Bgr = "BGR",
  Bhr = "BHR",
  Bhs = "BHS",
  Bih = "BIH",
  Blm = "BLM",
  Blr = "BLR",
  Blz = "BLZ",
  Bmu = "BMU",
  Bol = "BOL",
  Bra = "BRA",
  Brb = "BRB",
  Brn = "BRN",
  Btn = "BTN",
  Bvt = "BVT",
  Bwa = "BWA",
  Caf = "CAF",
  Can = "CAN",
  Cck = "CCK",
  Che = "CHE",
  Chl = "CHL",
  Chn = "CHN",
  Civ = "CIV",
  Cmr = "CMR",
  Cod = "COD",
  Cog = "COG",
  Cok = "COK",
  Col = "COL",
  Com = "COM",
  Cpv = "CPV",
  Cri = "CRI",
  Cub = "CUB",
  Cuw = "CUW",
  Cxr = "CXR",
  Cym = "CYM",
  Cyp = "CYP",
  Cze = "CZE",
  Deu = "DEU",
  Dji = "DJI",
  Dma = "DMA",
  Dnk = "DNK",
  Dom = "DOM",
  Dza = "DZA",
  Ecu = "ECU",
  Egy = "EGY",
  Eri = "ERI",
  Esh = "ESH",
  Esp = "ESP",
  Est = "EST",
  Eth = "ETH",
  Fin = "FIN",
  Fji = "FJI",
  Flk = "FLK",
  Fra = "FRA",
  Fro = "FRO",
  Fsm = "FSM",
  Gab = "GAB",
  Gbr = "GBR",
  Geo = "GEO",
  Ggy = "GGY",
  Gha = "GHA",
  Gib = "GIB",
  Gin = "GIN",
  Glp = "GLP",
  Gmb = "GMB",
  Gnb = "GNB",
  Gnq = "GNQ",
  Grc = "GRC",
  Grd = "GRD",
  Grl = "GRL",
  Gtm = "GTM",
  Guf = "GUF",
  Gum = "GUM",
  Guy = "GUY",
  Hkg = "HKG",
  Hmd = "HMD",
  Hnd = "HND",
  Hrv = "HRV",
  Hti = "HTI",
  Hun = "HUN",
  Idn = "IDN",
  Imn = "IMN",
  Ind = "IND",
  Iot = "IOT",
  Irl = "IRL",
  Irn = "IRN",
  Irq = "IRQ",
  Isl = "ISL",
  Isr = "ISR",
  Ita = "ITA",
  Jam = "JAM",
  Jey = "JEY",
  Jor = "JOR",
  Jpn = "JPN",
  Kaz = "KAZ",
  Ken = "KEN",
  Kgz = "KGZ",
  Khm = "KHM",
  Kir = "KIR",
  Kna = "KNA",
  Kor = "KOR",
  Kwt = "KWT",
  Lao = "LAO",
  Lbn = "LBN",
  Lbr = "LBR",
  Lby = "LBY",
  Lca = "LCA",
  Lie = "LIE",
  Lka = "LKA",
  Lso = "LSO",
  Ltu = "LTU",
  Lux = "LUX",
  Lva = "LVA",
  Mac = "MAC",
  Maf = "MAF",
  Mar = "MAR",
  Mco = "MCO",
  Mda = "MDA",
  Mdg = "MDG",
  Mdv = "MDV",
  Mex = "MEX",
  Mhl = "MHL",
  Mkd = "MKD",
  Mli = "MLI",
  Mlt = "MLT",
  Mmr = "MMR",
  Mne = "MNE",
  Mng = "MNG",
  Mnp = "MNP",
  Moz = "MOZ",
  Mrt = "MRT",
  Msr = "MSR",
  Mtq = "MTQ",
  Mus = "MUS",
  Mwi = "MWI",
  Mys = "MYS",
  Myt = "MYT",
  Nam = "NAM",
  Ncl = "NCL",
  Ner = "NER",
  Nfk = "NFK",
  Nga = "NGA",
  Nic = "NIC",
  Niu = "NIU",
  Nld = "NLD",
  Nor = "NOR",
  Npl = "NPL",
  Nru = "NRU",
  Nzl = "NZL",
  Omn = "OMN",
  Pak = "PAK",
  Pan = "PAN",
  Pcn = "PCN",
  Per = "PER",
  Phl = "PHL",
  Plw = "PLW",
  Png = "PNG",
  Pol = "POL",
  Pri = "PRI",
  Prk = "PRK",
  Prt = "PRT",
  Pry = "PRY",
  Pse = "PSE",
  Pyf = "PYF",
  Qat = "QAT",
  Reu = "REU",
  Rou = "ROU",
  Rus = "RUS",
  Rwa = "RWA",
  Sau = "SAU",
  Sdn = "SDN",
  Sen = "SEN",
  Sgp = "SGP",
  Sgs = "SGS",
  Shn = "SHN",
  Sjm = "SJM",
  Slb = "SLB",
  Sle = "SLE",
  Slv = "SLV",
  Smr = "SMR",
  Som = "SOM",
  Spm = "SPM",
  Srb = "SRB",
  Ssd = "SSD",
  Stp = "STP",
  Sur = "SUR",
  Svk = "SVK",
  Svn = "SVN",
  Swe = "SWE",
  Swz = "SWZ",
  Sxm = "SXM",
  Syc = "SYC",
  Syr = "SYR",
  Tca = "TCA",
  Tcd = "TCD",
  Tgo = "TGO",
  Tha = "THA",
  Tjk = "TJK",
  Tkl = "TKL",
  Tkm = "TKM",
  Tls = "TLS",
  Ton = "TON",
  Tto = "TTO",
  Tun = "TUN",
  Tur = "TUR",
  Tuv = "TUV",
  Twn = "TWN",
  Tza = "TZA",
  Uga = "UGA",
  Ukr = "UKR",
  Umi = "UMI",
  Ury = "URY",
  Usa = "USA",
  Uzb = "UZB",
  Vat = "VAT",
  Vct = "VCT",
  Ven = "VEN",
  Vgb = "VGB",
  Vir = "VIR",
  Vnm = "VNM",
  Vut = "VUT",
  Wlf = "WLF",
  Wsm = "WSM",
  Yem = "YEM",
  Zaf = "ZAF",
  Zmb = "ZMB",
  Zwe = "ZWE",
}

export type CountryInput = {
  alpha3?: InputMaybe<Scalars["String"]>;
  continentId: Scalars["ID"];
  currencyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  iconFileId?: InputMaybe<Scalars["ID"]>;
  item: CommonDictItemInput;
  languageIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  phoneCode: Scalars["String"];
  phoneTemplate: Scalars["String"];
};

export type CreateBankInput = {
  /** [Dictionary] Represents bank data */
  bank: BankInput;
};

export type CreateCheckoutPaymentInput = {
  amount: Scalars["BigNumber"];
  currency: Scalars["String"];
  customer: CheckoutCustomerInput;
  description: Scalars["String"];
  failure_url?: InputMaybe<Scalars["String"]>;
  reference: Scalars["String"];
  secure3d: Checkout3dsInput;
  source: CheckoutSourceInput;
  success_url?: InputMaybe<Scalars["String"]>;
};

export type CreateCityInput = {
  city: CityInput;
};

export type CreateContinentInput = {
  continent: ContinentInput;
};

export type CreateCountryInput = {
  country: CountryInput;
};

export type CreateCryptoAddress = {
  /** Example: ETH */
  blockchain: Scalars["String"];
  /** Example: USDT */
  token: Scalars["String"];
};

export type CreateCryptoCardInput = {
  /** Currently only virtual cards are supported */
  isVirtual: Scalars["Boolean"];
  /**
   * Default: VirtualN depending on how many cards user have
   * Max length: 40
   */
  name?: InputMaybe<Scalars["String"]>;
};

export type CreateCurrenciesInput = {
  currencies: Array<CurrencyInput>;
};

export type CreateCurrencyInput = {
  currency: CurrencyInput;
};

export type CreateExchangeInput = {
  accountId?: InputMaybe<Scalars["String"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  acquiring?: InputMaybe<AcquiringService>;
  base: FundsInput;
  quote: FundsInput;
};

export type CreateIntermediaryInput = {
  accountTypes?: InputMaybe<Array<InternalAccountType>>;
  beneficiaryAccountNumber?: InputMaybe<Scalars["String"]>;
  beneficiaryAddress?: InputMaybe<Scalars["String"]>;
  beneficiaryBIC?: InputMaybe<Scalars["String"]>;
  beneficiaryBank: Scalars["String"];
  beneficiaryIBAN?: InputMaybe<Scalars["String"]>;
  beneficiaryName: Scalars["String"];
  beneficiarySortCode?: InputMaybe<Scalars["String"]>;
  companyCountriesAlpha3?: InputMaybe<Array<Scalars["String"]>>;
  currenciesTickers?: InputMaybe<Array<Scalars["String"]>>;
  forbiddenNACECodes?: InputMaybe<Array<Scalars["String"]>>;
  name?: InputMaybe<Scalars["String"]>;
  userCountriesAlpha3?: InputMaybe<Array<Scalars["String"]>>;
};

export type CreateLanguageInput = {
  language: LanguageInput;
};

export type CreateNumberRangeInput = {
  numberRange: NumberRangeInput;
};

export type CreateOperationIdInput = {
  operationTitle: Scalars["String"];
  /** Serialized JSON containing variables to be inserted into an email template */
  templateDataJson?: InputMaybe<Scalars["String"]>;
};

export type CreateOperationInput = {
  title: Scalars["String"];
  verificationTypes: Array<TfaType>;
};

export type CreatePositionInput = {
  position: PositionInput;
};

export type CreateRateCommissionInput = {
  baseAssetTicker: Scalars["String"];
  percent: Scalars["Float"];
  quoteAssetTicker: Scalars["String"];
};

export type CreateRegionInput = {
  region: RegionInput;
};

export type CreateScoringRiskLevelInput = {
  max: Scalars["Int"];
  min: Scalars["Int"];
  /** LOW_RISK, MEDIUM_RISK, HIGH_RISK, VERY_HIGH_RISK, PROHIBITED_RISK */
  scoringRiskLevel: ScoringRiskLevelEnum;
};

export type CreateTemplateInput = {
  name: Scalars["String"];
  template?: InputMaybe<Scalars["String"]>;
  templateEN?: InputMaybe<Scalars["String"]>;
  templateRU?: InputMaybe<Scalars["String"]>;
  type: NotificationTypeEnum;
};

export type CreateTopicInput = {
  devices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name: Scalars["String"];
  namespace: Scalars["String"];
  template?: InputMaybe<CreateTemplateInput>;
};

export type CreateTransactionInput = {
  /** Amount without fee */
  amount: Scalars["BigNumber"];
  /** Example: ETH */
  blockchain: Scalars["String"];
  /** Crypto address */
  destination: Scalars["String"];
  /** Tag, example: 3537234 */
  memo?: InputMaybe<Scalars["String"]>;
  /** Example: USDT */
  token: Scalars["String"];
};

export type CreateTransactionObject = {
  __typename?: "CreateTransactionObject";
  amount: Scalars["BigNumber"];
  blockchain: NetworkList;
  createdAt: Scalars["DateTime"];
  destination: Scalars["String"];
  destinationOwner?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  operationId: Scalars["ID"];
  source: Scalars["String"];
  sourceOwner?: Maybe<Scalars["String"]>;
  status: TransactionStatusEnum;
  token: CoinsInfo;
  transactionFee?: Maybe<Scalars["BigNumber"]>;
  transactionHash?: Maybe<Scalars["String"]>;
  /** Incoming or outgoing or transfer transactions. */
  type: DirectionTypeEnum;
  updatedAt: Scalars["DateTime"];
};

export type CreateUnitInput = {
  unit: UnitInput;
};

export type CreateUserCardInput = {
  token: Scalars["String"];
  userId: Scalars["String"];
};

export type CreateVatInput = {
  /** [Dictionary] VAT is Value Added Tax number/code of company or person */
  vat: VatInput;
};

export type CreatedCryptoInternalPay = {
  __typename?: "CreatedCryptoInternalPay";
  receiver: User;
  transaction: CreateTransactionObject;
};

export enum CreditType {
  Account = "ACCOUNT",
  Card = "CARD",
  Iban = "IBAN",
  Phone = "PHONE",
  Swift = "SWIFT",
}

export type CryptoAddress = {
  __typename?: "CryptoAddress";
  address: Scalars["String"];
  balance: Scalars["BigNumber"];
  coin: Scalars["String"];
  currentNetwork: Scalars["String"];
  depositAllEnable: Scalars["Boolean"];
  memo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  networks: Array<NetworkList>;
  type: CurrencyType;
};

export type CryptoCard = {
  __typename?: "CryptoCard";
  designFile?: Maybe<File>;
  emitentInternalId: Scalars["ID"];
  expiryDate: Scalars["DateTime"];
  id: Scalars["ID"];
  isVirtual: Scalars["Boolean"];
  limits: CryptoCardPlainLimits;
  limitsUsage: CryptoCardLimits;
  maskedCardNumber: Scalars["String"];
  name: Scalars["String"];
  securitySettings: CryptoCardSecurity;
  status: CardStatusEnum;
  threeDSecuritySettings?: Maybe<CryptoCard3DSecuritySettings>;
  type: CardTypeEnum;
  vabsBalance?: Maybe<CryptoCardBalance>;
};

export type CryptoCard3DSecuritySettings = {
  __typename?: "CryptoCard3DSecuritySettings";
  /** Currently only ENG is supported  */
  languageCode: LanguageCodeEnum;
  /**
   * Masked value will be returned once password is set
   * Must be 8-36 characters long
   * No other constraints applied
   */
  password: Scalars["String"];
  /** Must be a valid number, only digits with "+" prefix allowed */
  phone: Scalars["String"];
  /** Currently only SMSOTP_AND_STATIC_PASSWORD is supported */
  type: Card3DSecureTypeEnum;
};

export type CryptoCardBalance = {
  __typename?: "CryptoCardBalance";
  free: Scalars["BigNumber"];
  token: Scalars["String"];
  topupAddressesInfo: Array<Maybe<TopupAddressInfo>>;
};

export type CryptoCardInternalTransferInput = {
  amount: Scalars["BigNumber"];
  token: Scalars["String"];
};

export type CryptoCardLimits = {
  __typename?: "CryptoCardLimits";
  dailyContactlessPurchase?: Maybe<CryptoCardLimitsUsage>;
  dailyInternetPurchase?: Maybe<CryptoCardLimitsUsage>;
  dailyOverallPurchase?: Maybe<CryptoCardLimitsUsage>;
  dailyPurchase?: Maybe<CryptoCardLimitsUsage>;
  dailyWithdrawal?: Maybe<CryptoCardLimitsUsage>;
  monthlyContactlessPurchase?: Maybe<CryptoCardLimitsUsage>;
  monthlyInternetPurchase?: Maybe<CryptoCardLimitsUsage>;
  monthlyOverallPurchase?: Maybe<CryptoCardLimitsUsage>;
  monthlyPurchase?: Maybe<CryptoCardLimitsUsage>;
  monthlyWithdrawal?: Maybe<CryptoCardLimitsUsage>;
  transactionContactlessPurchase?: Maybe<Scalars["Float"]>;
  transactionInternetPurchase?: Maybe<Scalars["Float"]>;
  transactionPurchase?: Maybe<Scalars["Float"]>;
  transactionWithdrawal?: Maybe<Scalars["Float"]>;
  weeklyContactlessPurchase?: Maybe<CryptoCardLimitsUsage>;
  weeklyInternetPurchase?: Maybe<CryptoCardLimitsUsage>;
  weeklyOverallPurchase?: Maybe<CryptoCardLimitsUsage>;
  weeklyPurchase?: Maybe<CryptoCardLimitsUsage>;
  weeklyWithdrawal?: Maybe<CryptoCardLimitsUsage>;
};

export type CryptoCardLimitsUsage = {
  __typename?: "CryptoCardLimitsUsage";
  available?: Maybe<Scalars["Float"]>;
  total?: Maybe<Scalars["Float"]>;
  used?: Maybe<Scalars["Float"]>;
};

export type CryptoCardPlainLimits = {
  __typename?: "CryptoCardPlainLimits";
  dailyContactlessPurchase?: Maybe<Scalars["Int"]>;
  dailyInternetPurchase?: Maybe<Scalars["Int"]>;
  dailyOverallPurchase?: Maybe<Scalars["Int"]>;
  dailyPurchase?: Maybe<Scalars["Int"]>;
  dailyWithdrawal?: Maybe<Scalars["Int"]>;
  monthlyContactlessPurchase?: Maybe<Scalars["Int"]>;
  monthlyInternetPurchase?: Maybe<Scalars["Int"]>;
  monthlyOverallPurchase?: Maybe<Scalars["Int"]>;
  monthlyPurchase?: Maybe<Scalars["Int"]>;
  monthlyWithdrawal?: Maybe<Scalars["Int"]>;
  transactionContactlessPurchase?: Maybe<Scalars["Int"]>;
  transactionInternetPurchase?: Maybe<Scalars["Int"]>;
  transactionPurchase?: Maybe<Scalars["Int"]>;
  transactionWithdrawal?: Maybe<Scalars["Int"]>;
  weeklyContactlessPurchase?: Maybe<Scalars["Int"]>;
  weeklyInternetPurchase?: Maybe<Scalars["Int"]>;
  weeklyOverallPurchase?: Maybe<Scalars["Int"]>;
  weeklyPurchase?: Maybe<Scalars["Int"]>;
  weeklyWithdrawal?: Maybe<Scalars["Int"]>;
};

export type CryptoCardSecurity = {
  __typename?: "CryptoCardSecurity";
  contactlessEnabled?: Maybe<Scalars["Boolean"]>;
  internetPurchaseEnabled?: Maybe<Scalars["Boolean"]>;
  overallLimitsEnabled?: Maybe<Scalars["Boolean"]>;
  withdrawalEnabled?: Maybe<Scalars["Boolean"]>;
};

export type CryptoCardSensitiveInfo = {
  __typename?: "CryptoCardSensitiveInfo";
  cardNumber: Scalars["String"];
  cvv: Scalars["String"];
};

export enum CryptoCategory {
  Buy = "BUY",
  Deposit = "DEPOSIT",
  Exchange = "EXCHANGE",
  Sell = "SELL",
  Transfer = "TRANSFER",
  Withdraw = "WITHDRAW",
}

export type CryptoExchange = InternalExchangeObject;

export type CryptoExchangeObject = {
  __typename?: "CryptoExchangeObject";
  description?: Maybe<Scalars["String"]>;
  exchangeId: Scalars["String"];
  executedQty: Scalars["BigNumber"];
  from: Scalars["String"];
  fromAsset: Scalars["String"];
  internalId: Scalars["String"];
  quantity: Scalars["BigNumber"];
  side: SideEnum;
  status: ExchangeStatus;
  to: Scalars["String"];
};

export enum CryptoExchangeType {
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export type CryptoPayment = {
  __typename?: "CryptoPayment";
  amount: Scalars["BigNumber"];
  blockchain: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  exchange: ExchangeEnum;
  exchangeId: Scalars["String"];
  from: Scalars["String"];
  fromService: Service;
  internalId: Scalars["String"];
  memo: Scalars["String"];
  status: TransactionStatusEnum;
  to: Scalars["String"];
  token: Scalars["String"];
  transactionFee: Scalars["String"];
};

export type CryptoPortfolio = {
  __typename?: "CryptoPortfolio";
  accountBalanceInBtc: Scalars["BigNumber"];
  coinPortfolios: Array<CoinPortfolio>;
};

export type CryptoStatistics = {
  __typename?: "CryptoStatistics";
  /** The total amount in BTC of transactions by specified category. */
  buy: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  receive: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  sell: Scalars["BigNumber"];
  /** The total amount in BTC of transactions by specified category. */
  send: Scalars["BigNumber"];
};

export enum CryptoStatus {
  Completed = "COMPLETED",
  Denied = "DENIED",
  Pending = "PENDING",
}

export type CryptoStatusObject = {
  __typename?: "CryptoStatusObject";
  binance: Scalars["Boolean"];
};

export type CryptoTransaction = {
  cryptoCategory: string;
  cryptoStatus: string;
  __typename?: "CryptoTransaction";
  amount: Scalars["BigNumber"];
  category: CryptoCategory;
  coin: Currency;
  /** The amount of transaction in BTC equivalent. */
  compareAmount: Scalars["BigNumber"];
  /** The wallet address. */
  destination: Scalars["String"];
  direction: TransactionDirection;
  hash?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isRepeatable: Scalars["Boolean"];
  memo?: Maybe<Scalars["String"]>;
  network: CryptoTransactionNetwork;
  /** The balance ID */
  source: Scalars["String"];
  status: CryptoStatus;
  time: Scalars["DateTime"];
  totalAmount: Scalars["BigNumber"];
  transactionFee: Scalars["BigNumber"];
};

export type CryptoTransactionGroup = {
  __typename?: "CryptoTransactionGroup";
  /** The statistics for all transaction's categories. */
  statistics: CryptoStatistics;
  transactions: Array<Maybe<CryptoTransactionWithRepeatable>>;
};

export type CryptoTransactionNetwork = {
  __typename?: "CryptoTransactionNetwork";
  addressRegex: Scalars["String"];
  coin: Scalars["String"];
  name: Scalars["String"];
  network: Scalars["String"];
  sameAddress: Scalars["Boolean"];
  withdrawFee: Scalars["BigNumber"];
  withdrawIntegerMultiple: Scalars["BigNumber"];
  withdrawMax: Scalars["BigNumber"];
  withdrawMin: Scalars["BigNumber"];
};

export type CryptoTransactionWithRepeatable = {
  __typename?: "CryptoTransactionWithRepeatable";
  isRepeatable: Scalars["Boolean"];
  transaction: CryptoTransaction;
};

export type CryptoWalletOwner = CompanyPayloadType | User;

export type Currency = {
  __typename?: "Currency";
  fastExchangeCommissionMultiplier?: Maybe<Scalars["BigNumber"]>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  item: CommonDictItem;
  minimalFastExchangeCommission: Scalars["BigNumber"];
  networkList?: Maybe<Array<CoinNetwork>>;
  precision: Scalars["Int"];
  symbol?: Maybe<Scalars["String"]>;
  ticker: Scalars["String"];
  type: CurrencyType;
};

/** Return currency from CEX */
export type CurrencyCex = {
  __typename?: "CurrencyCEX";
  available?: Maybe<Scalars["Float"]>;
  btc?: Maybe<Scalars["Float"]>;
  locked?: Maybe<Scalars["Float"]>;
  pairs: Array<Maybe<Scalars["String"]>>;
  token?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Float"]>;
};

export type CurrencyInput = {
  name: Scalars["String"];
  precision: Scalars["Int"];
  symbol?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  ticker: Scalars["String"];
  type: CurrencyType;
};

export enum CurrencyType {
  Crypto = "CRYPTO",
  Fiat = "FIAT",
}

export enum CurrencyTypeEnum {
  Crypto = "CRYPTO",
  Fiat = "FIAT",
}

export type CurrencyWhiteListOutputType = {
  __typename?: "CurrencyWhiteListOutputType";
  fullName: Scalars["String"];
  name: Scalars["String"];
  precision: Scalars["Float"];
  symbol?: Maybe<Scalars["String"]>;
  type: TransactionCurrencyTypeEnum;
};

/** Date range for filters */
export type DateFilters = {
  /** End date of range */
  endDate?: InputMaybe<Scalars["String"]>;
  /** Start date of range */
  startDate?: InputMaybe<Scalars["String"]>;
};

export type DateRangeFilterInput = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type DefiInvestMutation = {
  __typename?: "DefiInvestMutation";
  approveRequest: Scalars["Boolean"];
  invest: Scalars["String"];
  withdraw: Scalars["String"];
};

export type DefiInvestMutationApproveRequestArgs = {
  input: ApproveRequestInput;
};

export type DefiInvestMutationInvestArgs = {
  input: InvestInput;
};

export type DefiInvestMutationWithdrawArgs = {
  input: WithdrawInput;
};

export type DefiInvestQuery = {
  __typename?: "DefiInvestQuery";
  currencyWhiteList: Array<CurrencyWhiteListOutputType>;
  getInvestmentHistory: Array<OperationOutputType>;
  minimalInvestUSD: Scalars["String"];
  portfolio: PortfolioOutputType;
  portfolioHistory: Array<PortfolioHistoryOutputType>;
  positionHistory: Array<PositionHistoryOutputType>;
  positionItems: Array<PositionOutputType>;
  tryInvest: Scalars["Boolean"];
  tryWithdraw: Scalars["Boolean"];
};

export type DefiInvestQueryGetInvestmentHistoryArgs = {
  input?: InputMaybe<GetInvestmentHistoryInput>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<BanxeBackSortInputType>;
};

export type DefiInvestQueryPortfolioHistoryArgs = {
  input: PortfolioHistoryInput;
  pagination?: InputMaybe<BanxeBackPaginationInputType>;
  sort?: InputMaybe<BanxeBackSortInputType>;
};

export type DefiInvestQueryPositionHistoryArgs = {
  input: PositionHistoryInput;
  sort?: InputMaybe<BanxeBackSortInputType>;
};

export type DefiInvestQueryPositionItemsArgs = {
  input: PortfolioPositionItemsInput;
};

export type DefiInvestQueryTryInvestArgs = {
  input: InvestInput;
};

export type DefiInvestQueryTryWithdrawArgs = {
  input: WithdrawInput;
};

export type DeleteDeviceFromTopicInput = {
  /** device ids or identities */
  devices: Array<Scalars["String"]>;
  /** id or name */
  topicId: Scalars["String"];
};

export type DeleteDeviceInput = {
  deviceId: Scalars["String"];
};

export type DeleteIntermediaryInput = {
  id: Scalars["String"];
};

export type DeleteOperationInput = {
  title: Scalars["String"];
};

/** Return deposit */
export type Deposit = {
  __typename?: "Deposit";
  address?: Maybe<Scalars["String"]>;
  addressTag?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  date?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  txId?: Maybe<Scalars["String"]>;
};

export enum Device {
  Email = "EMAIL",
  Eos = "EOS",
  P256 = "P256",
  Phone = "PHONE",
}

export type DeviceInfo = {
  __typename?: "DeviceInfo";
  confirm: Scalars["Boolean"];
  deviceId: Scalars["String"];
  identity: Scalars["String"];
  type: Scalars["String"];
};

export type DeviceInfo2 = {
  __typename?: "DeviceInfo2";
  id: Scalars["ID"];
  /** phone, email, push token */
  identity: Scalars["String"];
  status?: Maybe<DeviceStatusType>;
  topics: Array<Maybe<Topic>>;
  type?: Maybe<NotificationTypeEnum>;
};

export type DeviceInfo2TopicsArgs = {
  input: TopicsInput;
};

export enum DeviceStatusType {
  Confirmed = "CONFIRMED",
  Unconfirmed = "UNCONFIRMED",
}

export type DevicesInput = {
  filter?: InputMaybe<Filter>;
  query?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<NotificationTypeEnum>;
};

export type DictFilterInput = {
  countryId?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<Filter>;
  itemQuery?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tickers?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<CurrencyType>;
};

export enum DirectionCryptoTransaction {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export enum DirectionTypeEnum {
  Commission = "COMMISSION",
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
  Transfer = "TRANSFER",
}

export type DocSet = {
  __typename?: "DocSet";
  idDocSetType: IdDocSetType;
  types: Array<UserIdentityType>;
};

export type DocSetTypeInput = {
  idDocSetType: IdDocSetType;
  types: Array<UserIdentityType>;
};

export enum EarnOperation {
  Invest = "INVEST",
  PayOff = "PAY_OFF",
}

export type EarnTransaction = {
  __typename?: "EarnTransaction";
  amount: Scalars["BigNumber"];
  coin: Currency;
  coinId: Scalars["ID"];
  direction: TransactionDirection;
  id: Scalars["ID"];
  operation: EarnOperation;
  time: Scalars["DateTime"];
};

export type EarnTransactionGroup = {
  __typename?: "EarnTransactionGroup";
  transactions: Array<Maybe<EarnTransactionWithRepeatable>>;
};

export type EarnTransactionWithRepeatable = {
  __typename?: "EarnTransactionWithRepeatable";
  isRepeatable: Scalars["Boolean"];
  transaction: EarnTransaction;
};

export type EmailDeviceInfo = {
  __typename?: "EmailDeviceInfo";
  confirm: Scalars["Boolean"];
  deviceId: Scalars["String"];
  identity: Scalars["String"];
  type: Device;
};

export type EstimateCryptoExchangeInput = {
  base: FundsToEstimateInput;
  quote: FundsToEstimateInput;
};

export type EstimatedCostsObject = {
  __typename?: "EstimatedCostsObject";
  baseFunds: FundsObject;
  fees: FeeBlockObject;
  increasedRate: Scalars["BigNumber"];
  quoteFunds: FundsObject;
  rate: Scalars["BigNumber"];
};

export type ExchangeCodeInput = {
  code: Scalars["String"];
  service: Service;
};

export enum ExchangeEnum {
  Binance = "BINANCE",
}

export enum ExchangeService {
  Binance = "BINANCE",
}

export enum ExchangeStatus {
  Canceled = "CANCELED",
  Expired = "EXPIRED",
  Filled = "FILLED",
  New = "NEW",
  PartiallyFilled = "PARTIALLY_FILLED",
  PendingCancel = "PENDING_CANCEL",
  Rejected = "REJECTED",
}

export type ExternalCompany = {
  __typename?: "ExternalCompany";
  addresses?: Maybe<Array<Maybe<AddressData>>>;
  contacts?: Maybe<Array<Maybe<ExternalCompanyContact>>>;
  country: Country;
  id: Scalars["ID"];
  item: CommonDictItem;
  kindOfActivity?: Maybe<Array<Maybe<Scalars["String"]>>>;
  members?: Maybe<Array<Maybe<ExternalCompanyMember>>>;
  registrationDate?: Maybe<Scalars["DateTime"]>;
  registrationNumber?: Maybe<Scalars["String"]>;
  state?: Maybe<ExternalCompanyState>;
  vat?: Maybe<Vat>;
  website?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ExternalCompanyContact = {
  __typename?: "ExternalCompanyContact";
  type: Scalars["String"];
  value: Scalars["String"];
};

export type ExternalCompanyMember = {
  __typename?: "ExternalCompanyMember";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: FullName;
  /** According to position type */
  position?: Maybe<Position>;
};

export enum ExternalCompanyState {
  Active = "ACTIVE",
}

export enum FastExchangeTypeEnum {
  CryptoCrypto = "CRYPTO_CRYPTO",
  CryptoFiat = "CRYPTO_FIAT",
  FiatCrypto = "FIAT_CRYPTO",
}

export type FeeBlockObject = {
  __typename?: "FeeBlockObject";
  /** The commission taken by blockchain. */
  network: FundsObject;
  /** The commission of exchange service. */
  service: FundsObject;
  /** The total amount of commissions. */
  total: FundsObject;
};

export type FiatPayment = {
  __typename?: "FiatPayment";
  amount: Scalars["BigNumber"];
  currencyTicker: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  exchangeId: Scalars["String"];
  fee: Scalars["BigNumber"];
  from: Scalars["String"];
  paymentId: Scalars["String"];
  status: AbsPaymentStatusEnum;
  to: Scalars["String"];
};

export type File = {
  __typename?: "File";
  /** required if private is true. Equal to null otherwise */
  accessToken?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  mimetype: Scalars["String"];
  originalName: Scalars["String"];
  owner: User;
  private: Scalars["Boolean"];
  /** to be implemented. Must be equal 'default' for now */
  serviceName: Scalars["String"];
  size: Scalars["String"];
  /** public url for file download */
  url: Scalars["String"];
};

export type FileSearchFilterInput = {
  /** how many files returns in request. Default: 20 */
  limit?: InputMaybe<Scalars["Float"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["String"]>;
  /** access token for file required if private is true */
  private?: InputMaybe<Scalars["Boolean"]>;
  /** searching in mimetype, serviceName, originalName for full or partial match */
  query?: InputMaybe<Scalars["String"]>;
  serviceName?: InputMaybe<Scalars["String"]>;
  /** how many files skip from first one. Default: 0 */
  skip?: InputMaybe<Scalars["Float"]>;
  uploadedAfter?: InputMaybe<Scalars["DateTime"]>;
  uploadedBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type Filter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type FilterFromCore = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

/** Result filters */
export type Filters = {
  /** Column by sorting */
  column?: InputMaybe<Scalars["String"]>;
  /** Column sorting direction */
  direction?: InputMaybe<Scalars["String"]>;
  /** Count item for response */
  limit?: InputMaybe<Scalars["Int"]>;
  /** Count item for offset */
  offset?: InputMaybe<Scalars["Int"]>;
  /** Search string on response */
  search?: InputMaybe<Scalars["String"]>;
};

export type FindAllTransactionsInput = {
  dateFilter?: InputMaybe<DateRangeFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type FindCardTransactionsInput = {
  categories?: InputMaybe<Array<InputMaybe<CardTransactionTypeEnum>>>;
  dateFilter?: InputMaybe<DateRangeFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<CardTransactionStatusEnum>;
};

export type FindCashTransactionsInput = {
  account?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<CashCategory>;
  dateFilter?: InputMaybe<DateRangeFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  /** Searches via specific transaction fields. */
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type FindConvertRatesInput = {
  baseAssetTicker: Scalars["String"];
  quoteAssetTicker: Scalars["String"];
  uuid: Scalars["ID"];
};

export type FindCryptoTransactionsInput = {
  category?: InputMaybe<CryptoCategory>;
  coinTicker?: InputMaybe<Scalars["String"]>;
  dateFilter?: InputMaybe<DateRangeFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  /** Searches via specific transaction fields. */
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type FindEarnTransactionsInput = {
  /** The coin ID taken from the Banxe dictionary service. */
  currency?: InputMaybe<Scalars["ID"]>;
  dateFilter?: InputMaybe<DateRangeFilterInput>;
};

export type FindOrderFiltersForPairInput = {
  filterName?: InputMaybe<Scalars["String"]>;
  /** The base/quote ticker to be applied as a pair part. */
  firstAsset: Scalars["String"];
  owner?: InputMaybe<ExchangeService>;
  /** The quote/base ticker to be applied as a pair part. */
  secondAsset: Scalars["String"];
};

export type FindOrderFiltersInput = {
  baseAsset?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<ExchangeService>;
  quoteAsset?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  symbol?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<OrderFilterType>;
};

export type FindOrderPairsInput = {
  baseAsset?: InputMaybe<Scalars["String"]>;
  owner: ExchangeService;
  /** Tries to find the pair with specified assets. */
  pair?: InputMaybe<Array<Scalars["String"]>>;
  quoteAsset?: InputMaybe<Scalars["String"]>;
  /** Tries to find any pairs with passed assets. */
  withAssets?: InputMaybe<Array<Scalars["String"]>>;
};

export type FindReceiptInput = {
  fastExchangeId: Scalars["ID"];
};

export type FindReceiptsInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type FindTransactionObject = {
  __typename?: "FindTransactionObject";
  stats: CalculateAmountByTypesObject;
  transactions: Array<Maybe<TransactionObject>>;
};

export type FindTransactions = {
  from?: InputMaybe<Scalars["DateTime"]>;
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  itemQuery?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<TransactionStatusEnum>>;
  to?: InputMaybe<Scalars["DateTime"]>;
  types?: InputMaybe<Array<DirectionTypeEnum>>;
};

export type FindUsersInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type FullName = {
  __typename?: "FullName";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  legalName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
};

export type FullNameInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
};

export type Funds = {
  __typename?: "Funds";
  amount: Scalars["BigNumber"];
  currency: Currency;
};

export type FundsInput = {
  amount: Scalars["BigNumber"];
  currencyId: Scalars["String"];
};

export type FundsObject = {
  __typename?: "FundsObject";
  amount: Scalars["BigNumber"];
  currency: Currency;
};

export type FundsToEstimateInput = {
  amount?: InputMaybe<Scalars["BigNumber"]>;
  currencyId: Scalars["String"];
};

/** Return Position of Futures */
export type FuturePosition = {
  __typename?: "FuturePosition";
  amount?: Maybe<Scalars["Float"]>;
  direction?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  leverage?: Maybe<Scalars["Float"]>;
  marginIsolated?: Maybe<Scalars["Float"]>;
  marginOpenOrder?: Maybe<Scalars["Float"]>;
  marginPosition?: Maybe<Scalars["Float"]>;
  marginType?: Maybe<Scalars["String"]>;
  pair?: Maybe<Scalars["String"]>;
  precisions?: Maybe<Scalars["String"]>;
  priceEntry?: Maybe<Scalars["Float"]>;
  priceLiquidation?: Maybe<Scalars["Float"]>;
  priceMark?: Maybe<Scalars["Float"]>;
  profitUnRealized?: Maybe<Scalars["Float"]>;
  sidePosition?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
};

/** Return Total of Futures */
export type FutureTotal = {
  __typename?: "FutureTotal";
  balanceFree?: Maybe<Scalars["Float"]>;
  balanceWallet?: Maybe<Scalars["Float"]>;
  marginBalance?: Maybe<Scalars["Float"]>;
  marginInitial?: Maybe<Scalars["Float"]>;
  marginMaint?: Maybe<Scalars["Float"]>;
  marginOpenOrder?: Maybe<Scalars["Float"]>;
  marginPosition?: Maybe<Scalars["Float"]>;
  profitUnrealized?: Maybe<Scalars["Float"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export enum GenderEnum {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export type GenerateTotpInput = {
  otpLength?: InputMaybe<Scalars["Int"]>;
  secret?: InputMaybe<Scalars["String"]>;
  window?: InputMaybe<Scalars["Int"]>;
};

export type GenerateTemplateInput = {
  /** Translation languages for email templates */
  languages: Array<TemplateLanguage>;
  /** Names of html templates for emails */
  names?: InputMaybe<Array<TemplateName>>;
};

export type GeoByAddressInput = {
  /** [Dictionary] Represents address by which search query will be executed */
  address: Scalars["String"];
};

export type GeoLocation = {
  __typename?: "GeoLocation";
  latitude: Scalars["String"];
  longitude: Scalars["String"];
};

export type GeoLocationInput = {
  latitude: Scalars["String"];
  longitude: Scalars["String"];
};

export type GetAccountByUniversalParamInput = {
  /** Account currency ticker or id */
  currency: Scalars["String"];
  /** Universal parameter. Identify user by its phone, email, or wallet id */
  query: Scalars["String"];
};

export type GetAccountRequest = {
  absAccountId?: InputMaybe<Scalars["ID"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  contomobileSubAccount?: InputMaybe<Scalars["String"]>;
  currencyId?: InputMaybe<Scalars["String"]>;
  currencyTicker?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internalType?: InputMaybe<InternalAccountType>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type GetAccountsStatementInput = {
  /** If no period provided, the "For all the time" option is used. The both options should not be present at the same time. */
  timePeriod?: InputMaybe<GetAccountsStatementTimePeriodInput>;
  type: GetAccountsStatementTypeInput;
};

export type GetAccountsStatementTimePeriodInput = {
  dateRange?: InputMaybe<DateRangeFilterInput>;
  exactDay?: InputMaybe<Scalars["DateTime"]>;
};

export type GetAccountsStatementTypeInput = {
  /** The asset to apply for statement. This should not be passed with group field. */
  accountAsset?: InputMaybe<Scalars["String"]>;
  /** The transactions type to include on statement creation. This should not be passed with accountAsset field. */
  group?: InputMaybe<AccountStatementGroup>;
};

export type GetAddressRequestInput = {
  addressId: Scalars["String"];
};

export type GetAddressResponse = {
  __typename?: "GetAddressResponse";
  buildingName?: Maybe<Scalars["String"]>;
  buildingNumber?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  flatNumber?: Maybe<Scalars["String"]>;
  geo: GeoLocation;
  id: Scalars["String"];
  isPrimary: Scalars["Boolean"];
  postalCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  subStreet?: Maybe<Scalars["String"]>;
};

export type GetAllUsersInput = {
  email?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<FilterFromCore>;
  gender?: InputMaybe<GenderEnum>;
  phone?: InputMaybe<Scalars["String"]>;
  roles?: InputMaybe<Array<RoleEnum>>;
  service?: InputMaybe<Service>;
  status?: InputMaybe<StatusEnum>;
  userIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type GetAvailableCurrenciesInput = {
  accountType: InternalAccountType;
};

export type GetAvailableErc20TokenForSumsubInput = {
  id?: InputMaybe<Scalars["ID"]>;
  tokenId?: InputMaybe<Scalars["String"]>;
  tokenName?: InputMaybe<Scalars["String"]>;
};

export type GetAvailableErc20TokensForSumsubInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type GetBankDetailsInput = {
  accountId?: InputMaybe<Scalars["String"]>;
  /** Deprecated: use accountId to get details for account */
  currencyId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Float"]>;
  skip?: InputMaybe<Scalars["Float"]>;
};

export type GetBankInput = {
  address?: InputMaybe<Scalars["String"]>;
  bankCode?: InputMaybe<Scalars["String"]>;
  bankName?: InputMaybe<Scalars["String"]>;
  bic?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type GetBanksInput = {
  likeConditions?: InputMaybe<Array<LikeConditionInput>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type GetCardTopUpFeeInput = {
  amount: Scalars["BigNumber"];
};

export type GetCheckoutTokenInput = {
  cvv?: InputMaybe<Scalars["String"]>;
  expiryMonth: Scalars["Float"];
  expiryYear: Scalars["Float"];
  name?: InputMaybe<Scalars["String"]>;
  number: Scalars["String"];
  type: CheckoutTokenType;
};

export type GetCityRequestPayloadInput = {
  cityId: Scalars["ID"];
};

export type GetCommonDictItemRequestPayloadInput = {
  id: Scalars["ID"];
};

export type GetCompaniesFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<CompanyStatus>;
};

export type GetCompaniesInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<CompanyStatus>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type GetCompanyInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type GetConvertRateInput = {
  baseAssetTicker: Scalars["String"];
  quoteAssetTicker: Scalars["String"];
};

export type GetConvertRatesInput = {
  baseAsset?: InputMaybe<RateCurrencyInput>;
  excludeReversed?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ConvertRateFilterInput>;
  mostPopular?: InputMaybe<Scalars["Boolean"]>;
  quoteAsset?: InputMaybe<RateCurrencyInput>;
};

export type GetCountryRequestPayloadInput = {
  countryId: Scalars["ID"];
};

export type GetCryptoCardBalanceAddressInput = {
  /**
   * Example: 'BTC', 'ETH'
   * Max length: 10
   */
  blockchain: Scalars["String"];
  /**
   * Example: 'USDT', 'BTC', 'ETH'
   * Max length: 10
   */
  token: Scalars["String"];
};

export type GetCryptoPortfolio = {
  itemQuery?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  /** Example: USDT */
  token?: InputMaybe<Scalars["String"]>;
  withBalance?: InputMaybe<Scalars["Boolean"]>;
};

export type GetCurrenciesByTypeRequestPayloadInput = {
  currencyId?: InputMaybe<Scalars["ID"]>;
  type: CurrencyType;
};

export type GetCurrencyByTickerRequestPayloadInput = {
  currencyId?: InputMaybe<Scalars["ID"]>;
  ticker: Scalars["String"];
};

export type GetCurrencyRequestPayloadInput = {
  currencyId: Scalars["ID"];
};

export type GetFileInput = {
  fileId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type GetIntermediariesInput = {
  /** Select intermediaries applicable for rate exchange */
  applicableForExchange?: InputMaybe<Scalars["Boolean"]>;
  /** Select intermediaries by bank name */
  bankNames?: InputMaybe<Array<Scalars["String"]>>;
  /** Country alpha3 array. If many - OR operation is applied */
  countryAlpha3?: InputMaybe<Array<Scalars["String"]>>;
  /** Currency tickers array. If many - OR operation is applied */
  currencyTickers?: InputMaybe<Array<Scalars["String"]>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type GetIntermediaryInput = {
  /** Search intermediary by beneficiary account number */
  beneficiaryAccountNumber?: InputMaybe<Scalars["String"]>;
  /** Search intermediary by beneficiary bank bic */
  beneficiaryBIC?: InputMaybe<Scalars["String"]>;
  /** Search intermediary by bank name */
  beneficiaryBank?: InputMaybe<Scalars["String"]>;
  /** Search intermediary by beneficiary IBAN */
  beneficiaryIBAN?: InputMaybe<Scalars["String"]>;
  /** Search intermediary by beneficiary sort code */
  beneficiarySortCode?: InputMaybe<Scalars["String"]>;
  /** Search intermediary by id */
  id?: InputMaybe<Scalars["String"]>;
};

export type GetInvestmentHistoryInput = {
  dateEnd: Scalars["Float"];
  dateStart: Scalars["Float"];
};

export type GetLanguageByCodeRequestPayloadInput = {
  code: Scalars["String"];
};

export type GetLastSendCodeInput = {
  attempts?: InputMaybe<Scalars["Float"]>;
  device?: InputMaybe<Device>;
  deviceId?: InputMaybe<Scalars["String"]>;
  identity?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Service>;
  status?: InputMaybe<StatusEnum>;
  type?: InputMaybe<SendCode>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type GetLastSendCodeResponse = {
  __typename?: "GetLastSendCodeResponse";
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
};

export type GetMyCompaniesInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<CompanyStatus>;
};

export type GetNaceCodeInput = {
  code?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
  sectionName?: InputMaybe<Scalars["String"]>;
};

export type GetNaceCodesInput = {
  code?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
  sectionName?: InputMaybe<Scalars["String"]>;
  withoutPagination?: InputMaybe<Scalars["Boolean"]>;
};

export type GetOperationIdInput = {
  operationId: Scalars["String"];
};

export type GetOperationInput = {
  title: Scalars["String"];
};

export type GetOwnerInput = {
  binanceEmail: Scalars["String"];
};

export type GetPaymentChargesInput = {
  amount: PaymentSourceTargetInput;
  benAcc: Scalars["String"];
  benBankCode: Scalars["String"];
  currency: PaymentSourceTargetInput;
  customerId?: InputMaybe<Scalars["String"]>;
  externalUserId: Scalars["String"];
  paymentAmount: Scalars["String"];
  paymentCurrency: Scalars["String"];
  sourceAcc: Scalars["String"];
  type: AbsPaymentType;
};

export type GetPhoneNumberInfoInput = {
  /** Phone numbers according to the E.164 standard */
  phoneNumbers: Array<Scalars["String"]>;
};

export type GetPhoneNumberInfoType = {
  __typename?: "GetPhoneNumberInfoType";
  error?: Maybe<TwilioLookupErrorType>;
  info?: Maybe<TwilioLookupResultType>;
  phoneNumber: Scalars["String"];
};

export type GetRateCommissionInput = {
  baseAssetTicker: Scalars["String"];
  quoteAssetTicker: Scalars["String"];
};

export type GetScoringRiskLevelInput = {
  id: Scalars["String"];
};

export type GetScoringRiskLevelsInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type GetTransactionFee = {
  /** Example: ETH */
  blockchain: Scalars["String"];
  /** Destination address */
  destinationAddress: Scalars["String"];
  /** Example: USDT */
  token: Scalars["String"];
};

export type GetTransactionPrintedForm = {
  /** Pass "transactionId" field here */
  transactionId: Scalars["ID"];
};

export type GetUserAddresssesRequestInput = {
  userId: Scalars["String"];
};

export type GetUserByPersonalDataInput = {
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type GetUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  /** Universal parameter, used to search by one of the values: phone, email, or wallet id. Do not use this param against the params mentioned in the list */
  query?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
  walletId?: InputMaybe<Scalars["String"]>;
};

export type GoogleSignInInput = {
  /** authorization code for auth user if no token provided */
  authorizationCode?: InputMaybe<Scalars["String"]>;
  /** first name */
  firstName?: InputMaybe<Scalars["String"]>;
  /** identity token if present */
  identityToken?: InputMaybe<Scalars["String"]>;
  /** last name */
  lastName?: InputMaybe<Scalars["String"]>;
  service: Service;
  /** token for PUSH notification */
  token?: InputMaybe<Scalars["String"]>;
};

export enum HistoryIntervalEnum {
  All = "ALL",
  D7 = "D7",
  H24 = "H24",
  M1 = "M1",
  M3 = "M3",
  Y1 = "Y1",
}

export enum IdDocSetType {
  ApplicantData = "APPLICANT_DATA",
  Company = "COMPANY",
  Identity = "IDENTITY",
  Identity2 = "IDENTITY2",
  Investability = "INVESTABILITY",
  PaymentMethods = "PAYMENT_METHODS",
  ProofOfResidence = "PROOF_OF_RESIDENCE",
  Selfie = "SELFIE",
  Selfie2 = "SELFIE2",
}

export type IdentityDoc = {
  __typename?: "IdentityDoc";
  common: CommonItemInfo;
  /** Country where code was created. ISO 3166-1 alpha-3 */
  country: Scalars["String"];
  file: File;
  id: Scalars["ID"];
  isVerified: Scalars["Boolean"];
  verificationMessages: Array<VerificationMessage>;
};

export enum IdentityType {
  Email = "EMAIL",
  Phone = "PHONE",
}

export enum InputReasonForAccountOpening {
  DailyExpenses = "DAILY_EXPENSES",
  FamilySupport = "FAMILY_SUPPORT",
  Investments = "INVESTMENTS",
  InvoiceSettlements = "INVOICE_SETTLEMENTS",
  Other = "OTHER",
  PaymentsForServicesAndGoods = "PAYMENTS_FOR_SERVICES_AND_GOODS",
  SalaryDeposits = "SALARY_DEPOSITS",
}

export type Intermediaries = {
  __typename?: "Intermediaries";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<Intermediary>;
};

export type Intermediary = {
  __typename?: "Intermediary";
  accountTypes: Array<InternalAccountType>;
  applicableForExchange?: Maybe<Scalars["Boolean"]>;
  beneficiaryAccountNumber?: Maybe<Scalars["String"]>;
  beneficiaryAddress?: Maybe<Scalars["String"]>;
  beneficiaryBIC?: Maybe<Scalars["String"]>;
  beneficiaryBank: Scalars["String"];
  beneficiaryIBAN?: Maybe<Scalars["String"]>;
  beneficiaryName: Scalars["String"];
  beneficiarySortCode?: Maybe<Scalars["String"]>;
  companyCountries: Array<Country>;
  companyCountryIds: Array<Scalars["String"]>;
  currencies: Array<Currency>;
  currencyIds: Array<Scalars["String"]>;
  forbiddenActivityIds: Array<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  userCountries: Array<Country>;
  userCountryIds: Array<Scalars["String"]>;
};

export enum InternalAccountType {
  MultipleCurrencyAccount = "MULTIPLE_CURRENCY_ACCOUNT",
  Usd = "USD",
}

export type InternalExchangeObject = {
  __typename?: "InternalExchangeObject";
  accrualTransactionId?: Maybe<Scalars["ID"]>;
  acquiring?: Maybe<AcquiringService>;
  createdAt: Scalars["DateTime"];
  debitTransactionId?: Maybe<Scalars["ID"]>;
  exchangeDirection: CryptoExchangeType;
  exchangeType: FastExchangeTypeEnum;
  failReason?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  operationId: Scalars["String"];
  paymentInfo: EstimatedCostsObject;
  status: NeuronExchangeStatus;
  userId: Scalars["ID"];
};

export type InternalPayInput = {
  /** Amount without fee */
  amount: Scalars["BigNumber"];
  /** Example: USDT */
  token: Scalars["String"];
  /** email/phone/walletId */
  userIdentifier: Scalars["String"];
};

export type InvestInput = {
  amount: Scalars["String"];
  riskLevel?: InputMaybe<RiskLevelEnum>;
  token: Scalars["String"];
  type: TransactionCurrencyTypeEnum;
};

export type IsExchangeAvailableInput = {
  exchangeType: FastExchangeTypeEnum;
};

export type IsPhoneNumberExistInput = {
  /** Phone number according to the E.164 standard */
  phoneNumber: Scalars["String"];
};

export type IsTransferAllowedRequestPayload = {
  amount: Scalars["BigNumber"];
  creditAccountId: Scalars["ID"];
  debitAccountId: Scalars["ID"];
  debitorUserId: Scalars["ID"];
  fee: Scalars["BigNumber"];
};

export type IsTransferAllowedResponseData = {
  __typename?: "IsTransferAllowedResponseData";
  description?: Maybe<Scalars["String"]>;
  isAllowed: Scalars["Boolean"];
};

export type Item =
  | Bank
  | City
  | Continent
  | Country
  | Currency
  | ExternalCompany
  | Language
  | NaceCode
  | NumberRange
  | Position
  | Region
  | Timezone
  | TransferMethod
  | Unit
  | Vat;

export enum ItemType {
  Bank = "BANK",
  City = "CITY",
  Continent = "CONTINENT",
  Country = "COUNTRY",
  Currency = "CURRENCY",
  ExternalCompany = "EXTERNAL_COMPANY",
  Language = "LANGUAGE",
  NaceCode = "NACE_CODE",
  NumberRange = "NUMBER_RANGE",
  Position = "POSITION",
  Region = "REGION",
  Unit = "UNIT",
  Vat = "VAT",
}

export type KybActiveLicenseInput = {
  /** Country id from banxe-dictionary */
  countryId: Scalars["ID"];
  /** License number */
  licenseNumber: Scalars["String"];
};

export type KybBeneficialInput = {
  address?: InputMaybe<Scalars["String"]>;
  /** Country id from banxe-dictionary */
  citizenshipId?: InputMaybe<Scalars["ID"]>;
  /** Country id from banxe-dictionary */
  countryOfBirthId?: InputMaybe<Scalars["ID"]>;
  /** Country id from banxe-dictionary */
  countryOfResidenceId?: InputMaybe<Scalars["ID"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  numberOfShares?: InputMaybe<Scalars["String"]>;
  personalCode?: InputMaybe<Scalars["String"]>;
  tin?: InputMaybe<Scalars["String"]>;
};

export type KybCustomerRepresentativeInput = {
  basisForYourRepresentation?: InputMaybe<Scalars["String"]>;
  /** Country id from banxe-dictionary */
  citizenshipId?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  personalCode?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  representativeAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type KybLegalPersonInput = {
  address?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfShares?: InputMaybe<Scalars["String"]>;
};

export type KybMainBusinessPartnersInput = {
  /** Country id from banxe-dictionary */
  countryId: Scalars["ID"];
  name: Scalars["String"];
};

export type KybPepInput = {
  /** Country id from banxe-dictionary */
  countryId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
};

/** Company establishment and other supporting documents */
export type KybSupportingDocumentsInput = {
  /** Articles of Association. File id from banxe-files */
  articlesOfAssociation?: InputMaybe<Scalars["String"]>;
  /** Certificate of Incorporation. File id from banxe-files */
  certificateOfIncorporation?: InputMaybe<Scalars["String"]>;
  /** License (if applicable; for licensed businesses only). File id from banxe-files */
  license?: InputMaybe<Scalars["String"]>;
  /** Documents as a proof of legal address (If company’s actual address is different from the legal address please provide). File id from banxe-files */
  proofOfLegalAddress?: InputMaybe<Scalars["String"]>;
  /** Extract from the register of legal entities (not older than 3 months). File id from banxe-files */
  registerOfLegalEntities?: InputMaybe<Scalars["String"]>;
  /** Representation documents. File id from banxe-files */
  representationDocuments?: InputMaybe<Scalars["String"]>;
  /** Document(s) displaying a source(s) of company funds (documented evidence showing funds are being generated). File id from banxe-files */
  sourceOfCompanyFunds?: InputMaybe<Scalars["String"]>;
  /** Document(s) establishing source of wealth of your company’s beneficial owner(s), i.e. investment/savings certificates, proof of inheritance, property sale deed documentation, etc. File id from banxe-files */
  sourceOfCompanyWealth?: InputMaybe<Scalars["String"]>;
};

export enum KeyEnum {
  Eos = "EOS",
  P256 = "P256",
}

export type Language = {
  __typename?: "Language";
  id: Scalars["ID"];
  item: CommonDictItem;
};

export enum LanguageCodeEnum {
  Eng = "ENG",
}

export type LanguageInput = {
  code: Scalars["String"];
  name: Scalars["String"];
};

export type LikeConditionInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export type LocalizedName = {
  __typename?: "LocalizedName";
  id: Scalars["ID"];
  lang: Language;
  name: Scalars["String"];
};

export type LocalizedNameInput = {
  langId: Scalars["ID"];
  name: Scalars["String"];
};

export type LogoutInput = {
  fromAll?: InputMaybe<Scalars["Boolean"]>;
  fromOther?: InputMaybe<Scalars["Boolean"]>;
  refreshToken: Scalars["String"];
};

export type MakeAnonymousInput = {
  stage: Scalars["Int"];
  userId: Scalars["ID"];
};

/** Action with favorite on the market */
export enum MarketActionEnum {
  /** Add favorite */
  Add = "ADD",
  /** Get favorites list */
  List = "LIST",
  /** Remove favorite */
  Remove = "REMOVE",
}

/** Return info about favorite */
export type MarketFavorite = {
  __typename?: "MarketFavorite";
  symbol?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MessengerInput = {
  chat: NotificationMessengerChatEnum;
  pattern: NotificationMessengerMessagePatternEnum;
  type: NotificationMessengerTypeEnum;
};

export enum ModeKyc {
  Automatically = "AUTOMATICALLY",
  Manual = "MANUAL",
}

export type Money = {
  __typename?: "Money";
  amount: Scalars["BigNumber"];
  currency: Currency;
  formattedAmount: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** [Cards] Allows admin to activate card */
  activateCryptoCard: Scalars["Boolean"];
  /**
   * [Cards]
   * APPROVE!
   * Allows user to activate card
   */
  activateMyCryptoCardApprove: Scalars["Boolean"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to activate card
   */
  activateMyCryptoCardSetup: Scalars["String"];
  addAvatar: Scalars["Boolean"];
  /** [Contacts] Create new contacts. (Admin Only) */
  addContacts: Array<Maybe<Contact>>;
  addConvertRate: ConvertRate;
  /** [Auth] Adds FCM token to user */
  addFCMToken: Scalars["Boolean"];
  /** [Payments] Adds some missing fields in payment entity. (ROLE = ADMIN/SUPER) */
  addMissingPaymentFields: Scalars["Boolean"];
  /** [Dictionary] Allows tag attaching to any item by id in 'Item' union */
  addTag: Array<Maybe<Item>>;
  /** [Dictionary] Allows tag attaching to any item by code in 'Item' union */
  addTagByCode: Array<Maybe<Item>>;
  /** [Auth] Registration user without confirmation (Role = SERVICE) */
  adminSignUp: User;
  /** [Auth] Returns JWT  */
  appleSignIn: Auth;
  /** [Neuronex Transaction Service] Approve transaction. */
  approveCryptoTransaction: ApproveTransaction;
  /** [Neuronex Fast Exchange] */
  approveExchange?: Maybe<Scalars["Boolean"]>;
  approvePayment: Payment;
  /** [Payments] Approve user's payment if it has failed. (Admin Only) */
  approveUserPayment: Payment;
  attachDeviceToTopic?: Maybe<Topic>;
  /**
   * [Cards]
   * APPROVE!
   * Allows user to block (permanently close) card;
   */
  blockMyCryptoCardApprove: Scalars["Boolean"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to block (permanently close) card;
   */
  blockMyCryptoCardSetup: Scalars["String"];
  /** [Auth] Change email/phone */
  changeIdentity: Send2FaTokenResponse;
  /**
   * [Cards] Allows user to set active vABS balance;
   * Each underlying vabs card account has only one balance for given token no matter how many networks are supporting this token
   */
  changeMyCryptoCardBalance: CryptoCardBalance;
  /** [Cards] Allows user to change card's name */
  changeMyCryptoCardName: Scalars["Boolean"];
  /** [Auth] Send tokens to confirm password change */
  changePassword: Send2FaTokenResponse;
  /** [Auth] Change user status (Role = SERVICE) */
  changeUserStatus: Scalars["Boolean"];
  /** [Auth] Returns JWT  */
  checkVerificationPhrase: Auth;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows clearing all records stored in database
   */
  clearDb: Scalars["Boolean"];
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows clearing chosen entity records stored in database
   */
  clearEntityTable: Scalars["Boolean"];
  /** [Auth] Verify codes and change user password */
  confirmChangePassword: Scalars["Boolean"];
  /** [Auth] Confirm changed email/phone */
  confirmChangedIdentity: Scalars["Boolean"];
  /** [2FA] Confirm disabling 2FA */
  confirmDisabling2FA: Scalars["Boolean"];
  /** [2FA] Confirm enabling 2FA */
  confirmEnabling2FA: Scalars["Boolean"];
  /** [Auth] Verify codes and return access tokens */
  confirmSignIn: Auth;
  /** [Referrers] Сonfirm the invitation */
  confirmationInvite: Scalars["Boolean"];
  /** [2FA] SUPER-ADMIN-SERVICE-ONLY Create operation. Title will be uppercased */
  create2FAOperation: Scalars["Boolean"];
  /** [2FA] Generates 2FA operation id */
  create2FAOperationId: Scalars["ID"];
  createApplicantsForCompanies: Scalars["Boolean"];
  /** [Dictionary] Allows creating bank (ROLE = ADMIN/SERVICE/SUPER) */
  createBank: Bank;
  /** [Payments] Create payment in Checkout (ROLE = [ADMIN, SUPER]) */
  createCheckoutPayment: CheckoutPayment;
  /** [Dictionary] Allows city creation (ROLE = ADMIN/SERVICE/SUPER) */
  createCity: City;
  /** [banxe-bitrix] Create a company (service/admin/super role) */
  createCompany: Scalars["String"];
  /** [banxe-bitrix] Create contact (service/admin/super role) */
  createContact: Scalars["String"];
  /** [Dictionary] Allows continent creation (ROLE = ADMIN/SERVICE/SUPER) */
  createContinent: Continent;
  /** [Dictionary] Allows country creation (ROLE = ADMIN/SERVICE/SUPER) */
  createCountry: Country;
  /** [Cards] Allows user to create crypto card backed by vABS account funds */
  createCryptoCard: CryptoCard;
  /** [Neuronex Transaction Service] Create transaction. */
  createCryptoTransaction: CreateTransactionObject;
  /** [Dictionary] (Operation for inter-service communication)  Allows currency creation */
  createCurrencies?: Maybe<Array<Currency>>;
  /** [Dictionary] Allows currency creation (ROLE = ADMIN/SERVICE/SUPER) */
  createCurrency: Currency;
  /** [Payments] Create or update current user card */
  createCurrentUserCard: UserCard;
  /** [banxe-bitrix] Create a deal (service/admin/super role) */
  createDeal: Scalars["String"];
  /** [Neuronex Fast Exchange] */
  createExchange: CryptoExchange;
  /** [Accounts] Add intermediary bank information */
  createIntermediary: Intermediary;
  /** [Neuronex Transaction Service] Create internal transfer. */
  createInternalCryptoTransaction: CreatedCryptoInternalPay;
  /** [Dictionary] Allows language creation (ROLE = ADMIN/SERVICE/SUPER) */
  createLanguage: Language;
  /** [banxe-bitrix] Create or update a lead (service/admin/super role) */
  createLead: BitrixLeadType;
  /** [Dictionary] Allows number range creation (ROLE = ADMIN/SERVICE/SUPER) */
  createNumberRange: NumberRange;
  /** [Dictionary] Allows position creation (ROLE = ADMIN/SERVICE/SUPER) */
  createPosition: Position;
  /** [Dictionary] Allows creation of rate commission in percent */
  createRateCommission: RateCommission;
  /** [Dictionary] Allows region creation */
  createRegion: Region;
  /** [Identity] Create scoring risk level by data (requires admin/super/service role) */
  createScoringRiskLevel: ScoringRiskLevel;
  createTemplate: Template;
  createTopic: Topic;
  /** [Dictionary] Allows measure unit creation */
  createUnit: Unit;
  /**
   * [Notification] SERVICE-USER-ONLY. Save or update html templates in the database
   * @deprecated This mutation will be removed in the next version, because the generation of email templates starts automatically at the start of banxe-notification
   */
  createUpdateTemplatesFromHTML: Scalars["Boolean"];
  /** [Payments] Create or update user card (ROLE = [ADMIN, SUPER]) */
  createUserCard: UserCard;
  /** [Dictionary] Allows VAT creation */
  createVat: Vat;
  defiInvestMutation: DefiInvestMutation;
  /** [2FA] SUPER-ADMIN-SERVICE-ONLY Delete operation (soft-remove) */
  delete2FAOperation: Scalars["Boolean"];
  /** [Identity] remove all personal data of users from database (debug method) */
  deleteAllUsersFromIdentity: Scalars["Boolean"];
  deleteConvertRate: ConvertRate;
  /** [Payments] Delete current user card by card id */
  deleteCurrentUserCard: Scalars["String"];
  deleteDevice: DeviceInfo2;
  deleteDeviceFromTopic?: Maybe<Topic>;
  /** [Auth] Delete user`s extra data by key (ROLE = ADMIN) */
  deleteExtra: Scalars["String"];
  /** [Accounts] Delete intermediary bank information */
  deleteIntermediary: Intermediary;
  /** [Payments] Delete user card by card id (ROLE = [ADMIN, SUPER]) */
  deleteUserCard: Scalars["String"];
  /** [2FA] Send request to disable user 2FA */
  disable2FA: TfaResponse;
  /** [2FA] Disable all 2FA with recovery code */
  disableAll2FA: Scalars["Boolean"];
  /** [2FA] Send request to enable user 2FA */
  enable2FA: TfaResponse;
  /** [Dictionary] Force coin networks fetching and setting up deposit and withdraw tags (ROLE = ADMIN) */
  fetchCoinNetworks: Scalars["String"];
  fetchDataFromAccounts: Scalars["String"];
  /** [Accounts] Start updating new column - account type holder according to user id field */
  fillAccountHolderTypeColumn: Scalars["Boolean"];
  /** [Accounts] Start opening contomobile sub account column for all accounts in need */
  fillContomobileAccountSubAccountColumn: Scalars["Boolean"];
  /** [Neuronex Transaction Service] Create crypto address. */
  findOrCreateCryptoAddress?: Maybe<CryptoAddress>;
  /** [Cards] Allows admin to freeze (temporarily block operations for) card */
  freezeCryptoCard: Scalars["Boolean"];
  /**
   * [Cards]
   * APPROVE!
   * Allows user to freeze (temporarily block operations for) card
   */
  freezeMyCryptoCardApprove: Scalars["Boolean"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to freeze (temporarily block operations for) card
   */
  freezeMyCryptoCardSetup: Scalars["String"];
  /**
   * [Notification] SERVICE-USER-ONLY. Generates templates by substituting values for the specified languages into the base templates
   * @deprecated This mutation will be removed in the next version, because the generation of email templates starts automatically at the start of banxe-notification
   */
  generateHTMLTemplates: Scalars["Boolean"];
  /** [2FA] Generate TOTP */
  generateTOTP: Scalars["String"];
  /** [Identity] Get token for Sumsub SDK */
  getSumsubAccessToken: SumsubAccessToken;
  /** [Auth] get token for email */
  getToken: Auth;
  /** [Auth] Returns JWT  */
  googleSignIn: Auth;
  /** [Files] generates temporary url with sign for file-uploading. File must be uploaded with POST request as form-data(ROLE = USER/ADMIN/SERVICE) */
  initUploadSession: UploadSesion;
  /** [Dictionary] Add cities to database from city directory table (ROLE = ADMIN/SUPER) */
  loadCityDirectory: Scalars["Boolean"];
  /** [Identity] remove all personal data of user (admin method/TBI) */
  makeAnonymous: User;
  /**
   * [Cards]
   * APPROVE!
   * Allows user to get card's sensitive info
   */
  myCryptoCardSensitiveInfoApprove: CryptoCardSensitiveInfo;
  /** [Auth] Delete current user`s extra data by key */
  myDeleteExtra: Scalars["String"];
  /** [Auth] Create or update current user's extra data */
  myUpdateExtra: Scalars["String"];
  /** [Payments] Normalizes payments' data. (For Super, Admin and Service users Only) */
  normalizePayments: Scalars["Boolean"];
  /** [Auth] Get a new access token based on refresh token */
  oauth2Token: Auth;
  /** [Accounts] Close current user account with given ID. (User Only) */
  orderAccountDocs: Scalars["Boolean"];
  /** [Dictionary] Start parsing banks from BANKDIRECTORY tables */
  parseBankDirectory: Scalars["Boolean"];
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows populate database with test cities from pre-parsed data source
   */
  parseCitiesFromJson: Array<Maybe<City>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows populate database with test cities which has geo data from pre-parsed data source
   */
  parseCitiesWithGeoFromJson: Array<Maybe<AddressData>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows populate database with test countries from pre-parsed data source
   */
  parseCountriesFromJson: Array<Maybe<Country>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows populate database with test currencies from pre-parsed data source
   */
  parseCurrenciesFromJson: Array<Maybe<Currency>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows populate database with test languages from pre-parsed data source
   */
  parseLanguagesFromJson: Array<Maybe<Language>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows you to fill the database with timezones by pre-parsed data source.
   */
  parseTimezones: Array<Maybe<Timezone>>;
  /**
   * [Dictionary] SuperAdmin _test mutation
   * Allows you to fill the database with transfer methods by pre-parsed data source.
   */
  parseTransferMethodsFromJson: Array<Maybe<TransferMethod>>;
  preparePayment: Payment;
  /** [Payments] Start awaiting liquidity payments processing (ROLE = [ADMIN, SUPER]) */
  processAwaitingLiquidityPayments: Scalars["Boolean"];
  /** [Accounts] (Operation for inter-service communication) Allows to transfer data */
  processTransfer: ProcessTransferResponseData;
  /**
   * [Auth] Update user profile
   * @deprecated This query is no longer supported
   */
  profile: Profile;
  /** [Dictionary] Recovers bank bic and swift codes (ROLE = ADMIN/SERVICE/SUPER) */
  recoverBankBicAndSwiftCodes: Scalars["Boolean"];
  registerDevice: DeviceInfo2;
  /** [Auth] Register phone */
  registerPhone: Scalars["Boolean"];
  /** [Dictionary] Removes banks (ROLE = ADMIN/SERVICE/SUPER) */
  removeBanks: Scalars["Boolean"];
  /** [Dictionary] Removes banks with invalid bic and swift codes (ROLE = ADMIN/SERVICE/SUPER) */
  removeBanksWithInvalidBicAndSwiftCodes: Scalars["Boolean"];
  /** [Dictionary] Removes cities (ROLE = ADMIN/SERVICE/SUPER) */
  removeCities: Scalars["Boolean"];
  /** [Contacts] Delete contacts by ID. (Admin Only) */
  removeContacts: Array<Contact>;
  /** [Dictionary] Removes continents (ROLE = ADMIN/SERVICE/SUPER) */
  removeContinents: Scalars["Boolean"];
  /** [Dictionary] Removes countries (ROLE = ADMIN/SERVICE/SUPER) */
  removeCountries: Scalars["Boolean"];
  /** [Dictionary] Removes currencies (ROLE = ADMIN/SERVICE/SUPER) */
  removeCurrencies: Scalars["Boolean"];
  /** [Dictionary] Removes coin networks (ROLE = ADMIN/SERVICE/SUPER) */
  removeLanguages: Scalars["Boolean"];
  /** [Contacts] Delete current user contacts by ID. (User only) */
  removeMyContacts: Array<Contact>;
  /** [Dictionary] Removes NACE codes (ROLE = ADMIN/SERVICE/SUPER) */
  removeNaceCodes: Scalars["Boolean"];
  /** [Dictionary] Removes number ranges (ROLE = ADMIN/SERVICE/SUPER) */
  removeNumberRanges: Scalars["Boolean"];
  /** [Dictionary] Removes positions (ROLE = ADMIN/SERVICE/SUPER) */
  removePositions: Scalars["Boolean"];
  /** [Dictionary] Removes regions (ROLE = ADMIN/SERVICE/SUPER) */
  removeRegions: Scalars["Boolean"];
  /** [Identity] Remove scoring risk level by id (requires admin/super/service role) */
  removeScoringRiskLevel: Scalars["String"];
  /** [Dictionary] Removes tags (ROLE = ADMIN/SERVICE/SUPER) */
  removeTags: Scalars["Boolean"];
  /** [Dictionary] Removes units (ROLE = ADMIN/SERVICE/SUPER) */
  removeUnits: Scalars["Boolean"];
  /** [Dictionary] Removes vats (ROLE = ADMIN/SERVICE/SUPER) */
  removeVats: Scalars["Boolean"];
  repeatTransaction: RepeatedTransaction;
  /** [Cards] Allows admin to replace card */
  replaceCryptoCard: CryptoCard;
  /**
   * [Cards]
   * APPROVE!
   * Allows user to replace card; Returns new card;
   */
  replaceMyCryptoCardApprove: CryptoCard;
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to replace card
   */
  replaceMyCryptoCardSetup: Scalars["String"];
  /** [Payments] Reset users top-up limits (ROLE = [ADMIN, SUPER]) */
  resetUsersTopUpLimits: Scalars["Boolean"];
  restorePassword: Scalars["Boolean"];
  retrieveWallets: Array<WalletObject>;
  send: NotificationStatus;
  /** [2FA] Send 2FA token. If type is not defined prefered enabled type will be selected */
  send2FAToken: Send2FaTokenResponse;
  sendCompanyVerification: Array<CompanyIdentityDoc>;
  /** [Referrers] Send an email/sms invitation on behalf of a friend to create an account */
  sendInvitationCreateAccount: Scalars["Boolean"];
  /** [Identity] send selected documents of current user for KYC procedure */
  sendMyVerification: Array<UserIdentityDoc>;
  sendPayment: Payment;
  /** [Auth] Send a code to email/sms for 2FA operation */
  sendTFACode: Send2FaTokenResponse;
  /** [Identity] send user into CRM (admin/debug method) */
  sendUserIntoCrm: Scalars["Boolean"];
  /** [Identity] send selected documents of user for KYC procedure (admin method) */
  sendVerification: Array<UserIdentityDoc>;
  /** [Payments] Set card top-up config for checkout payments (ROLE = [ADMIN, SUPER]) */
  setCardTopUpConfig: CardTopUpConfig;
  setExchangeCommission: Scalars["Boolean"];
  /** [Auth] Set user's extra field config (ROLE = ADMIN) */
  setExtraConfig: Scalars["Boolean"];
  /** [Files] changes 'isPrivate' for some file */
  setFilePrivacy: File;
  /** [Cards] Allows user to set card's 3DS settings */
  setMyCryptoCard3DSecureSettings: Scalars["Boolean"];
  /** [Identity] Set required docs types/sets and levelName of flow */
  setSumsubRequiredDocsConfig: SumsubRequiredDocsConfig;
  /** [Identity] Updating maintenanceLevel for all users. Requires (service/admin/super role) */
  setUsersChargeMaintenanceLevel: Scalars["Boolean"];
  signUp: User;
  /** [Auth] Standardise auth users phone numbers */
  standardiseAuthUsersPhoneNumbers: Scalars["Boolean"];
  /** [Identity] Standardise KYB phone numbers */
  standardiseKYBPhoneNumbers: Scalars["Boolean"];
  /** [Identity] Standardise users phone numbers */
  standardiseUsersPhoneNumbers: Scalars["Boolean"];
  /** @deprecated This query will be removed in the next version. Use startKYBNew */
  startKYB: Scalars["Boolean"];
  startKYBNew: Scalars["Boolean"];
  /** Query for starting user manual KYC procedure */
  startManualKYC: Scalars["Boolean"];
  /** [Payments] Start a checkout top up process (ROLE = [USER, ADMIN, SUPER]) */
  startTopUpBalanceByCard: CheckoutPayment;
  switchConvertRate: ConvertRate;
  syncAccountBalance: Account;
  /** [Payments] Synchronize balances in all payments. (Admin Only) */
  synchronizeBalances: Scalars["Boolean"];
  /** [Payments] Synchronize all ABS payments with ours. (Admin Only) */
  synchronizePayments: Scalars["Boolean"];
  /** [Auth] Get a new access token based on refresh token */
  token: Auth;
  /** [Cards] Allows user to transfer funds from vabs main account to vabs card account */
  transferToCryptoCardAccount: Scalars["Boolean"];
  /** [Cards] Allows user to transfer funds from vabs card account to vabs main account */
  transferToMainAccount: Scalars["Boolean"];
  /** [Cards] Allows admin to to unfreeze (allow operations for) card */
  unfreezeCryptoCard: Scalars["Boolean"];
  /**
   * [Cards]
   * APPROVE!
   * Allows user to unfreeze (allow operations for) card
   */
  unfreezeMyCryptoCardApprove: Scalars["Boolean"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to unfreeze (allow operations for) card
   */
  unfreezeMyCryptoCardSetup: Scalars["String"];
  /** [Auth] Get the remaining attempts to enter the code */
  unlockUserOperation: Scalars["Boolean"];
  /** [2FA] SUPER-ADMIN-SERVICE-ONLY Update operation */
  update2FAOperation: Scalars["Boolean"];
  /** [Accounts] Start updating status of all currency accounts */
  updateAccountsStatus: Scalars["Boolean"];
  /** [Addresses] update address of any user (admin method) */
  updateAddress: Address;
  /** [Identity] Start updating Sumsub applicant sourceKey for all users. Requires service/admin/super role */
  updateApplicantsSourceKey: Scalars["Boolean"];
  /** [Identity] Add Erc20 Tokens in db from https://developers.sumsub.com/payment-methods/tokenList.html (service/admin/super role) */
  updateAvailableErc20TokensForSumsub: Scalars["Boolean"];
  /** [Accounts] Update current user account's balance. (User Only) */
  updateBalance: Account;
  /** [Dictionary] Allows bank update (ROLE = ADMIN/SERVICE/SUPER) */
  updateBank: Bank;
  /** [Dictionary] Allows city updation (ROLE = ADMIN/SERVICE/SUPER) */
  updateCity: City;
  /** [Companies] Update companies activity ids */
  updateCompaniesActivityIds: BooleanResponse;
  /** [banxe-bitrix] Update company data (service/admin/super role) */
  updateCompany: Scalars["Boolean"];
  updateCompanyDocuments: Array<CompanyIdentityDoc>;
  /** [banxe-bitrix] Update contact data (service/admin/super role) */
  updateContact: Scalars["Boolean"];
  /** [Dictionary] Allows continent deletion (ROLE = ADMIN/SERVICE/SUPER) */
  updateContinent: Continent;
  updateConvertRate: ConvertRate;
  /** [Dictionary] Allows country updation (ROLE = ADMIN/SERVICE/SUPER) */
  updateCountry: Country;
  /** [Cards] Allows admin to update crypto card security settings */
  updateCryptoCardSecurity: CryptoCard;
  /** [Dictionary] Updates crypto currencies icons from CoinMarketCap API */
  updateCryptoCurrencyIcons: Scalars["Boolean"];
  /** [Dictionary] Allows currency update (ROLE = ADMIN/SERVICE/SUPER) */
  updateCurrency: Currency;
  /** [Identity] add new documents for user (admin method) */
  updateDocuments: Array<UserIdentityDoc>;
  /** Update exchange info (ROLE = ADMIN/SERVICE/SUPER) */
  updateExchangeInfo: Scalars["Boolean"];
  /** [Auth] Create or update user's extra data (ROLE = ADMIN) */
  updateExtra: Scalars["String"];
  /** [Accounts] Update intermediary bank information */
  updateIntermediary: Intermediary;
  /** [Identity] update the KYC step */
  updateKycStep: Scalars["Boolean"];
  /** [Dictionary] Allows language updation (ROLE = ADMIN/SERVICE/SUPER) */
  updateLanguage: Language;
  /** [banxe-bitrix] Update or create lead data (service/admin/super role) */
  updateLead: BitrixLeadType;
  /** [Addresses] update some address for current user */
  updateMyAddress: Address;
  updateMyCompanyQuestionnaire: CompanyQuestionnaire;
  /** [Contacts] Create or update (Upsert) contact for current user. (User Only). Invalid phones will be filtred */
  updateMyContacts: Array<Maybe<Contact>>;
  /**
   * [Cards]
   * APPROVE!
   * Allows user to update card's 3DS settings
   */
  updateMyCryptoCard3DSecureSettingsApprove: Scalars["Boolean"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to update card's 3DS settings
   */
  updateMyCryptoCard3DSecureSettingsSetup: Scalars["String"];
  /** [Cards] Allows user to update crypto card limits */
  updateMyCryptoCardLimits: CryptoCard;
  /**
   * [Cards] Allows user to update crypto card pin
   * @deprecated Cannot be used for virtual cards
   */
  updateMyCryptoCardPin: Scalars["Boolean"];
  /** [Cards] Allows user to update crypto card security settings */
  updateMyCryptoCardSecurity: CryptoCard;
  /** [Identity] add new documents for current user */
  updateMyDocuments: Array<UserIdentityDoc>;
  /** [Identity] update personal data for current user */
  updateMyPersonal: UserPersonal;
  /** [Dictionary] Updates NACE code (ROLE = ADMIN/SERVICE/SUPER) */
  updateNACECode: NaceCode;
  /** [Dictionary] Allows number range updation (ROLE = ADMIN/SERVICE/SUPER) */
  updateNumberRange: NumberRange;
  /** [Identity] update personal data for any user (admin method) */
  updatePersonal: UserPersonal;
  /** [Dictionary] Allows position updation (ROLE = ADMIN/SERVICE/SUPER) */
  updatePosition: Position;
  /** [Dictionary] Allows region updation */
  updateRegion: Region;
  /** [Identity] Update scoring risk level with new updated data (requires admin/super/service role) */
  updateScoringRiskLevel: ScoringRiskLevel;
  /** [Dictionary] Force update trading coins tags (ROLE = ADMIN/SERVICE/SUPER) */
  updateTagsOnCoins: Scalars["String"];
  updateTemplate: Template;
  updateTopic: Topic;
  /** [Dictionary] Allows measure unit updation */
  updateUnit: Unit;
  /** [Accounts] Update currency accounts status for user */
  updateUserAccountsStatus: Array<Account>;
  /** [Identity] Update user scoring by id for bitrix lead (requires admin/super/service role) */
  updateUserScoring: UserScoringType;
  /** [Identity] Update user screening by user id for bitrix lead (requires admin/super/service role) */
  updateUserScreening?: Maybe<Scalars["Boolean"]>;
  /** [Dictionary] Allows VAT updation */
  updateVat: Vat;
  updatedPayment: Payment;
  uploadDocumentForKYB: Scalars["Boolean"];
  /**
   * [Notification] SERVICE-USER-ONLY. Uploading images for templates to files-api
   * @deprecated This mutation will be removed in the next version, because now embedded images are used in email templates
   */
  uploadImagesForTemplates: Scalars["Boolean"];
  /** [Dictionary] Validates bank bic and swift codes (ROLE = ADMIN/SERVICE/SUPER) */
  validateBankBicAndSwiftCodes: ValidateBankBicAndSwiftCodesResults;
  /** [2FA] Verify OTP token */
  verify2FAToken: Scalars["Boolean"];
};

export type MutationActivateCryptoCardArgs = {
  input: CommonCryptoCardInput;
};

export type MutationActivateMyCryptoCardApproveArgs = {
  input: Verify2FaInput;
};

export type MutationActivateMyCryptoCardSetupArgs = {
  input: CommonCryptoCardInput;
};

export type MutationAddAvatarArgs = {
  avatarId: Scalars["String"];
};

export type MutationAddContactsArgs = {
  input: AddContactsInput;
};

export type MutationAddConvertRateArgs = {
  input: ConvertRateInput;
};

export type MutationAddFcmTokenArgs = {
  token: Scalars["String"];
};

export type MutationAddTagArgs = {
  input: AddTagInput;
};

export type MutationAddTagByCodeArgs = {
  input: AddTagByCodeInput;
};

export type MutationAdminSignUpArgs = {
  input: AdminSignUpInput;
};

export type MutationAppleSignInArgs = {
  input: AppleSignInInput;
};

export type MutationApproveCryptoTransactionArgs = {
  input: ApproveTransactionInput;
};

export type MutationApproveExchangeArgs = {
  input: ApproveExchangeInput;
};

export type MutationApprovePaymentArgs = {
  input: ApprovePaymentInput;
};

export type MutationApproveUserPaymentArgs = {
  input: ApproveUserPaymentInput;
};

export type MutationAttachDeviceToTopicArgs = {
  input: AttachDeviceToTopicInput;
};

export type MutationBlockMyCryptoCardApproveArgs = {
  input: Verify2FaInput;
};

export type MutationBlockMyCryptoCardSetupArgs = {
  input: CommonCryptoCardInput;
};

export type MutationChangeIdentityArgs = {
  input: ChangeIdentityInput;
};

export type MutationChangeMyCryptoCardBalanceArgs = {
  input: ChangeCryptoCardBalanceInput;
};

export type MutationChangeMyCryptoCardNameArgs = {
  input: ChangeCryptoCardNameInput;
};

export type MutationChangePasswordArgs = {
  oldPassword: Scalars["String"];
};

export type MutationChangeUserStatusArgs = {
  input: ChangeUserStatusInput;
};

export type MutationCheckVerificationPhraseArgs = {
  input: VerificationPhraseInput;
};

export type MutationClearEntityTableArgs = {
  entityName: Scalars["String"];
};

export type MutationConfirmChangePasswordArgs = {
  input: ConfirmChangePasswordInput;
};

export type MutationConfirmChangedIdentityArgs = {
  input: ConfirmChangedIdentityInput;
};

export type MutationConfirmDisabling2FaArgs = {
  input: Confirm2FaInput;
};

export type MutationConfirmEnabling2FaArgs = {
  input: Confirm2FaInput;
};

export type MutationConfirmSignInArgs = {
  input: AuthVerify2FaInput;
};

export type MutationConfirmationInviteArgs = {
  inviteCodeOrEmail: Scalars["String"];
};

export type MutationCreate2FaOperationArgs = {
  input: CreateOperationInput;
};

export type MutationCreate2FaOperationIdArgs = {
  input: CreateOperationIdInput;
};

export type MutationCreateBankArgs = {
  input: CreateBankInput;
};

export type MutationCreateCheckoutPaymentArgs = {
  input: CreateCheckoutPaymentInput;
};

export type MutationCreateCityArgs = {
  input: CreateCityInput;
};

export type MutationCreateCompanyArgs = {
  input: BitrixCreateCompanyInput;
};

export type MutationCreateContactArgs = {
  input: BitrixCreateContactInput;
};

export type MutationCreateContinentArgs = {
  input: CreateContinentInput;
};

export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};

export type MutationCreateCryptoCardArgs = {
  input: CreateCryptoCardInput;
};

export type MutationCreateCryptoTransactionArgs = {
  input: CreateTransactionInput;
};

export type MutationCreateCurrenciesArgs = {
  input: CreateCurrenciesInput;
};

export type MutationCreateCurrencyArgs = {
  input: CreateCurrencyInput;
};

export type MutationCreateCurrentUserCardArgs = {
  token: Scalars["String"];
};

export type MutationCreateDealArgs = {
  input: BitrixCreateDealInput;
};

export type MutationCreateExchangeArgs = {
  input: CreateExchangeInput;
};

export type MutationCreateIntermediaryArgs = {
  input: CreateIntermediaryInput;
};

export type MutationCreateInternalCryptoTransactionArgs = {
  input: InternalPayInput;
};

export type MutationCreateLanguageArgs = {
  input: CreateLanguageInput;
};

export type MutationCreateLeadArgs = {
  input: BitrixCreateOrUpdateLeadInput;
  sendResponse?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateNumberRangeArgs = {
  input: CreateNumberRangeInput;
};

export type MutationCreatePositionArgs = {
  input: CreatePositionInput;
};

export type MutationCreateRateCommissionArgs = {
  input: CreateRateCommissionInput;
};

export type MutationCreateRegionArgs = {
  input: CreateRegionInput;
};

export type MutationCreateScoringRiskLevelArgs = {
  input: CreateScoringRiskLevelInput;
};

export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};

export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};

export type MutationCreateUnitArgs = {
  input: CreateUnitInput;
};

export type MutationCreateUpdateTemplatesFromHtmlArgs = {
  names: Array<TemplateName>;
};

export type MutationCreateUserCardArgs = {
  input: CreateUserCardInput;
};

export type MutationCreateVatArgs = {
  input: CreateVatInput;
};

export type MutationDelete2FaOperationArgs = {
  input: DeleteOperationInput;
};

export type MutationDeleteConvertRateArgs = {
  id: Scalars["String"];
};

export type MutationDeleteCurrentUserCardArgs = {
  cardId: Scalars["String"];
};

export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};

export type MutationDeleteDeviceFromTopicArgs = {
  input: DeleteDeviceFromTopicInput;
};

export type MutationDeleteExtraArgs = {
  key: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationDeleteIntermediaryArgs = {
  input: DeleteIntermediaryInput;
};

export type MutationDeleteUserCardArgs = {
  cardId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationDisable2FaArgs = {
  input: TfaInput;
};

export type MutationDisableAll2FaArgs = {
  input: TfaClearAll2FaInput;
};

export type MutationEnable2FaArgs = {
  input: TfaInput;
};

export type MutationFindOrCreateCryptoAddressArgs = {
  input: CreateCryptoAddress;
};

export type MutationFreezeCryptoCardArgs = {
  input: BlockCryptoCardInput;
};

export type MutationFreezeMyCryptoCardApproveArgs = {
  input: Verify2FaInput;
};

export type MutationFreezeMyCryptoCardSetupArgs = {
  input: CommonCryptoCardInput;
};

export type MutationGenerateHtmlTemplatesArgs = {
  input: GenerateTemplateInput;
};

export type MutationGenerateTotpArgs = {
  input?: InputMaybe<GenerateTotpInput>;
};

export type MutationGetTokenArgs = {
  email: Scalars["String"];
};

export type MutationGoogleSignInArgs = {
  input: GoogleSignInInput;
};

export type MutationInitUploadSessionArgs = {
  isPrivate?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationMakeAnonymousArgs = {
  input: MakeAnonymousInput;
};

export type MutationMyCryptoCardSensitiveInfoApproveArgs = {
  input: Verify2FaInput;
};

export type MutationMyDeleteExtraArgs = {
  key: Scalars["String"];
};

export type MutationMyUpdateExtraArgs = {
  input: UpdateExtraInput;
};

export type MutationOauth2TokenArgs = {
  input: OAuth2TokenInput;
};

export type MutationOrderAccountDocsArgs = {
  input: OrderAccountDocsInput;
};

export type MutationPreparePaymentArgs = {
  input: PreparePaymentInput;
};

export type MutationProcessTransferArgs = {
  input: ProcessTransferRequestPayload;
};

export type MutationProfileArgs = {
  input: ProfileInput;
};

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRegisterPhoneArgs = {
  input: AuthRegisterPhoneInput;
};

export type MutationRemoveBanksArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveCitiesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveContactsArgs = {
  input: RemoveContactsInput;
};

export type MutationRemoveContinentsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveCountriesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveCurrenciesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveLanguagesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveMyContactsArgs = {
  input: RemoveMyContactsInput;
};

export type MutationRemoveNaceCodesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveNumberRangesArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemovePositionsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveRegionsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveScoringRiskLevelArgs = {
  id: Scalars["String"];
};

export type MutationRemoveTagsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveUnitsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRemoveVatsArgs = {
  input: RemoveDictItemsInput;
};

export type MutationRepeatTransactionArgs = {
  input: RepeatTransactionInput;
};

export type MutationReplaceCryptoCardArgs = {
  input: CommonCryptoCardInput;
};

export type MutationReplaceMyCryptoCardApproveArgs = {
  input: Verify2FaInput;
};

export type MutationReplaceMyCryptoCardSetupArgs = {
  input: CommonCryptoCardInput;
};

export type MutationRestorePasswordArgs = {
  input: RestorePasswordInput;
};

export type MutationRetrieveWalletsArgs = {
  input: RetrieveWalletsInput;
};

export type MutationSendArgs = {
  input: SendInput;
};

export type MutationSend2FaTokenArgs = {
  input: Send2FaTokenInput;
};

export type MutationSendCompanyVerificationArgs = {
  input: SendCompanyVerficationInput;
};

export type MutationSendInvitationCreateAccountArgs = {
  input: ReferrersSendInvitationCreateAccountInput;
};

export type MutationSendMyVerificationArgs = {
  input: SendMyVerificationInput;
};

export type MutationSendPaymentArgs = {
  input: SendPaymentInput;
};

export type MutationSendTfaCodeArgs = {
  input: AuthTfaSend;
};

export type MutationSendUserIntoCrmArgs = {
  id: Scalars["String"];
};

export type MutationSendVerificationArgs = {
  input: SendVerificationInput;
};

export type MutationSetCardTopUpConfigArgs = {
  input: SetCardTopUpConfigInput;
};

export type MutationSetExchangeCommissionArgs = {
  input: SetExchangeCommissionInput;
};

export type MutationSetExtraConfigArgs = {
  input: SetExtraConfigInput;
};

export type MutationSetFilePrivacyArgs = {
  input: SetFilePrivacyInput;
};

export type MutationSetMyCryptoCard3DSecureSettingsArgs = {
  input: SetCryptoCard3DSecuritySettingsInput;
};

export type MutationSetSumsubRequiredDocsConfigArgs = {
  input: SetSumsubRequiredDocConfigInput;
};

export type MutationSignUpArgs = {
  input: UserInput;
};

export type MutationStartKybArgs = {
  input: StartKybInput;
};

export type MutationStartKybNewArgs = {
  input: StartKybNewInput;
};

export type MutationStartTopUpBalanceByCardArgs = {
  input: StartTopUpBalanceByCardInput;
};

export type MutationSwitchConvertRateArgs = {
  id: Scalars["String"];
};

export type MutationSyncAccountBalanceArgs = {
  accountId: Scalars["String"];
};

export type MutationSynchronizePaymentsArgs = {
  input: SynchronizePaymentsInput;
};

export type MutationTokenArgs = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type MutationTransferToCryptoCardAccountArgs = {
  input: CryptoCardInternalTransferInput;
};

export type MutationTransferToMainAccountArgs = {
  input: CryptoCardInternalTransferInput;
};

export type MutationUnfreezeCryptoCardArgs = {
  input: CommonCryptoCardInput;
};

export type MutationUnfreezeMyCryptoCardApproveArgs = {
  input: Verify2FaInput;
};

export type MutationUnfreezeMyCryptoCardSetupArgs = {
  input: CommonCryptoCardInput;
};

export type MutationUnlockUserOperationArgs = {
  input: UnlockUserOperationInput;
};

export type MutationUpdate2FaOperationArgs = {
  input: UpdateOperationInput;
};

export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput2;
};

export type MutationUpdateBalanceArgs = {
  input: UpdateBalanceInput;
};

export type MutationUpdateBankArgs = {
  input: UpdateBankInput;
};

export type MutationUpdateCityArgs = {
  input: UpdateCityInput;
};

export type MutationUpdateCompanyArgs = {
  input: BitrixUpdateCompanyInput;
};

export type MutationUpdateCompanyDocumentsArgs = {
  input: UpdateCompanyDocumentsInput;
};

export type MutationUpdateContactArgs = {
  input: BitrixUpdateContactInput;
};

export type MutationUpdateContinentArgs = {
  input: UpdateContinentInput;
};

export type MutationUpdateConvertRateArgs = {
  id: Scalars["String"];
  input: ConvertRateInput;
};

export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};

export type MutationUpdateCryptoCardSecurityArgs = {
  input: UpdateCryptoCardSecurityInput;
};

export type MutationUpdateCurrencyArgs = {
  input: UpdateCurrencyInput;
};

export type MutationUpdateDocumentsArgs = {
  input: UpdateDocumentsInput;
};

export type MutationUpdateExchangeInfoArgs = {
  input: UpdateExchangeInfoInput;
};

export type MutationUpdateExtraArgs = {
  input: UpdateExtraInput;
  userId: Scalars["String"];
};

export type MutationUpdateIntermediaryArgs = {
  input: UpdateIntermediaryInput;
};

export type MutationUpdateKycStepArgs = {
  step: Scalars["Float"];
};

export type MutationUpdateLanguageArgs = {
  input: UpdateLanguageInput;
};

export type MutationUpdateLeadArgs = {
  input: BitrixCreateOrUpdateLeadInput;
};

export type MutationUpdateMyAddressArgs = {
  input: UpdateMyAddressInput;
};

export type MutationUpdateMyCompanyQuestionnaireArgs = {
  input: UpdateMyCompanyQuestionnaireInput;
};

export type MutationUpdateMyContactsArgs = {
  input: UpdateMyContactInput;
};

export type MutationUpdateMyCryptoCard3DSecureSettingsApproveArgs = {
  input: Verify2FaInput;
};

export type MutationUpdateMyCryptoCard3DSecureSettingsSetupArgs = {
  input: UpdateCryptoCard3DSecuritySettingsInput;
};

export type MutationUpdateMyCryptoCardLimitsArgs = {
  input: UpdateCryptoCardLimitsInput;
};

export type MutationUpdateMyCryptoCardPinArgs = {
  input: UpdateCryptoCardPinInput;
};

export type MutationUpdateMyCryptoCardSecurityArgs = {
  input: UpdateCryptoCardSecurityInput;
};

export type MutationUpdateMyDocumentsArgs = {
  input: UpdateMyDocumentsInput;
};

export type MutationUpdateMyPersonalArgs = {
  input: UpdateMyPersonalInput;
};

export type MutationUpdateNaceCodeArgs = {
  input: UpdateNaceCodeInput;
};

export type MutationUpdateNumberRangeArgs = {
  input: UpdateNumberRangeInput;
};

export type MutationUpdatePersonalArgs = {
  input: UpdatePersonalInput;
};

export type MutationUpdatePositionArgs = {
  input: UpdatePositionInput;
};

export type MutationUpdateRegionArgs = {
  input: UpdateRegionInput;
};

export type MutationUpdateScoringRiskLevelArgs = {
  input: UpdateScoringRiskLevelInput;
};

export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};

export type MutationUpdateTopicArgs = {
  input: UpdateTopicInput;
};

export type MutationUpdateUnitArgs = {
  input: UpdateUnitInput;
};

export type MutationUpdateUserAccountsStatusArgs = {
  userId: Scalars["String"];
};

export type MutationUpdateUserScoringArgs = {
  input: UpdateUserScoringInput;
};

export type MutationUpdateUserScreeningArgs = {
  userId: Scalars["String"];
};

export type MutationUpdateVatArgs = {
  input: UpdateVatInput;
};

export type MutationUpdatedPaymentArgs = {
  input: UpdatePaymentInput;
};

export type MutationUploadDocumentForKybArgs = {
  fileId: Scalars["String"];
};

export type MutationValidateBankBicAndSwiftCodesArgs = {
  input: ValidateBankBicAndSwiftCodesInput;
};

export type MutationVerify2FaTokenArgs = {
  input: Verify2FaInput;
};

export type MyAccountsInput = {
  accountNumbers?: InputMaybe<Array<Scalars["String"]>>;
  companyId?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<Filter>;
  requestGCP?: InputMaybe<Scalars["Boolean"]>;
};

export type MyCryptoCardsInput = {
  filter?: InputMaybe<Filter>;
  /** Retrieves cards with status in status list */
  statusList?: InputMaybe<Array<CardStatusEnum>>;
};

export type MyPaymentsInput = {
  accountIds?: InputMaybe<Array<Scalars["String"]>>;
  amount?: InputMaybe<Scalars["BigNumber"]>;
  amountRange?: InputMaybe<AmountRangeInput>;
  cardIds?: InputMaybe<Array<Scalars["String"]>>;
  categories?: InputMaybe<Array<PaymentCategory>>;
  creditType?: InputMaybe<Array<CreditType>>;
  currencyId?: InputMaybe<Scalars["ID"]>;
  dateRange?: InputMaybe<DateRangeInput>;
  direction?: InputMaybe<PaymentDirection>;
  filter?: InputMaybe<Filter>;
  query?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<CommonSort>;
  sortOrder?: InputMaybe<SortOrder>;
  state?: InputMaybe<PaymentState>;
  types?: InputMaybe<Array<PaymentType>>;
};

export type MyVabsBalancesInput = {
  filter?: InputMaybe<Filter>;
};

export type NaceCode = {
  __typename?: "NACECode";
  children?: Maybe<Array<NaceCode>>;
  item: CommonDictItem;
  parent?: Maybe<NaceCode>;
  section: NaceCodeSection;
};

export type NaceCodeChildrenArgs = {
  input?: InputMaybe<GetNaceCodesInput>;
};

export type NaceCodeSection = {
  __typename?: "NACECodeSection";
  description: Scalars["String"];
  item: CommonDictItem;
};

export type NaceCodes = {
  __typename?: "NACECodes";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<NaceCode>;
};

export type NameOrCompany = {
  __typename?: "NameOrCompany";
  companyName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type NameOrCompanyInput = {
  companyName?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type NameOrContactInput = {
  contactId?: InputMaybe<Scalars["String"]>;
  nameOrCompany?: InputMaybe<NameOrCompanyInput>;
};

export type NetworkList = {
  __typename?: "NetworkList";
  addressRegex: Scalars["String"];
  coin: Scalars["String"];
  depositDesc: Scalars["String"];
  depositDust: Scalars["BigNumber"];
  depositEnable: Scalars["Boolean"];
  isDefault: Scalars["Boolean"];
  memoRegex: Scalars["String"];
  name: Scalars["String"];
  network: Scalars["String"];
  resetAddressStatus: Scalars["Boolean"];
  sameAddress: Scalars["Boolean"];
  specialTips?: Maybe<Scalars["String"]>;
  withdrawDesc: Scalars["String"];
  withdrawEnable: Scalars["Boolean"];
  withdrawFee: Scalars["BigNumber"];
  withdrawIntegerMultiple: Scalars["BigNumber"];
  withdrawMax: Scalars["BigNumber"];
  withdrawMin: Scalars["BigNumber"];
};

export enum NeuronExchangeStatus {
  Awaiting = "AWAITING",
  Debiting = "DEBITING",
  Expired = "EXPIRED",
  Failed = "FAILED",
  Paying = "PAYING",
  Success = "SUCCESS",
}

export type NewCompanies = {
  __typename?: "NewCompanies";
  data?: Maybe<Array<CompanyPayloadType>>;
  error?: Maybe<ResponseError>;
};

export type NewCompany = {
  __typename?: "NewCompany";
  data?: Maybe<CompanyPayloadType>;
  error?: Maybe<ResponseError>;
};

export type NotificationCodeType = {
  __typename?: "NotificationCodeType";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  type: NotificationTypeEnum;
};

export type NotificationCodesType = {
  __typename?: "NotificationCodesType";
  email?: Maybe<NotificationCodeType>;
  sms?: Maybe<NotificationCodeType>;
};

export enum NotificationMessengerChatEnum {
  RocketchatPaymentsCash = "ROCKETCHAT_PAYMENTS_CASH",
  TelegramBanxeNotification = "TELEGRAM_BANXE_NOTIFICATION",
  TelegramVirtualAbsDev = "TELEGRAM_VIRTUAL_ABS_DEV",
}

export enum NotificationMessengerMessagePatternEnum {
  SmsInternalError = "SMS_INTERNAL_ERROR",
  SmsNotDelivered = "SMS_NOT_DELIVERED",
  SmsNotDeliveredAllProviders = "SMS_NOT_DELIVERED_ALL_PROVIDERS",
  SmsSent = "SMS_SENT",
}

export enum NotificationMessengerTypeEnum {
  RocketChat = "ROCKET_CHAT",
  Telegram = "TELEGRAM",
}

export enum NotificationResult {
  Error = "ERROR",
  Pending = "PENDING",
  Sent = "SENT",
  Success = "SUCCESS",
}

export type NotificationStatus = {
  __typename?: "NotificationStatus";
  devices: Array<Maybe<DeviceInfo2>>;
  error?: Maybe<Array<Scalars["String"]>>;
  id?: Maybe<Scalars["ID"]>;
  result: NotificationResult;
  topic?: Maybe<Topic>;
  type: NotificationTypeEnum;
};

export type NotificationStatusDevicesArgs = {
  input: DevicesInput;
};

export type NotificationTopic = {
  __typename?: "NotificationTopic";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  namespace?: Maybe<Scalars["String"]>;
  templateName?: Maybe<Scalars["String"]>;
};

export enum NotificationType {
  Email = "EMAIL",
  Messenger = "MESSENGER",
  Push = "PUSH",
  Rmq = "RMQ",
  Sms = "SMS",
  Webhook = "WEBHOOK",
  Websocket = "WEBSOCKET",
}

export enum NotificationTypeEnum {
  Email = "EMAIL",
  Messenger = "MESSENGER",
  Push = "PUSH",
  Rmq = "RMQ",
  Sms = "SMS",
  Webhook = "WEBHOOK",
  Websocket = "WEBSOCKET",
}

export type NumberRange = {
  __typename?: "NumberRange";
  item: CommonDictItem;
};

export type NumberRangeInput = {
  code: Scalars["String"];
  name: Scalars["String"];
};

export type OAuth2Input = {
  clientId: Scalars["String"];
  loginHint?: InputMaybe<Scalars["String"]>;
  redirectUri: Scalars["String"];
  responseType: ResponseType;
  scope: Scalars["String"];
  service: Service;
  state?: InputMaybe<Scalars["String"]>;
};

export type OAuth2TokenInput = {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  code: Scalars["String"];
  redirectUri: Scalars["String"];
  service: Service;
};

export enum OperationIdStatus {
  Pending = "PENDING",
  Verified = "VERIFIED",
}

export type OperationOutputType = {
  __typename?: "OperationOutputType";
  amount?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["String"]>;
  createAt: Scalars["String"];
  currency?: Maybe<Scalars["String"]>;
  status: OperationStatusEnum;
};

export enum OperationStatusEnum {
  AwaitInvesting = "AWAIT_INVESTING",
  AwaitWithdrawing = "AWAIT_WITHDRAWING",
  Invest = "INVEST",
  Investing = "INVESTING",
  InWaitingWithdrawingPool = "IN_WAITING_WITHDRAWING_POOL",
  ServiceTransferExternalToInternal = "SERVICE_TRANSFER_EXTERNAL_TO_INTERNAL",
  ServiceTransferInternalToExternal = "SERVICE_TRANSFER_INTERNAL_TO_EXTERNAL",
  Withdraw = "WITHDRAW",
  Withdrawing = "WITHDRAWING",
}

export type OrderAccountDocsInput = {
  accountId: Scalars["ID"];
  docType: AccountDocumentType;
  email?: InputMaybe<Scalars["String"]>;
};

export type OrderAssetInfo = {
  __typename?: "OrderAssetInfo";
  asset: Scalars["String"];
  precision: Scalars["Int"];
};

/** Return commission by order */
export type OrderCommission = {
  __typename?: "OrderCommission";
  asset?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** Return pair coin details */
export type OrderDetail = {
  __typename?: "OrderDetail";
  balance?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum OrderFilterType {
  Global = "GLOBAL",
  Pair = "PAIR",
}

export type OrderFiltersForPair = {
  __typename?: "OrderFiltersForPair";
  filters: Array<Maybe<BaseOrderFilter>>;
  pair?: Maybe<OrderPair>;
};

export type OrderPair = {
  __typename?: "OrderPair";
  baseAsset: OrderAssetInfo;
  isTrading: Scalars["Boolean"];
  owner: ExchangeService;
  quoteAsset: OrderAssetInfo;
  symbol: Scalars["String"];
};

/** Order status */
export enum OrderStatus {
  /** For spot & future */
  History = "HISTORY",
  /** For spot & future */
  Open = "OPEN",
  /** For spot & future */
  Trade = "TRADE",
  /** Only for future */
  Transactions = "TRANSACTIONS",
}

export type PaginatedType = {
  __typename?: "PaginatedType";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<CompanyPayloadType>;
};

export type PaginationCompaniesType = {
  __typename?: "PaginationCompaniesType";
  data?: Maybe<PaginatedType>;
  error?: Maybe<ResponseError>;
};

/** Return pair */
export type Pair = {
  __typename?: "Pair";
  brokerName?: Maybe<Scalars["String"]>;
  externalName?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  lastPrice?: Maybe<Scalars["Float"]>;
  prevDayPrice?: Maybe<Scalars["Float"]>;
  priceChangePercent?: Maybe<Scalars["Float"]>;
  priceChangeValue?: Maybe<Scalars["Float"]>;
  second?: Maybe<Scalars["String"]>;
};

/** Return all info about pairs */
export type PairGroup = {
  __typename?: "PairGroup";
  /** Menu on json format */
  menu?: Maybe<Scalars["String"]>;
  pairsByCoin: Array<Maybe<Pair>>;
};

/** Return all info about pairs */
export type PairGroupPairsByCoinArgs = {
  coin: Scalars["String"];
};

/** Return pair */
export type PairOrder = {
  __typename?: "PairOrder";
  brokerName?: Maybe<Scalars["String"]>;
  first?: Maybe<OrderDetail>;
  lastPrice?: Maybe<Scalars["Float"]>;
  listExchangeOrderTypes: Array<Maybe<Scalars["String"]>>;
  minAmount?: Maybe<Scalars["Float"]>;
  minStep?: Maybe<Scalars["Float"]>;
  pair?: Maybe<Scalars["String"]>;
  second?: Maybe<OrderDetail>;
  tickerSize?: Maybe<Scalars["Float"]>;
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["BigNumber"];
  approver?: Maybe<CompanyMember>;
  attachment?: Maybe<File>;
  category: PaymentCategory;
  common: CommonItemInfo;
  creator?: Maybe<CompanyMember>;
  credit: PaymentAccount;
  creditType: CreditType;
  creditorBalance?: Maybe<Scalars["BigNumber"]>;
  currency?: Maybe<Currency>;
  debit: PaymentAccount;
  debitorBalance?: Maybe<Scalars["BigNumber"]>;
  declineReason?: Maybe<Scalars["String"]>;
  direction?: Maybe<PaymentDirection>;
  fee: Scalars["BigNumber"];
  feeRatio: Scalars["BigNumber"];
  id: Scalars["ID"];
  invoicePrintedOn?: Maybe<Scalars["DateTime"]>;
  nostroAccount?: Maybe<Scalars["String"]>;
  nostroCurrency?: Maybe<Scalars["String"]>;
  nostroId?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  paymentOrderNo?: Maybe<Scalars["String"]>;
  receipt?: Maybe<File>;
  settled?: Maybe<Scalars["DateTime"]>;
  state: PaymentState;
  systemDescription?: Maybe<Scalars["String"]>;
  type: PaymentType;
  vat?: Maybe<Scalars["BigNumber"]>;
};

export type PaymentAccount = {
  __typename?: "PaymentAccount";
  account?: Maybe<Account>;
  accountNumber?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  bank?: Maybe<Bank>;
  cardId?: Maybe<Scalars["String"]>;
  cardNumber?: Maybe<Scalars["CardNumber"]>;
  company?: Maybe<Company>;
  contact?: Maybe<Contact>;
  country?: Maybe<Country>;
  firstName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["IBAN"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  nameOrCompany?: Maybe<NameOrCompany>;
  phoneNumber?: Maybe<Scalars["PhoneNumber"]>;
  swift?: Maybe<Scalars["SWIFT"]>;
};

export type PaymentAccountInput = {
  accountId?: InputMaybe<Scalars["ID"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  bankBic?: InputMaybe<Scalars["String"]>;
  bankId?: InputMaybe<Scalars["ID"]>;
  bankInfo?: InputMaybe<BankInfoInput>;
  bankSortCode?: InputMaybe<Scalars["String"]>;
  cardId?: InputMaybe<Scalars["ID"]>;
  cardNumber?: InputMaybe<Scalars["CardNumber"]>;
  countryId?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["IBAN"]>;
  nameOrContact?: InputMaybe<NameOrContactInput>;
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]>;
  swift?: InputMaybe<Scalars["SWIFT"]>;
  /** Universal parameter. Identify user by its phone, email, or wallet id */
  userIdentifier?: InputMaybe<Scalars["String"]>;
};

export enum PaymentCategory {
  Deposit = "DEPOSIT",
  Exchange = "EXCHANGE",
  FundRaising = "FUND_RAISING",
  Reccurent = "RECCURENT",
  Remittance = "REMITTANCE",
  Scheduled = "SCHEDULED",
  Transfer = "TRANSFER",
}

export type PaymentCharges = {
  __typename?: "PaymentCharges";
  amountCurrency: Scalars["String"];
  amountValue: Scalars["String"];
  tariffCurrency: Scalars["String"];
  tariffValue: Scalars["String"];
};

export enum PaymentDirection {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export type PaymentSourceTargetInput = {
  source: Scalars["String"];
  target: Scalars["String"];
};

export enum PaymentState {
  AwaitingLiquidity = "AWAITING_LIQUIDITY",
  Declined = "DECLINED",
  Draft = "DRAFT",
  Initiated = "INITIATED",
  Pending = "PENDING",
  Settled = "SETTLED",
}

export enum PaymentType {
  Card2Card = "CARD2CARD",
  Domestic = "DOMESTIC",
  Inner = "INNER",
  Swift = "SWIFT",
}

export type Payments = {
  __typename?: "Payments";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<Payment>;
};

export type PhoneDeviceInfo = {
  __typename?: "PhoneDeviceInfo";
  confirm: Scalars["Boolean"];
  deviceId: Scalars["String"];
  identity: Scalars["String"];
  type: Device;
};

export enum PlannedAccountUse {
  BankTransfers = "BANK_TRANSFERS",
  CardPayments = "CARD_PAYMENTS",
  CollectionOfFunds = "COLLECTION_OF_FUNDS",
  InternalPayments = "INTERNAL_PAYMENTS",
  OnlinePurchases = "ONLINE_PURCHASES",
  Other = "OTHER",
}

export type PnL = {
  __typename?: "PnL";
  funds: Funds;
  percentage: Scalars["BigNumber"];
};

export type PortfolioHistoryInput = {
  dateEnd: Scalars["Float"];
  dateStart: Scalars["Float"];
  positionSymbol?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type PortfolioHistoryOutputType = {
  __typename?: "PortfolioHistoryOutputType";
  createAt: Scalars["Float"];
  estimatedAmountCurrencyDifference: Scalars["String"];
  estimatedAmountPriceDifference: Scalars["String"];
  estimatedChangeDifference: Scalars["String"];
  positionSymbol: Scalars["String"];
  token: Scalars["String"];
};

export type PortfolioOutputType = {
  __typename?: "PortfolioOutputType";
  estimatedAmountPrice: Scalars["String"];
  estimatedBalancePrice: Scalars["String"];
  estimatedChangePrice: Scalars["String"];
  estimatedDailyAmountPrice: Scalars["String"];
  estimatedDailyChangePrice: Scalars["String"];
  investedPrice: Scalars["String"];
  positions: Array<Maybe<PositionOutputType>>;
};

export type PortfolioPositionItemsInput = {
  currencySymbol?: InputMaybe<Scalars["String"]>;
  currencyType?: InputMaybe<CurrencyTypeEnum>;
};

export type Position = {
  __typename?: "Position";
  id: Scalars["ID"];
  item: CommonDictItem;
};

export type PositionHistoryInput = {
  interval: HistoryIntervalEnum;
  pointsCount?: InputMaybe<Scalars["Float"]>;
  positionSymbol: Scalars["String"];
  riskLevel?: InputMaybe<RiskLevelEnum>;
};

export type PositionHistoryOutputType = {
  __typename?: "PositionHistoryOutputType";
  createAt: Scalars["Float"];
  estimatedAmountCurrency: Scalars["String"];
  estimatedAmountPrice: Scalars["String"];
  estimatedBalanceCurrency: Scalars["String"];
  estimatedBalancePrice: Scalars["String"];
  estimatedChange: Scalars["String"];
  investedCurrency: Scalars["String"];
  investedPrice: Scalars["String"];
  positionSymbol: Scalars["String"];
  token: Scalars["String"];
};

export type PositionInput = {
  code: Scalars["String"];
  name: Scalars["String"];
};

/** Position order */
export enum PositionOrder {
  Long = "LONG",
  Short = "SHORT",
}

export type PositionOutputType = {
  __typename?: "PositionOutputType";
  currencySymbol: Scalars["String"];
  endDate: Scalars["Float"];
  estimatedAmount: Scalars["String"];
  estimatedAmountPrice: Scalars["String"];
  estimatedBalance: Scalars["String"];
  estimatedBalancePrice: Scalars["String"];
  estimatedChange: Scalars["Float"];
  estimatedChangePrice: Scalars["String"];
  estimatedDailyAmountCurrency: Scalars["String"];
  estimatedDailyAmountPrice: Scalars["String"];
  estimatedDailyChangeCurrency: Scalars["String"];
  estimatedDailyChangePrice: Scalars["String"];
  invested: Scalars["String"];
  investedPrice: Scalars["String"];
  positionSymbol: Scalars["String"];
  riskLevel: RiskLevelEnum;
  startDate: Scalars["Float"];
  status: PositionStatusEnum;
  type: TransactionCurrencyTypeEnum;
};

export enum PositionStatusEnum {
  Investing = "INVESTING",
  None = "NONE",
  Withdrawing = "WITHDRAWING",
}

export type PreparePaymentInput = {
  /** Payment amount. */
  amount: Scalars["BigNumber"];
  attachmentId?: InputMaybe<Scalars["ID"]>;
  category: PaymentCategory;
  /** Payment receiver (To). */
  credit: PaymentAccountInput;
  /** Payment currency */
  currencyId: Scalars["ID"];
  /** Payment sender (From). */
  debit: PaymentAccountInput;
  note?: InputMaybe<Scalars["String"]>;
  operationId?: InputMaybe<Scalars["String"]>;
  schedule?: InputMaybe<Scalars["DateTime"]>;
  type: PaymentType;
  vat?: InputMaybe<Scalars["BigNumber"]>;
};

export type ProcessCheckoutStatementInput = {
  endDate?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["String"]>;
};

export enum ProcessStatus {
  Declined = "DECLINED",
  Settled = "SETTLED",
}

export type ProcessTransferRequestPayload = {
  amount: Scalars["BigNumber"];
  creditAccountId: Scalars["ID"];
  debitAccountId: Scalars["ID"];
  debitorUserId: Scalars["ID"];
  fee: Scalars["BigNumber"];
};

export type ProcessTransferResponseData = {
  __typename?: "ProcessTransferResponseData";
  description?: Maybe<Scalars["String"]>;
  status: ProcessStatus;
};

export type Profile = {
  __typename?: "Profile";
  avatar?: Maybe<File>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<GenderEnum>;
  language: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  service: Service;
  timezone: Scalars["String"];
  username?: Maybe<Scalars["String"]>;
};

export type ProfileInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<GenderEnum>;
  language?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  service: Service;
  timezone?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type ProfileTransaction =
  | CardTransaction
  | CashTransaction
  | CryptoTransaction
  | EarnTransaction;

export type PublicKeyInfo = {
  __typename?: "PublicKeyInfo";
  key: Scalars["String"];
  type: KeyEnum;
};

export type PublicKeyInput = {
  format: KeyEnum;
  /** publicKey for auth user */
  publicKey: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  /** [Accounts] Get All Accounts, (Admin Only) */
  accounts?: Maybe<Array<Maybe<Account>>>;
  /** [Dictionary] Get address data from given geolocation input */
  addressByGeo: Array<Maybe<AddressData>>;
  /** Returns all known transactions appended with isRepeatable field */
  allTransactions: Array<Maybe<TransactionWithRepeatable>>;
  /** [Cards] Allows to get list of crypto balances of current user */
  availableCryptoCardBalances: Array<Maybe<CryptoCardBalance>>;
  /** [Dictionary] Allows getting list of banks */
  banks: Array<Maybe<Bank>>;
  /** [Identity] Calculate scoring risk level by scoring number (requires admin/super/service role) */
  calculateScoringRiskLevel: ScoringRiskLevelEnum;
  cardTransactions: CardTransactionGroup;
  cardTransactionsReport: Report;
  cashTransactions: CashTransactionGroup;
  cashTransactionsReport: Report;
  /** [Payments] Change amount in mock checkout statement (ROLE = [ADMIN, SUPER]) */
  changeMockCheckoutStatementAmount: Scalars["String"];
  /** [Identity] Check crypto transaction details (service/admin/super role) */
  checkCryptoTransaction: CheckCryptoTransaction;
  /** [Referrers] Check the confirmation of the invitation */
  checkUserConfirmedInvite: Scalars["Boolean"];
  /** [Dictionary] Allows getting list of cities */
  cities: Array<Maybe<City>>;
  /** [Dictionary] Get city data from given input */
  cityByName: Array<Maybe<City>>;
  /** [Notification] Compile an email template to .html file (requires service/admin/super role) */
  compileEmailTemplateToHTML: Scalars["String"];
  /** [Auth] Confirm device */
  confirm: Scalars["Boolean"];
  /** [Contacts] Get all contacts. (Admin Only) */
  contacts: Contacts;
  /** [Dictionary] Allows getting list of */
  continents: Array<Maybe<Continent>>;
  /** [Dictionary] Allows getting list of countries */
  countries: Array<Maybe<Country>>;
  /** [Neuronex Transaction Service] Get my crypto portfolio. */
  cryptoPortfolio: CryptoPortfolio;
  cryptoStatus: CryptoStatusObject;
  cryptoTransactions: CryptoTransactionGroup;
  cryptoTransactionsReport: Report;
  /** [Dictionary] Allows getting list of currencies */
  currencies: Array<Maybe<Currency>>;
  defiInvestQuery: DefiInvestQuery;
  devices: Array<Maybe<DeviceInfo2>>;
  dontUseMe: CompanyMember;
  dontUseMeToo: Company;
  earnTransactions: EarnTransactionGroup;
  earnTransactionsReport: Report;
  /** [Auth] Get a new access token and refresh token after login */
  exchangeAuthCode: Auth;
  fastExchangeReceipt?: Maybe<Receipt>;
  fastExchangeReceipts: Array<Maybe<Receipt>>;
  /** [Files] check file by id */
  file: File;
  /** [Files] check all or filtered files */
  files: Array<File>;
  /** [Companies] find company with new error output format */
  findCompany: NewCompany;
  /** [Neuronex Transaction Service] Find transactions. */
  findCryptoTransactions: FindTransactionObject;
  findRates: Array<ConvertRate>;
  findUsers: Users;
  /** [Dictionary] Get address data from given address input */
  geoByAddress: Array<Maybe<AddressData>>;
  /** [2FA] SUPER-ADMIN-SERVICE-ONLY Get operation by title */
  get2FAOperation: TfaOperation;
  /** [2FA] Generates 2FA operation id */
  get2FAOperationId: TfaOperationId;
  /** Query for starting user manual KYC procedure */
  getAccessTokenForLivenessLevel: Scalars["String"];
  /** [Accounts] (Operation for inter-service communication) Allow getting the Account */
  getAccount?: Maybe<Account>;
  /** [Accounts] (Operation for inter-service communication) Allow getting the user accounts by query string, which can be email, phone or wallet id */
  getAccountByUniversalParam?: Maybe<AccountsUserData>;
  getAccountsStatement: Statement;
  getAddress: GetAddressResponse;
  /** [Addresses] get all addresses (admin method) */
  getAddresses: Array<Address>;
  /** [2FA] SUPER-ADMIN-SERVICE-ONLY Get all operations */
  getAll2FAOperations: Array<TfaOperation>;
  /** [Banxe Fast Exchange] Get all exchanges. */
  getAllExchanges: Array<AllExchanges>;
  /** [Auth] Get users list with their full infos (Role = ADMIN, SERVICE, SUPER) */
  getAllUsers: UsersFull;
  /** [Auth] Get the number of milliseconds until the next attempt to send a confirmation code */
  getAttemptAvailabilityTime: Scalars["Int"];
  /** [Auth] Get user info (new query for getUser) only internal request (Role = SERVICE) */
  getAuthUser: AuthorizedUser;
  /** [Accounts] Get available country ids for buy */
  getAvailableCurrencies: Array<Scalars["String"]>;
  /** [Identity] Get erc20 Tokens (service/admin/super role) */
  getAvailableErc20TokenForSumsub?: Maybe<AvailableErc20TokenForSumsubType>;
  /** [Identity] Get all Erc20 Tokens (service/admin/super role) */
  getAvailableErc20TokensForSumsub: AvailableErc20TokensForSumsubType;
  getBank?: Maybe<Bank>;
  /** [Accounts] Get bank details for currency */
  getBankDetails: BankDetails;
  getBanks: Banks;
  /** [Dictionary] (Operation for inter-service communication) Allows getting list of banks */
  getBanksInfo: Array<Maybe<Bank>>;
  /** [banxe-bitrix] Get bitrix entity fields (service/admin/super role) */
  getBitrixFields: Array<BitrixParsedFieldType>;
  /** [Payments] Get card top-up config for checkout payments (ROLE = [ADMIN, SERVICE, SUPER]) */
  getCardTopUpConfig: CardTopUpConfig;
  /** [Payments] Get total checkout fee (ROLE= [USER, ADMIN, SUPER]) */
  getCardTopUpFee: CardTopUpFee;
  /** [Payments] Get card token from Checkout (ROLE = [ADMIN, SUPER]) */
  getCheckoutCardToken: CardData;
  /** [Dictionary] (Operation for inter-service communication) Allows getting city info */
  getCityInfo?: Maybe<City>;
  /** [Auth] Get the number of milliseconds after which the code will expire */
  getCodeExpirationTime: Scalars["Int"];
  /** [2FA] Сommon types of user verification and operations */
  getCommon2FA: Array<TfaType>;
  /** [Companies] Get companies */
  getCompanies: PaginationCompaniesType;
  /** [Companies] find company */
  getCompany: CompanyPayloadType;
  /** [Contacts] Get contact by id. (Admin Only) */
  getContactById: Contact;
  /** [Referrers] Get the number of users waiting for the drawing of invitation codes */
  getCountUsersWaitingDraw: Scalars["Float"];
  /** [Dictionary] Get country by alpha3 ISO code */
  getCountryByAlpha3: Country;
  /** [Dictionary] Get country by alpha2 ISO code */
  getCountryByCode: Country;
  /** [Dictionary] (Operation for inter-service communication) Allows getting country info */
  getCountryInfo?: Maybe<Country>;
  /**
   * [Cards] Allows user to get address for token/blockchain pair in vABS balance;
   * Each underlying vabs card account has only one balance for given token no matter how many networks are supporting this token
   */
  getCryptoCardsBalanceAddress: TopupAddressInfo;
  /** [Dictionary] (Operation for inter-service communication) Allows getting list of currencies */
  getCurrencies: Array<Maybe<Currency>>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting list of currencies by their type */
  getCurrenciesByType: Array<Maybe<Currency>>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting currency by ticker */
  getCurrencyByTicker?: Maybe<Currency>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting currency */
  getCurrencyInfo?: Maybe<Currency>;
  /** [Payments] Get current user cards */
  getCurrentUserCards: Array<UserCard>;
  /** [Payments] Get current user top-up limits */
  getCurrentUserTopUpLimits: UserTopUpLimits;
  /** [2FA] Get enabled 2FA */
  getEnabled2FA: Array<TfaType>;
  /** [Files] (Operation for inter-service communication) check file info by its id and user id */
  getFileInfo: File;
  /** [Accounts] Get intermediaries bank details */
  getIntermediaries: Intermediaries;
  /** [Accounts] Get intermediary bank details */
  getIntermediary: Intermediary;
  /** [Dictionary] (Operation for inter-service communication) Allows getting language info by code */
  getLanguageByCode?: Maybe<Language>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting language info */
  getLanguageInfo?: Maybe<Language>;
  /** [Notification] Get the latest sent SMS and EMAIL codes of the user (requires SERVICE role) */
  getLastNotificationCodes: NotificationCodesType;
  /** [Auth] SERVICE-ONLY. Get the last code sent */
  getLastSendCode: GetLastSendCodeResponse;
  /** [banxe-bitrix] Get lead data by an external user ID (service/admin/super role) */
  getLeadByExternalUserId?: Maybe<BitrixLeadType>;
  /** [Identity] Get main business activity ids for company (Service user only) */
  getMainActivityIds: Array<Scalars["String"]>;
  /** [Auth] Returns current user's active sessions */
  getMyActiveSessions: Array<Session>;
  /** [Companies] Get user's companies */
  getMyCompanies: NewCompanies;
  /** [Identity] get personal data for current user */
  getMyPersonalData: User;
  /** [Dictionary] Allows creating or getting bank (ROLE = ADMIN/SERVICE/SUPER) */
  getOrCreateBankByIban?: Maybe<Bank>;
  getOwner: CryptoWalletOwner;
  /** [Payments] Get payment charges. */
  getPaymentChargeInfo: PaymentCharges;
  /** [Dictionary] Get information about mobile numbers. Requires service/admin/super role */
  getPhoneNumbersInfo: Array<GetPhoneNumberInfoType>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting position info */
  getPositionInfo?: Maybe<Position>;
  /** Get convert rate (internal) */
  getRate?: Maybe<ConvertRate>;
  /** [Dictionary] Allows getting rate commission in percent */
  getRateCommission: RateCommission;
  /** [Dictionary] (Operation for inter-service communication) Allows getting rate commission in percent */
  getRateCommissionInfo?: Maybe<RateCommission>;
  getRates: Array<ConvertRate>;
  /** [2FA] Creates recovery code and returns it. Once recovery code is created, no one can get it again. To delete/recreate recovery code you need to disableAll2FA */
  getRecoveryCode: Scalars["String"];
  /** [Referrers] Find a referrer */
  getReferrer: Referrer;
  /** [Auth] Get the remaining attempts to enter the code */
  getRemainingAttemptsEnterCode: Scalars["Int"];
  /** [Identity] Get residence country id for user */
  getResidenceCountryId?: Maybe<Scalars["String"]>;
  /** [Identity] Get scoring risk level by id (requires admin/super/service role) */
  getScoringRiskLevel?: Maybe<ScoringRiskLevel>;
  /** [Identity] Get all scorings risk level (requires admin/super/service role) */
  getScoringRiskLevels: ScoringRiskLevels;
  /** [Identity] Get config with required docs */
  getSumsubRequiredDocsConfig: SumsubRequiredDocsConfig;
  getTimezones: Array<Timezone>;
  /** [Neuronex Transaction Service] Get transaction fee. */
  getTransactionFee: Scalars["BigNumber"];
  /**
   * [Auth] Get user info only internal request (Role = SERVICE)
   * @deprecated Please, use getAuthUser instead.
   */
  getUser: AuthorizedUser;
  getUserAddresses: Array<GetAddressResponse>;
  getUserAddressesMicroservice: User;
  /** [Identity] Get user by personal data (requires admin/super/service role) */
  getUserByPersonalData: User;
  /** [Payments] Get user cards (ROLE = [ADMIN, SUPER]) */
  getUserCards: Array<UserCard>;
  /** [Payments] Get user top-up limits (ROLE = [ADMIN, SUPER]) */
  getUserTopUpLimits: UserTopUpLimits;
  /**
   * [Identity] Get all personal data of users (requires admin/super/service role)
   * @deprecated this method is invalid, use findUsers method.
   */
  getUsers: Array<User>;
  /** [Dictionary] (Operation for inter-service communication) Allows getting VAT info */
  getVatInfo?: Maybe<Vat>;
  /** [Auth] Returns the phrase that you want to sign up for further verification */
  getVerificationPhrase: VerificationPhrase;
  /**
   * [Referrers] SUPER-ADMIN-ONLY Drawing codes between users from a whitelist
   * @deprecated This query will be removed in the next version. The drawing will start automatically
   */
  invitesDrawing: Scalars["Boolean"];
  isExchangeAvailable: Scalars["Boolean"];
  /** [Dictionary] Check if the number exists in the Twilio Lookup database. Requires service/admin/super role */
  isPhoneNumberExist: Scalars["Boolean"];
  /** [Accounts] (Operation for inter-service communication) Allows getting is transfer allowed */
  isTransferAllowed?: Maybe<IsTransferAllowedResponseData>;
  /** [Dictionary] Allows getting list of languages */
  languages: Array<Maybe<Language>>;
  /**
   * [Auth] If you specify refreshToken (with no options), it will be deleted.
   * If you specify fromOther = true, all tokens (except one provided by yours) will be deleted.
   * If you specify fromAll = true, all tokens will be deleted
   */
  logout: Scalars["Boolean"];
  /** Allways return list of favorites */
  marketFavorites?: Maybe<Array<Maybe<MarketFavorite>>>;
  /** [Accounts] Get current user accounts. (User Only) */
  myAccounts?: Maybe<Array<Maybe<Account>>>;
  /** [Contacts] Get current user contacts. (User Only) */
  myContacts: Contacts;
  /** [Cards] Allows user to get encrypted cvv2 */
  myCryptoCardCvv2: Scalars["String"];
  /** [Cards] Allows user to get encrypted card number */
  myCryptoCardNumber: Scalars["String"];
  /**
   * [Cards] Allows user to get encrypted pin
   * @deprecated Cannot be used for virtual cards
   */
  myCryptoCardPin: Scalars["String"];
  /**
   * [Cards]
   * SETUP!
   * Returns operationId for approval
   * Allows user to get card's sensitive info
   */
  myCryptoCardSensitiveInfoSetup: Scalars["String"];
  /** [Cards] Allows to get list of crypto cards of current user */
  myCryptoCards: Array<Maybe<CryptoCard>>;
  /** [Accounts] Get current user accounts currencies. (User Only) */
  myCurrencies: Array<Currency>;
  /** [Auth] Get current user's extra by key */
  myGetExtra: Scalars["JSON"];
  /** [Payments] Get payment history. */
  myPayments: Payments;
  /** [Dictionary] Get NACE code */
  naceCode?: Maybe<NaceCode>;
  /** [Dictionary] Getting list of NACE codes */
  naceCodes?: Maybe<NaceCodes>;
  /** [Dictionary] Allows getting list of number ranges */
  numberRanges: Array<Maybe<NumberRange>>;
  /** [Auth] oAuth2 grant permission on openID */
  oauth2: Redirect;
  /** @deprecated This query will be removed in the next version */
  orderFilters: Array<Maybe<CompleteOrderFilter>>;
  orderFiltersForPair: OrderFiltersForPair;
  orderPairs: Array<Maybe<OrderPair>>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE | TRANSACTIONS ] */
  orders?: Maybe<WalletOrders>;
  /** [Dictionary] Allows getting list of positions */
  positions: Array<Maybe<Position>>;
  /** [Neuronex Fast Exchange] */
  prepareExchange: EstimatedCostsObject;
  /** [Payments] Start checkout statement processing (ROLE = [ADMIN, SUPER]) */
  processCheckoutStatement: Scalars["String"];
  /** [Dictionary] Allows getting list of regions */
  regions: Array<Maybe<Region>>;
  requests: Array<Maybe<Request>>;
  resendPaymentsToTransactions: Scalars["String"];
  /** [Accounts] Search for accounts paginated, (Admin Only) */
  searchAccounts: Accounts;
  /** [Dictionary] Searches for cities (paginated version of cities query) */
  searchCities: Cities;
  /** [Dictionary] Searches for countries */
  searchCountries: Countries;
  /** Search for transactions (ROLE = SERVICE/ADMIN/SUPER) */
  searchTransactions: TransactionsWithRepeatable;
  /** Search for wallets (ROLE = SERVICE/ADMIN/SUPER) */
  searchWallets: WalletsObject;
  /** [Auth] Send code on device */
  sendCode: Scalars["Boolean"];
  /**
   * [Referrers] SUPER-ADMIN-ONLY Send an invitation code to email
   * @deprecated This query will be removed in the next version. There are no plans to send the code to an email outside of the draw
   */
  sendInviteCode: Scalars["Boolean"];
  /** [Auth] Sign in for service users only */
  serviceSignIn: Auth;
  /** [Auth] Checks creditails and enabled TFA */
  signIn: Send2FaTokenResponse;
  statuses: Array<Maybe<NotificationStatus>>;
  templates: Array<Maybe<Template>>;
  /** [Auth] Test query */
  testAuth: Scalars["Boolean"];
  topics: Array<Maybe<Topic>>;
  /** For FUTURE trading requests */
  tradeFuture?: Maybe<TradeFuture>;
  /** For SPOT trading requests */
  tradeSpot?: Maybe<TradeSpot>;
  transactionPrintedForm: TransactionPrintedForm;
  /** [Dictionary] Allows getting list of transfer methods. */
  transferMethods: Array<TransferMethod>;
  /** [Dictionary] Allows getting list of measure units */
  units: Array<Maybe<Unit>>;
  /** Get data about user from CEX server */
  userCEX?: Maybe<UserCex>;
  /** [Payments] Get payment history. */
  userPayments: Array<Payment>;
  /** @deprecated users Query only for supports Resolve Field, don't use it */
  users: User;
  /** [Dictionary] Allows getting list of VATs */
  vats: Array<Maybe<Vat>>;
  /** [2FA] Check the reCAPTCHA token */
  verifyCaptchaToken: Scalars["Boolean"];
  /** [Auth] Get the data of an authorized user. */
  viewer: AuthorizedUser;
  /** For SPOT/FUTURE wallets actions */
  walletCommon?: Maybe<WalletCommon>;
  /** For FUTURE wallet */
  walletFuture?: Maybe<WalletFuture>;
  /** For SPOT wallet */
  walletSpot?: Maybe<WalletSpot>;
};

export type QueryAccountsArgs = {
  input: AccountsInput;
};

export type QueryAddressByGeoArgs = {
  input: AddressByGeoInput;
};

export type QueryAllTransactionsArgs = {
  input: FindAllTransactionsInput;
};

export type QueryAvailableCryptoCardBalancesArgs = {
  input: MyVabsBalancesInput;
};

export type QueryBanksArgs = {
  input: DictFilterInput;
};

export type QueryCalculateScoringRiskLevelArgs = {
  scoring: Scalars["Float"];
};

export type QueryCardTransactionsArgs = {
  input: FindCardTransactionsInput;
};

export type QueryCardTransactionsReportArgs = {
  input: DateRangeFilterInput;
};

export type QueryCashTransactionsArgs = {
  input: FindCashTransactionsInput;
};

export type QueryCashTransactionsReportArgs = {
  input: DateRangeFilterInput;
};

export type QueryChangeMockCheckoutStatementAmountArgs = {
  amount: Scalars["Float"];
};

export type QueryCheckCryptoTransactionArgs = {
  input: CheckCryptoTransactionInput;
};

export type QueryCheckUserConfirmedInviteArgs = {
  userId: Scalars["String"];
};

export type QueryCitiesArgs = {
  input: DictFilterInput;
};

export type QueryCityByNameArgs = {
  input: CityByNameInput;
};

export type QueryCompileEmailTemplateToHtmlArgs = {
  template: TemplateName;
  variables: Scalars["JSON"];
};

export type QueryConfirmArgs = {
  input: ConfirmInput;
};

export type QueryContactsArgs = {
  input: ContactsInput;
};

export type QueryContinentsArgs = {
  input: DictFilterInput;
};

export type QueryCountriesArgs = {
  input: DictFilterInput;
};

export type QueryCryptoPortfolioArgs = {
  input: GetCryptoPortfolio;
};

export type QueryCryptoTransactionsArgs = {
  input: FindCryptoTransactionsInput;
};

export type QueryCryptoTransactionsReportArgs = {
  input: DateRangeFilterInput;
};

export type QueryCurrenciesArgs = {
  input: DictFilterInput;
};

export type QueryDevicesArgs = {
  input: DevicesInput;
};

export type QueryEarnTransactionsArgs = {
  input: FindEarnTransactionsInput;
};

export type QueryEarnTransactionsReportArgs = {
  input: DateRangeFilterInput;
};

export type QueryExchangeAuthCodeArgs = {
  input: ExchangeCodeInput;
};

export type QueryFastExchangeReceiptArgs = {
  input: FindReceiptInput;
};

export type QueryFastExchangeReceiptsArgs = {
  input: FindReceiptsInput;
};

export type QueryFileArgs = {
  id: Scalars["String"];
};

export type QueryFilesArgs = {
  filter: FileSearchFilterInput;
};

export type QueryFindCompanyArgs = {
  input: GetCompanyInput;
};

export type QueryFindCryptoTransactionsArgs = {
  input: FindTransactions;
};

export type QueryFindRatesArgs = {
  input: FindConvertRatesInput;
};

export type QueryFindUsersArgs = {
  input: FindUsersInput;
};

export type QueryGeoByAddressArgs = {
  input: GeoByAddressInput;
};

export type QueryGet2FaOperationArgs = {
  input: GetOperationInput;
};

export type QueryGet2FaOperationIdArgs = {
  input: GetOperationIdInput;
};

export type QueryGetAccountArgs = {
  input: GetAccountRequest;
};

export type QueryGetAccountByUniversalParamArgs = {
  input: GetAccountByUniversalParamInput;
};

export type QueryGetAccountsStatementArgs = {
  input: GetAccountsStatementInput;
};

export type QueryGetAddressArgs = {
  input: GetAddressRequestInput;
};

export type QueryGetAddressesArgs = {
  input: AddressesInput;
};

export type QueryGetAllUsersArgs = {
  input: GetAllUsersInput;
};

export type QueryGetAttemptAvailabilityTimeArgs = {
  input: UserCodeInfoInput;
};

export type QueryGetAuthUserArgs = {
  input: GetUserInput;
};

export type QueryGetAvailableCurrenciesArgs = {
  input: GetAvailableCurrenciesInput;
};

export type QueryGetAvailableErc20TokenForSumsubArgs = {
  input: GetAvailableErc20TokenForSumsubInput;
};

export type QueryGetAvailableErc20TokensForSumsubArgs = {
  input: GetAvailableErc20TokensForSumsubInput;
};

export type QueryGetBankArgs = {
  input: GetBankInput;
};

export type QueryGetBankDetailsArgs = {
  input: GetBankDetailsInput;
};

export type QueryGetBanksArgs = {
  input: GetBanksInput;
};

export type QueryGetBanksInfoArgs = {
  input: DictFilterInput;
};

export type QueryGetBitrixFieldsArgs = {
  bitrixEntity: BitrixEntitiesEnum;
};

export type QueryGetCardTopUpFeeArgs = {
  input: GetCardTopUpFeeInput;
};

export type QueryGetCheckoutCardTokenArgs = {
  input: GetCheckoutTokenInput;
};

export type QueryGetCityInfoArgs = {
  input: GetCityRequestPayloadInput;
};

export type QueryGetCodeExpirationTimeArgs = {
  input: UserCodeInfoInput;
};

export type QueryGetCommon2FaArgs = {
  input: Common2FaInput;
};

export type QueryGetCompaniesArgs = {
  input: GetCompaniesInput;
};

export type QueryGetCompanyArgs = {
  input: GetCompanyInput;
};

export type QueryGetContactByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetCountryByAlpha3Args = {
  alpha3: Scalars["String"];
};

export type QueryGetCountryByCodeArgs = {
  code: Scalars["String"];
};

export type QueryGetCountryInfoArgs = {
  input: GetCountryRequestPayloadInput;
};

export type QueryGetCryptoCardsBalanceAddressArgs = {
  input: GetCryptoCardBalanceAddressInput;
};

export type QueryGetCurrenciesArgs = {
  input: DictFilterInput;
};

export type QueryGetCurrenciesByTypeArgs = {
  input: GetCurrenciesByTypeRequestPayloadInput;
};

export type QueryGetCurrencyByTickerArgs = {
  input: GetCurrencyByTickerRequestPayloadInput;
};

export type QueryGetCurrencyInfoArgs = {
  input: GetCurrencyRequestPayloadInput;
};

export type QueryGetFileInfoArgs = {
  input: GetFileInput;
};

export type QueryGetIntermediariesArgs = {
  input: GetIntermediariesInput;
};

export type QueryGetIntermediaryArgs = {
  input: GetIntermediaryInput;
};

export type QueryGetLanguageByCodeArgs = {
  input: GetLanguageByCodeRequestPayloadInput;
};

export type QueryGetLanguageInfoArgs = {
  input: GetCommonDictItemRequestPayloadInput;
};

export type QueryGetLastNotificationCodesArgs = {
  userId: Scalars["String"];
};

export type QueryGetLastSendCodeArgs = {
  input: GetLastSendCodeInput;
};

export type QueryGetLeadByExternalUserIdArgs = {
  externalUserId: Scalars["String"];
};

export type QueryGetMainActivityIdsArgs = {
  companyId: Scalars["String"];
};

export type QueryGetMyCompaniesArgs = {
  input: GetMyCompaniesInput;
};

export type QueryGetOrCreateBankByIbanArgs = {
  iban: Scalars["String"];
};

export type QueryGetOwnerArgs = {
  input: GetOwnerInput;
};

export type QueryGetPaymentChargeInfoArgs = {
  input: GetPaymentChargesInput;
};

export type QueryGetPhoneNumbersInfoArgs = {
  input: GetPhoneNumberInfoInput;
};

export type QueryGetPositionInfoArgs = {
  input: GetCommonDictItemRequestPayloadInput;
};

export type QueryGetRateArgs = {
  input: GetConvertRateInput;
};

export type QueryGetRateCommissionArgs = {
  input: GetRateCommissionInput;
};

export type QueryGetRateCommissionInfoArgs = {
  input: GetRateCommissionInput;
};

export type QueryGetRatesArgs = {
  input: GetConvertRatesInput;
};

export type QueryGetRemainingAttemptsEnterCodeArgs = {
  input: UserCodeInfoInput;
};

export type QueryGetResidenceCountryIdArgs = {
  userId: Scalars["String"];
};

export type QueryGetScoringRiskLevelArgs = {
  input: GetScoringRiskLevelInput;
};

export type QueryGetScoringRiskLevelsArgs = {
  input: GetScoringRiskLevelsInput;
};

export type QueryGetTransactionFeeArgs = {
  input: GetTransactionFee;
};

export type QueryGetUserArgs = {
  userId: Scalars["String"];
};

export type QueryGetUserAddressesArgs = {
  input: GetUserAddresssesRequestInput;
};

export type QueryGetUserAddressesMicroserviceArgs = {
  id: Scalars["String"];
};

export type QueryGetUserByPersonalDataArgs = {
  input: GetUserByPersonalDataInput;
};

export type QueryGetUserCardsArgs = {
  userId: Scalars["String"];
};

export type QueryGetUserTopUpLimitsArgs = {
  userId: Scalars["String"];
};

export type QueryGetVatInfoArgs = {
  input: GetCommonDictItemRequestPayloadInput;
};

export type QueryGetVerificationPhraseArgs = {
  input: PublicKeyInput;
};

export type QueryInvitesDrawingArgs = {
  countWinners: Scalars["Int"];
};

export type QueryIsExchangeAvailableArgs = {
  input: IsExchangeAvailableInput;
};

export type QueryIsPhoneNumberExistArgs = {
  input: IsPhoneNumberExistInput;
};

export type QueryIsTransferAllowedArgs = {
  input: IsTransferAllowedRequestPayload;
};

export type QueryLanguagesArgs = {
  input: DictFilterInput;
};

export type QueryLogoutArgs = {
  input: LogoutInput;
};

export type QueryMarketFavoritesArgs = {
  action?: InputMaybe<MarketActionEnum>;
  symbol?: InputMaybe<Scalars["String"]>;
  wallet?: InputMaybe<Wallet>;
};

export type QueryMyAccountsArgs = {
  input: MyAccountsInput;
};

export type QueryMyContactsArgs = {
  input: ContactsInput;
};

export type QueryMyCryptoCardCvv2Args = {
  input: CommonGetCryptoCardSecureDataInput;
};

export type QueryMyCryptoCardNumberArgs = {
  input: CommonGetCryptoCardSecureDataInput;
};

export type QueryMyCryptoCardPinArgs = {
  input: CommonGetCryptoCardSecureDataInput;
};

export type QueryMyCryptoCardSensitiveInfoSetupArgs = {
  input: CommonGetCryptoCardSecureDataInput;
};

export type QueryMyCryptoCardsArgs = {
  input: MyCryptoCardsInput;
};

export type QueryMyGetExtraArgs = {
  key: Scalars["String"];
};

export type QueryMyPaymentsArgs = {
  input: MyPaymentsInput;
};

export type QueryNaceCodeArgs = {
  input: GetNaceCodeInput;
};

export type QueryNaceCodesArgs = {
  input: GetNaceCodesInput;
};

export type QueryNumberRangesArgs = {
  input: DictFilterInput;
};

export type QueryOauth2Args = {
  input: OAuth2Input;
};

export type QueryOrderFiltersArgs = {
  input: FindOrderFiltersInput;
};

export type QueryOrderFiltersForPairArgs = {
  input: FindOrderFiltersForPairInput;
};

export type QueryOrderPairsArgs = {
  input: FindOrderPairsInput;
};

export type QueryOrdersArgs = {
  status?: InputMaybe<OrderStatus>;
  wallet?: InputMaybe<Wallet>;
};

export type QueryPositionsArgs = {
  input: DictFilterInput;
};

export type QueryPrepareExchangeArgs = {
  input: EstimateCryptoExchangeInput;
};

export type QueryProcessCheckoutStatementArgs = {
  input: ProcessCheckoutStatementInput;
};

export type QueryRegionsArgs = {
  input: DictFilterInput;
};

export type QueryRequestsArgs = {
  input: RequestsInput;
};

export type QueryResendPaymentsToTransactionsArgs = {
  fromDaysAgo: Scalars["Float"];
};

export type QuerySearchAccountsArgs = {
  input: SearchAccountsInput;
};

export type QuerySearchCitiesArgs = {
  input: DictFilterInput;
};

export type QuerySearchCountriesArgs = {
  input: DictFilterInput;
};

export type QuerySearchTransactionsArgs = {
  input: SearchTransactionsInput;
};

export type QuerySearchWalletsArgs = {
  input: SearchWalletsInput;
};

export type QuerySendCodeArgs = {
  input: SendCodeInput;
};

export type QuerySendInviteCodeArgs = {
  email: Scalars["String"];
};

export type QueryServiceSignInArgs = {
  input: ServiceAuthInput;
};

export type QuerySignInArgs = {
  input: AuthInput;
};

export type QueryStatusesArgs = {
  input: StatusesInput;
};

export type QueryTemplatesArgs = {
  input: TemplatesInput;
};

export type QueryTestAuthArgs = {
  userId: Scalars["String"];
};

export type QueryTopicsArgs = {
  input: TopicsInput;
};

export type QueryTransactionPrintedFormArgs = {
  input: GetTransactionPrintedForm;
};

export type QueryTransferMethodsArgs = {
  input: TransferMethodInput;
};

export type QueryUnitsArgs = {
  input: DictFilterInput;
};

export type QueryUserPaymentsArgs = {
  input: UserPaymentsInput;
};

export type QueryUsersArgs = {
  id: Scalars["String"];
};

export type QueryVatsArgs = {
  input: DictFilterInput;
};

export type QueryVerifyCaptchaTokenArgs = {
  input: ReCaptchaInput;
};

export type RateCommission = {
  __typename?: "RateCommission";
  baseAssetTicker: Scalars["String"];
  id: Scalars["ID"];
  percent: Scalars["Float"];
  quoteAssetTicker: Scalars["String"];
};

export type RateCurrencyInput = {
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  tickers?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<CurrencyType>;
};

export type ReCaptchaInput = {
  captchaAction?: InputMaybe<Scalars["String"]>;
  captchaToken: Scalars["String"];
};

export enum ReasonForAccountOpening {
  BusinessMaintenance = "BUSINESS_MAINTENANCE",
  DailyExpenses = "DAILY_EXPENSES",
  FamilySupport = "FAMILY_SUPPORT",
  Investments = "INVESTMENTS",
  InvoiceSettlements = "INVOICE_SETTLEMENTS",
  Other = "OTHER",
  PaymentsForGoods = "PAYMENTS_FOR_GOODS",
  PaymentsForServices = "PAYMENTS_FOR_SERVICES",
  PaymentsForServicesAndGoods = "PAYMENTS_FOR_SERVICES_AND_GOODS",
  PaymentsToSuppliers = "PAYMENTS_TO_SUPPLIERS",
  SalaryDeposits = "SALARY_DEPOSITS",
  SalaryPayments = "SALARY_PAYMENTS",
}

export type ReasonForAccountOpeningInput = {
  other?: InputMaybe<Scalars["String"]>;
  reasons?: InputMaybe<Array<InputReasonForAccountOpening>>;
};

export type ReasonForAccountOpeningType = {
  __typename?: "ReasonForAccountOpeningType";
  other?: Maybe<Scalars["String"]>;
  reasons?: Maybe<Array<ReasonForAccountOpening>>;
};

export type ReasonForCompanyAccountOpening = {
  __typename?: "ReasonForCompanyAccountOpening";
  other: Scalars["String"];
  reason: ReasonForAccountOpening;
};

export type ReasonForCompanyAccountOpeningInput = {
  other?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<ReasonForAccountOpening>;
};

export type Receipt = {
  __typename?: "Receipt";
  exchangedSubCurrencyAmount?: Maybe<Scalars["BigNumber"]>;
  fastExchange?: Maybe<CryptoExchange>;
  id: Scalars["ID"];
  notification?: Maybe<ReceiptNotification>;
  receivedFromUserAmount?: Maybe<Scalars["BigNumber"]>;
  /** The asset which has been actually transferred from a user to the service. */
  receivedFromUserCurrency?: Maybe<Currency>;
  sentToSubCurrenciesRate?: Maybe<Scalars["BigNumber"]>;
  sentToUserAmount?: Maybe<Scalars["BigNumber"]>;
  /** The asset which has been actually transferred from the service to a user. */
  sentToUserCurrency?: Maybe<Currency>;
  /** The sold currency means asset that user pays. */
  serviceFeeInSoldCurrency?: Maybe<Scalars["BigNumber"]>;
  serviceFeeInSubCurrency?: Maybe<Scalars["BigNumber"]>;
  /** The sold currency means asset that user pays. */
  soldToSentCurrenciesRate?: Maybe<Scalars["BigNumber"]>;
  /** The sold currency means asset that user pays. */
  soldToSubCurrenciesRate?: Maybe<Scalars["BigNumber"]>;
  /** The asset that service buy/sell to proceed accrual transaction for a user. */
  subCurrency?: Maybe<Currency>;
};

export type ReceiptNotification = {
  __typename?: "ReceiptNotification";
  /** IDs of the devices that has received the notification. */
  devices: Array<Scalars["String"]>;
  id: Scalars["ID"];
  language: Scalars["String"];
  payload: Scalars["String"];
  requestId: Scalars["String"];
  topic: NotificationTopic;
  type: NotificationType;
};

export type Redirect = {
  __typename?: "Redirect";
  statusCode: Scalars["Float"];
  url: Scalars["String"];
};

export type Referrer = {
  __typename?: "Referrer";
  id: Scalars["ID"];
  inviteCode: Scalars["String"];
  referrals: Array<Maybe<Scalars["String"]>>;
};

export type ReferrersSendInvitationCreateAccountInput = {
  captcha: CaptchaInput;
  service: ServiceEnum;
  /** Email address or mobile phone number */
  to: Scalars["String"];
};

export type Region = {
  __typename?: "Region";
  country?: Maybe<Country>;
  item: CommonDictItem;
};

export type RegionInput = {
  countryId: Scalars["ID"];
  item: CommonDictItemInput;
};

export type RegisterDeviceInput = {
  identity: Scalars["String"];
  /** status (confirmed or unconfirmed) */
  status?: InputMaybe<DeviceStatusType>;
  /** topic id or name */
  topic?: InputMaybe<Scalars["String"]>;
  /** phone, email, push token */
  type: NotificationTypeEnum;
};

export type RemoveContactsInput = {
  contactIds: Array<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type RemoveDictItemsInput = {
  ids: Array<Scalars["String"]>;
};

export type RemoveMyContactsInput = {
  contactIds: Array<Scalars["ID"]>;
};

export type RepeatTransactionInput = {
  /** The ID of transaction */
  transactionId: Scalars["String"];
};

export type RepeatedExchangeTransaction = {
  __typename?: "RepeatedExchangeTransaction";
  /** Base currency amount */
  amount: Scalars["BigNumber"];
  /** Base currency token */
  base: Scalars["String"];
  /** The debit/credit fiat account used for buy/sell */
  fiatAccountId?: Maybe<Scalars["String"]>;
  /** Exchange operation */
  operation: Scalars["String"];
  /** Quote currency token */
  quote: Scalars["String"];
  serviceName: Service;
};

export type RepeatedInternalPayTransaction = {
  __typename?: "RepeatedInternalPayTransaction";
  amount: Scalars["BigNumber"];
  recipientQualifier: Scalars["String"];
  serviceName: Service;
  token: Scalars["String"];
};

export type RepeatedNeuronTransaction = {
  __typename?: "RepeatedNeuronTransaction";
  amount: Scalars["BigNumber"];
  blockchain: Scalars["String"];
  destination: Scalars["String"];
  memo?: Maybe<Scalars["String"]>;
  serviceName: Service;
  token: Scalars["String"];
};

export type RepeatedPaymentTransaction = {
  __typename?: "RepeatedPaymentTransaction";
  amount: Scalars["BigNumber"];
  credit: CashPaymentAccount;
  currencyId: Scalars["String"];
  debit: CashPaymentAccount;
  serviceName: Service;
};

export type RepeatedTransaction =
  | RepeatedExchangeTransaction
  | RepeatedInternalPayTransaction
  | RepeatedNeuronTransaction
  | RepeatedPaymentTransaction;

export type Report = {
  __typename?: "Report";
  url: Scalars["String"];
};

export type Request = {
  __typename?: "Request";
  common: CommonItemInfo;
  id: Scalars["ID"];
  requestHeaders: Scalars["JsonObjectScalar"];
  /** ID of http request passed into x-request-id header */
  requestId: Scalars["ID"];
  requestPayload: Scalars["JsonObjectScalar"];
  /** Request type according to Wallester API methods */
  requestType: RequestTypeEnum;
  responseHeaders: Scalars["JsonObjectScalar"];
  responsePayload: Scalars["JsonObjectScalar"];
  /** Status of processing of received request */
  status: RequestStatusEnum;
  /**
   * Counter for tryes to requests API, using by schedule worker for limiting requests
   *         for NOT_PROCESSED records. Default is 1, maximum is 3
   */
  tryCount: Scalars["Int"];
};

export enum RequestStatusEnum {
  NotProcessed = "NOT_PROCESSED",
  NotValidated = "NOT_VALIDATED",
  Processed = "PROCESSED",
  ProcessedWithErrors = "PROCESSED_WITH_ERRORS",
}

export enum RequestTypeEnum {
  ActivateCard = "ACTIVATE_CARD",
  BlockCard = "BLOCK_CARD",
  ChangeCardPin = "CHANGE_CARD_PIN",
  CloseCard = "CLOSE_CARD",
  CreateAccount = "CREATE_ACCOUNT",
  CreateCard = "CREATE_CARD",
  CreatePerson = "CREATE_PERSON",
  EnrolCardFor_3DSecure = "ENROL_CARD_FOR_3D_SECURE",
  ExtractFiles = "EXTRACT_FILES",
  GetAccountByExternalId = "GET_ACCOUNT_BY_EXTERNAL_ID",
  GetCardByExternalId = "GET_CARD_BY_EXTERNAL_ID",
  GetCardHistory = "GET_CARD_HISTORY",
  GetCardLimitsUsageOrUpdateCardLimits = "GET_CARD_LIMITS_USAGE_OR_UPDATE_CARD_LIMITS",
  GetEncryptedCardNumber = "GET_ENCRYPTED_CARD_NUMBER",
  GetEncryptedCvv2 = "GET_ENCRYPTED_CVV2",
  GetEncryptedPin = "GET_ENCRYPTED_PIN",
  GetPersonByExternalId = "GET_PERSON_BY_EXTERNAL_ID",
  Ping = "PING",
  ReplaceCard = "REPLACE_CARD",
  UnblockCard = "UNBLOCK_CARD",
  Update_3DSecureSettings = "UPDATE_3D_SECURE_SETTINGS",
  UpdateCardName = "UPDATE_CARD_NAME",
  UpdateCardSecurity = "UPDATE_CARD_SECURITY",
}

export type RequestsInput = {
  filter?: InputMaybe<Filter>;
  /** Case-insensitive search for requestPayload, responsePayload, requestHeader, responseHeader fields */
  itemQuery?: InputMaybe<Scalars["String"]>;
  /** Maximal request to API tryes */
  maxTryCount?: InputMaybe<Scalars["Int"]>;
  /** Minimal request to API tryes */
  minTryCount?: InputMaybe<Scalars["Int"]>;
  /** Filter by rabbitmq message id  */
  requestId?: InputMaybe<Scalars["String"]>;
  /** Filter by request methods */
  requestTypes?: InputMaybe<Array<RequestTypeEnum>>;
  /** Sort records by created at date */
  sortOrder?: InputMaybe<SortOrder>;
  /** Filter by request processing status */
  status?: InputMaybe<RequestStatusEnum>;
  /** If true, soft deleted records will be shown */
  withDeleted?: InputMaybe<Scalars["Boolean"]>;
};

export type ResponseError = {
  __typename?: "ResponseError";
  code: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  message: Scalars["String"];
  stack?: Maybe<Scalars["String"]>;
};

export enum ResponseType {
  Code = "CODE",
}

export type RestorePasswordInput = {
  code: Scalars["String"];
  device: Device;
  identity: Scalars["String"];
  newPassword: Scalars["String"];
  service: Service;
};

export type RetrieveWalletsInput = {
  /** The coin ticker */
  asset: Scalars["String"];
  /** The blockchain main coin ticker */
  blockchain: Scalars["String"];
  userIds: Array<Scalars["ID"]>;
};

export enum RiskLevelEnum {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export enum RoleEnum {
  Admin = "ADMIN",
  AmlOfficer = "AML_OFFICER",
  Moderator = "MODERATOR",
  Service = "SERVICE",
  Super = "SUPER",
  User = "USER",
}

export enum SmsProviderEnum {
  Twilio = "TWILIO",
  Vonage = "VONAGE",
}

export type ScoringRiskLevel = {
  __typename?: "ScoringRiskLevel";
  id: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
  scoringRiskLevel: ScoringRiskLevelEnum;
};

export enum ScoringRiskLevelEnum {
  HighRisk = "HIGH_RISK",
  LowRisk = "LOW_RISK",
  MediumRisk = "MEDIUM_RISK",
  ProhibitedRisk = "PROHIBITED_RISK",
  VeryHighRisk = "VERY_HIGH_RISK",
}

export type ScoringRiskLevels = {
  __typename?: "ScoringRiskLevels";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<ScoringRiskLevel>;
};

export type SearchAccountsInput = {
  accountNumbers?: InputMaybe<Array<Scalars["String"]>>;
  companyIds?: InputMaybe<Array<Scalars["ID"]>>;
  currencyIds?: InputMaybe<Array<Scalars["ID"]>>;
  currencyTickers?: InputMaybe<Array<Scalars["String"]>>;
  filter?: InputMaybe<Filter>;
  holderType?: InputMaybe<AccountHolderType>;
  likeConditions?: InputMaybe<Array<LikeConditionInput>>;
  states?: InputMaybe<Array<AccountState>>;
  type?: InputMaybe<AccountType>;
  userIds?: InputMaybe<Array<Scalars["ID"]>>;
  walletIds?: InputMaybe<Array<Scalars["String"]>>;
  withNonZeroBalances?: InputMaybe<Scalars["Boolean"]>;
};

export type SearchTransactionsInput = {
  dateFilter?: InputMaybe<DateRangeFilterInput>;
  filter?: InputMaybe<Filter>;
  sendFromService?: InputMaybe<Array<Service>>;
  transactionIds?: InputMaybe<Array<Scalars["String"]>>;
  userIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type SearchWalletsInput = {
  addresses?: InputMaybe<Array<Scalars["String"]>>;
  blockchains?: InputMaybe<Array<Scalars["String"]>>;
  filter?: InputMaybe<Filter>;
  memos?: InputMaybe<Array<Scalars["String"]>>;
  tokens?: InputMaybe<Array<Scalars["String"]>>;
  userIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type Send2FaTokenInput = {
  operationId?: InputMaybe<Scalars["ID"]>;
  operationTitle?: InputMaybe<Scalars["String"]>;
  /** Serialized JSON containing variables to be inserted into an email template */
  templateDataJson?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<TfaType>;
};

export type Send2FaTokenResponse = {
  __typename?: "Send2FATokenResponse";
  emailRetryAfter?: Maybe<Scalars["DateTime"]>;
  operationId: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  response: Array<TfaType>;
  smsRetryAfter?: Maybe<Scalars["DateTime"]>;
};

export enum SendCode {
  ChangeEmail = "CHANGE_EMAIL",
  ChangePhone = "CHANGE_PHONE",
  Forgotpassword = "FORGOTPASSWORD",
  Registration = "REGISTRATION",
  RegistrationPhone = "REGISTRATION_PHONE",
}

export type SendCodeInput = {
  captcha: ReCaptchaInput;
  device: Device;
  identity: Scalars["String"];
  service: Service;
  type?: InputMaybe<SendCode>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type SendCompanyVerficationInput = {
  companyId: Scalars["ID"];
  docIds: Array<Scalars["ID"]>;
};

export type SendInput = {
  devices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["String"]>;
  messenger?: InputMaybe<MessengerInput>;
  /** json with escaped quotes: { "message": "Hello World!", "subject": "this is subject", "variables": { "title": "some title", ... } } */
  payload: Scalars["String"];
  service?: InputMaybe<Service>;
  templateId?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<TopicSendOptionsInput>;
  type: NotificationTypeEnum;
  typeServiceForSendingSms?: InputMaybe<SmsProviderEnum>;
};

export type SendMyVerificationInput = {
  /** Ids of user identity documents that should be sent for KYC procedure */
  docIds: Array<Scalars["ID"]>;
};

export type SendPaymentInput = {
  paymentId: Scalars["ID"];
  tfaType?: InputMaybe<TfaType>;
};

export type SendVerificationInput = {
  /** Ids of user identity documents that should be sent for KYC procedure */
  docIds: Array<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export enum Service {
  Banxeid = "BANXEID",
  Cards = "CARDS",
  Cex = "CEX",
  Cryptoapi = "CRYPTOAPI",
  CryptoProcessing = "CRYPTO_PROCESSING",
  Dcard = "DCARD",
  Defi = "DEFI",
  DefiInvest = "DEFI_INVEST",
  FastExchange = "FAST_EXCHANGE",
  Hermesus = "HERMESUS",
  Neuronex = "NEURONEX",
  Neuronwallet = "NEURONWALLET",
  P2P = "P2P",
  Tompay = "TOMPAY",
  TransactionService = "TRANSACTION_SERVICE",
}

export type ServiceAuthInput = {
  device: Device;
  identity: Scalars["String"];
  password: Scalars["String"];
  service: Service;
};

export enum ServiceEnum {
  Banxeid = "BANXEID",
  Cards = "CARDS",
  Cex = "CEX",
  Cryptoapi = "CRYPTOAPI",
  CryptoProcessing = "CRYPTO_PROCESSING",
  Dcard = "DCARD",
  Defi = "DEFI",
  DefiInvest = "DEFI_INVEST",
  FastExchange = "FAST_EXCHANGE",
  Hermesus = "HERMESUS",
  Neuronex = "NEURONEX",
  Neuronwallet = "NEURONWALLET",
  P2P = "P2P",
  Tompay = "TOMPAY",
  TransactionService = "TRANSACTION_SERVICE",
}

export type Session = {
  __typename?: "Session";
  createdAt: Scalars["DateTime"];
  geoLocation?: Maybe<Scalars["String"]>;
  ipAddress: Scalars["String"];
  refreshToken: Scalars["String"];
  userAgent: Scalars["String"];
  userId: Scalars["String"];
};

export type SetCardTopUpConfigInput = {
  clientEeaFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  clientNonEeaFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  clientUkFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  corporateClientTransitContractId?: InputMaybe<Scalars["String"]>;
  corporateInwardPaymentsIncomeContractId?: InputMaybe<Scalars["String"]>;
  dailyLimit?: InputMaybe<Scalars["Float"]>;
  defaultFee?: InputMaybe<Scalars["BigNumber"]>;
  defaultFeeMargin?: InputMaybe<Scalars["BigNumber"]>;
  feePercentage?: InputMaybe<Scalars["BigNumber"]>;
  minimalLimit?: InputMaybe<Scalars["Float"]>;
  monthlyLimit?: InputMaybe<Scalars["Float"]>;
  otherDirectCostsContractId?: InputMaybe<Scalars["String"]>;
  otherReceivablesContractId?: InputMaybe<Scalars["String"]>;
  ownEeaFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  ownNonEeaFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  ownUkFinancialInstitutionsContractId?: InputMaybe<Scalars["String"]>;
  payablesSettlementContractId?: InputMaybe<Scalars["String"]>;
  physicalClientTransitContractId?: InputMaybe<Scalars["String"]>;
  physicalInwardPaymentsIncomeContractId?: InputMaybe<Scalars["String"]>;
  provisionContractId?: InputMaybe<Scalars["String"]>;
  receivablesFromCustomersContractId?: InputMaybe<Scalars["String"]>;
  transactionLimit?: InputMaybe<Scalars["Float"]>;
};

/** When configuring up settings for the first time you must provide all values */
export type SetCryptoCard3DSecuritySettingsInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /** Currently only ENG is supported  */
  languageCode: LanguageCodeEnum;
  /**
   * Masked value will be returned once password is set
   * Must be 8-36 characters long
   * No other constraints applied
   */
  password: Scalars["String"];
  /** Must be a valid number, only digits with "+" prefix allowed */
  phone: Scalars["String"];
  /** Currently only SMSOTP_AND_STATIC_PASSWORD is supported */
  type: Card3DSecureTypeEnum;
};

export type SetExchangeCommissionInput = {
  commissionMultiplier: Scalars["BigNumber"];
  userId: Scalars["ID"];
};

export type SetExtraConfigInput = {
  /** Max length of extra array */
  extraCountLimit: Scalars["Int"];
  /** Max size of extra array in bytes */
  extraSizeLimit: Scalars["Int"];
};

export type SetFilePrivacyInput = {
  /** file id */
  id: Scalars["String"];
  /** set if file must be private or not. If value is true accessToken for file will be refreshed */
  value: Scalars["Boolean"];
};

export type SetSumsubRequiredDocConfigInput = {
  /** Flow levelName from https://test-api.sumsub.com/checkus#/sdkIntegrations/levels or https://api.sumsub.com/checkus#/sdkIntegrations/levels */
  companyLevelName: Scalars["String"];
  /** Which documents types are required */
  docSets: Array<DocSetTypeInput>;
  /** Flow levelName from https://test-api.sumsub.com/checkus#/sdkIntegrations/levels or https://api.sumsub.com/checkus#/sdkIntegrations/levels */
  levelName: Scalars["String"];
};

export enum SideEnum {
  Buy = "BUY",
  Sell = "SELL",
}

/** Side order */
export enum SideOrder {
  /** Buy pair */
  Buy = "BUY",
  /** Sell pair */
  Sell = "SELL",
}

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type SourceInfo = {
  __typename?: "SourceInfo";
  bin: Scalars["String"];
  cardCategory: Scalars["String"];
  cardType: Scalars["String"];
  expiryMonth: Scalars["Int"];
  expiryYear: Scalars["Int"];
  issuer: Scalars["String"];
  issuerCountry: Scalars["String"];
  last4: Scalars["String"];
  paymentId: Scalars["ID"];
  productId: Scalars["String"];
  productType: Scalars["String"];
  scheme: Scalars["String"];
  type: Scalars["String"];
};

export enum SourceOfFunds {
  Investments = "INVESTMENTS",
  Loan = "LOAN",
  Other = "OTHER",
  RevenueFromSales = "REVENUE_FROM_SALES",
}

export type StartKybInput = {
  /** Business activity license */
  activityLicense?: InputMaybe<KybActiveLicenseInput>;
  /** Annual company turnover */
  annual?: InputMaybe<CompanyAnnual>;
  /** Is any of the beneficial owners listed above is a US person? */
  beneficialUSDetails?: InputMaybe<Scalars["String"]>;
  /** Beneficial owners, private persons who ultimately, directly or indirectly own or control 25 % or more of the shares property of foundation of the voting rights of a legal company, owners and founders of the company, other beneficiaries */
  beneficials?: InputMaybe<Array<KybBeneficialInput>>;
  /** Explanation of the business model */
  businessModelExplanation?: InputMaybe<Scalars["String"]>;
  companyCorrespondenceAddress?: InputMaybe<Scalars["String"]>;
  /** Country of residence for tax purposes. id from banxe-dictionary */
  countryForTaxId?: InputMaybe<Scalars["ID"]>;
  /** Create a crypto account ? */
  createCryptoAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Create a fiat account ? */
  createFiatAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Information about the customer representative */
  customerRepresentative?: InputMaybe<KybCustomerRepresentativeInput>;
  /** Is any of the representatives of the company listed above is a US citizen? */
  customerRepresentativeUSDetails?: InputMaybe<Scalars["String"]>;
  dateOfRegistration?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  /** Legal persons, where Customer owns or controls 25 % or more of the shares */
  legalPersonsOver25?: InputMaybe<Array<KybLegalPersonInput>>;
  /** Main business activity (NACE code(s)) */
  mainBusinessActivityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Main Business partners */
  mainBusinessPartners?: InputMaybe<Array<KybMainBusinessPartnersInput>>;
  /** All persons do not own or control 25 % or more of the shares property of foundation of the voting rights of a legal company */
  notAllPersonsOwn25?: InputMaybe<Scalars["Boolean"]>;
  /** Is any of the beneficial owners listed above considered to be a PEP? */
  pepBeneficials?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the beneficial owners listed above considered to be a close family member or close associate of the PEP? */
  pepBeneficialsFamilyMember?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the representatives of the company listed above considered to be a PEP? */
  pepCustomerRepresentatives?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the representatives of the company listed above considered to be a close family member or close associate of the PEP? */
  pepCustomerRepresentativesFamilyMember?: InputMaybe<Array<KybPepInput>>;
  /** Telephone number with country code */
  phoneNumber?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Array<PlannedAccountUse>>;
  reasonsForAccountOpening?: InputMaybe<Array<InputReasonForAccountOpening>>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  /** Company establishment and other supporting documents */
  supportingDocuments?: InputMaybe<KybSupportingDocumentsInput>;
  /** Taxpayer identification number (TIN) */
  tin?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
  webSite?: InputMaybe<Scalars["String"]>;
};

export type StartKybNewInput = {
  /** Business activity license */
  activityLicense?: InputMaybe<KybActiveLicenseInput>;
  /** Annual company turnover */
  annual?: InputMaybe<CompanyAnnual>;
  /** Is any of the beneficial owners listed above is a US person? */
  beneficialUSDetails?: InputMaybe<Scalars["String"]>;
  /** Beneficial owners, private persons who ultimately, directly or indirectly own or control 25 % or more of the shares property of foundation of the voting rights of a legal company, owners and founders of the company, other beneficiaries */
  beneficials?: InputMaybe<Array<KybBeneficialInput>>;
  /** Explanation of the business model */
  businessModelExplanation?: InputMaybe<Scalars["String"]>;
  companyCorrespondenceAddress?: InputMaybe<Scalars["String"]>;
  /** Country of residence for tax purposes. id from banxe-dictionary */
  countryForTaxId?: InputMaybe<Scalars["ID"]>;
  /** Create a crypto account ? */
  createCryptoAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Create a fiat account ? */
  createFiatAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Information about the customer representative */
  customerRepresentative?: InputMaybe<KybCustomerRepresentativeInput>;
  /** Is any of the representatives of the company listed above is a US citizen? */
  customerRepresentativeUSDetails?: InputMaybe<Scalars["String"]>;
  dateOfRegistration?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  /** Legal persons, where Customer owns or controls 25 % or more of the shares */
  legalPersonsOver25?: InputMaybe<Array<KybLegalPersonInput>>;
  /** Main business activity (NACE code(s)) */
  mainBusinessActivityIds: Array<Scalars["ID"]>;
  /** Main Business partners */
  mainBusinessPartners?: InputMaybe<Array<KybMainBusinessPartnersInput>>;
  /** All persons do not own or control 25 % or more of the shares property of foundation of the voting rights of a legal company */
  notAllPersonsOwn25?: InputMaybe<Scalars["Boolean"]>;
  /** Is any of the beneficial owners listed above considered to be a PEP? */
  pepBeneficials?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the beneficial owners listed above considered to be a close family member or close associate of the PEP? */
  pepBeneficialsFamilyMember?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the representatives of the company listed above considered to be a PEP? */
  pepCustomerRepresentatives?: InputMaybe<Array<KybPepInput>>;
  /** Is any of the representatives of the company listed above considered to be a close family member or close associate of the PEP? */
  pepCustomerRepresentativesFamilyMember?: InputMaybe<Array<KybPepInput>>;
  /** Telephone number with country code */
  phoneNumber?: InputMaybe<Scalars["String"]>;
  plannedAccountUse?: InputMaybe<Array<PlannedAccountUse>>;
  reasonsForAccountOpening?: InputMaybe<Array<InputReasonForAccountOpening>>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  /** Company establishment and other supporting documents */
  supportingDocuments?: InputMaybe<KybSupportingDocumentsInput>;
  /** Taxpayer identification number (TIN) */
  tin?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
  webSite?: InputMaybe<Scalars["String"]>;
};

export type StartTopUpBalanceByCardInput = {
  card: CardDataInput;
  checkoutId?: InputMaybe<Scalars["String"]>;
  checkoutPaymentType: CheckoutPaymentType;
  checkoutToken?: InputMaybe<Scalars["String"]>;
  creditAccountNumber: Scalars["String"];
  currencyId: Scalars["String"];
  failureUrl?: InputMaybe<Scalars["String"]>;
  fee: Scalars["BigNumber"];
  operationId?: InputMaybe<Scalars["String"]>;
  successUrl?: InputMaybe<Scalars["String"]>;
  totalAmount: Scalars["BigNumber"];
};

export type Statement = {
  __typename?: "Statement";
  url: Scalars["String"];
};

export enum StatusEnum {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  Unconfirmed = "UNCONFIRMED",
}

export type StatusesInput = {
  filter?: InputMaybe<Filter>;
  topicId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<NotificationTypeEnum>;
};

export type SumsubAccessToken = {
  __typename?: "SumsubAccessToken";
  token: Scalars["String"];
  userId: Scalars["String"];
};

export type SumsubRequiredDocsConfig = {
  __typename?: "SumsubRequiredDocsConfig";
  /** LevelName of flow from https://test-api.sumsub.com/checkus#/sdkIntegrations/levels or https://api.sumsub.com/checkus#/sdkIntegrations/levels */
  companyLevelName: Scalars["String"];
  /** Which documents types are required */
  docSets: Array<DocSet>;
  /** LevelName of flow from https://test-api.sumsub.com/checkus#/sdkIntegrations/levels or https://api.sumsub.com/checkus#/sdkIntegrations/levels */
  levelName: Scalars["String"];
};

export type SynchronizePaymentsInput = {
  /** Date time to start payment synchronization from. If not set, the value will be taken from cache. */
  startDateTime?: InputMaybe<Scalars["DateTime"]>;
};

export type TfaClearAll2FaInput = {
  recoveryCode: Scalars["String"];
};

export type TfaInput = {
  type: TfaType;
};

export type TfaOperation = {
  __typename?: "TFAOperation";
  title: Scalars["String"];
  verificationTypes: Array<TfaType>;
};

export type TfaOperationId = {
  __typename?: "TFAOperationId";
  emailRetryAfter?: Maybe<Scalars["DateTime"]>;
  operation: TfaOperation;
  smsRetryAfter?: Maybe<Scalars["DateTime"]>;
  status: OperationIdStatus;
};

export type TfaResponse = {
  __typename?: "TFAResponse";
  emailRetryAfter?: Maybe<Scalars["DateTime"]>;
  operationId: Scalars["String"];
  response: Scalars["String"];
  smsRetryAfter?: Maybe<Scalars["DateTime"]>;
};

export enum TfaType {
  Email = "EMAIL",
  Otp = "OTP",
  Sms = "SMS",
}

export enum TfaTypeEnum {
  Email = "EMAIL",
  Otp = "OTP",
  Sms = "SMS",
}

export type Template = {
  __typename?: "Template";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  /** hi {email}! welcome to our site! */
  template: Scalars["String"];
  templateEN: Scalars["String"];
  templateRU: Scalars["String"];
  type?: Maybe<NotificationTypeEnum>;
};

/** Translation languages for email templates */
export enum TemplateLanguage {
  En = "EN",
  Ru = "RU",
}

/** Names of html templates for emails */
export enum TemplateName {
  AccountCredited = "ACCOUNT_CREDITED",
  CashWithdrawalIban = "CASH_WITHDRAWAL_IBAN",
  CashWithdrawalSortcode = "CASH_WITHDRAWAL_SORTCODE",
  CashWithdrawalSwift = "CASH_WITHDRAWAL_SWIFT",
  ChangePassword = "CHANGE_PASSWORD",
  CheckBuy = "CHECK_BUY",
  CheckExchange = "CHECK_EXCHANGE",
  CheckSell = "CHECK_SELL",
  CryptoWithdrawal = "CRYPTO_WITHDRAWAL",
  Disable_2Factor = "DISABLE_2FACTOR",
  InviteButton = "INVITE_BUTTON",
  InviteCode = "INVITE_CODE",
  InviteLink = "INVITE_LINK",
  NewDevice = "NEW_DEVICE",
  QuestionFromSupport = "QUESTION_FROM_SUPPORT",
  ResetPassword = "RESET_PASSWORD",
  TechnicalSupport = "TECHNICAL_SUPPORT",
  TransactionFailed = "TRANSACTION_FAILED",
  UnableAccountVerify = "UNABLE_ACCOUNT_VERIFY",
  Verify_2Factor = "VERIFY_2FACTOR",
  VerifyComplete = "VERIFY_COMPLETE",
  Welcome = "WELCOME",
}

export type TemplatesInput = {
  filter?: InputMaybe<Filter>;
  query?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<NotificationTypeEnum>;
};

export type Timestamps = {
  __typename?: "Timestamps";
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type Timezone = {
  __typename?: "Timezone";
  item: CommonDictItem;
  /** The time offset */
  offset: Scalars["Int"];
  offsetDst: Scalars["Int"];
};

export enum TokenTypeEnum {
  Email = "EMAIL",
  Otp = "OTP",
  Sms = "SMS",
}

export type TokenTypeInput = {
  token: Scalars["String"];
  type: TokenTypeEnum;
};

export type TokensForApprovalTransaction = {
  token: Scalars["String"];
  type: TfaTypeEnum;
};

export type Topic = {
  __typename?: "Topic";
  devices: Array<Maybe<DeviceInfo2>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  namespace: Scalars["String"];
};

export type TopicDevicesArgs = {
  input: TopicsInput;
};

export type TopicSendOptionsInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  namespace?: InputMaybe<Scalars["String"]>;
  templateName?: InputMaybe<Scalars["String"]>;
};

export type TopicsInput = {
  filter?: InputMaybe<Filter>;
  namespace?: InputMaybe<Scalars["String"]>;
  query?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<NotificationTypeEnum>;
};

export type TopupAddressInfo = {
  __typename?: "TopupAddressInfo";
  address: Scalars["String"];
  blockchain: Scalars["String"];
  tagOrMemo?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
};

/** Return balance: coins, usd ao */
export type TradeBalance = {
  __typename?: "TradeBalance";
  coins: Array<Maybe<TradeBalanceDetail>>;
  usd?: Maybe<Scalars["Float"]>;
};

/** Return balance details by coin */
export type TradeBalanceDetail = {
  __typename?: "TradeBalanceDetail";
  amount?: Maybe<Scalars["Float"]>;
  available?: Maybe<Scalars["Float"]>;
  btcValue?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["String"]>;
  locked?: Maybe<Scalars["Float"]>;
};

/** For FUTURE trading requests */
export type TradeFuture = {
  __typename?: "TradeFuture";
  /** Return info by current positions */
  accountInformationPositions: Array<Maybe<FuturePosition>>;
  /** Return info by symbol for current user */
  accountInformationTotal?: Maybe<FutureTotal>;
  /** Return info by all orders */
  allOrders: Array<Maybe<TradeFutureOrder>>;
  /** Cancel all active orders */
  cancelAllOrder?: Maybe<Scalars["Boolean"]>;
  /** Cancel order by pair and orderId */
  cancelOrder?: Maybe<Scalars["Boolean"]>;
  /** Change current leverage */
  changeLeverage?: Maybe<TradeLeverage>;
  /** Change current margin */
  changeMargin?: Maybe<Scalars["Boolean"]>;
  /** Return info by chart marks */
  chartMarks: Array<Maybe<ChartMark>>;
  /** Create order by request */
  createOrder?: Maybe<Scalars["Boolean"]>;
  /** Set/get listen key */
  listenKey?: Maybe<Scalars["String"]>;
  /** Change margin */
  modifyMargin?: Maybe<Scalars["Boolean"]>;
  /** Return info by opened orders */
  openOrders: Array<Maybe<TradeFutureOrder>>;
  /** Return info by pair */
  pairData?: Maybe<TradePair>;
  /** Return info by all pairs */
  pairsSummary: Array<Maybe<TradePair>>;
  /** Return info by history of transactions */
  transactionsHistory: Array<Maybe<TradeTransaction>>;
  /** Return info by user trades */
  userTrades: Array<Maybe<TradeOfUser>>;
};

/** For FUTURE trading requests */
export type TradeFutureAccountInformationPositionsArgs = {
  filters?: InputMaybe<Filters>;
  symbol: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureAccountInformationTotalArgs = {
  symbol: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureAllOrdersArgs = {
  filters?: InputMaybe<Filters>;
  pair: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureCancelAllOrderArgs = {
  orderIdList: Array<InputMaybe<Scalars["String"]>>;
  symbolList: Array<InputMaybe<Scalars["String"]>>;
};

/** For FUTURE trading requests */
export type TradeFutureCancelOrderArgs = {
  orderId: Scalars["String"];
  pair: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureChangeLeverageArgs = {
  leverage: Scalars["Int"];
  pair: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureChangeMarginArgs = {
  pair: Scalars["String"];
  type?: InputMaybe<ChangeMarginEnum>;
};

/** For FUTURE trading requests */
export type TradeFutureChartMarksArgs = {
  filters?: InputMaybe<Filters>;
  pair: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureCreateOrderArgs = {
  activationPrice?: InputMaybe<Scalars["Float"]>;
  callbackRate?: InputMaybe<Scalars["String"]>;
  pair: Scalars["String"];
  position?: InputMaybe<PositionOrder>;
  price?: InputMaybe<Scalars["Float"]>;
  quantity: Scalars["Float"];
  side?: InputMaybe<SideOrder>;
  stopPrice?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<TypeFutureOrder>;
  workingType?: InputMaybe<WorkingFutureOrder>;
};

/** For FUTURE trading requests */
export type TradeFutureListenKeyArgs = {
  key?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<TypeListenKey>;
};

/** For FUTURE trading requests */
export type TradeFutureModifyMarginArgs = {
  amount: Scalars["String"];
  pair: Scalars["String"];
  side: Scalars["String"];
  type: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureOpenOrdersArgs = {
  filters?: InputMaybe<Filters>;
  pair?: InputMaybe<Scalars["String"]>;
};

/** For FUTURE trading requests */
export type TradeFuturePairDataArgs = {
  pair: Scalars["String"];
};

/** For FUTURE trading requests */
export type TradeFutureTransactionsHistoryArgs = {
  filters?: InputMaybe<Filters>;
  pair?: InputMaybe<Scalars["String"]>;
};

/** For FUTURE trading requests */
export type TradeFutureUserTradesArgs = {
  filters?: InputMaybe<Filters>;
  pair: Scalars["String"];
};

/** Return future order */
export type TradeFutureOrder = {
  __typename?: "TradeFutureOrder";
  activationPrice?: Maybe<Scalars["Float"]>;
  avgPrice?: Maybe<Scalars["Float"]>;
  callbackRate?: Maybe<Scalars["Float"]>;
  clientOrderId?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["String"]>;
  pair?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  qtyExecuted?: Maybe<Scalars["Float"]>;
  qtyOrig?: Maybe<Scalars["Float"]>;
  reduceOnly?: Maybe<Scalars["Boolean"]>;
  side?: Maybe<Scalars["String"]>;
  sidePosition?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stopPrice?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Float"]>;
  timeInForce?: Maybe<Scalars["String"]>;
  trades: Array<Maybe<Scalars["String"]>>;
  type?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["Float"]>;
};

/** Return info about leverage */
export type TradeLeverage = {
  __typename?: "TradeLeverage";
  leverage?: Maybe<Scalars["Int"]>;
  maxNotionalValue?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
};

/** Return trade of user */
export type TradeOfUser = {
  __typename?: "TradeOfUser";
  commission?: Maybe<OrderCommission>;
  orderId?: Maybe<Scalars["String"]>;
  pair?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  qtyQuote?: Maybe<Scalars["Float"]>;
  realizedPnl?: Maybe<Scalars["Float"]>;
  side?: Maybe<Scalars["String"]>;
  sidePosition?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Float"]>;
  tradeId?: Maybe<Scalars["String"]>;
};

/** Return info about pair */
export type TradePair = {
  __typename?: "TradePair";
  assetBase?: Maybe<Scalars["String"]>;
  assetQuote?: Maybe<Scalars["String"]>;
  exchangeId?: Maybe<Scalars["String"]>;
  filters?: Maybe<TradePairFilters>;
  leverage: Array<Maybe<TradePairLeverage>>;
  pair?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  ticker?: Maybe<TradePairTicker>;
};

/** Return info about pair filters */
export type TradePairFilters = {
  __typename?: "TradePairFilters";
  multipleDecimal?: Maybe<Scalars["Float"]>;
  multipleDown?: Maybe<Scalars["Float"]>;
  multipleUp?: Maybe<Scalars["Float"]>;
  notionalMin?: Maybe<Scalars["Float"]>;
  priceMax?: Maybe<Scalars["Float"]>;
  priceMin?: Maybe<Scalars["Float"]>;
  qtyMax?: Maybe<Scalars["Float"]>;
  qtyMin?: Maybe<Scalars["Float"]>;
  sizeStep?: Maybe<Scalars["Float"]>;
  sizeTicker?: Maybe<Scalars["Float"]>;
};

/** Return info about pair filters */
export type TradePairLeverage = {
  __typename?: "TradePairLeverage";
  bracket?: Maybe<Scalars["Int"]>;
  cum?: Maybe<Scalars["Int"]>;
  initial?: Maybe<Scalars["Int"]>;
  marginMaintRatio?: Maybe<Scalars["Float"]>;
  notionalCap?: Maybe<Scalars["Float"]>;
  notionalFloor?: Maybe<Scalars["Int"]>;
};

/** Return info about pair ticker */
export type TradePairTicker = {
  __typename?: "TradePairTicker";
  askLowest?: Maybe<Scalars["String"]>;
  bidHighest?: Maybe<Scalars["String"]>;
  perDayHigh?: Maybe<Scalars["Float"]>;
  perDayLow?: Maybe<Scalars["Float"]>;
  priceChange?: Maybe<Scalars["Float"]>;
  priceChangePercent?: Maybe<Scalars["Float"]>;
  priceLast?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  volumeBase?: Maybe<Scalars["Float"]>;
  volumeQuote?: Maybe<Scalars["Float"]>;
};

/** For SPOT trading requests */
export type TradeSpot = {
  __typename?: "TradeSpot";
  /** Return info by active orders */
  activeOrders: Array<Maybe<TradeSpotOrder>>;
  /** Cancel order by pair and orderId */
  cancelOrder?: Maybe<Scalars["Boolean"]>;
  /** Create order by request */
  createOrder?: Maybe<Scalars["Boolean"]>;
  /** Return info by current pair */
  currentPair?: Maybe<PairOrder>;
  /** Set/get listen key */
  listenKey?: Maybe<Scalars["String"]>;
  /** Return info by balance each coin */
  loadBalances?: Maybe<TradeBalance>;
  /** Return info by orders from history */
  loadHistory: Array<Maybe<TradeSpotOrder>>;
  /** Return info by all trades */
  loadTrades: Array<Maybe<TradeTrade>>;
  /** Return info by pair group */
  pairsGroup?: Maybe<PairGroup>;
};

/** For SPOT trading requests */
export type TradeSpotActiveOrdersArgs = {
  filters?: InputMaybe<Filters>;
  search?: InputMaybe<Scalars["String"]>;
  symbol?: InputMaybe<Scalars["String"]>;
};

/** For SPOT trading requests */
export type TradeSpotCancelOrderArgs = {
  orderId: Scalars["String"];
  pair: Scalars["String"];
};

/** For SPOT trading requests */
export type TradeSpotCreateOrderArgs = {
  amount: Scalars["Float"];
  baseAmount: Scalars["Float"];
  price: Scalars["Float"];
  side?: InputMaybe<SideOrder>;
  stopPrice?: InputMaybe<Scalars["Float"]>;
  symbol: Scalars["String"];
  type?: InputMaybe<TypeSpotOrder>;
};

/** For SPOT trading requests */
export type TradeSpotCurrentPairArgs = {
  symbol: Scalars["String"];
};

/** For SPOT trading requests */
export type TradeSpotListenKeyArgs = {
  key?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<TypeListenKey>;
};

/** For SPOT trading requests */
export type TradeSpotLoadBalancesArgs = {
  filters?: InputMaybe<Filters>;
};

/** For SPOT trading requests */
export type TradeSpotLoadHistoryArgs = {
  filters?: InputMaybe<Filters>;
  symbol: Scalars["String"];
};

/** For SPOT trading requests */
export type TradeSpotLoadTradesArgs = {
  filters?: InputMaybe<Filters>;
  pair: Scalars["String"];
};

/** Return spot order */
export type TradeSpotOrder = {
  __typename?: "TradeSpotOrder";
  amount?: Maybe<Scalars["Float"]>;
  commission?: Maybe<OrderCommission>;
  created?: Maybe<Scalars["Float"]>;
  direction?: Maybe<Scalars["String"]>;
  executed?: Maybe<Scalars["Float"]>;
  firstCurrency?: Maybe<Scalars["String"]>;
  formatDate?: Maybe<Scalars["String"]>;
  isHandle?: Maybe<Scalars["Boolean"]>;
  orderId?: Maybe<Scalars["String"]>;
  pair?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  secondCurrency?: Maybe<Scalars["String"]>;
  stopPrice?: Maybe<Scalars["Float"]>;
  take_profit?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Return order */
export type TradeTrade = {
  __typename?: "TradeTrade";
  amount?: Maybe<Scalars["Float"]>;
  direction?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["String"]>;
};

/** Return info about transaction */
export type TradeTransaction = {
  __typename?: "TradeTransaction";
  asset?: Maybe<Scalars["String"]>;
  income?: Maybe<Scalars["Float"]>;
  info?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Float"]>;
  tradeId?: Maybe<Scalars["String"]>;
  tranId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Income type of transaction */
export enum Transaction {
  /** All types */
  All = "ALL",
  /** Commission */
  Commission = "COMMISSION",
  /** Fees rebate */
  CommissionRebate = "COMMISSION_REBATE",
  /** Funding fee */
  FundingFee = "FUNDING_FEE",
  /** Insurance clear */
  InsuranceClear = "INSURANCE_CLEAR",
  /** Realized PNL */
  RealizedPnl = "REALIZED_PNL",
  /** Transfer */
  Transfer = "TRANSFER",
  /** Welcome bonus */
  WelcomeBonus = "WELCOME_BONUS",
}

export enum TransactionCurrencyTypeEnum {
  Crypto = "CRYPTO",
  Fiat = "FIAT",
}

export enum TransactionDirection {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export type TransactionObject = {
  __typename?: "TransactionObject";
  amount: Scalars["BigNumber"];
  blockchain: NetworkList;
  createdAt: Scalars["DateTime"];
  destination: Scalars["String"];
  destinationOwner?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  operationId: Scalars["ID"];
  source: Scalars["String"];
  sourceOwner?: Maybe<Scalars["String"]>;
  status: TransactionStatusEnum;
  token: CoinsInfo;
  transactionFee?: Maybe<Scalars["BigNumber"]>;
  transactionHash?: Maybe<Scalars["String"]>;
  /** Incoming or outgoing or transfer transactions. */
  type: DirectionTypeEnum;
  updatedAt: Scalars["DateTime"];
};

export type TransactionPrintedForm = {
  __typename?: "TransactionPrintedForm";
  downloadUrl: Scalars["String"];
};

export enum TransactionStatusEnum {
  Confirmed = "CONFIRMED",
  Failed = "FAILED",
  New = "NEW",
  Orphaned = "ORPHANED",
  Pending = "PENDING",
  Resent = "RESENT",
  Unconfirmed = "UNCONFIRMED",
}

export type TransactionWithRepeatable = {
  __typename?: "TransactionWithRepeatable";
  isRepeatable: Scalars["Boolean"];
  transaction: ProfileTransaction;
};

export type TransactionsWithRepeatable = {
  __typename?: "TransactionsWithRepeatable";
  count: Scalars["Int"];
  nodes: Array<TransactionWithRepeatable>;
};

/** Return transfer */
export type Transfer = {
  __typename?: "Transfer";
  amount?: Maybe<Scalars["Float"]>;
  date?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  transId?: Maybe<Scalars["String"]>;
};

export type TransferMethod = {
  __typename?: "TransferMethod";
  country?: Maybe<Country>;
  item: CommonDictItem;
  types: Array<TransferMethodType>;
};

export type TransferMethodInput = {
  countries?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  countryId?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<Filter>;
  itemQuery?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tickers?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<CurrencyType>;
  types?: InputMaybe<Array<InputMaybe<TransferMethodType>>>;
};

export enum TransferMethodType {
  BankTransfer = "BANK_TRANSFER",
  DebitCreditCard = "DEBIT_CREDIT_CARD",
  GiftCard = "GIFT_CARD",
  OnlineWallet = "ONLINE_WALLET",
  OtherOptions = "OTHER_OPTIONS",
}

export type TwilioLookupCallerNameType = {
  __typename?: "TwilioLookupCallerNameType";
  callerName: Scalars["String"];
  callerType: Scalars["String"];
  errorCode?: Maybe<Scalars["Float"]>;
};

export type TwilioLookupCarrierType = {
  __typename?: "TwilioLookupCarrierType";
  errorCode?: Maybe<Scalars["Float"]>;
  mobileCountryCode: Scalars["String"];
  mobileNetworkCode: Scalars["String"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type TwilioLookupErrorType = {
  __typename?: "TwilioLookupErrorType";
  code: Scalars["Float"];
  message: Scalars["String"];
  moreInfo: Scalars["String"];
  phoneNumber: Scalars["String"];
  status: Scalars["Float"];
};

export type TwilioLookupResultType = {
  __typename?: "TwilioLookupResultType";
  callerName?: Maybe<TwilioLookupCallerNameType>;
  carrier?: Maybe<TwilioLookupCarrierType>;
  countryCode: Scalars["String"];
  nationalFormat: Scalars["String"];
  phoneNumber: Scalars["String"];
  url: Scalars["String"];
};

/** Type future order */
export enum TypeFutureOrder {
  Limit = "LIMIT",
  Market = "MARKET",
  Stop = "STOP",
  StopMarket = "STOP_MARKET",
  TakeProfit = "TAKE_PROFIT",
  TakeProfitMarket = "TAKE_PROFIT_MARKET",
  TrailingStopMarket = "TRAILING_STOP_MARKET",
}

/** Listen key */
export enum TypeListenKey {
  /** Get listen key */
  Get = "GET",
  /** Keep current key */
  Keep = "KEEP",
}

/** Type spot order */
export enum TypeSpotOrder {
  Limit = "LIMIT",
  LimitMaker = "LIMIT_MAKER",
  Market = "MARKET",
  StopLossLimit = "STOP_LOSS_LIMIT",
  TakeProfitLimit = "TAKE_PROFIT_LIMIT",
}

export type Unit = {
  __typename?: "Unit";
  /** [Dictionary] e.g. name: Kilogram, code: KG */
  item: CommonDictItem;
};

export type UnitInput = {
  code: Scalars["String"];
  name: Scalars["String"];
};

export type UnlockUserOperationInput = {
  codeType: SendCode;
  identity: Scalars["String"];
  service: Service;
  userId?: InputMaybe<Scalars["String"]>;
};

export type UpdateAddressInput = {
  buildingName?: InputMaybe<Scalars["String"]>;
  buildingNumber?: InputMaybe<Scalars["String"]>;
  /** id of city from dictionary-microservice */
  cityId?: InputMaybe<Scalars["ID"]>;
  /** additional comment */
  comment?: InputMaybe<Scalars["String"]>;
  flatNumber?: InputMaybe<Scalars["String"]>;
  formattedStringValue?: InputMaybe<Scalars["String"]>;
  geo: GeoLocationInput;
  id?: InputMaybe<Scalars["String"]>;
  /** should be true if current address is primary for user */
  isPrimary: Scalars["Boolean"];
  postalCode?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  subStreet?: InputMaybe<Scalars["String"]>;
};

export type UpdateAddressInput2 = {
  address: UpdateAddressInput;
  userId: Scalars["ID"];
};

export type UpdateBalanceInput = {
  accountId: Scalars["ID"];
  available?: InputMaybe<Scalars["BigNumber"]>;
  full?: InputMaybe<Scalars["BigNumber"]>;
  held?: InputMaybe<Scalars["BigNumber"]>;
};

export type UpdateBankInput = {
  /** [Dictionary] Represents bank data */
  bank?: InputMaybe<BankInput>;
  /** [Dictionary] Unique identified */
  id: Scalars["ID"];
  /** [Dictionary] Desired state of bank object */
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateCityInput = {
  city?: InputMaybe<CityInput>;
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateCompanyDocumentsInput = {
  companyId: Scalars["ID"];
  documents: Array<CompanyIdentityDocInput>;
};

export type UpdateContinentInput = {
  continent?: InputMaybe<ContinentInput>;
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateCountryInput = {
  country?: InputMaybe<CountryInput>;
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
};

/** Values that were not provided will not be updated */
export type UpdateCryptoCard3DSecuritySettingsInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /** Currently only ENG is supported */
  languageCode?: InputMaybe<LanguageCodeEnum>;
  /**
   * Masked value will be returned once password is set
   * Must be 8-36 characters long
   * No other constraints applied
   */
  password?: InputMaybe<Scalars["String"]>;
  /** Must be a valid number, only digits with "+" prefix allowed */
  phone?: InputMaybe<Scalars["String"]>;
  /** Currently only SMSOTP_AND_STATIC_PASSWORD is supported */
  type?: InputMaybe<Card3DSecureTypeEnum>;
};

/**
 * Values that were not provided will be ignored
 * Values range: 0-500000
 */
export type UpdateCryptoCardLimitsInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  dailyContactlessPurchase?: InputMaybe<Scalars["Int"]>;
  dailyInternetPurchase?: InputMaybe<Scalars["Int"]>;
  dailyOverallPurchase?: InputMaybe<Scalars["Int"]>;
  dailyPurchase?: InputMaybe<Scalars["Int"]>;
  dailyWithdrawal?: InputMaybe<Scalars["Int"]>;
  monthlyContactlessPurchase?: InputMaybe<Scalars["Int"]>;
  monthlyInternetPurchase?: InputMaybe<Scalars["Int"]>;
  monthlyOverallPurchase?: InputMaybe<Scalars["Int"]>;
  monthlyPurchase?: InputMaybe<Scalars["Int"]>;
  monthlyWithdrawal?: InputMaybe<Scalars["Int"]>;
  transactionContactlessPurchase?: InputMaybe<Scalars["Int"]>;
  transactionInternetPurchase?: InputMaybe<Scalars["Int"]>;
  transactionPurchase?: InputMaybe<Scalars["Int"]>;
  transactionWithdrawal?: InputMaybe<Scalars["Int"]>;
  weeklyContactlessPurchase?: InputMaybe<Scalars["Int"]>;
  weeklyInternetPurchase?: InputMaybe<Scalars["Int"]>;
  weeklyOverallPurchase?: InputMaybe<Scalars["Int"]>;
  weeklyPurchase?: InputMaybe<Scalars["Int"]>;
  weeklyWithdrawal?: InputMaybe<Scalars["Int"]>;
};

/** Specify plain pin as text */
export type UpdateCryptoCardPinInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  /** Should be 4 characters long */
  pin: Scalars["String"];
};

/** Values that were not provided will not be updated */
export type UpdateCryptoCardSecurityInput = {
  /** Card unique identifier */
  cardId: Scalars["ID"];
  contactlessEnabled?: InputMaybe<Scalars["Boolean"]>;
  internetPurchaseEnabled?: InputMaybe<Scalars["Boolean"]>;
  overallLimitsEnabled?: InputMaybe<Scalars["Boolean"]>;
  withdrawalEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateCurrencyInput = {
  currency?: InputMaybe<CurrencyInput>;
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateDocumentsInput = {
  documents: Array<UserIdentityDocInput>;
  userId: Scalars["ID"];
};

export type UpdateExchangeInfoInput = {
  owner: ExchangeService;
  rawData: Scalars["String"];
};

export type UpdateExtraInput = {
  data: Scalars["JSON"];
  key: Scalars["String"];
};

export type UpdateIntermediaryInput = {
  accountTypes?: InputMaybe<Array<InternalAccountType>>;
  beneficiaryAccountNumber?: InputMaybe<Scalars["String"]>;
  beneficiaryAddress?: InputMaybe<Scalars["String"]>;
  beneficiaryBIC?: InputMaybe<Scalars["String"]>;
  beneficiaryBank?: InputMaybe<Scalars["String"]>;
  beneficiaryIBAN?: InputMaybe<Scalars["String"]>;
  beneficiaryName?: InputMaybe<Scalars["String"]>;
  beneficiarySortCode?: InputMaybe<Scalars["String"]>;
  companyCountriesAlpha3?: InputMaybe<Array<Scalars["String"]>>;
  currenciesTickers?: InputMaybe<Array<Scalars["String"]>>;
  forbiddenNACECodes?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  userCountriesAlpha3?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateLanguageInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageInput>;
};

export type UpdateMyAddressInput = {
  address: UpdateAddressInput;
};

export type UpdateMyCompanyQuestionnaireInput = {
  NACElvl1?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  NACElvl2?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  actualAddress?: InputMaybe<Scalars["ID"]>;
  annualTurnover?: InputMaybe<Scalars["ID"]>;
  businessCountryIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  businessModelExplanation?: InputMaybe<Scalars["String"]>;
  companyId: Scalars["ID"];
  legalAddress?: InputMaybe<Scalars["ID"]>;
  numberOfEmployees?: InputMaybe<Scalars["ID"]>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  plannedAccountUse?: InputMaybe<PlannedAccountUse>;
  reasonForAccountOpening?: InputMaybe<ReasonForCompanyAccountOpeningInput>;
  sourceOfFund?: InputMaybe<CompanySourceOfFundsInput>;
  taxResidenceCountryId?: InputMaybe<Scalars["ID"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateMyContactInput = {
  contactInfo: Array<InputMaybe<ContactInput>>;
};

export type UpdateMyDocumentsInput = {
  documents: Array<UserIdentityDocInput>;
};

export type UpdateMyPersonalInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  gender?: InputMaybe<Gender>;
  isFamilyMemberPep?: InputMaybe<Scalars["Boolean"]>;
  isPep?: InputMaybe<Scalars["Boolean"]>;
  isUsa?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<FullNameInput>;
  nationality?: InputMaybe<Scalars["String"]>;
  nationalityCountryId?: InputMaybe<Scalars["ID"]>;
  placeOfBirthAddressId?: InputMaybe<Scalars["ID"]>;
  placeOfBirthCountryId?: InputMaybe<Scalars["ID"]>;
  plannedAccountUse?: InputMaybe<Array<PlannedAccountUse>>;
  reasonForAccountOpening?: InputMaybe<ReasonForAccountOpeningInput>;
  residenceCountryId?: InputMaybe<Scalars["ID"]>;
  termsAndConditions?: InputMaybe<Scalars["Boolean"]>;
  tin?: InputMaybe<Scalars["String"]>;
};

export type UpdateNaceCodeDataInput = {
  code?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  section?: InputMaybe<UpdateNaceCodeSectionDataInput>;
};

export type UpdateNaceCodeInput = {
  id: Scalars["String"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  naceCode: UpdateNaceCodeDataInput;
};

export type UpdateNaceCodeSectionDataInput = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateNumberRangeInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  numberRange?: InputMaybe<NumberRangeInput>;
};

export type UpdateOperationInput = {
  title?: InputMaybe<Scalars["String"]>;
  titleToFind: Scalars["String"];
  verificationTypes?: InputMaybe<Array<TfaType>>;
};

export type UpdatePaymentInput = {
  amount?: InputMaybe<Scalars["BigNumber"]>;
  attachmentId?: InputMaybe<Scalars["ID"]>;
  category?: InputMaybe<PaymentCategory>;
  credit?: InputMaybe<PaymentAccountInput>;
  currencyId?: InputMaybe<Scalars["ID"]>;
  debit?: InputMaybe<PaymentAccountInput>;
  id: Scalars["ID"];
  note?: InputMaybe<Scalars["String"]>;
  schedule?: InputMaybe<Scalars["DateTime"]>;
  type?: InputMaybe<PaymentType>;
  vat?: InputMaybe<Scalars["BigNumber"]>;
};

export type UpdatePersonalInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  gender?: InputMaybe<Gender>;
  isFamilyMemberPep?: InputMaybe<Scalars["Boolean"]>;
  isPep?: InputMaybe<Scalars["Boolean"]>;
  isUsa?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<FullNameInput>;
  nationality?: InputMaybe<Scalars["String"]>;
  nationalityCountryId?: InputMaybe<Scalars["ID"]>;
  placeOfBirthAddressId?: InputMaybe<Scalars["ID"]>;
  placeOfBirthCountryId?: InputMaybe<Scalars["ID"]>;
  plannedAccountUse?: InputMaybe<Array<PlannedAccountUse>>;
  reasonForAccountOpening?: InputMaybe<ReasonForAccountOpeningInput>;
  residenceCountryId?: InputMaybe<Scalars["ID"]>;
  termsAndConditions?: InputMaybe<Scalars["Boolean"]>;
  tin?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type UpdatePositionInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  position?: InputMaybe<PositionInput>;
};

export type UpdateRegionInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  region?: InputMaybe<RegionInput>;
};

export type UpdateScoringRiskLevelInput = {
  id: Scalars["String"];
  max?: InputMaybe<Scalars["Int"]>;
  min?: InputMaybe<Scalars["Int"]>;
  /** LOW_RISK, MEDIUM_RISK, HIGH_RISK, VERY_HIGH_RISK, PROHIBITED_RISK */
  scoringRiskLevel?: InputMaybe<ScoringRiskLevelEnum>;
};

export type UpdateTemplateInput = {
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  template?: InputMaybe<Scalars["String"]>;
  templateId: Scalars["ID"];
  type?: InputMaybe<NotificationTypeEnum>;
};

export type UpdateTopicInput = {
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  namespace?: InputMaybe<Scalars["String"]>;
  topicId: Scalars["String"];
};

export type UpdateUnitInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  unit?: InputMaybe<UnitInput>;
};

export type UpdateUserScoringInput = {
  userId: Scalars["String"];
};

export type UpdateVatInput = {
  id: Scalars["ID"];
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  /** [Dictionary] VAT is Value Added Tax number/code of company or person */
  vat?: InputMaybe<VatInput>;
};

export type UploadSesion = {
  __typename?: "UploadSesion";
  /** unix time */
  expiresAt: Scalars["Float"];
  id: Scalars["ID"];
  /** to be implemented */
  serviceName: Scalars["String"];
  /** shows if uploading was successful. If session expired it is still 'created' status */
  status: UploadSessionStatus;
  /** file must be uploaded on this url by POST request via form-data */
  url: Scalars["String"];
  /** session creator */
  user: User;
};

export enum UploadSessionStatus {
  Created = "created",
  Successful = "successful",
}

export type User = {
  __typename?: "User";
  accounts?: Maybe<Array<Maybe<Account>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  avatar: File;
  avatarId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  documents?: Maybe<Array<Maybe<UserIdentityDoc>>>;
  id: Scalars["ID"];
  kycStep?: Maybe<Scalars["Float"]>;
  personal: UserPersonal;
  role: RoleEnum;
  service: Service;
  status: StatusEnum;
  updatedAt: Scalars["DateTime"];
  userId: Scalars["String"];
  verificationStatus: UserVerificationStatus;
};

export type UserAccountsArgs = {
  input: MyAccountsInput;
};

/** Get data about user from CEX server */
export type UserCex = {
  __typename?: "UserCEX";
  balance?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  lang?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type UserCard = {
  __typename?: "UserCard";
  bin: Scalars["String"];
  cardCategory?: Maybe<Scalars["String"]>;
  cardType?: Maybe<Scalars["String"]>;
  checkoutId: Scalars["String"];
  expiryMonth: Scalars["Float"];
  expiryYear: Scalars["Float"];
  fingerprint: Scalars["String"];
  id: Scalars["ID"];
  issuer?: Maybe<Scalars["String"]>;
  issuerCountry?: Maybe<Scalars["String"]>;
  last4: Scalars["String"];
  productId?: Maybe<Scalars["String"]>;
  productType?: Maybe<Scalars["String"]>;
  scheme?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  userId: Scalars["String"];
};

export type UserCodeInfoInput = {
  codeType: SendCode;
  identity: Scalars["String"];
  service: Service;
};

export type UserFull = {
  __typename?: "UserFull";
  EOS?: Maybe<PublicKeyInfo>;
  avatar?: Maybe<File>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  companies: Array<CompanyPayloadAuthType>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<DeviceInfo>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<GenderEnum>;
  lastName?: Maybe<Scalars["String"]>;
  leadId?: Maybe<Scalars["String"]>;
  p256?: Maybe<PublicKeyInfo>;
  phone?: Maybe<DeviceInfo>;
  role: Scalars["String"];
  service: Service;
  status: StatusEnum;
  updatedAt: Scalars["DateTime"];
  userId: Scalars["String"];
  username?: Maybe<Scalars["String"]>;
};

export type UserFullCompaniesArgs = {
  input?: InputMaybe<GetCompaniesFilterInput>;
};

export type UserIdentityDoc = {
  __typename?: "UserIdentityDoc";
  doc: IdentityDoc;
  /** Front or back side of document. Null if only one side of document is uploaded. */
  subType?: Maybe<UserIdentitySubType>;
  type: UserIdentityType;
};

export type UserIdentityDocInput = {
  /** Country where code was created. ISO 3166-1 alpha-3 */
  country: Scalars["String"];
  /** Id of file from files-microservice. Make sure that it is available for current user */
  fileId: Scalars["ID"];
  /** Front or back side of document. Complete this only if both sides of document are uploading, otherwise send null */
  subType?: InputMaybe<UserIdentitySubType>;
  /** Type of document */
  type: UserIdentityType;
};

export enum UserIdentitySubType {
  BackSide = "BACK_SIDE",
  FrontSide = "FRONT_SIDE",
}

export enum UserIdentityType {
  Agreement = "AGREEMENT",
  BankCard = "BANK_CARD",
  BankStatement = "BANK_STATEMENT",
  Contract = "CONTRACT",
  Drivers = "DRIVERS",
  DriversTranslation = "DRIVERS_TRANSLATION",
  EmploymentCertificate = "EMPLOYMENT_CERTIFICATE",
  IdCard = "ID_CARD",
  IdDocPhoto = "ID_DOC_PHOTO",
  IncomeSource = "INCOME_SOURCE",
  InvestorDoc = "INVESTOR_DOC",
  Other = "OTHER",
  Passport = "PASSPORT",
  PaymentMethod = "PAYMENT_METHOD",
  ProfileImage = "PROFILE_IMAGE",
  ResidencePermit = "RESIDENCE_PERMIT",
  Selfie = "SELFIE",
  UtilityBill = "UTILITY_BILL",
  VehicleRegistrationCertificate = "VEHICLE_REGISTRATION_CERTIFICATE",
  VideoSelfie = "VIDEO_SELFIE",
}

export type UserInput = {
  avatarId?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  captcha: ReCaptchaInput;
  code: Scalars["String"];
  device: Device;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<GenderEnum>;
  identity: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  realEmail?: InputMaybe<Scalars["String"]>;
  /** User ID of the inviting user */
  referralCode?: InputMaybe<Scalars["String"]>;
  /** Region client (e.g. DE, US, CH) */
  region?: InputMaybe<Scalars["String"]>;
  service: Service;
  username?: InputMaybe<Scalars["String"]>;
};

export type UserPaymentsInput = {
  accountIds?: InputMaybe<Array<Scalars["String"]>>;
  dateRange?: InputMaybe<DateRangeInput>;
  filter?: InputMaybe<Filter>;
  sortBy?: InputMaybe<CommonSort>;
  sortOrder?: InputMaybe<SortOrder>;
  userId: Scalars["String"];
};

export type UserPersonal = {
  __typename?: "UserPersonal";
  adverseMedia?: Maybe<Scalars["Boolean"]>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  isFamilyMemberPep?: Maybe<Scalars["Boolean"]>;
  isPep?: Maybe<Scalars["Boolean"]>;
  isUsa?: Maybe<Scalars["Boolean"]>;
  modeKyc?: Maybe<ModeKyc>;
  name?: Maybe<FullName>;
  nationality?: Maybe<Scalars["String"]>;
  nationalityCountry?: Maybe<Country>;
  phone?: Maybe<Scalars["String"]>;
  placeOfBirth?: Maybe<Country>;
  plannedAccountUse?: Maybe<Array<PlannedAccountUse>>;
  reasonForAccountOpening?: Maybe<ReasonForAccountOpeningType>;
  residenceCountry?: Maybe<Country>;
  sanctionList?: Maybe<Scalars["Boolean"]>;
  scoring?: Maybe<Scalars["Float"]>;
  scoringRiskLevel?: Maybe<ScoringRiskLevelEnum>;
  termsAndConditions?: Maybe<Scalars["Boolean"]>;
  tin?: Maybe<Scalars["String"]>;
};

export type UserScoringDetailsType = {
  __typename?: "UserScoringDetailsType";
  code: Scalars["String"];
  value: Scalars["Float"];
};

export type UserScoringType = {
  __typename?: "UserScoringType";
  details: Array<UserScoringDetailsType>;
  total: Scalars["Float"];
};

export enum UserTariff {
  BanxePlus = "BANXE_PLUS",
}

export type UserTopUpLimits = {
  __typename?: "UserTopUpLimits";
  dailyAvailable: Scalars["BigNumber"];
  dailyLimit: Scalars["Float"];
  minimalLimit: Scalars["Float"];
  monthlyAvailable: Scalars["BigNumber"];
  monthlyLimit: Scalars["Float"];
  monthlyLimitResetDate?: Maybe<Scalars["String"]>;
  transactionLimit: Scalars["Float"];
  userId: Scalars["String"];
};

export enum UserTypeEnum {
  Company = "COMPANY",
  Individual = "INDIVIDUAL",
}

export enum UserVerificationStatus {
  Blocked = "BLOCKED",
  Error = "ERROR",
  Pending = "PENDING",
  Rejected = "REJECTED",
  RejectedRetry = "REJECTED_RETRY",
  Successful = "SUCCESSFUL",
  WaitingForRequiredDocuments = "WAITING_FOR_REQUIRED_DOCUMENTS",
}

export type Users = {
  __typename?: "Users";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<User>;
};

export type UsersFull = {
  __typename?: "UsersFull";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<UserFull>;
};

export type Vat = {
  __typename?: "VAT";
  id: Scalars["ID"];
  item: CommonDictItem;
};

export type ValidateBankBicAndSwiftCodesInput = {
  filter?: InputMaybe<Filter>;
};

export type ValidateBankBicAndSwiftCodesResult = {
  __typename?: "ValidateBankBicAndSwiftCodesResult";
  bankBIC?: Maybe<Scalars["String"]>;
  bankId: Scalars["String"];
  bankName: Scalars["String"];
  bankSwift?: Maybe<Scalars["String"]>;
  isValidBIC: Scalars["Boolean"];
  isValidSwift: Scalars["Boolean"];
  sameColumns: Scalars["Boolean"];
};

export type ValidateBankBicAndSwiftCodesResults = {
  __typename?: "ValidateBankBicAndSwiftCodesResults";
  count: Scalars["Int"];
  cursor: Scalars["Int"];
  nodes: Array<ValidateBankBicAndSwiftCodesResult>;
};

export type VatInput = {
  code: Scalars["String"];
  name: Scalars["String"];
};

export type VerificationMessage = {
  __typename?: "VerificationMessage";
  messages: Array<Scalars["String"]>;
  type: VerificationMessageType;
};

export enum VerificationMessageType {
  Green = "GREEN",
  Red = "RED",
  RedFinal = "RED_FINAL",
  Sent = "SENT",
}

export type VerificationPhrase = {
  __typename?: "VerificationPhrase";
  /** phrase expiration time */
  expire: Scalars["DateTime"];
  /** stringify phrase value */
  phrase: Scalars["String"];
  /** phrase that signed by server */
  serverSign: Scalars["String"];
};

export type VerificationPhraseInput = {
  format: KeyEnum;
  /** stringify phrase value */
  phrase: Scalars["String"];
  /** publicKey for auth user */
  publicKey: Scalars["String"];
  /** phrase that signed by server */
  serverSign: Scalars["String"];
  service: Service;
  /** phrase that signed by client */
  sign: Scalars["String"];
  /** token for PUSH notification */
  token?: InputMaybe<Scalars["String"]>;
};

export type VerificationTokens = {
  token: Scalars["String"];
  type: TfaType;
};

export type Verify2FaInput = {
  operationId: Scalars["String"];
  tokens: Array<VerifyTokenInput>;
};

export type VerifyTokenInput = {
  token: Scalars["String"];
  type: TfaType;
};

/** Wallet type */
export enum Wallet {
  /** Future wallet */
  Future = "FUTURE",
  /** Spot wallet */
  Spot = "SPOT",
}

/** Actions for wallets SPOT/FUTURE */
export type WalletCommon = {
  __typename?: "WalletCommon";
  /** Make transfer between SPOT-FUTURE */
  transfer?: Maybe<Scalars["Boolean"]>;
};

/** Actions for wallets SPOT/FUTURE */
export type WalletCommonTransferArgs = {
  amount: Scalars["Float"];
  token: Scalars["String"];
  type?: InputMaybe<WalletExchange>;
};

/** Wallet exchange type */
export enum WalletExchange {
  /** Exchange to future */
  ToFutures = "TO_FUTURES",
  /** Exchange to spot */
  ToSpot = "TO_SPOT",
}

/** Actions for wallet FUTURE */
export type WalletFuture = {
  __typename?: "WalletFuture";
  /** Return all balance positions */
  balancePositions: Array<Maybe<FuturePosition>>;
  /** Return total margins & balances */
  balanceTotal?: Maybe<FutureTotal>;
  /** Return info by each coin */
  coins: Array<Maybe<Coin>>;
  /** Require brocker API_KEY & API_SECRET_KEY. Must be moved to Virtual ABS */
  fees: Scalars["String"];
  /** Return list of transfers */
  transfer: Array<Maybe<Transfer>>;
};

/** Actions for wallet FUTURE */
export type WalletFutureBalancePositionsArgs = {
  filters?: InputMaybe<Filters>;
};

/** Actions for wallet FUTURE */
export type WalletFutureTransferArgs = {
  filters?: InputMaybe<Filters>;
};

export type WalletObject = {
  __typename?: "WalletObject";
  /** Recharge address. */
  address: Scalars["String"];
  balance?: Maybe<BalanceObject>;
  /** The blockchain. */
  blockchain: NetworkList;
  /** The blockchain id. */
  blockchainId: Scalars["ID"];
  /** The ID of a wallet. */
  id: Scalars["ID"];
  /** Memo tag for address. */
  memo?: Maybe<Scalars["String"]>;
  /** The crypto currency. */
  token: CoinsInfo;
  /** The crypto currency id. */
  tokenId: Scalars["ID"];
  /** The owner Id. */
  userId: Scalars["ID"];
};

/** Return order */
export type WalletOrder = {
  __typename?: "WalletOrder";
  /** SPOT [ ] || FUTURE [ OPEN | HISTORY ] */
  activationPrice?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  amount?: Maybe<Scalars["Float"]>;
  /** SPOT [ ] || FUTURE [ TRANSACTIONS ] */
  asset?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  avgPrice?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  baseAsset?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ OPEN | HISTORY ] */
  callbackRate?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  clientOrderId?: Maybe<Scalars["String"]>;
  /** SPOT [ TRADE ] || FUTURE [ TRADE ] */
  commission?: Maybe<OrderCommission>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  formatDate?: Maybe<Scalars["String"]>;
  /** SPOT [ TRADE ] || FUTURE [ ] */
  id?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ TRANSACTIONS ] */
  income?: Maybe<Scalars["Float"]>;
  /** SPOT [ ] || FUTURE [ TRANSACTIONS ] */
  incomeType?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ TRANSACTIONS ] */
  info?: Maybe<Scalars["String"]>;
  /** SPOT [ TRADE ] || FUTURE [ ] */
  isBestMatch?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ TRADE ] || FUTURE [ ] */
  isBuyer?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ TRADE ] || FUTURE [ ] */
  isMaker?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ OPEN ] || FUTURE [ ] */
  isManual?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ ] */
  isWorking?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  orderId?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ ] */
  orderListId?: Maybe<Scalars["Int"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  pair?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  price?: Maybe<Scalars["Float"]>;
  /** SPOT [ TRADE ] || FUTURE [ TRADE ] */
  qty?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ ] */
  qtyCummulativeQuote?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  qtyExecuted?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ ] */
  qtyIceberg?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  qtyOrig?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ ] */
  qtyOrigQuoteOrder?: Maybe<Scalars["Float"]>;
  /** SPOT [ TRADE ] || FUTURE [ TRADE ] */
  qtyQuote?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  quoteAsset?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ TRADE ] */
  realizedPnl?: Maybe<Scalars["Float"]>;
  /** SPOT [ ] || FUTURE [ OPEN | HISTORY ] */
  reduceOnly?: Maybe<Scalars["Boolean"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  side?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ OPEN | HISTORY | TRADE ] */
  sidePosition?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  status?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  stopPrice?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE | TRANSACTIONS ] */
  symbol?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN ] || FUTURE [ ] */
  tickerSize?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY | TRADE ] || FUTURE [ OPEN | HISTORY | TRADE | TRANSACTIONS ] */
  time?: Maybe<Scalars["Float"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  timeInForce?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ TRADE | TRANSACTIONS ] */
  tradeId?: Maybe<Scalars["String"]>;
  /** SPOT [ ] || FUTURE [ TRANSACTIONS ] */
  tranId?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  type?: Maybe<Scalars["String"]>;
  /** SPOT [ OPEN | HISTORY ] || FUTURE [ OPEN | HISTORY ] */
  updateTime?: Maybe<Scalars["Float"]>;
};

/** Actions for wallet FUTURE */
export type WalletOrders = {
  __typename?: "WalletOrders";
  /** Return list of orders */
  orderList: Array<Maybe<WalletOrder>>;
};

/** Actions for wallet FUTURE */
export type WalletOrdersOrderListArgs = {
  dateFilters?: InputMaybe<DateFilters>;
  filters?: InputMaybe<Filters>;
  income?: InputMaybe<Transaction>;
  pair: Scalars["String"];
};

/** Actions for wallet SPOT */
export type WalletSpot = {
  __typename?: "WalletSpot";
  /** Return balance by all coins */
  balance: Array<Maybe<CurrencyCex>>;
  /** Return info by each coin */
  coins: Array<Maybe<Coin>>;
  /** Return list of deposits */
  deposit: Array<Maybe<Deposit>>;
  /** Require brocker API_KEY & API_SECRET_KEY. Must be moved to Virtual ABS */
  fees: Scalars["String"];
  /** Return list of networks */
  networks: Array<Maybe<AssetNetwork>>;
  /** Return balance by all coins as value on BTC */
  totalBTC?: Maybe<Scalars["Float"]>;
  /** Return balance by all coins as value on USDT */
  totalUSDT?: Maybe<Scalars["Float"]>;
  /** Return list of withdrawals */
  withdrawal: Array<Maybe<Withdrawal>>;
};

/** Actions for wallet SPOT */
export type WalletSpotBalanceArgs = {
  filters?: InputMaybe<Filters>;
};

/** Actions for wallet SPOT */
export type WalletSpotDepositArgs = {
  filters?: InputMaybe<Filters>;
  token?: InputMaybe<Scalars["String"]>;
};

/** Actions for wallet SPOT */
export type WalletSpotNetworksArgs = {
  token: Scalars["String"];
};

/** Actions for wallet SPOT */
export type WalletSpotWithdrawalArgs = {
  filters?: InputMaybe<Filters>;
  token?: InputMaybe<Scalars["String"]>;
};

export type WalletsObject = {
  __typename?: "WalletsObject";
  count: Scalars["Int"];
  nodes: Array<WalletObject>;
};

export type WithdrawInput = {
  riskLevel?: InputMaybe<RiskLevelEnum>;
  token: Scalars["String"];
  type: TransactionCurrencyTypeEnum;
};

/** Return withdrawal */
export type Withdrawal = {
  __typename?: "Withdrawal";
  address?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  created?: Maybe<Scalars["String"]>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  memo?: Maybe<Scalars["String"]>;
  network?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  transferId?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["String"]>;
  withdrawalId?: Maybe<Scalars["String"]>;
};

/** Working future order */
export enum WorkingFutureOrder {
  ContractPrice = "CONTRACT_PRICE",
}

export type ConfirmSignInMutationVariables = Exact<{
  input: AuthVerify2FaInput;
}>;

export type ConfirmSignInMutation = {
  __typename?: "Mutation";
  confirmSignIn: {
    __typename?: "Auth";
    accessToken: string;
    refreshToken: string;
  };
};

export type CreateBankMutationVariables = Exact<{
  input: CreateBankInput;
}>;

export type CreateBankMutation = {
  __typename?: "Mutation";
  createBank: { __typename?: "Bank"; id: string };
};

export type CreateCityMutationVariables = Exact<{
  input: CreateCityInput;
}>;

export type CreateCityMutation = {
  __typename?: "Mutation";
  createCity: { __typename?: "City"; id: string };
};

export type CreateContinentMutationVariables = Exact<{
  input: CreateContinentInput;
}>;

export type CreateContinentMutation = {
  __typename?: "Mutation";
  createContinent: {
    __typename?: "Continent";
    item: { __typename?: "CommonDictItem"; id: string };
  };
};

export type CreateCountryMutationVariables = Exact<{
  input: CreateCountryInput;
}>;

export type CreateCountryMutation = {
  __typename?: "Mutation";
  createCountry: { __typename?: "Country"; id: string };
};

export type CreateCurrencyMutationVariables = Exact<{
  input: CreateCurrencyInput;
}>;

export type CreateCurrencyMutation = {
  __typename?: "Mutation";
  createCurrency: { __typename?: "Currency"; ticker: string };
};

export type CreateLanguageMutationVariables = Exact<{
  input: CreateLanguageInput;
}>;

export type CreateLanguageMutation = {
  __typename?: "Mutation";
  createLanguage: { __typename?: "Language"; id: string };
};

export type CreateRegionMutationVariables = Exact<{
  input: CreateRegionInput;
}>;

export type CreateRegionMutation = {
  __typename?: "Mutation";
  createRegion: {
    __typename?: "Region";
    item: { __typename?: "CommonDictItem"; id: string };
  };
};

export type DeleteBankMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteBankMutation = {
  __typename?: "Mutation";
  removeBanks: boolean;
};

export type DeleteCityMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteCityMutation = {
  __typename?: "Mutation";
  removeCities: boolean;
};

export type DeleteContinentMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteContinentMutation = {
  __typename?: "Mutation";
  removeContinents: boolean;
};

export type DeleteCountryMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteCountryMutation = {
  __typename?: "Mutation";
  removeCountries: boolean;
};

export type DeleteCurrencyMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteCurrencyMutation = {
  __typename?: "Mutation";
  removeCurrencies: boolean;
};

export type DeleteLanguageMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteLanguageMutation = {
  __typename?: "Mutation";
  removeLanguages: boolean;
};

export type DeleteRegionMutationVariables = Exact<{
  input: RemoveDictItemsInput;
}>;

export type DeleteRegionMutation = {
  __typename?: "Mutation";
  removeRegions: boolean;
};

export type AccountsQueryVariables = Exact<{
  input: SearchAccountsInput;
}>;

export type AccountsQuery = {
  __typename?: "Query";
  searchAccounts: {
    __typename?: "Accounts";
    count: number;
    nodes: Array<{
      __typename?: "Account";
      absAccountId?: string | null;
      absAccountNickname?: string | null;
      absAccountStatus?: string | null;
      absAccountType?: string | null;
      accountNumber: string;
      createdAt: string;
      holderType?: AccountHolderType | null;
      id: string;
      internalType: InternalAccountType;
      name: string;
      openingDate?: string | null;
      reasonForInactive?: string | null;
      state: AccountState;
      type: AccountType;
      userId: string;
      walletId: string;
      balance: {
        __typename?: "Balance";
        available: {
          __typename?: "Money";
          amount: string;
          currency: { __typename?: "Currency"; ticker: string };
        };
        full: {
          __typename?: "Money";
          amount: string;
          currency: { __typename?: "Currency"; ticker: string };
        };
        held: {
          __typename?: "Money";
          amount: string;
          currency: { __typename?: "Currency"; ticker: string };
        };
      };
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
      currency: { __typename?: "Currency"; ticker: string };
    }>;
  };
};

export type GetAllTransactionsQueryVariables = Exact<{
  input: SearchTransactionsInput;
}>;

export type GetAllTransactionsQuery = {
  __typename?: "Query";
  searchTransactions: {
    __typename?: "TransactionsWithRepeatable";
    count: number;
    nodes: Array<{
      __typename?: "TransactionWithRepeatable";
      isRepeatable: boolean;
      transaction:
        | {
            __typename?: "CardTransaction";
            cardId?: string | null;
            cryptoAmount: string;
            cryptoFee: string;
            fiatAmount: string;
            fiatFee: string;
            id: string;
            merchantCountryCode?: CountryCodeEnum | null;
            merchantName?: string | null;
            serviceFee: string;
            serviceFeeInCryptoCurrency: string;
            time: string;
            totalCryptoAmount: string;
            transactionId: string;
            userId: string;
            cardCategory: CardTransactionTypeEnum;
            cardDirection: CardTransactionDirectionEnum;
            cardStatus: CardTransactionStatusEnum;
            cryptoCoin: { __typename?: "Currency"; ticker: string };
            fiatCoin: { __typename?: "Currency"; ticker: string };
            serviceCoin: { __typename?: "Currency"; ticker: string };
          }
        | {
            __typename?: "CashTransaction";
            amount: string;
            compareAmount: string;
            direction: TransactionDirection;
            fee: string;
            id: string;
            service: string;
            status: CashStatus;
            time: string;
            totalAmount: string;
            cashCategory: CashCategory;
            coin: { __typename?: "Currency"; ticker: string };
            from: {
              __typename?: "CashPaymentAccount";
              absAccountId?: string | null;
              accountNumber?: string | null;
              accountId?: string | null;
              bankAddress?: string | null;
              bankBic?: string | null;
              bankCityId?: string | null;
              bankId?: string | null;
              bankName?: string | null;
              bankSortCode?: string | null;
              cardId?: string | null;
              cardNumber?: string | null;
              contactCompanyName?: string | null;
              contactFirstName?: string | null;
              contactId?: string | null;
              contactLastName?: string | null;
              countryId?: string | null;
              eWalletId?: string | null;
              iban?: string | null;
              id: string;
              phoneNumber?: string | null;
              swift?: string | null;
              temenosAccountId?: string | null;
            };
            sourceInfo?: {
              __typename?: "SourceInfo";
              bin: string;
              cardCategory: string;
              cardType: string;
              expiryMonth: number;
              expiryYear: number;
              issuer: string;
              issuerCountry: string;
              last4: string;
              paymentId: string;
              productId: string;
              productType: string;
              scheme: string;
              type: string;
            } | null;
            to: {
              __typename?: "CashPaymentAccount";
              absAccountId?: string | null;
              accountNumber?: string | null;
              accountId?: string | null;
              bankAddress?: string | null;
              bankBic?: string | null;
              bankCityId?: string | null;
              bankId?: string | null;
              bankName?: string | null;
              bankSortCode?: string | null;
              cardId?: string | null;
              cardNumber?: string | null;
              contactCompanyName?: string | null;
              contactFirstName?: string | null;
              contactId?: string | null;
              contactLastName?: string | null;
              countryId?: string | null;
              eWalletId?: string | null;
              iban?: string | null;
              id: string;
              phoneNumber?: string | null;
              swift?: string | null;
              temenosAccountId?: string | null;
            };
          }
        | {
            __typename?: "CryptoTransaction";
            amount: string;
            transactionFee: string;
            totalAmount: string;
            compareAmount: string;
            direction: TransactionDirection;
            destination: string;
            source: string;
            id: string;
            hash?: string | null;
            memo?: string | null;
            time: string;
            cryptoCategory: CryptoCategory;
            cryptoStatus: CryptoStatus;
            coin: { __typename?: "Currency"; ticker: string };
            network: {
              __typename?: "CryptoTransactionNetwork";
              network: string;
            };
          }
        | {
            __typename?: "EarnTransaction";
            amount: string;
            coinId: string;
            direction: TransactionDirection;
            id: string;
            operation: EarnOperation;
            time: string;
            coin: { __typename?: "Currency"; ticker: string };
          };
    }>;
  };
};

export type GetAllUsersQueryVariables = Exact<{
  input: GetAllUsersInput;
}>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  getAllUsers: {
    __typename?: "UsersFull";
    count: number;
    nodes: Array<{
      __typename?: "UserFull";
      birthDate?: string | null;
      createdAt: string;
      firstName?: string | null;
      gender?: GenderEnum | null;
      lastName?: string | null;
      leadId?: string | null;
      role: string;
      service: Service;
      status: StatusEnum;
      updatedAt: string;
      userId: string;
      username?: string | null;
      EOS?: { __typename?: "PublicKeyInfo"; key: string; type: KeyEnum } | null;
      avatar?: { __typename?: "File"; url: string } | null;
      email?: {
        __typename?: "DeviceInfo";
        confirm: boolean;
        deviceId: string;
        identity: string;
        type: string;
      } | null;
      p256?: {
        __typename?: "PublicKeyInfo";
        key: string;
        type: KeyEnum;
      } | null;
      phone?: {
        __typename?: "DeviceInfo";
        confirm: boolean;
        deviceId: string;
        identity: string;
        type: string;
      } | null;
      companies: Array<{
        __typename?: "CompanyPayloadAuthType";
        bic?: string | null;
        applicantId?: string | null;
        companyCorrespondenceAddress?: string | null;
        countryForTaxId?: string | null;
        dateOfRegistration?: string | null;
        email?: string | null;
        iban?: string | null;
        id: string;
        legalAddress?: string | null;
        legalName?: string | null;
        mainBusinessActivityIds: Array<string>;
        phoneNumber?: string | null;
        registrationNumber?: string | null;
        status: CompanyStatus;
        tariff: CompanyTariff;
        tin?: string | null;
        userId: string;
        webSite?: string | null;
      }>;
    }>;
  };
};

export type GetBanksQueryVariables = Exact<{
  input: GetBanksInput;
}>;

export type GetBanksQuery = {
  __typename?: "Query";
  getBanks: {
    __typename?: "Banks";
    count: number;
    nodes: Array<{
      __typename?: "Bank";
      address: string;
      bankCode?: string | null;
      bankName: string;
      bic?: string | null;
      city: string;
      country: string;
      id: string;
      swift?: string | null;
      item: {
        __typename?: "CommonDictItem";
        code: string;
        id: string;
        name: string;
        tags?: Array<string | null> | null;
        common: {
          __typename?: "CommonItemInfo";
          isDeleted: boolean;
          timestamps: {
            __typename?: "Timestamps";
            createdAt: string;
            updatedAt: string;
          };
        };
      };
    }>;
  };
};

export type SearchCitiesQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type SearchCitiesQuery = {
  __typename?: "Query";
  searchCities: {
    __typename?: "Cities";
    count: number;
    nodes: Array<{
      __typename?: "City";
      id: string;
      country?: {
        __typename?: "Country";
        id: string;
        item?: { __typename?: "CommonDictItem"; name: string } | null;
      } | null;
      region?: {
        __typename?: "Region";
        country?: {
          __typename?: "Country";
          id: string;
          item?: { __typename?: "CommonDictItem"; name: string } | null;
        } | null;
        item: { __typename?: "CommonDictItem"; name: string; id: string };
      } | null;
      item: {
        __typename?: "CommonDictItem";
        code: string;
        id: string;
        name: string;
        tags?: Array<string | null> | null;
        common: {
          __typename?: "CommonItemInfo";
          isDeleted: boolean;
          timestamps: {
            __typename?: "Timestamps";
            createdAt: string;
            updatedAt: string;
          };
        };
      };
    }>;
  };
};

export type ContinentsQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type ContinentsQuery = {
  __typename?: "Query";
  continents: Array<{
    __typename?: "Continent";
    item: {
      __typename?: "CommonDictItem";
      name: string;
      code: string;
      id: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  } | null>;
};

export type GetCountriesQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type GetCountriesQuery = {
  __typename?: "Query";
  countries: Array<{
    __typename?: "Country";
    alpha3?: string | null;
    id: string;
    phoneCode: string;
    phoneTemplate: string;
    continent?: {
      __typename?: "Continent";
      item: { __typename?: "CommonDictItem"; name: string; id: string };
    } | null;
    item?: {
      __typename?: "CommonDictItem";
      code: string;
      name: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    } | null;
  } | null>;
};

export type SearchCryptoWalletsQueryVariables = Exact<{
  input: SearchWalletsInput;
}>;

export type SearchCryptoWalletsQuery = {
  __typename?: "Query";
  searchWallets: {
    __typename?: "WalletsObject";
    count: number;
    nodes: Array<{
      __typename?: "WalletObject";
      address: string;
      id: string;
      memo?: string | null;
      tokenId: string;
      userId: string;
      balance?: {
        __typename?: "BalanceObject";
        current: string;
        id: string;
        token: string;
      } | null;
      blockchain: {
        __typename?: "NetworkList";
        addressRegex: string;
        coin: string;
        depositEnable: boolean;
        isDefault: boolean;
        memoRegex: string;
        name: string;
        network: string;
        resetAddressStatus: boolean;
        sameAddress: boolean;
        specialTips?: string | null;
        withdrawDesc: string;
        withdrawEnable: boolean;
        withdrawFee: string;
        withdrawIntegerMultiple: string;
        withdrawMax: string;
        withdrawMin: string;
      };
      token: {
        __typename?: "CoinsInfo";
        coin: string;
        depositAllEnable: boolean;
        name: string;
        type: CurrencyType;
        defaultNetwork?: {
          __typename?: "NetworkList";
          addressRegex: string;
          coin: string;
          depositEnable: boolean;
          isDefault: boolean;
          memoRegex: string;
          name: string;
          network: string;
          resetAddressStatus: boolean;
          sameAddress: boolean;
          specialTips?: string | null;
          withdrawDesc: string;
          withdrawEnable: boolean;
          withdrawFee: string;
          withdrawIntegerMultiple: string;
          withdrawMax: string;
          withdrawMin: string;
        } | null;
      };
    }>;
  };
};

export type GetCurrenciesQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type GetCurrenciesQuery = {
  __typename?: "Query";
  getCurrencies: Array<{
    __typename?: "Currency";
    icon?: string | null;
    id: string;
    precision: number;
    symbol?: string | null;
    ticker: string;
    type: CurrencyType;
    item: {
      __typename?: "CommonDictItem";
      code: string;
      name: string;
      tags?: Array<string | null> | null;
      localizedNames?: Array<{
        __typename?: "LocalizedName";
        name: string;
        lang: {
          __typename?: "Language";
          item: { __typename?: "CommonDictItem"; name: string };
        };
      } | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    networkList?: Array<{
      __typename?: "CoinNetwork";
      addressRegex: string;
      coin: string;
      depositDesc: string;
      depositEnable: boolean;
      id: string;
      isDefault: boolean;
      memoRegex: string;
      name: string;
      network: string;
      resetAddressStatus: boolean;
      sameAddress: boolean;
      specialTips?: string | null;
      withdrawDesc: string;
      withdrawEnable: boolean;
      withdrawFee: string;
      withdrawIntegerMultiple: string;
      withdrawMax: string;
      withdrawMin: string;
    }> | null;
  } | null>;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
  __typename?: "Query";
  getCurrencies: Array<{
    __typename?: "Currency";
    id: string;
    ticker: string;
  } | null>;
};

export type LanguagesQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type LanguagesQuery = {
  __typename?: "Query";
  languages: Array<{
    __typename?: "Language";
    id: string;
    item: {
      __typename?: "CommonDictItem";
      name: string;
      code: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  } | null>;
};

export type NaceCodeQueryVariables = Exact<{
  input: GetNaceCodeInput;
}>;

export type NaceCodeQuery = {
  __typename?: "Query";
  naceCode?: {
    __typename?: "NACECode";
    item: {
      __typename?: "CommonDictItem";
      code: string;
      id: string;
      name: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    section: {
      __typename?: "NACECodeSection";
      description: string;
      item: {
        __typename?: "CommonDictItem";
        name: string;
        code: string;
        id: string;
        tags?: Array<string | null> | null;
      };
    };
    parent?: {
      __typename?: "NACECode";
      item: {
        __typename?: "CommonDictItem";
        name: string;
        code: string;
        id: string;
      };
    } | null;
    children?: Array<{
      __typename?: "NACECode";
      item: {
        __typename?: "CommonDictItem";
        code: string;
        name: string;
        id: string;
      };
    }> | null;
  } | null;
};

export type NaceCodesQueryVariables = Exact<{
  input: GetNaceCodesInput;
}>;

export type NaceCodesQuery = {
  __typename?: "Query";
  naceCodes?: {
    __typename?: "NACECodes";
    count: number;
    nodes: Array<{
      __typename?: "NACECode";
      item: {
        __typename?: "CommonDictItem";
        code: string;
        id: string;
        name: string;
        tags?: Array<string | null> | null;
        common: {
          __typename?: "CommonItemInfo";
          isDeleted: boolean;
          timestamps: {
            __typename?: "Timestamps";
            createdAt: string;
            updatedAt: string;
          };
        };
      };
      section: {
        __typename?: "NACECodeSection";
        description: string;
        item: {
          __typename?: "CommonDictItem";
          name: string;
          code: string;
          id: string;
          tags?: Array<string | null> | null;
        };
      };
      parent?: {
        __typename?: "NACECode";
        item: {
          __typename?: "CommonDictItem";
          name: string;
          code: string;
          id: string;
        };
      } | null;
      children?: Array<{
        __typename?: "NACECode";
        item: {
          __typename?: "CommonDictItem";
          code: string;
          name: string;
          id: string;
        };
      }> | null;
    }>;
  } | null;
};

export type GetBankQueryVariables = Exact<{
  input: GetBankInput;
}>;

export type GetBankQuery = {
  __typename?: "Query";
  getBank?: {
    __typename?: "Bank";
    address: string;
    bankCode?: string | null;
    bankName: string;
    bic?: string | null;
    city: string;
    country: string;
    id: string;
    swift?: string | null;
    item: {
      __typename?: "CommonDictItem";
      code: string;
      id: string;
      name: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  } | null;
};

export type RegionsQueryVariables = Exact<{
  input: DictFilterInput;
}>;

export type RegionsQuery = {
  __typename?: "Query";
  regions: Array<{
    __typename?: "Region";
    country?: {
      __typename?: "Country";
      item?: { __typename?: "CommonDictItem"; name: string; id: string } | null;
    } | null;
    item: {
      __typename?: "CommonDictItem";
      id: string;
      name: string;
      code: string;
      tags?: Array<string | null> | null;
      common: {
        __typename?: "CommonItemInfo";
        isDeleted: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  } | null>;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "AuthorizedUser";
    email?: { __typename?: "EmailDeviceInfo"; identity: string } | null;
  };
};

export type GetUserStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserStatusQuery = {
  __typename?: "Query";
  viewer: { __typename?: "AuthorizedUser"; status: StatusEnum };
};

export type Send2FaTokenMutationVariables = Exact<{
  input: AuthTfaSend;
}>;

export type Send2FaTokenMutation = {
  __typename?: "Mutation";
  sendTFACode: {
    __typename?: "Send2FATokenResponse";
    operationId: string;
    response: Array<TfaType>;
  };
};

export type SignInQueryVariables = Exact<{
  input: AuthInput;
}>;

export type SignInQuery = {
  __typename?: "Query";
  signIn: {
    __typename?: "Send2FATokenResponse";
    operationId: string;
    response: Array<TfaType>;
  };
};

export type TokenMutationVariables = Exact<{
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
}>;

export type TokenMutation = {
  __typename?: "Mutation";
  token: {
    __typename?: "Auth";
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
  };
};

export type UpdateBankMutationVariables = Exact<{
  input: UpdateBankInput;
}>;

export type UpdateBankMutation = {
  __typename?: "Mutation";
  updateBank: { __typename?: "Bank"; id: string };
};

export type UpdateCityMutationVariables = Exact<{
  input: UpdateCityInput;
}>;

export type UpdateCityMutation = {
  __typename?: "Mutation";
  updateCity: { __typename?: "City"; id: string };
};

export type UpdateContinentMutationVariables = Exact<{
  input: UpdateContinentInput;
}>;

export type UpdateContinentMutation = {
  __typename?: "Mutation";
  updateContinent: {
    __typename?: "Continent";
    item: { __typename?: "CommonDictItem"; id: string };
  };
};

export type UpdateCountryMutationVariables = Exact<{
  input: UpdateCountryInput;
}>;

export type UpdateCountryMutation = {
  __typename?: "Mutation";
  updateCountry: { __typename?: "Country"; id: string };
};

export type UpdateCurrencyMutationVariables = Exact<{
  input: UpdateCurrencyInput;
}>;

export type UpdateCurrencyMutation = {
  __typename?: "Mutation";
  updateCurrency: { __typename?: "Currency"; ticker: string };
};

export type UpdateLanguageMutationVariables = Exact<{
  input: UpdateLanguageInput;
}>;

export type UpdateLanguageMutation = {
  __typename?: "Mutation";
  updateLanguage: { __typename?: "Language"; id: string };
};

export type UpdateRegionMutationVariables = Exact<{
  input: UpdateRegionInput;
}>;

export type UpdateRegionMutation = {
  __typename?: "Mutation";
  updateRegion: {
    __typename?: "Region";
    item: { __typename?: "CommonDictItem"; id: string };
  };
};

export const ConfirmSignInDocument = gql`
  mutation confirmSignIn($input: AuthVerify2FAInput!) {
    confirmSignIn(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const CreateBankDocument = gql`
  mutation createBank($input: CreateBankInput!) {
    createBank(input: $input) {
      id
    }
  }
`;
export const CreateCityDocument = gql`
  mutation createCity($input: CreateCityInput!) {
    createCity(input: $input) {
      id
    }
  }
`;
export const CreateContinentDocument = gql`
  mutation createContinent($input: CreateContinentInput!) {
    createContinent(input: $input) {
      item {
        id
      }
    }
  }
`;
export const CreateCountryDocument = gql`
  mutation createCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      id
    }
  }
`;
export const CreateCurrencyDocument = gql`
  mutation createCurrency($input: CreateCurrencyInput!) {
    createCurrency(input: $input) {
      ticker
    }
  }
`;
export const CreateLanguageDocument = gql`
  mutation createLanguage($input: CreateLanguageInput!) {
    createLanguage(input: $input) {
      id
    }
  }
`;
export const CreateRegionDocument = gql`
  mutation createRegion($input: CreateRegionInput!) {
    createRegion(input: $input) {
      item {
        id
      }
    }
  }
`;
export const DeleteBankDocument = gql`
  mutation deleteBank($input: RemoveDictItemsInput!) {
    removeBanks(input: $input)
  }
`;
export const DeleteCityDocument = gql`
  mutation deleteCity($input: RemoveDictItemsInput!) {
    removeCities(input: $input)
  }
`;
export const DeleteContinentDocument = gql`
  mutation deleteContinent($input: RemoveDictItemsInput!) {
    removeContinents(input: $input)
  }
`;
export const DeleteCountryDocument = gql`
  mutation deleteCountry($input: RemoveDictItemsInput!) {
    removeCountries(input: $input)
  }
`;
export const DeleteCurrencyDocument = gql`
  mutation deleteCurrency($input: RemoveDictItemsInput!) {
    removeCurrencies(input: $input)
  }
`;
export const DeleteLanguageDocument = gql`
  mutation deleteLanguage($input: RemoveDictItemsInput!) {
    removeLanguages(input: $input)
  }
`;
export const DeleteRegionDocument = gql`
  mutation deleteRegion($input: RemoveDictItemsInput!) {
    removeRegions(input: $input)
  }
`;
export const AccountsDocument = gql`
  query accounts($input: SearchAccountsInput!) {
    searchAccounts(input: $input) {
      count
      nodes {
        absAccountId
        absAccountNickname
        absAccountStatus
        absAccountType
        accountNumber
        balance {
          available {
            amount
            currency {
              ticker
            }
          }
          full {
            amount
            currency {
              ticker
            }
          }
          held {
            amount
            currency {
              ticker
            }
          }
        }
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
        createdAt
        currency {
          ticker
        }
        holderType
        id
        internalType
        name
        openingDate
        reasonForInactive
        state
        type
        userId
        walletId
      }
    }
  }
`;
export const GetAllTransactionsDocument = gql`
  query getAllTransactions($input: SearchTransactionsInput!) {
    searchTransactions(input: $input) {
      count
      nodes {
        isRepeatable
        transaction {
          ... on EarnTransaction {
            amount
            coin {
              ticker
            }
            coinId
            direction
            id
            operation
            time
          }
          ... on CryptoTransaction {
            cryptoCategory: category
            amount
            transactionFee
            totalAmount
            compareAmount
            coin {
              ticker
            }
            direction
            destination
            source
            id
            hash
            network {
              network
            }
            cryptoStatus: status
            memo
            time
          }
          ... on CardTransaction {
            cardId
            cardCategory: category
            cryptoAmount
            cryptoCoin {
              ticker
            }
            cryptoFee
            cardDirection: direction
            fiatAmount
            fiatCoin {
              ticker
            }
            fiatFee
            id
            merchantCountryCode
            merchantName
            serviceCoin {
              ticker
            }
            serviceFee
            serviceFeeInCryptoCurrency
            cardStatus: status
            time
            totalCryptoAmount
            transactionId
            userId
          }
          ... on CashTransaction {
            amount
            cashCategory: category
            coin {
              ticker
            }
            compareAmount
            direction
            fee
            from {
              absAccountId
              accountNumber
              accountId
              bankAddress
              bankBic
              bankCityId
              bankId
              bankName
              bankSortCode
              cardId
              cardNumber
              contactCompanyName
              contactFirstName
              contactId
              contactLastName
              countryId
              eWalletId
              iban
              id
              phoneNumber
              swift
              temenosAccountId
            }
            id
            service
            sourceInfo {
              bin
              cardCategory
              cardType
              expiryMonth
              expiryYear
              issuer
              issuerCountry
              last4
              paymentId
              productId
              productType
              scheme
              type
            }
            status
            time
            to {
              absAccountId
              accountNumber
              accountId
              bankAddress
              bankBic
              bankCityId
              bankId
              bankName
              bankSortCode
              cardId
              cardNumber
              contactCompanyName
              contactFirstName
              contactId
              contactLastName
              countryId
              eWalletId
              iban
              id
              phoneNumber
              swift
              temenosAccountId
            }
            totalAmount
          }
        }
      }
    }
  }
`;
export const GetAllUsersDocument = gql`
  query getAllUsers($input: GetAllUsersInput!) {
    getAllUsers(input: $input) {
      count
      nodes {
        EOS {
          key
          type
        }
        avatar {
          url
        }
        birthDate
        createdAt
        email {
          confirm
          deviceId
          identity
          type
        }
        firstName
        gender
        lastName
        leadId
        p256 {
          key
          type
        }
        phone {
          confirm
          deviceId
          identity
          type
        }
        role
        service
        status
        updatedAt
        userId
        username
        companies {
          bic
          applicantId
          companyCorrespondenceAddress
          countryForTaxId
          dateOfRegistration
          email
          iban
          id
          legalAddress
          legalName
          mainBusinessActivityIds
          phoneNumber
          registrationNumber
          status
          tariff
          tin
          userId
          webSite
        }
      }
    }
  }
`;
export const GetBanksDocument = gql`
  query getBanks($input: GetBanksInput!) {
    getBanks(input: $input) {
      count
      nodes {
        address
        bankCode
        bankName
        bic
        city
        country
        id
        swift
        item {
          code
          id
          name
          tags
          common {
            isDeleted
            timestamps {
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const SearchCitiesDocument = gql`
  query searchCities($input: DictFilterInput!) {
    searchCities(input: $input) {
      count
      nodes {
        country {
          id
          item {
            name
          }
        }
        region {
          country {
            id
            item {
              name
            }
          }
          item {
            name
            id
          }
        }
        id
        item {
          code
          common {
            isDeleted
            timestamps {
              createdAt
              updatedAt
            }
          }
          id
          name
          tags
        }
      }
    }
  }
`;
export const ContinentsDocument = gql`
  query continents($input: DictFilterInput!) {
    continents(input: $input) {
      item {
        name
        code
        id
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const GetCountriesDocument = gql`
  query getCountries($input: DictFilterInput!) {
    countries(input: $input) {
      alpha3
      continent {
        item {
          name
          id
        }
      }
      id
      item {
        code
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
        name
        tags
      }
      phoneCode
      phoneTemplate
    }
  }
`;
export const SearchCryptoWalletsDocument = gql`
  query searchCryptoWallets($input: SearchWalletsInput!) {
    searchWallets(input: $input) {
      count
      nodes {
        address
        balance {
          current
          id
          token
        }
        blockchain {
          addressRegex
          coin
          depositEnable
          isDefault
          memoRegex
          name
          network
          resetAddressStatus
          sameAddress
          specialTips
          withdrawDesc
          withdrawEnable
          withdrawFee
          withdrawIntegerMultiple
          withdrawMax
          withdrawMin
        }
        id
        memo
        token {
          coin
          defaultNetwork {
            addressRegex
            coin
            depositEnable
            isDefault
            memoRegex
            name
            network
            resetAddressStatus
            sameAddress
            specialTips
            withdrawDesc
            withdrawEnable
            withdrawFee
            withdrawIntegerMultiple
            withdrawMax
            withdrawMin
          }
          depositAllEnable
          name
          type
        }
        tokenId
        userId
      }
    }
  }
`;
export const GetCurrenciesDocument = gql`
  query getCurrencies($input: DictFilterInput!) {
    getCurrencies(input: $input) {
      icon
      id
      item {
        code
        localizedNames {
          lang {
            item {
              name
            }
          }
          name
        }
        name
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
      networkList {
        addressRegex
        coin
        depositDesc
        depositEnable
        id
        isDefault
        memoRegex
        name
        network
        resetAddressStatus
        sameAddress
        specialTips
        withdrawDesc
        withdrawEnable
        withdrawFee
        withdrawIntegerMultiple
        withdrawMax
        withdrawMin
      }
      precision
      symbol
      ticker
      type
    }
  }
`;
export const CurrenciesDocument = gql`
  query currencies {
    getCurrencies(input: { tickers: ["EUR", "GBP", "USD", "RUB"] }) {
      id
      ticker
    }
  }
`;
export const LanguagesDocument = gql`
  query languages($input: DictFilterInput!) {
    languages(input: $input) {
      id
      item {
        name
        code
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const NaceCodeDocument = gql`
  query naceCode($input: GetNACECodeInput!) {
    naceCode(input: $input) {
      item {
        code
        id
        name
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
      section {
        description
        item {
          name
          code
          id
          tags
        }
      }
      parent {
        item {
          name
          code
          id
        }
      }
      children {
        item {
          code
          name
          id
        }
      }
    }
  }
`;
export const NaceCodesDocument = gql`
  query naceCodes($input: GetNACECodesInput!) {
    naceCodes(input: $input) {
      count
      nodes {
        item {
          code
          id
          name
          tags
          common {
            isDeleted
            timestamps {
              createdAt
              updatedAt
            }
          }
        }
        section {
          description
          item {
            name
            code
            id
            tags
          }
        }
        parent {
          item {
            name
            code
            id
          }
        }
        children {
          item {
            code
            name
            id
          }
        }
      }
    }
  }
`;
export const GetBankDocument = gql`
  query getBank($input: GetBankInput!) {
    getBank(input: $input) {
      address
      bankCode
      bankName
      bic
      city
      country
      id
      swift
      item {
        code
        id
        name
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const RegionsDocument = gql`
  query regions($input: DictFilterInput!) {
    regions(input: $input) {
      country {
        item {
          name
          id
        }
      }
      item {
        id
        name
        code
        tags
        common {
          isDeleted
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const ViewerDocument = gql`
  query viewer {
    viewer {
      email {
        identity
      }
    }
  }
`;
export const GetUserStatusDocument = gql`
  query getUserStatus {
    viewer {
      status
    }
  }
`;
export const Send2FaTokenDocument = gql`
  mutation send2FAToken($input: AuthTFASend!) {
    sendTFACode(input: $input) {
      operationId
      response
    }
  }
`;
export const SignInDocument = gql`
  query signIn($input: AuthInput!) {
    signIn(input: $input) {
      operationId
      response
    }
  }
`;
export const TokenDocument = gql`
  mutation token($accessToken: String!, $refreshToken: String!) {
    token(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;
export const UpdateBankDocument = gql`
  mutation updateBank($input: UpdateBankInput!) {
    updateBank(input: $input) {
      id
    }
  }
`;
export const UpdateCityDocument = gql`
  mutation updateCity($input: UpdateCityInput!) {
    updateCity(input: $input) {
      id
    }
  }
`;
export const UpdateContinentDocument = gql`
  mutation updateContinent($input: UpdateContinentInput!) {
    updateContinent(input: $input) {
      item {
        id
      }
    }
  }
`;
export const UpdateCountryDocument = gql`
  mutation updateCountry($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      id
    }
  }
`;
export const UpdateCurrencyDocument = gql`
  mutation updateCurrency($input: UpdateCurrencyInput!) {
    updateCurrency(input: $input) {
      ticker
    }
  }
`;
export const UpdateLanguageDocument = gql`
  mutation updateLanguage($input: UpdateLanguageInput!) {
    updateLanguage(input: $input) {
      id
    }
  }
`;
export const UpdateRegionDocument = gql`
  mutation updateRegion($input: UpdateRegionInput!) {
    updateRegion(input: $input) {
      item {
        id
      }
    }
  }
`;
export type Requester<C = {}, E = unknown> = <R, V>(
  doc: DocumentNode,
  vars?: V,
  options?: C
) => Promise<R>;
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    confirmSignIn(
      variables: ConfirmSignInMutationVariables,
      options?: C
    ): Promise<ConfirmSignInMutation> {
      return requester<ConfirmSignInMutation, ConfirmSignInMutationVariables>(
        ConfirmSignInDocument,
        variables,
        options
      );
    },
    createBank(
      variables: CreateBankMutationVariables,
      options?: C
    ): Promise<CreateBankMutation> {
      return requester<CreateBankMutation, CreateBankMutationVariables>(
        CreateBankDocument,
        variables,
        options
      );
    },
    createCity(
      variables: CreateCityMutationVariables,
      options?: C
    ): Promise<CreateCityMutation> {
      return requester<CreateCityMutation, CreateCityMutationVariables>(
        CreateCityDocument,
        variables,
        options
      );
    },
    createContinent(
      variables: CreateContinentMutationVariables,
      options?: C
    ): Promise<CreateContinentMutation> {
      return requester<
        CreateContinentMutation,
        CreateContinentMutationVariables
      >(CreateContinentDocument, variables, options);
    },
    createCountry(
      variables: CreateCountryMutationVariables,
      options?: C
    ): Promise<CreateCountryMutation> {
      return requester<CreateCountryMutation, CreateCountryMutationVariables>(
        CreateCountryDocument,
        variables,
        options
      );
    },
    createCurrency(
      variables: CreateCurrencyMutationVariables,
      options?: C
    ): Promise<CreateCurrencyMutation> {
      return requester<CreateCurrencyMutation, CreateCurrencyMutationVariables>(
        CreateCurrencyDocument,
        variables,
        options
      );
    },
    createLanguage(
      variables: CreateLanguageMutationVariables,
      options?: C
    ): Promise<CreateLanguageMutation> {
      return requester<CreateLanguageMutation, CreateLanguageMutationVariables>(
        CreateLanguageDocument,
        variables,
        options
      );
    },
    createRegion(
      variables: CreateRegionMutationVariables,
      options?: C
    ): Promise<CreateRegionMutation> {
      return requester<CreateRegionMutation, CreateRegionMutationVariables>(
        CreateRegionDocument,
        variables,
        options
      );
    },
    deleteBank(
      variables: DeleteBankMutationVariables,
      options?: C
    ): Promise<DeleteBankMutation> {
      return requester<DeleteBankMutation, DeleteBankMutationVariables>(
        DeleteBankDocument,
        variables,
        options
      );
    },
    deleteCity(
      variables: DeleteCityMutationVariables,
      options?: C
    ): Promise<DeleteCityMutation> {
      return requester<DeleteCityMutation, DeleteCityMutationVariables>(
        DeleteCityDocument,
        variables,
        options
      );
    },
    deleteContinent(
      variables: DeleteContinentMutationVariables,
      options?: C
    ): Promise<DeleteContinentMutation> {
      return requester<
        DeleteContinentMutation,
        DeleteContinentMutationVariables
      >(DeleteContinentDocument, variables, options);
    },
    deleteCountry(
      variables: DeleteCountryMutationVariables,
      options?: C
    ): Promise<DeleteCountryMutation> {
      return requester<DeleteCountryMutation, DeleteCountryMutationVariables>(
        DeleteCountryDocument,
        variables,
        options
      );
    },
    deleteCurrency(
      variables: DeleteCurrencyMutationVariables,
      options?: C
    ): Promise<DeleteCurrencyMutation> {
      return requester<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>(
        DeleteCurrencyDocument,
        variables,
        options
      );
    },
    deleteLanguage(
      variables: DeleteLanguageMutationVariables,
      options?: C
    ): Promise<DeleteLanguageMutation> {
      return requester<DeleteLanguageMutation, DeleteLanguageMutationVariables>(
        DeleteLanguageDocument,
        variables,
        options
      );
    },
    deleteRegion(
      variables: DeleteRegionMutationVariables,
      options?: C
    ): Promise<DeleteRegionMutation> {
      return requester<DeleteRegionMutation, DeleteRegionMutationVariables>(
        DeleteRegionDocument,
        variables,
        options
      );
    },
    accounts(
      variables: AccountsQueryVariables,
      options?: C
    ): Promise<AccountsQuery> {
      return requester<AccountsQuery, AccountsQueryVariables>(
        AccountsDocument,
        variables,
        options
      );
    },
    getAllTransactions(
      variables: GetAllTransactionsQueryVariables,
      options?: C
    ): Promise<GetAllTransactionsQuery> {
      return requester<
        GetAllTransactionsQuery,
        GetAllTransactionsQueryVariables
      >(GetAllTransactionsDocument, variables, options);
    },
    getAllUsers(
      variables: GetAllUsersQueryVariables,
      options?: C
    ): Promise<GetAllUsersQuery> {
      return requester<GetAllUsersQuery, GetAllUsersQueryVariables>(
        GetAllUsersDocument,
        variables,
        options
      );
    },
    getBanks(
      variables: GetBanksQueryVariables,
      options?: C
    ): Promise<GetBanksQuery> {
      return requester<GetBanksQuery, GetBanksQueryVariables>(
        GetBanksDocument,
        variables,
        options
      );
    },
    searchCities(
      variables: SearchCitiesQueryVariables,
      options?: C
    ): Promise<SearchCitiesQuery> {
      return requester<SearchCitiesQuery, SearchCitiesQueryVariables>(
        SearchCitiesDocument,
        variables,
        options
      );
    },
    continents(
      variables: ContinentsQueryVariables,
      options?: C
    ): Promise<ContinentsQuery> {
      return requester<ContinentsQuery, ContinentsQueryVariables>(
        ContinentsDocument,
        variables,
        options
      );
    },
    getCountries(
      variables: GetCountriesQueryVariables,
      options?: C
    ): Promise<GetCountriesQuery> {
      return requester<GetCountriesQuery, GetCountriesQueryVariables>(
        GetCountriesDocument,
        variables,
        options
      );
    },
    searchCryptoWallets(
      variables: SearchCryptoWalletsQueryVariables,
      options?: C
    ): Promise<SearchCryptoWalletsQuery> {
      return requester<
        SearchCryptoWalletsQuery,
        SearchCryptoWalletsQueryVariables
      >(SearchCryptoWalletsDocument, variables, options);
    },
    getCurrencies(
      variables: GetCurrenciesQueryVariables,
      options?: C
    ): Promise<GetCurrenciesQuery> {
      return requester<GetCurrenciesQuery, GetCurrenciesQueryVariables>(
        GetCurrenciesDocument,
        variables,
        options
      );
    },
    currencies(
      variables?: CurrenciesQueryVariables,
      options?: C
    ): Promise<CurrenciesQuery> {
      return requester<CurrenciesQuery, CurrenciesQueryVariables>(
        CurrenciesDocument,
        variables,
        options
      );
    },
    languages(
      variables: LanguagesQueryVariables,
      options?: C
    ): Promise<LanguagesQuery> {
      return requester<LanguagesQuery, LanguagesQueryVariables>(
        LanguagesDocument,
        variables,
        options
      );
    },
    naceCode(
      variables: NaceCodeQueryVariables,
      options?: C
    ): Promise<NaceCodeQuery> {
      return requester<NaceCodeQuery, NaceCodeQueryVariables>(
        NaceCodeDocument,
        variables,
        options
      );
    },
    naceCodes(
      variables: NaceCodesQueryVariables,
      options?: C
    ): Promise<NaceCodesQuery> {
      return requester<NaceCodesQuery, NaceCodesQueryVariables>(
        NaceCodesDocument,
        variables,
        options
      );
    },
    getBank(
      variables: GetBankQueryVariables,
      options?: C
    ): Promise<GetBankQuery> {
      return requester<GetBankQuery, GetBankQueryVariables>(
        GetBankDocument,
        variables,
        options
      );
    },
    regions(
      variables: RegionsQueryVariables,
      options?: C
    ): Promise<RegionsQuery> {
      return requester<RegionsQuery, RegionsQueryVariables>(
        RegionsDocument,
        variables,
        options
      );
    },
    viewer(
      variables?: ViewerQueryVariables,
      options?: C
    ): Promise<ViewerQuery> {
      return requester<ViewerQuery, ViewerQueryVariables>(
        ViewerDocument,
        variables,
        options
      );
    },
    getUserStatus(
      variables?: GetUserStatusQueryVariables,
      options?: C
    ): Promise<GetUserStatusQuery> {
      return requester<GetUserStatusQuery, GetUserStatusQueryVariables>(
        GetUserStatusDocument,
        variables,
        options
      );
    },
    send2FAToken(
      variables: Send2FaTokenMutationVariables,
      options?: C
    ): Promise<Send2FaTokenMutation> {
      return requester<Send2FaTokenMutation, Send2FaTokenMutationVariables>(
        Send2FaTokenDocument,
        variables,
        options
      );
    },
    signIn(variables: SignInQueryVariables, options?: C): Promise<SignInQuery> {
      return requester<SignInQuery, SignInQueryVariables>(
        SignInDocument,
        variables,
        options
      );
    },
    token(
      variables: TokenMutationVariables,
      options?: C
    ): Promise<TokenMutation> {
      return requester<TokenMutation, TokenMutationVariables>(
        TokenDocument,
        variables,
        options
      );
    },
    updateBank(
      variables: UpdateBankMutationVariables,
      options?: C
    ): Promise<UpdateBankMutation> {
      return requester<UpdateBankMutation, UpdateBankMutationVariables>(
        UpdateBankDocument,
        variables,
        options
      );
    },
    updateCity(
      variables: UpdateCityMutationVariables,
      options?: C
    ): Promise<UpdateCityMutation> {
      return requester<UpdateCityMutation, UpdateCityMutationVariables>(
        UpdateCityDocument,
        variables,
        options
      );
    },
    updateContinent(
      variables: UpdateContinentMutationVariables,
      options?: C
    ): Promise<UpdateContinentMutation> {
      return requester<
        UpdateContinentMutation,
        UpdateContinentMutationVariables
      >(UpdateContinentDocument, variables, options);
    },
    updateCountry(
      variables: UpdateCountryMutationVariables,
      options?: C
    ): Promise<UpdateCountryMutation> {
      return requester<UpdateCountryMutation, UpdateCountryMutationVariables>(
        UpdateCountryDocument,
        variables,
        options
      );
    },
    updateCurrency(
      variables: UpdateCurrencyMutationVariables,
      options?: C
    ): Promise<UpdateCurrencyMutation> {
      return requester<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(
        UpdateCurrencyDocument,
        variables,
        options
      );
    },
    updateLanguage(
      variables: UpdateLanguageMutationVariables,
      options?: C
    ): Promise<UpdateLanguageMutation> {
      return requester<UpdateLanguageMutation, UpdateLanguageMutationVariables>(
        UpdateLanguageDocument,
        variables,
        options
      );
    },
    updateRegion(
      variables: UpdateRegionMutationVariables,
      options?: C
    ): Promise<UpdateRegionMutation> {
      return requester<UpdateRegionMutation, UpdateRegionMutationVariables>(
        UpdateRegionDocument,
        variables,
        options
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
