import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import { CreateCountryInput } from "shared/api/apollo/__generated__";

const createCountry = createEvent<CreateCountryInput>();

const createCountryFx = createEffect(async (input: CreateCountryInput) => {
  const response = await requestClient.createCountry({ input });
  return response.createCountry;
});

const $isLoading = createCountryFx.pending;

forward({
  from: createCountry,
  to: createCountryFx,
});

sample({
  clock: createCountryFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createCountryFx.doneData,
  fn: (doneData) => {
    document.location = `/dictionary/countries/${doneData.id}`;
  },
});

export const createCountryModel = { createCountry, $isLoading };
