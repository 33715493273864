import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import { CreateCurrencyInput } from "shared/api/apollo/__generated__";

const createCurrency = createEvent<CreateCurrencyInput>();

const createCurrencyFx = createEffect(async (input: CreateCurrencyInput) => {
  const response = await requestClient.createCurrency({ input });
  return response.createCurrency;
});

const $isLoading = createCurrencyFx.pending;

forward({
  from: createCurrency,
  to: createCurrencyFx,
});

sample({
  clock: createCurrencyFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createCurrencyFx.doneData,
  fn: (doneData) => {
    document.location = `/dictionary/currencies/${doneData.ticker}`;
  },
});

export const createCurrencyModel = { createCurrency, $isLoading };
