import { Country } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeData = (data: Country[]): DataType[] => {
  const response: DataType[] = [];
  data.forEach((country) => {
    response.push({
      continent: country.continent?.item.name as string,
      alpha3: country.alpha3 as string,
      countryId: country?.id as string,
      name: country?.item?.name as string,
      phoneCode: country?.phoneCode,
      key: country?.id,
    });
  });
  return response;
};
