import jwtDecode from "jwt-decode";

type authTokenPayload = {
  sub: string;
  role: string;
  status: string;
  service: string;
  iat: number;
  exp: number;
  jti: string;
};

export const getAuthStatusFromToken = (token?: string | null) => {
  if (token) {
    const { exp } = jwtDecode<authTokenPayload>(token);
    if (exp) {
      return exp > new Date().getTime() / 1000 + 30;
    }
  }

  return false;
};
