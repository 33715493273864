import React from "react";

import { Descriptions, Tabs, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NaceCode } from "shared/api/apollo/__generated__";

import { serializeNaceCodeInfo } from "../lib";

interface CurrentNaceCodeProps {
  naceCode: NaceCode;
}

export const CurrentNaceCodeInfoView: React.FC<CurrentNaceCodeProps> = ({
  naceCode,
}) => {
  const { t } = useTranslation();
  const naceCodeInfo = serializeNaceCodeInfo(naceCode);

  return (
    <Tabs defaultActiveKey="naceCodeInfo">
      <Tabs.TabPane tab={t("naceCodeView.naceCodeTab")} key="naceCodeInfo">
        <Descriptions
          key="naceCodeInfo"
          layout="horizontal"
          bordered
          size="small"
          column={1}
          title={t("naceCodeView.title")}
          style={{
            minWidth: "600px",
            maxWidth: "800px",
            marginBottom: "10px",
          }}
        >
          {naceCodeInfo &&
            Object.entries(naceCodeInfo).map((info) => {
              if (!Array.isArray(info[1])) {
                return (
                  <Descriptions.Item
                    key={info[0]}
                    label={t(`naceCodeView.${info[0]}`)}
                  >
                    {info[1] || "-"}
                  </Descriptions.Item>
                );
              } else {
                return (
                  <Descriptions.Item
                    key={info[0]}
                    label={t(`naceCodeView.${info[0]}`)}
                  >
                    {info[1].length
                      ? info[1].map((tag) => <Tag>{tag}</Tag>)
                      : "-"}
                  </Descriptions.Item>
                );
              }
            })}
        </Descriptions>
      </Tabs.TabPane>
      {naceCode?.parent && (
        <Tabs.TabPane tab={t("naceCodeView.parentTab")} key="parentsNaceCodes">
          <Descriptions
            key="childrenNaceCodesInfo"
            layout="horizontal"
            size="small"
            column={1}
            title={naceCode?.parent?.item?.code}
            style={{
              minWidth: "600px",
              maxWidth: "800px",
              marginBottom: "10px",
            }}
          >
            {naceCode?.parent &&
              Object.entries(naceCode?.parent?.item).map((parentInfo) => {
                if (!parentInfo[0].includes("__")) {
                  if (parentInfo[0] === "id") {
                    return (
                      <Descriptions.Item
                        key={parentInfo[0]}
                        label={t(`naceCodeView.children.${parentInfo[0]}`)}
                      >
                        <Link to={`/dictionary/nace-codes/${parentInfo[1]}`}>
                          {parentInfo[1]?.toString()}
                        </Link>
                      </Descriptions.Item>
                    );
                  }
                  return (
                    <Descriptions.Item
                      key={parentInfo[0]}
                      label={t(`naceCodeView.parent.${parentInfo[0]}`)}
                    >
                      {parentInfo[1]?.toString() || "-"}
                    </Descriptions.Item>
                  );
                }
              })}
          </Descriptions>
        </Tabs.TabPane>
      )}
      {naceCode?.children?.length && (
        <Tabs.TabPane
          tab={t("naceCodeView.childrenTab")}
          key="childrenNaceCodes"
        >
          {naceCode?.children.map((child) => (
            <Descriptions
              key="childrenNaceCodesInfo"
              layout="horizontal"
              size="small"
              column={1}
              title={child?.item?.code}
              style={{
                minWidth: "600px",
                maxWidth: "800px",
                marginBottom: "10px",
              }}
            >
              {child &&
                Object.entries(child.item).map((childInfo) => {
                  if (!childInfo[0].includes("__")) {
                    if (childInfo[0] === "id") {
                      return (
                        <Descriptions.Item
                          key={childInfo[0]}
                          label={t(`naceCodeView.children.${childInfo[0]}`)}
                        >
                          <Link to={`/dictionary/nace-codes/${childInfo[1]}`}>
                            {childInfo[1]?.toString()}
                          </Link>
                        </Descriptions.Item>
                      );
                    }
                    return (
                      <Descriptions.Item
                        key={childInfo[0]}
                        label={t(`naceCodeView.children.${childInfo[0]}`)}
                      >
                        {childInfo[1]?.toString()}
                      </Descriptions.Item>
                    );
                  }
                })}
            </Descriptions>
          ))}
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};
