import React from "react";

import { AdminTemplate } from "shared/templates";

export const HomePage: React.FC = () => {
  return (
    <AdminTemplate>
      <h1>Home</h1>
    </AdminTemplate>
  );
};
 