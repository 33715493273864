import {
  combine,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
} from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  RemoveDictItemsInput,
  UpdateCurrencyInput,
} from "shared/api/apollo/__generated__";

const updateCurrency = createEvent<UpdateCurrencyInput>();
const setIsChangeMode = createEvent<boolean>();
const deleteCurrency = createEvent<RemoveDictItemsInput>();

const deleteCurrencyFx = createEffect(async (input: RemoveDictItemsInput) => {
  const response = await requestClient.deleteCurrency({ input });
  return response.removeCurrencies;
});

const updateCurrencyFx = createEffect(async (input: UpdateCurrencyInput) => {
  const response = await requestClient.updateCurrency({ input });

  return response.updateCurrency;
});

const $isLoading = combine(
  updateCurrencyFx.pending,
  deleteCurrencyFx.pending,
  (a, b) => a || b
);
const $isChangeMode = restore(setIsChangeMode, false);

forward({
  from: updateCurrency,
  to: updateCurrencyFx,
});

forward({
  from: deleteCurrency,
  to: deleteCurrencyFx,
});

sample({
  clock: updateCurrencyFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: updateCurrencyFx.doneData,
  fn: (data) => ({
    text: `Info has successfully updated for currency with ticker ${data.ticker}`,
  }),
  target: toastModel.showSuccessToast,
});

sample({
  clock: updateCurrencyFx.doneData,
  fn: () => false,
  target: setIsChangeMode,
});

sample({
  clock: deleteCurrencyFx.doneData,
  fn: () => (document.location = "/dictionary/currencies"),
});

sample({
  clock: deleteCurrencyFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

export const updateCurrencyModel = {
  $isChangeMode,
  $isLoading,
  updateCurrency,
  setIsChangeMode,
  deleteCurrency,
};
