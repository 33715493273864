import React, { useEffect } from "react";

import { useStore } from "effector-react";
import { useParams } from "react-router-dom";

import { allTransactionsModel } from "features";

import { CurrentTransactionView } from "entities/index";

import { AdminTemplate } from "shared/templates";
import { Spin } from "antd";

export const CurrentTransactionPage: React.FC = () => {
  const params = useParams();

  useEffect(() => {
    allTransactionsModel?.getAllTransactions({
      transactionIds: [params?.id as string],
    });
  }, []);

  const transaction = useStore(allTransactionsModel?.$allTransactions);
  const isLoading = useStore(allTransactionsModel?.$isTransactionsOnLoad);

  return (
    <AdminTemplate>
      {isLoading ? (
        <Spin />
      ) : (
        <CurrentTransactionView transaction={transaction[0]} />
      )}
    </AdminTemplate>
  );
};
