import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import { CreateCityInput } from "shared/api/apollo/__generated__";

const createCity = createEvent<CreateCityInput>();

const createCityFx = createEffect(async (input: CreateCityInput) => {
  const response = await requestClient.createCity({ input });
  return response.createCity;
});

const $isLoading = createCityFx.pending;

forward({
  from: createCity,
  to: createCityFx,
});

sample({
  clock: createCityFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createCityFx.doneData,
  fn: (doneData) => (document.location = `/dictionary/cities/${doneData.id}`),
});

export const createCityModel = { $isLoading, createCity };
