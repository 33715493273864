import React, { useEffect } from "react";

import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useStore } from "effector-react";

import { createLanguageModel } from "../model";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CreateLanguageForm: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  const isLoading = useStore(createLanguageModel.$isLoading);

  const handleCreateLanguageClick = (values: {
    name: string;
    code: string;
  }) => {
    createLanguageModel.createLanguage({
      language: {
        name: values?.name,
        code: values?.code,
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/dictionary/languages");
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          name="createBank"
          onFinish={handleCreateLanguageClick}
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 3 }}
        >
          <Form.Item key="label">
            <span>{t("createLanguage.description")}</span>
          </Form.Item>
          <Form.Item
            label={t("createLanguage.languageName")}
            key="name"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="name"
          >
            <Input placeholder={t("createLanguage.languageName")} />
          </Form.Item>
          <Form.Item
            label={t("createLanguage.languageCode")}
            key="code"
            rules={[{ required: true, message: t("common.requiredMessage") }]}
            name="code"
          >
            <Input placeholder={t("createLanguage.languageCode")} />
          </Form.Item>
          <Form.Item key="createButton">
            <Button type="primary" htmlType="submit">
              {t("createLanguage.createLanguage")}
            </Button>
            <Button
              onClick={handleCancelClick}
              style={{ marginLeft: "10px" }}
              type="default"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
