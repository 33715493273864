import React, { useEffect, useState } from "react";

import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ChangeRegionInfoForm,
  regionsModel,
  updateRegionModel,
} from "features";

import { CurrentRegionView } from "entities/index";

import { Region } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";

export const CurrentRegionPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams();

  const isLoading = useStore(regionsModel.$isLoading);
  const chosenRegion = useStore(regionsModel.$regions);
  const isChangeOnLoad = useStore(updateRegionModel.$isLoading);
  const isEditMode = useStore(updateRegionModel.$isChangeMode);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    regionsModel.getRegions({ filter: { ids: [params?.id as string] } });
  }, [isEditMode]);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateRegionModel.setIsChangeMode(false)
      : updateRegionModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteBankClick = () => {
    setIsModalOpen(false);
    updateRegionModel.deleteRegion({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeRegionInfoForm region={chosenRegion[0] as Region} />
            ) : (
              <CurrentRegionView region={chosenRegion[0] as Region} />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? t("common.cancelEdit") : t("regions.editRegion")}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete region
              </Button>
            )}
          </>
        )}
      </AdminTemplate>
      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteBankClick}
        visible={isModalOpen}
        title="Delete region confirmation"
      >
        Delete "{chosenRegion[0]?.item?.name}" region?
      </Modal>
    </>
  );
};
