import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import { CreateLanguageInput } from "shared/api/apollo/__generated__";

const createLanguage = createEvent<CreateLanguageInput>();

const createLanguageFx = createEffect(async (input: CreateLanguageInput) => {
  const response = await requestClient.createLanguage({ input });
  return response.createLanguage;
});

const $isLoading = createLanguageFx.pending;

forward({
  from: createLanguage,
  to: createLanguageFx,
});

sample({
  clock: createLanguageFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createLanguageFx.doneData,
  fn: (doneData) => {
    document.location = `/dictionary/languages/${doneData.id}`;
  },
});

export const createLanguageModel = { $isLoading, createLanguage };
