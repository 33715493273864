import { Continent } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeDataFortable = (data: Continent[]): DataType[] => {
  const result: DataType[] = [];
  data.forEach((continent) => {
    result.push({
      continentName: continent?.item?.name,
      continentCode: continent?.item?.code,
      continentId: continent?.item?.id,
      key: continent?.item?.id,
    });
  });
  return result;
};
