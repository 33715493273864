import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { TokenDocument } from "./__generated__";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_PUBLIC_URL}/graphql`,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const authMiddleware = setContext(() => {
  const token = localStorage.getItem("@accessToken");
  return {
    headers: {
      authorization: token || "",
      timeout: 92000,
    },
  };
});

let pendingRequests: any = [];

export const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

export const getNewToken = () => {
  return client.mutate({
    fetchPolicy: "no-cache",
    mutation: TokenDocument,
    variables: {
      accessToken: localStorage.getItem("@accessToken"),
      refreshToken: localStorage.getItem("@refreshToken"),
    },
  });
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, retryLink, httpLink]),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
});
