import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  PaginationProps,
  Row,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { useStore } from "effector-react";

import { fiatCurrenciesModel } from "entities/index";

import { SearchTypeEnum } from "shared/config";

import { checkSearchParams, serializeData } from "../lib";
import { balancesModel } from "../model";
import { DataType } from "../config";

export const FiatBalances: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchPage = searchParams.get("page");
  const searchType = searchParams.get("searchType") || "accountId";

  const balances = useStore(balancesModel.$balances);
  const isAccountsOnLoad = useStore(balancesModel?.$isLoading);
  const fiatCurrencies = useStore(fiatCurrenciesModel?.$fiatCurrencies);
  const fiatAccountCount = useStore(balancesModel?.$accountsCount);

  const [form] = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "accountId");
      setSearchParams(searchParams);
    }
  }, []);

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const [isZeroHidden, setIsZeroHidden] = useState<boolean>(false);

  useEffect(() => {
    balancesModel.getBalances(checkSearchParams(searchParams));
    if (searchParams.get("id")) {
      setIsSearchResult(true);
    }
  }, [currentSearchPage]);

  const handleSearchClick = (values: { id: string }) => {
    if (values?.id) {
      searchParams.set("id", values?.id);
      searchParams.delete("page");
      setSearchParams(searchParams);
    }
    setSearchParams(searchParams);
    balancesModel?.getBalances(checkSearchParams(searchParams));
    if (values?.id || searchParams.get("onlyPositive")) {
      setIsSearchResult(true);
    }

    form.resetFields();
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("page");
    searchParams.delete("onlyPositive");
    if (searchType === "currencyId") {
      searchParams.set("searchType", "accountId");
    }
    setSearchParams(searchParams);
    setIsZeroHidden(false);
    balancesModel.getBalances(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleSearchByFiatClick = (fiatId: string) => {
    searchParams.set("id", fiatId.toString());
    searchParams.set("searchType", "currencyId");
    setSearchParams(searchParams);
    balancesModel.getBalances({
      filter: { limit: 10 },
      currencyIds: [fiatId?.toString()],
    });
    setIsSearchResult(true);
  };

  const handleHideZeroBalancesClick = () => {
    setIsZeroHidden((prev) => !prev);
    if (!isZeroHidden) {
      searchParams.set("onlyPositive", "true");
      setSearchParams(searchParams);
    } else {
      searchParams.delete("onlyPositive");
      setSearchParams(searchParams);
    }
    if (searchParams.get("searchType") && searchParams.get("id")) {
      balancesModel.getBalances(checkSearchParams(searchParams));
    }
  };

  const handleMenuItemClick = (menuItem: ItemType) => {
    searchParams.set("searchType", menuItem?.key as string);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const dropdownItems = (
    <Menu
      onClick={handleMenuItemClick}
      items={[
        {
          label: t("fiatAccountsBlock.searchBy", {
            searchType: t(`fiatAccountsBlock.accountId`),
          }),
          key: SearchTypeEnum.ACCOUNT_ID,
        },
        {
          label: t("fiatAccountsBlock.searchBy", {
            searchType: t(`fiatAccountsBlock.userId`),
          }),
          key: SearchTypeEnum.USER_ID,
        },
        {
          label: t("fiatAccountsBlock.searchBy", {
            searchType: t(`fiatAccountsBlock.walletId`),
          }),
          key: SearchTypeEnum.WALLET_ID,
        },
        {
          label: t("fiatAccountsBlock.searchBy", {
            searchType: t(`fiatAccountsBlock.accountNumber`),
          }),
          key: SearchTypeEnum.ACCOUNT_NUMBER,
        },
      ]}
    />
  );

  const columns: ColumnsType<DataType> = [
    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      sorter: (a, b) => {
        if (a.accountName > b.accountName) {
          return -1;
        } else if (a.accountName < b.accountName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Account balance",
      dataIndex: "accountBalance",
      key: "accountBalance",
      sorter: (a, b) =>
        Number(a.accountBalance.split(" ")[0]) -
        Number(b.accountBalance.split(" ")[0]),
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.accountId > b.accountId) {
          return -1;
        } else if (a.accountId < b.accountId) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Holder ID",
      dataIndex: "holderId",
      key: "holderId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.holderId > b.holderId) {
          return -1;
        } else if (a.holderId < b.holderId) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Wallet ID",
      dataIndex: "walletId",
      key: "walletId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => Number(a.walletId) - Number(b.walletId),
    },
    {
      title: "Account number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
      sorter: (a, b) => {
        if (a.accountNumber > b.accountNumber) {
          return -1;
        } else if (a.accountNumber < b.accountNumber) {
          return 1;
        }
        return 0;
      },
    },
  ];

  const handleTableItemClick = (id: string) => {
    navigate(`/balances/fiat/${id}`);
  };

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  return (
    <Col>
      <Row>
        <Form form={form} name="search" onFinish={handleSearchClick}>
          <Row gutter={[10, 10]}>
            <Col>
              <Dropdown overlay={dropdownItems}>
                <Button>
                  {searchType === "currencyId"
                    ? "Currency id"
                    : t(`fiatAccountsBlock.${searchType}`)}
                </Button>
              </Dropdown>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: "10px" }} required name="id">
                <Input
                  disabled={searchType === "currencyId"}
                  placeholder={
                    searchType === "currencyId"
                      ? "Currency id"
                      : t("fiatAccountsBlock.searchBy", {
                          searchType: t(`fiatAccountsBlock.${searchType}`),
                        })
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: "10px" }}>
                <Button
                  loading={isAccountsOnLoad}
                  disabled={isAccountsOnLoad || searchType === "currencyId"}
                  htmlType="submit"
                  type="primary"
                >
                  {t("fiatAccountsBlock.search")}
                </Button>
              </Form.Item>
            </Col>
            {fiatCurrencies.map((currency) => {
              return (
                <Col>
                  <Button
                    key={currency?.id}
                    loading={isAccountsOnLoad}
                    disabled={isAccountsOnLoad}
                    type="default"
                    onClick={() =>
                      handleSearchByFiatClick(currency?.id as string)
                    }
                  >
                    {currency?.ticker}
                  </Button>
                </Col>
              );
            })}
            <Col>
              <Button
                key="onlyPositive"
                loading={isAccountsOnLoad}
                disabled={isAccountsOnLoad}
                type={isZeroHidden ? "default" : "primary"}
                onClick={handleHideZeroBalancesClick}
              >
                {isZeroHidden
                  ? t("fiatAccountsBlock.allBalances")
                  : t("fiatAccountsBlock.onlyPositiveBalances")}
              </Button>
            </Col>
          </Row>
        </Form>
        <Col>
          {isSearchResult && (
            <Button
              key="resetSearch"
              style={{ marginLeft: "10px" }}
              onClick={handleResetSearchResultClick}
              loading={isAccountsOnLoad}
              type="primary"
            >
              {t("fiatAccountsBlock.resetSearch")}
            </Button>
          )}
        </Col>
      </Row>
      <Table
        style={{ marginBottom: "10px", userSelect: "none" }}
        onRow={(record) => {
          return {
            style: { cursor: "pointer" },
            onClick: () => handleTableItemClick(record?.accountId),
          };
        }}
        loading={isAccountsOnLoad}
        pagination={false}
        columns={columns}
        dataSource={serializeData(balances)}
      />
      <Pagination
        disabled={isAccountsOnLoad}
        style={{ userSelect: "none" }}
        current={Number(currentSearchPage) ? Number(currentSearchPage) : 1}
        defaultCurrent={Number(currentSearchPage)}
        onChange={handlePageClick}
        total={fiatAccountCount}
        showSizeChanger={false}
      />
    </Col>
  );
};
