import { ContinentsBlock } from "features";
import React from "react";
import { AdminTemplate } from "shared/templates";

export const DictionaryContinentsPage: React.FC = () => {
  return (
    <AdminTemplate>
      <ContinentsBlock />
    </AdminTemplate>
  );
};
