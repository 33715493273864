import { Account } from "shared/api/apollo/__generated__";

export const serializeData = (balance: Account): Account => {
  return {
    name: balance?.name,
    id: balance?.id,
    walletId: balance?.walletId,
    accountNumber: balance?.accountNumber,
    userId: balance?.userId,
    balance: balance?.balance,
    type: balance?.type,
    internalType: balance?.internalType,
    state: balance?.state,
    reasonForInactive: balance?.reasonForInactive,
    createdAt: balance?.createdAt,
    holderType: balance?.holderType,
    absAccountId: balance?.absAccountId,
    absAccountNickname: balance?.absAccountNickname,
    absAccountStatus: balance?.absAccountStatus,
    absAccountType: balance?.absAccountType,
    common: balance?.common,
    currency: balance?.currency,
  };
};
