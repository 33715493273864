import {
    CardTransaction,
  CashTransaction,
  CryptoTransaction,
  EarnTransaction,
  TransactionWithRepeatable,
} from "shared/api/apollo/__generated__";
import { Result } from "../model";

export const serializeAccounts = (accountList: TransactionWithRepeatable[]) => {
  let result: Result = { crypto: [], cash: [], card: [], earn: [] };
  accountList.forEach((account) => {
    if (account?.transaction.__typename === "CryptoTransaction") {
      result?.crypto?.push(account as unknown as CryptoTransaction);
    } else if (account?.transaction.__typename === "CardTransaction") {
      result?.card?.push(account as unknown as CardTransaction);
    } else if (account?.transaction.__typename === "CashTransaction") {
      result?.cash?.push(account as unknown as CashTransaction);
    } else {
      result?.earn?.push(account as unknown as EarnTransaction);
    }
  });
  return result;
};
