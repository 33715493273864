import React from "react";

import { LanguagesBlock } from "features";

import { AdminTemplate } from "shared/templates";

export const DictionaryLanguagesPage: React.FC = () => {
  return (
    <AdminTemplate>
      <LanguagesBlock />
    </AdminTemplate>
  );
};
