import { Button, Modal, Spin } from "antd";
import { useStore } from "effector-react";
import { CurrentContinentView } from "entities/index";
import { ChangeContinentInfoForm } from "features";
import { updateContinentModel } from "features/ChangeContinentInfoForm/model";
import { continentsModel } from "features/ContinentsBlock/model";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Continent } from "shared/api/apollo/__generated__";
import { AdminTemplate } from "shared/templates";

export const CurrentContinentPage: React.FC = () => {
  const params = useParams();

  const isLoading = useStore(continentsModel.$isLoading);
  const chosenContinent = useStore(continentsModel.$continents);
  const isChangeOnLoad = useStore(updateContinentModel.$isLoading);
  const isEditMode = useStore(updateContinentModel.$isChangeMode);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    continentsModel.getContinents({ filter: { ids: [params?.id as string] } });
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      updateContinentModel.setIsChangeMode(false);
    };
  }, []);

  const handleEditButtonClick = () => {
    isEditMode
      ? updateContinentModel.setIsChangeMode(false)
      : updateContinentModel.setIsChangeMode(true);
  };

  const handleOpenModalClick = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleDeleteContinentClick = () => {
    setIsModalOpen(false);
    updateContinentModel.deleteContinent({ ids: [params?.id as string] });
  };

  return (
    <>
      <AdminTemplate>
        {isLoading || isChangeOnLoad ? (
          <Spin />
        ) : (
          <>
            {isEditMode ? (
              <ChangeContinentInfoForm
                continent={chosenContinent[0] as Continent}
              />
            ) : (
              <CurrentContinentView
                continent={chosenContinent[0] as Continent}
              />
            )}
            <Button onClick={handleEditButtonClick}>
              {isEditMode ? "Cancel edit" : "Edit continent info"}
            </Button>
            {!isEditMode && (
              <Button
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => handleOpenModalClick(true)}
              >
                Delete continent
              </Button>
            )}
          </>
        )}
      </AdminTemplate>

      <Modal
        onCancel={handleCancelClick}
        onOk={handleDeleteContinentClick}
        visible={isModalOpen}
        title="Delete continents confirmation"
      >
        Delete "{chosenContinent[0]?.item?.name}" continent?
      </Modal>
    </>
  );
};
