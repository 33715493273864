import React from "react";

import { useTranslation } from "react-i18next";
import { Descriptions, Tag } from "antd";

import { Bank } from "shared/api/apollo/__generated__";

import { serializeBankInfo } from "../lib";

interface CurrentBankProps {
  bank: Bank;
}

export const CurrentBankView: React.FC<CurrentBankProps> = ({ bank }) => {
  const { t } = useTranslation();

  const serializedBankInfo = serializeBankInfo(bank);

  return (
    <Descriptions
      layout="horizontal"
      bordered
      size="small"
      column={1}
      title={t("bankInfo.title")}
      key="bankCardInfo"
      style={{ minWidth: "600px", maxWidth: "800px", marginBottom: "10px" }}
    >
      {serializedBankInfo && (
        <>
          {Object.entries(serializedBankInfo).map((bankInfo) => {
            if (!bankInfo[0]?.includes("__")) {
              if (Array.isArray(bankInfo[1])) {
                return (
                  <Descriptions.Item
                    key={bankInfo[0]}
                    label={t(`bankInfo.${bankInfo[0]}`)}
                  >
                    {bankInfo[1]?.map((tag) => (
                      <Tag>{tag}</Tag>
                    ))}
                  </Descriptions.Item>
                );
              }
              return (
                <Descriptions.Item
                  key={bankInfo[0]}
                  label={t(`bankInfo.${bankInfo[0]}`)}
                >
                  {bankInfo[1]?.toString() || "-"}
                </Descriptions.Item>
              );
            }
          })}
        </>
      )}
    </Descriptions>
  );
};
