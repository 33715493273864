import { WalletObject } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeData = (filteredData: WalletObject[]): DataType[] => {
  let result: DataType[] = [];
  filteredData.forEach((balance) =>
    result.push({
      address: balance?.address,
      balance: balance?.balance?.current as string,
      coin: balance?.token?.coin,
      holderId: balance?.userId,
      balanceId: balance?.id,
      key: balance?.id,
    })
  );
  return result;
};
