import React from "react";
import { Tabs } from "antd";

import { CryptoBalances, FiatBalances } from "features";

import { AdminTemplate } from "shared/templates";

const { TabPane } = Tabs;

export const BalancesPage: React.FC = () => {
  return (
    <AdminTemplate>
      <h1>Balances</h1>
      <Tabs defaultActiveKey="fiatBalances">
        <TabPane tab="Fiat balances" key="fiatBalances">
          <FiatBalances />
        </TabPane>
        <TabPane tab="Crypto balances" key="cryptoBalances">
          <CryptoBalances />
        </TabPane>
      </Tabs>
    </AdminTemplate>
  );
};
