import { notification } from "antd";
import { createEffect } from "effector";

export interface ToastBodyProps {
  text: string;
}

const showErrorToast = createEffect(({ text }: ToastBodyProps) => {
  notification.error({ message: "Something went wrong!", description: text });
});

const showSuccessToast = createEffect(({ text }: ToastBodyProps) => {
  notification.success({
    message: "Operation completed successfully!",
    description: text,
  });
});

export const toastModel = { showErrorToast, showSuccessToast };
