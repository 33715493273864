import { Account } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeData = (filteredData: Account[]): DataType[] => {
  let result: DataType[] = [];
  filteredData.forEach((balance) =>
    result.push({
      accountNumber: balance?.accountNumber,
      accountName: balance?.name,
      accountId: balance?.id,
      holderId: balance?.userId,
      walletId: balance?.walletId,
      accountBalance: `${balance?.balance?.available?.amount} ${balance?.currency?.ticker}`,
      key: balance?.id,
    })
  );
  return result;
};
