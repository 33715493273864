import { CommonDictItem, Language } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeDataForTable = (data: Language[]): DataType[] => {
  const result: DataType[] = [];
  data.forEach((language) => {
    result.push({
      name: language?.item.name,
      id: language?.id,
      isDeleted: language?.item.common?.isDeleted.toString(),
      createdAt: language?.item.common?.timestamps?.createdAt,
      updatedAt: language?.item.common?.timestamps?.updatedAt,
      key: language?.id,
    });
  });
  return result;
};
