import { Region } from "shared/api/apollo/__generated__";
import { DataType } from "../config";

export const serializeDataForTable = (data: Region[]): DataType[] => {
  const result: DataType[] = [];
  data.forEach((region) =>
    result.push({
      key: region?.item?.id,
      regionName: region?.item?.name,
      regionId: region?.item?.id,
      regionCountry: region?.country?.item?.name as string,
      regionCountryId: region?.country?.item?.id as string,
    })
  );
  return result;
};
