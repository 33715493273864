import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";
import { requestClient } from "shared/api/apollo/requestClient";
import { CreateContinentInput } from "shared/api/apollo/__generated__";

const createContinent = createEvent<CreateContinentInput>();

const createContinentFx = createEffect(async (input: CreateContinentInput) => {
  const response = await requestClient.createContinent({ input });
  return response?.createContinent;
});

const $isLoading = createContinentFx.pending;

forward({
  from: createContinent,
  to: createContinentFx,
});

sample({
  clock: createContinentFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createContinentFx.doneData,
  fn: (doneData) =>
    (document.location = `/dictionary/continents/${doneData.item.id}`),
});

export const createContinentModel = { $isLoading, createContinent };
