import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from "effector";
import { requestClient } from "shared/api/apollo/requestClient";
import {
  SearchWalletsInput,
  WalletObject,
} from "shared/api/apollo/__generated__";

const getCryptoBalances = createEvent<SearchWalletsInput>();

const getCryptoBalancesFx = createEffect(async (input: SearchWalletsInput) => {
  const response = await requestClient.searchCryptoWallets({ input });
  return response?.searchWallets;
});

const $cryptoBalances = createStore<WalletObject[]>([]);
const $cryptoBalancesCount = createStore<number>(0);
const $isLoading = getCryptoBalancesFx.pending;

sample({
  clock: getCryptoBalances,
  target: getCryptoBalancesFx,
});

sample({
  clock: getCryptoBalancesFx.doneData,
  fn: (newBalances): WalletObject[] => {
    return [...(newBalances?.nodes as WalletObject[])];
  },
  target: $cryptoBalances,
});

sample({
  clock: getCryptoBalancesFx.doneData,
  fn: (balances) => balances?.count,
  target: $cryptoBalancesCount,
});

export const cryptoBalancesModel = {
  getCryptoBalances,
  $cryptoBalances,
  $isLoading,
  $cryptoBalancesCount,
};
