import { createEvent, restore } from "effector";
import { SearchTransactionsInput } from "shared/api/apollo/__generated__";

const setTransactionsFilters = createEvent<SearchTransactionsInput>();
const resetTransactionsFilters = createEvent();

const $transactionsFilters = restore(setTransactionsFilters, {
  filter: { limit: 10 },
}).reset(resetTransactionsFilters);

export const transactionsFiltersModel = {
  $transactionsFilters,
  setTransactionsFilters,
  resetTransactionsFilters,
};
