import React, { useEffect, useState } from "react";

import { useStore } from "effector-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Button,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Row,
  DatePicker,
  Col,
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import Paragraph from "antd/lib/typography/Paragraph";

import { allTransactionsModel } from "../model";
import { checkSearchParams, serializeTransInfo } from "../lib";
import { DataType } from "../config";

export const AllTransactionsBlock: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchPage = searchParams.get("page");

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  useEffect(() => {
    if (!searchParams.get("searchType")) {
      searchParams.set("searchType", "transId");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    allTransactionsModel.getAllTransactions(checkSearchParams(searchParams));
    if (searchParams.get("id") || searchParams.get("from")) {
      setIsSearchResult(true);
    }
  }, [currentSearchPage]);

  const allTransactions = useStore(allTransactionsModel?.$allTransactions);
  const transactionsCount = useStore(allTransactionsModel?.$transactionsCount);
  const isLoading = useStore(allTransactionsModel?.$isTransactionsOnLoad);

  const handlePageClick: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleTransactionClick = (id: string) => {
    navigate(`/transactions/${id}`);
  };

  const handleSearchClick = (values: { id: string }) => {
    if (values?.id) {
      searchParams.set("id", values?.id as string);
    }
    searchParams.delete("page");
    setSearchParams(searchParams);
    allTransactionsModel.getAllTransactions(checkSearchParams(searchParams));
    setIsSearchResult(true);
    form.resetFields();
  };

  const handleResetSearchResultClick = () => {
    searchParams.delete("id");
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("page");
    searchParams.set("searchType", "transId");
    setSearchParams(searchParams);
    allTransactionsModel.getAllTransactions(checkSearchParams(searchParams));
    setIsSearchResult(false);
  };

  const handleChoseFromDate = (values: unknown, dates: unknown[]) => {
    console.log(values);
    searchParams.set("from", dates[0] as string);
    searchParams.set("to", dates[1] as string);
    setSearchParams(searchParams);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("allTransactions.type"),
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        if (a.type > b.type) {
          return -1;
        } else if (a.type < b.type) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("allTransactions.amount"),
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) =>
        Number(a.amount.split(" ")[0]) - Number(b.amount.split(" ")[0]),
    },
    {
      title: t("allTransactions.category"),
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => {
        if (a.category > b.category) {
          return -1;
        } else if (a.category < b.category) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("allTransactions.transId"),
      dataIndex: "transId",
      key: "transId",
      render: (text) => (
        <Paragraph style={{ padding: 0, margin: 0 }} copyable>
          {text}
        </Paragraph>
      ),
    },
    {
      title: t("allTransactions.status"),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        if (a.status > b.status) {
          return -1;
        } else if (a.status < b.status) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("allTransactions.time"),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => {
        if (a.time > b.time) {
          return -1;
        } else if (a.time < b.time) {
          return 1;
        }
        return 0;
      },
    },
  ];

  return (
    <>
      <p>
        {t("allTransactions.totalCount")}: {transactionsCount}
      </p>
      <Form form={form} name="search" onFinish={handleSearchClick}>
        <Row gutter={[10, 10]}>
          <Col>
            <Form.Item>
              <RangePicker
                format={"MM.DD.YYYY"}
                onChange={handleChoseFromDate}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="id">
              <Input placeholder={t("allTransactions.searchByTransId")} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                loading={isLoading}
                disabled={isLoading}
                htmlType="submit"
                type="primary"
              >
                {t("common.search")}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {isSearchResult && (
              <Button
                key="resetSearch"
                onClick={handleResetSearchResultClick}
                loading={isLoading}
                type="primary"
              >
                {t("common.resetSearch")}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
      <Table
        style={{ marginBottom: "10px", userSelect: "none" }}
        onRow={(record) => {
          return {
            style: { cursor: "pointer" },
            onClick: () => handleTransactionClick(record?.transId as string),
          };
        }}
        pagination={false}
        loading={isLoading}
        columns={columns}
        dataSource={serializeTransInfo(allTransactions)}
      />
      <Pagination
        style={{ userSelect: "none" }}
        current={Number(currentSearchPage) ? Number(currentSearchPage) : 1}
        defaultCurrent={Number(currentSearchPage)}
        onChange={handlePageClick}
        total={transactionsCount}
        showSizeChanger={false}
      />
    </>
  );
};
