import React from "react";

import { TransactionWithRepeatable } from "shared/api/apollo/__generated__";

import { serializeCardTransaction } from "../lib";
import { CardTransactionView } from "./CardTransactionView";
import { CashTransactionView } from "./CashTransactionView";
import { CryptoTransactionView } from "./CryptoTransactionView";
import { EarnTransactionView } from "./EarnTransactionView";

interface TransactionProps {
  transaction: TransactionWithRepeatable;
}

export const CurrentTransactionView: React.FC<TransactionProps> = ({
  transaction,
}) => {
  const transactionType = transaction?.transaction?.__typename;

  return (
    <>
      {transactionType === "CashTransaction" && (
        <CashTransactionView cashTransaction={transaction?.transaction} />
      )}
      {transactionType === "CryptoTransaction" && (
        <CryptoTransactionView cryptoTransaction={transaction?.transaction} />
      )}
      {transactionType === "EarnTransaction" && (
        <EarnTransactionView earnTransaction={transaction?.transaction} />
      )}
      {transactionType === "CardTransaction" && (
        <CardTransactionView
          cardTransaction={serializeCardTransaction(transaction?.transaction)}
        />
      )}
    </>
  );
};
