import { createEffect, createEvent, forward, sample } from "effector";
import { toastModel } from "entities/index";

import { requestClient } from "shared/api/apollo/requestClient";
import { CreateRegionInput } from "shared/api/apollo/__generated__";

const createRegion = createEvent<CreateRegionInput>();

const createRegionFx = createEffect(async (input: CreateRegionInput) => {
  const response = await requestClient.createRegion({ input });
  return response.createRegion;
});

const $isLoading = createRegionFx.pending;

forward({
  from: createRegion,
  to: createRegionFx,
});

sample({
  clock: createRegionFx.failData,
  fn: (failData) => ({ text: failData.message }),
  target: toastModel.showErrorToast,
});

sample({
  clock: createRegionFx.doneData,
  fn: (doneData) => {
    document.location = `/dictionary/regions/${doneData.item?.id}`;
  },
});

export const createRegionModel = { $isLoading, createRegion };
